<template>
    <div class="s-form-product">
        <h1 class="app-title text-center mb-1">ACTUALIZAR PRECIO</h1>
        <MLineInformationComp
            title="Producto:"
            :darkMode="false"
            :subTitle="dataPrice.product"
        />
        <MLineInformationComp
            :darkMode="false"
            title="Precio Actual:"
            :subTitle="dataPrice.sale_price.toFixed(2)"
        />
        <v-divider :class="mobile ? 'mt-1 mb-3' : 'mt-1 mb-2'"></v-divider>
        <v-form
            v-model="valid"
            lazy-validation
            ref="formprice"
        >
            <ORowFormComp
                :mobile="mobile"
                informationRow="Nuevo Precio Venta:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Nuevo Precio Venta' : ''"
                    v-model="newPrice"
                    ref="price"
                    :counter="10"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El precio es requerido',
                        value => this.mRuleNumberDecimal(value) || 'El precio debe ser un número',
                        value => (`${value}`).length<=10 || 'El precio, no debe exceder los 10 dígitos'
                    ]"
                />
            </ORowFormComp>
        </v-form>
        <div class="d-flex justify-center mt-1">
            <MBtnNormalComp
                @click="eValidForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp,
    MLineInformationComp
} from "../molecules";
import { ORowFormComp } from "../organisms";
import { rulesMixin } from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        MTextFieldComp,
        MBtnNormalComp,
        ORowFormComp,
        MLineInformationComp
    },
    props: {
        mobile: Boolean,
        dataPrice: Object
    },
    data: () => ({
        valid: true,
        newPrice: null
    }),
    methods: {
        eValidForm() {
            var isValidForm = false;
            if (this.$refs.formprice.validate()) isValidForm = true;
            const parameters = {
                'isValidForm': isValidForm,
                'newPrice': this.newPrice
            }
            this.$emit('saveformprice', parameters);
        },
        resetForm() {
            this.newPrice = null;
            this.$refs.formprice.resetValidation();
        },
        activateFocus() {
            this.$refs.price.activateFocus();
        }
    }
}
</script>