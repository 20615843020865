import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';
class PaymentCompliance {
    constructor (
        payment_compliance = null,
        glos = null,
        fk_payment = null
    )
    {
        this.payment_compliance = payment_compliance;
        this.glos = glos;
        this.fk_payment  = fk_payment;
    }
    static async addPaymentCompliance(parameters) {
        const response = await axios.post(
            `${apiUrls.PAYMENT_COMPLIANCE}/add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editPaymentCompliance(parameters) {
        const response = await axios.put(
            `${apiUrls.PAYMENT_COMPLIANCE}/edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default PaymentCompliance;