<template>
    <div>
        <div class="pa-2 d-flex">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    VENTAS
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex aling-center">
                <OBtnToolTipHeaderComp
                    icono="mdi-cash-clock"
                    smsToolTip="Datos Generales"
                    class="mr-1"
                    @click="eGetGrossIncome()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    smsToolTip="Volver a ventas"
                    @click="tab=0"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap">
                    <div class="product__group-search d-flex justify-center pt-2 pl-2 pr-2">
                        <div class="product__search" style="margin-right:2px">
                            <MSelectComp
                                label="Caja Registradora"
                                :items="cashRegisters"
                                itemValue="id"
                                itemText="cash_register"
                                :hideDetails="true"
                                v-model="idCashRegister"
                                @change="eChangeCashRegister"
                            />
                        </div>
                        <div class="product__search" style="margin-left:2px">
                            <MSelectComp
                                label="Tipo Pago"
                                :items="itemsTypeSale"
                                itemValue="value"
                                itemText="name"
                                :hideDetails="true"
                                v-model="typeSale"
                                @change="eChangeTypeSale"
                            />
                        </div>
                    </div>
                    <div class="product__group-search d-flex justify-center pt-2 pl-2 pr-2">
                        <div class="product__search" style="margin-right:2px">
                            <MTextFieldComp
                                type="date"
                                label="Fecha Inicio"
                                :hideDetails="true"
                                v-model="dateOne"
                                :clearable="false"
                                @change="eChangeDateOne"
                            />
                        </div>
                        <div class="product__search" style="margin-left:2px">
                            <MTextFieldComp
                                type="date"
                                label="Fecha Final"
                                :hideDetails="true"
                                v-model="dateTwo"
                                :clearable="false"
                                @change="eChangeDateTwo"
                            />
                        </div>
                    </div>
                </div>
                <v-divider class="mt-2"></v-divider>
                <div class="pa-2 pt-1 pb-0">
                    <div class="d-flex">
                        <h1 class="app-title mr-1">Total Venta:</h1>
                        <h1 class="app-subtitle">
                            {{`${sumSales.toFixed(2)} Bs.`}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title mr-1">Cantidad Ventas:</h1>
                        <h1 class="app-subtitle">
                            {{total}}
                        </h1>
                    </div>
                </div>
                <v-divider></v-divider>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="salesList.length==0 && !dialogLoaderData && mobile"
                />
                <STableSaleComp
                    v-if="!dialogLoaderData && !mobile"
                    :salesList="salesList"
                    :isReport="true"
                    @clickdelete="eConfirmDeleteSale"
                    @clickdetail="eShowDetailSale"
                    @clickinfo="eShowInfoSale"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderData && mobile"
                >
                    <SCardReportSaleComp
                        class="ma-2"
                        v-for="sale in salesList"
                        :key="sale.id"
                        :sale="sale"
                        :isReport="true"
                        @clickdelete="eConfirmDeleteSale(sale)"
                        @clickdetail="eShowDetailSale(sale)"
                        @clickinfo="eShowInfoSale(sale)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="mobile && !dialogLoaderData && salesList.length>0"
                ></v-divider>
                <SPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderData && salesList.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2 pb-0 pt-1">
                    <OInfoSaleComp
                        :sale="sale"
                    />
                </div>
                <v-divider class="mt-1"></v-divider>
                <STableDetailReportSaleComp
                    v-if="!mobile"
                    :detailSale="sale.detail"
                    @clickinventory="eShowDetailExpirationDate"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-else
                >
                    <SCardDetailSaleComp
                        class="ma-2"
                        v-for="product in sale.detail"
                        :key="product.id"
                        :product="product"
                        @clickinventory="eShowDetailExpirationDate(product)"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <SDialogInventoriesMoveComp
            :value="dialogExpirationDate"
            :product="product"
            @clickclose="dialogExpirationDate=false"
        />
        <SInfomationSaleComp
            :value="dialogInfoSale"
            :sale="sale"
            @clickclose="dialogInfoSale=false"
        />
        <SDialogBalanceComp
            :value="dialogBalance"
            :totalSale="sumSales"
            :totalGroosIncome="grossIncome"
            @clickclose="dialogBalance=false"
        />
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="dialogConfirm=false"
            @clickyes="ePerformDatabaseAction()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MSelectComp,
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp,
    OLoaderDataComp,
    OInfoSaleComp
} from "../../components/organisms";
import {
    STableSaleComp,
    STableDetailReportSaleComp,
    SDialogInventoriesMoveComp,
    SInfomationSaleComp,
    SCardReportSaleComp,
    SCardDetailSaleComp,
    SPaginationComp,
    SDialogBalanceComp
} from "../../components/species";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    userMixin,
    animationScrollMixin,
    datesMixin,
    rulesMixin,
    printMixin
} from "../../mixins";
import {
    CashRegister,
    Sale
} from "../../models";
export default {
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        userMixin,
        animationScrollMixin,
        datesMixin,
        rulesMixin,
        printMixin
    ],
    props: {
        mini: Boolean
    },
    components: {
        OBtnToolTipHeaderComp,
        MTextFieldComp,
        MSelectComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        MNoDataComp,
        OLoaderDataComp,
        STableSaleComp,
        STableDetailReportSaleComp,
        OInfoSaleComp,
        SDialogInventoriesMoveComp,
        SInfomationSaleComp,
        SCardReportSaleComp,
        SCardDetailSaleComp,
        SPaginationComp,
        SDialogBalanceComp
    },
    data: () => ({
        tab: 0,
        dateOne: "",
        dateTwo: "",
        itemsTypeSale: [
            { name: "Todos", value: "a"},
            { name: "Efectivo", value: "e"},
            { name: "Qr", value: "q"},
            { name: "Tarjeta", value: "t"}
        ],
        typeSale: "a",
        cashRegisters: [
            { id: 0, cash_register: "Todos" }
        ],
        idCashRegister: 0,
        salesList: [],
        sumSales: 0,
        idSale: 0,
        indexSale: -1,
        sale: { 'detail': [] },
        product: {},
        dialogExpirationDate: false,
        dialogInfoSale: false,
        grossIncome: 0,
        dialogBalance: false
    }),
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else this.dialogError = false;
        },
        handleKeyUpReportSale(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 1) this.ePerformDatabaseAction();
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getRequest() {
            this.dialogLoaderData = true;
            try {
                const cashRegisters = CashRegister.getCashRegisters();
                const sales = Sale.reportSales(1, "", "", "a", 0, 0);
                const responses = await Promise.all([cashRegisters, sales]);
                responses[0].forEach(element => {
                    this.cashRegisters.push(element);
                });
                this.salesList = responses[1].data;
                this.lastPage = responses[1].last_page;
                this.currentPage = responses[1].current_page;
                this.total = responses[1].total;
                this.sumSales = responses[1].sum_sales;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async getSale(page, dateOne, dateTwo, typeSale, idCashRegister) {
            this.dialogLoaderData = true;
            try {
                const response = await Sale.reportSales(page, dateOne, dateTwo, typeSale, idCashRegister, 0);
                this.salesList = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.sumSales = response.sum_sales;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eConfirmDeleteSale(sale) {
            this.indexSale = this.salesList.indexOf(sale);
            this.idSale = sale.id;
            this.smsConfirm = [`¿${this.mUserFullName()}, está seguro(a) de anular la venta de: ${sale.client.full_name}?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.dialogConfirm = true;
            }, time);
        },
        async deleteSale() {
            try {
                await Sale.changeState({id: this.idSale});
                this.salesList.splice(this.indexSale, 1);
                this.dialogLoaderOperation = false;
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se anuló la venta"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            this.deleteSale();
        },
        eShowDetailSale(sale) {
            this.sale = sale;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eShowDetailExpirationDate(product) {
            this.product = product;
            this.dialogExpirationDate = true;
        },
        eShowInfoSale(sale) {
            this.sale = sale;
            this.dialogInfoSale = true;
        },
        eChangeDateOne(dateOne) {
            if (dateOne == "" || this.dateTwo == "")
                this.mDataGeneralShowSmsAction(["Error de Fechas", "Seleccione bien las fechas"], "error");
            else {
                if (dateOne > this.dateTwo) this.dateTwo = dateOne;
                this.getSale(1, dateOne, this.dateTwo, this.typeSale, this.idCashRegister);
            }
        },
        eChangeDateTwo(dateTwo) {
            if (dateTwo == "" || this.dateOne == "")
                this.mDataGeneralShowSmsAction(["Error de Fechas", "Seleccione bien las fechas"], "error");
            else {
                if (dateTwo < this.dateOne) this.dateOne = dateTwo;
                this.getSale(1, this.dateOne, dateTwo, this.typeSale, this.idCashRegister);
            }
        },
        eChangeTypeSale(typeSale) {
            this.getSale(1, this.dateOne, this.dateTwo, typeSale, this.idCashRegister);
        },
        eChangeCashRegister(idCashRegister) {
            this.getSale(1, this.dateOne, this.dateTwo, this.typeSale, idCashRegister);
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getSale(this.currentPage, this.dateOne, this.dateTwo, this.typeSale, this.idCashRegister);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getSale(page, this.dateOne, this.dateTwo, this.typeSale, this.idCashRegister);
                }, time);
            }
        },
        async eGetGrossIncome() {
            this.dialogLoaderOperation = true;
            try {
                const response = await Sale.grossIncome(this.dateOne, this.dateTwo, this.typeSale, this.idCashRegister);
                this.grossIncome = response;
                this.dialogLoaderOperation = false;
                this.dialogBalance = true;
            } catch(error) {
                this.processErrorServer(error);
            }
        }
    },
    mounted() {
        this.dateOne = this.dateTwo = this.mDateSystem(false);
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpReportSale);
                this.getRequest();
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpReportSale);
    }
}
</script>