var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-form-product"},[(!_vm.aloneForm)?_c('h1',{staticClass:"app-title text-center mb-2"},[_vm._v(" "+_vm._s(_vm.indexPurchase===-1 ? 'REGISTRAR' : 'EDITAR')+" ")]):_vm._e(),_c('v-form',{ref:"formpurchase",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('ORowFormComp',{ref:"billreceipt",attrs:{"mobile":_vm.mobile,"informationRow":"Descuento Comercial:","classText":"s-form-purchase__input"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Descuento Comercial' : '',"rules":[
                    value => !this.mRuleEmptyField(value) || 'Es requerido',
                    value => this.mRuleNumberDecimal(value) || 'Debe ser un número'
                ]},model:{value:(_vm.dataPurchase.discount),callback:function ($$v) {_vm.$set(_vm.dataPurchase, "discount", $$v)},expression:"dataPurchase.discount"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Otro Descuento:","classText":"s-form-purchase__input"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Otro Descuento' : '',"rules":[
                    value => !this.mRuleEmptyField(value) || 'Es requerido',
                    value => this.mRuleNumberDecimal(value) || 'Debe ser un número'
                ]},model:{value:(_vm.dataPurchase.other_discount),callback:function ($$v) {_vm.$set(_vm.dataPurchase, "other_discount", $$v)},expression:"dataPurchase.other_discount"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"N° Recibo/Factura:","classText":"s-form-purchase__input"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'N° Recibo/Factura' : '',"counter":30,"rules":[
                    value => !this.mRuleEmptyField(value) || 'El N° Recibo/Factura es requerido',
                    value => (`${value}`).length<=30 || 'El N° Recibo/Factura, no debe exceder los 30 dígitos'
                ]},model:{value:(_vm.dataPurchase.bill_receipt),callback:function ($$v) {_vm.$set(_vm.dataPurchase, "bill_receipt", $$v)},expression:"dataPurchase.bill_receipt"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Fecha Compra:","classText":"s-form-purchase__input"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Fecha Compra' : '',"type":"date","rules":[
                    value => !this.mRuleEmptyField(value) || 'El descuento es requerido'
                ]},model:{value:(_vm.dataPurchase.date_purchase),callback:function ($$v) {_vm.$set(_vm.dataPurchase, "date_purchase", $$v)},expression:"dataPurchase.date_purchase"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Glosa:","classText":"s-form-purchase__input"}},[_c('MTextAreaComp',{attrs:{"label":_vm.mobile ? 'Glosa' : ''},model:{value:(_vm.dataPurchase.gloss),callback:function ($$v) {_vm.$set(_vm.dataPurchase, "gloss", $$v)},expression:"dataPurchase.gloss"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Proveedor:","classText":"s-form-purchase__input"}},[_c('div',{class:_vm.dataPurchase.activate_error && _vm.dataPurchase.fk_provider == null
                    ? 'product__search-laboratories product__search-error d-flex align-center pl-3'
                    : 'product__search-laboratories d-flex align-center pl-3 mb-2',on:{"click":function($event){return _vm.$emit('clickprovider')}}},[(_vm.mobile)?_c('h2',[_vm._v("Proveedor")]):_vm._e(),_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(_vm.dataPurchase.fk_provider==null ? 'Sin elección' : _vm.dataPurchase.provider.provider)+" ")])])]),(_vm.dataPurchase.activate_error && _vm.dataPurchase.fk_provider == null)?_c('div',{staticClass:"d-flex justify-end"},[_c('div',{class:!_vm.mobile ? 's-form-purchase__input' : ''},[_c('h3',{staticClass:"app-error-form"},[_vm._v(" El proveedor es requerido ")])])]):_vm._e()],1),_c('div',{staticClass:"d-flex justify-center mt-2"},[_c('MBtnNormalComp',{on:{"click":function($event){return _vm.eValidForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }