<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Total Precio</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Dcto. Cial.</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Otro Dcto.</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Total Compra</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Ganancia</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">N° Rec./Fac.</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha Compra</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Proveedor</h1>
                    </th>
                    <th width="150px">
                        <h1 class="app-title-dark text-center">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(purchase, index) in purchasesList"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{mPSumOfTotalsPrice(purchase.products, true).toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{purchase.discount.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{purchase.other_discount.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{mPTotalPurchaseWithDiscount(purchase).toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{mPTotalGain(purchase).toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <text-highlight
                            class="app-subtitle"
                            :queries="search"
                        >
                            {{purchase.bill_receipt}}
                        </text-highlight>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{mDateFormat(purchase.date_purchase)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{purchase.provider.provider}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            :smsToolTip="`Detalle de compra`"
                            icon="mdi-list-box"
                            class="mr-1"
                            @click="$emit('clickdetail', purchase)"
                        />
                        <OBtnToolTipTableComp
                            smsToolTip="Editar compra"
                            icon="mdi-pencil"
                            class="mr-1"
                            @click="$emit('clickedit', purchase)"
                        />
                        <OBtnToolTipTableComp
                            smsToolTip="Imprimir la compra"
                            icon="mdi-printer"
                            class="mr-1"
                            @click="$emit('clickprint', purchase)"
                        />
                        <OBtnToolTipTableComp
                            smsToolTip="Eliminar la compra"
                            icon="mdi-delete"
                            @click="$emit('clickdelete', purchase)"
                        />
                    </td>
                </tr>
                <tr v-if="purchasesList.length === 0">
                    <td colspan="10">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import { OBtnToolTipTableComp } from "../organisms";
import {
    datesMixin,
    purchaseMixin
} from "../../mixins";
export default {
    mixins: [
        datesMixin,
        purchaseMixin
    ],
    components: {
        OBtnToolTipTableComp,
        TextHighlight
    },
    props: {
        purchasesList: Array,
        search: String
    }
}
</script>