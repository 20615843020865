var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-form-product"},[_c('h1',{staticClass:"app-title text-center mb-2"},[_vm._v(" "+_vm._s(_vm.index==-1 ? "REGISTRAR" : "EDITAR")+" ")]),_c('v-form',{ref:"formclient",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Nombre/Empresa:"}},[_c('MTextFieldComp',{ref:"client",attrs:{"label":_vm.mobile ? 'Nombre/Empresa' : '',"counter":70,"rules":[
                    value => !this.mRuleEmptyField(value) || 'El nombre/empresa es requerido',
                    value => (`${value}`).length<=70 || 'El nombre/empresa no debe exceder los 70 dígitos'
                ]},model:{value:(_vm.dataClient.full_name),callback:function ($$v) {_vm.$set(_vm.dataClient, "full_name", $$v)},expression:"dataClient.full_name"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"CI/NIT:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'CI/NIT' : '',"counter":50,"rules":[
                    value => !this.mRuleEmptyField(value) || 'Es ci/nit es requerido',
                    value => !this.mRuleWithSpaces(value) || 'El ci/nit no debe contener espacios',
                    value => (`${value}`).length<=50 || 'El ci/nit no debe exceder los 50 dígitos'
                ]},model:{value:(_vm.dataClient.ci_nit),callback:function ($$v) {_vm.$set(_vm.dataClient, "ci_nit", $$v)},expression:"dataClient.ci_nit"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Complemento:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Complemento' : '',"counter":10,"rules":[
                    value => (`${value}`).length<=10 || 'El complemento no debe exceder los 10 dígitos'
                ]},model:{value:(_vm.dataClient.complement),callback:function ($$v) {_vm.$set(_vm.dataClient, "complement", $$v)},expression:"dataClient.complement"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Entidad:"}},[_c('MSelectComp',{attrs:{"label":_vm.mobile ? 'Entidad' : '',"items":_vm.items,"itemValue":"value","itemText":"name"},model:{value:(_vm.dataClient.entity),callback:function ($$v) {_vm.$set(_vm.dataClient, "entity", $$v)},expression:"dataClient.entity"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Celular:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Celular' : '',"counter":10,"rules":[
                    value => (`${value}`).length<=10 || 'El celular no debe exceder los 10 dígitos',
                    value => !_vm.eIsValidPhone(value) || 'El celular solo debe contener números'
                ]},model:{value:(_vm.dataClient.phone),callback:function ($$v) {_vm.$set(_vm.dataClient, "phone", $$v)},expression:"dataClient.phone"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Correo:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Correo' : '',"counter":50,"rules":[
                    value => (`${value}`).length<=50 || 'El correo no debe exceder los 50 dígitos'
                ]},model:{value:(_vm.dataClient.e_mail),callback:function ($$v) {_vm.$set(_vm.dataClient, "e_mail", $$v)},expression:"dataClient.e_mail"}})],1),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('MBtnNormalComp',{on:{"click":function($event){return _vm.eValidForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }