<template>
    <div class="s-form-product">
        <h1 class="app-title text-center mb-2">
            {{indexProduct==-1 ? "REGISTRAR" : "EDITAR"}}
        </h1>
        <v-form
            v-model="valid"
            lazy-validation
            ref="formproduct"
        >
            <ORowFormComp
                :mobile="mobile"
                informationRow="Producto:"
            >
                <MTextFieldComp
                    ref="product"
                    :label="mobile ? 'Producto' : ''"
                    v-model="dataProduct.product"
                    :counter="30"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El producto es requerido',
                        value => (`${value}`).length<=30 || 'El producto no debe exceder los 30 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Precio Venta:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Precio Venta' : ''"
                    v-model="dataProduct.sale_price"
                    :counter="10"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El precio es requerido',
                        value => this.mRuleNumberDecimal(value) || 'El precio debe ser un número',
                        value => (`${value}`).length<=10 || 'El precio, no debe exceder los 10 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Descuento máximo:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Descuento máximo' : ''"
                    v-model="dataProduct.max_discount"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El descuento es requerido',
                        value => this.mRuleNumberDecimal(value) || 'El descuento debe ser un número'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Código Barra:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Código Barra' : ''"
                    v-model="dataProduct.barcore"
                    :counter="30"
                    :rules="[
                        value => (`${value}`).length<=30 || 'El código barra, no debe exceder los 30 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Ubicación:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Ubicación' : ''"
                    v-model="dataProduct.location"
                    :counter="20"
                    :rules="[
                        value => (`${value}`).length<=20 || 'La ubicación, no debe exceder los 20 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Reg. Sanitario:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Reg. Sanitario' : ''"
                    v-model="dataProduct.health_register"
                    :counter="30"
                    :rules="[
                        value => (`${value}`).length<=30 || 'El reg. sanitario, no debe exceder los 30 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Composición:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Composición' : ''"
                    v-model="dataProduct.composition"
                    :counter="100"
                    :rules="[
                        value => (`${value}`).length<=100 || 'La composición, no debe exceder los 100 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Vía administración:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Vía administración' : ''"
                    v-model="dataProduct.via_administration"
                    :counter="30"
                    :rules="[
                        value => (`${value}`).length<=30 || 'La vía administración, no debe exceder los 30 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Tipo Producto:"
            >
                <MTextFieldComp
                    v-if="isNewTypeProduct"
                    :disabled="true"
                    :label="mobile ? 'Tipo Producto' : ''"
                    v-model="dataTypeProduct.type_product"
                />
                <MSelectComp
                    v-else
                    :items="typesProducts"
                    itemValue="id"
                    itemText="type_product"
                    :label="mobile ? 'Tipo Producto' : ''"
                    v-model="dataProduct.fk_type_product"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El tipo producto es requerido'
                    ]"
                />
                <OBtnToolTipHeaderComp
                    v-if="isNewTypeProduct"
                    icono="mdi-close"
                    class="ml-1 mt-1"
                    smsToolTip="Quitar nuevo tipo producto"
                    @click="$emit('clickdeletetypeproduct')"
                />
                <OBtnToolTipHeaderComp
                    v-else
                    icono="mdi-plus"
                    class="ml-1 mt-1"
                    smsToolTip="Nuevo Tipo Producto"
                    @click="$emit('clicktypeproduct')"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Descripción:"
            >
                <MTextAreaComp
                    :label="mobile ? 'Descripción' : ''"
                    v-model="dataProduct.description"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Laboratorio:"
            >
                <MTextFieldComp
                    v-if="isNewLaboratory"
                    :disabled="true"
                    :label="mobile ? 'Laboratorio' : ''"
                    v-model="dataLaboratory.laboratory"
                />
                <div
                    style="width:100%"
                    v-else
                >
                    <div
                        :class="dataProduct.activate_error && dataProduct.fk_laboratory == null
                            ? 'product__search-laboratories product__search-error d-flex align-center pl-3'
                            : 'product__search-laboratories d-flex align-center pl-3 mb-2'"
                        @click="$emit('clicklaboratoryform')"
                    >
                        <h2 v-if="mobile">Laboratorio</h2>
                        <h1 class="app-subtitle">
                            {{dataProduct.fk_laboratory==null ? 'Sin elección' : dataProduct.laboratory.laboratory}}
                        </h1>
                    </div>
                    <h3
                        class="app-error-form"
                        v-if="dataProduct.activate_error && dataProduct.fk_laboratory == null"
                    >
                        El laboratorio es requerido
                    </h3>
                </div>
                <OBtnToolTipHeaderComp
                    v-if="isNewLaboratory"
                    icono="mdi-close"
                    class="ml-1 mt-1"
                    smsToolTip="Nuevo Laboratorio"
                    @click="$emit('clickdeletelaboratory')"
                />
                <OBtnToolTipHeaderComp
                    v-else
                    icono="mdi-plus"
                    class="ml-1 mt-1"
                    smsToolTip="Nuevo Laboratorio"
                    @click="$emit('clicknewlaboratory')"
                />
            </ORowFormComp>
            <div
                :class="isNewLaboratory
                    ? 'd-flex justify-center'
                    : 'd-flex justify-center mt-2'"
            >
                <MBtnNormalComp
                    @click="eValidForm()"
                >
                    <v-icon>mdi-bookmark-check</v-icon>
                    GUARDAR
                </MBtnNormalComp>
            </div>
        </v-form>
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MSelectComp,
    MBtnNormalComp,
    MTextAreaComp
} from "../molecules";
import {
    ORowFormComp,
    OBtnToolTipHeaderComp
} from "../organisms";
import { rulesMixin } from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        MTextFieldComp,
        MBtnNormalComp,
        ORowFormComp,
        MSelectComp,
        MTextAreaComp,
        OBtnToolTipHeaderComp
    },
    props: {
        mobile: Boolean,
        dataProduct: Object,
        indexProduct: Number,
        typesProducts: Array,
        isNewTypeProduct: Boolean,
        dataTypeProduct: Object,
        isNewLaboratory: Boolean,
        dataLaboratory: Object
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.dataProduct.fk_laboratory == null)
                this.dataProduct.activate_error = true;
            if (this.$refs.formproduct.validate() && this.dataProduct.fk_laboratory != null)
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.formproduct.resetValidation();
        },
        activateFocus() {
            this.$refs.product.activateFocus();
        }
    }
}
</script>