<template>
    <div>
        <div class="d-none">
            <div ref="sheetpurchase">
                <SSheetPrintPurchaseComp
                    :purchase="purchase"
                />
            </div>
        </div>
        <div class="section-print">
            <div class="client__header pa-2">
                <div class="d-flex align-center">
                    <OBtnToolTipHeaderComp
                        v-if="!mobile"
                        :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                        smsToolTip="Ocultar Menú"
                        @click="$emit('clickmini')"
                    />
                    <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                        {{`COMPRAS (${total})`}}
                    </h1>
                </div>
                <v-spacer></v-spacer>
                <div class="d-flex align-center client__header-block-second">
                    <OSearchHeaderComp
                        label="N° Rec./Fac."
                        v-model="search"
                        class="mr-1"
                        @entersearch="eSearchPurchasesBillReceipts()"
                    />
                    <OBtnToolTipHeaderComp
                        icono="mdi-plus"
                        class="mr-1"
                        smsToolTip="Registrar Compra"
                        @click="tab=1"
                    />
                    <OBtnToolTipHeaderComp
                        icono="mdi-database"
                        smsToolTip="Volver a lista de compras"
                        @click="eBackTableList()"
                    />
                </div>
            </div>
            <div class="client__line-header" id="position-scroll"></div>
            <v-tabs-items
                style="background:transparent !important"
                v-model="tab"
                :touchless="true"
            >
                <v-tab-item>
                    <div class="d-flex justify-center pt-2 pl-2 pr-2">
                        <div class="product__search" style="margin-right:2px">
                            <MTextFieldComp
                                label="Inicio"
                                type="date"
                                :hideDetails="true"
                                v-model="dateOne"
                                @change="eChangeDateOne"
                            />
                        </div>
                        <div class="product__search" style="margin-left:2px">
                            <MTextFieldComp
                                label="Inicio"
                                type="date"
                                :hideDetails="true"
                                v-model="dateTwo"
                                @change="eChangeDateTwo"
                            />
                        </div>
                    </div>
                    <v-divider class="mt-2"></v-divider>
                    <OLoaderDataComp
                        class="mt-2"
                        v-if="dialogLoaderData"
                        smsLoaderData="Obteniendo Información"
                    />
                    <MNoDataComp
                        smsNotData="SIN DATOS"
                        class="ma-1"
                        v-if="purchasesList.length==0 && !dialogLoaderData && mobile"
                    />
                    <STablePurchasesComp
                        v-if="!dialogLoaderData && !mobile"
                        :purchasesList="purchasesList"
                        :search="searchFilter"
                        @clickdetail="eShowDetailPurchase"
                        @clickedit="eOpenFormEditPurchase"
                        @clickprint="ePrintPurchase"
                        @clickdelete="eConfirmDeletePurchase"
                    />
                    <div
                        class="d-flex justify-center flex-wrap"
                        v-if="!dialogLoaderData && mobile"
                    >
                        <SCardPurchaseComp
                            class="ma-2"
                            v-for="purchase in purchasesList"
                            :key="purchase.id"
                            :purchase="purchase"
                            :search="searchFilter"
                            @clickdetail="eShowDetailPurchase(purchase)"
                            @clickedit="eOpenFormEditPurchase(purchase)"
                            @clickprint="ePrintPurchase(purchase)"
                            @clickdelete="eConfirmDeletePurchase(purchase)"
                        />
                    </div>
                    <v-divider
                        class="mt-1"
                        v-if="mobile && !dialogLoaderData && purchasesList.length>0"
                    ></v-divider>
                    <SPaginationComp
                        class="mt-2 mb-2"
                        v-if="!dialogLoaderData && purchasesList.length > 0"
                        :condicionGo="currentPage < lastPage"
                        :condicionBack="currentPage > 1"
                        :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                        @clickback="eChangePage(false)"
                        @clickgo="eChangePage(true)"
                        @clickpage="eSearchForPage"
                    />
                </v-tab-item>
                <v-tab-item>
                    <EFormCompletPurchaseComp
                        :dataPurchase="dataPurchase"
                        :detailProducts="detailProducts"
                        :indexPurchase="-1"
                        :mobile="mobile"
                        ref="formcompletpuchase"
                        @clickprovider="eOpenDialogProviders()"
                        @clickproducts="eOpenFormSelectProduct(false)"
                        @saveForm="eConfirmFormAdd"
                        @deletedetail="eDeleteProductDetail"
                        @editdetail="eOpenFormEditDetail"
                    />
                </v-tab-item>
                <v-tab-item>
                    <div v-if="purchase.id != undefined" class="pa-2 pb-0 pt-1">
                        <OInfoPurchaseComp
                            :purchase="purchase"
                        />
                        <MBtnNormalComp
                            class="mt-1"
                            @click="eOpenFormSelectProduct(true)"
                        >
                            Añadir Producto
                        </MBtnNormalComp>
                    </div>
                    <STableProductPurchaseComp
                        v-if="!dialogLoaderData && !mobile"
                        :productPurchaseList="purchase.products"
                        @clickdetail="eShowDetailProduct"
                        @clickdelete="eCofirmDeleteProductPurchase"
                        @clickedit="eOpenFormProductDetail"
                    />
                    <div
                        class="d-flex justify-center flex-wrap"
                        v-if="!dialogLoaderData && mobile"
                    >
                        <SCardProductPurchaseComp
                            class="ma-2"
                            v-for="productPurchase in purchase.products"
                            :quantityInfo="purchase.products.length"
                            :key="productPurchase.id"
                            :productPurchase="productPurchase"
                            @clickdetail="eShowDetailProduct(productPurchase)"
                            @clickdelete="eCofirmDeleteProductPurchase(productPurchase)"
                            @clickedit="eOpenFormProductDetail(productPurchase)"
                        />
                    </div>
                    <OTotalesDetailPurchaseComp
                        class="pa-2 pt-0"
                        :detailProducts="purchase.products"
                        :dataPurchase="purchase"
                        :isDataBase="true"
                    />
                </v-tab-item>
                <v-tab-item>
                    <div class="d-flex justify-center pa-2">
                        <SFormPurchaseComp
                            ref="formpurchaseedit"
                            :mobile="mobile"
                            :dataPurchase="dataPurchase"
                            :indexPurchase="0"
                            :aloneForm="false"
                            @clickprovider="dialogProviders = true"
                            @saveform="eConfirmFormPurchaseEdit"
                        />
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
        <SDialogSearchProviderComp
            :valueDialog="dialogProviders"
            v-model="provider.search"
            :filterData="provider.filterData"
            ref="dialogproviders"
            :providersList="provider.filterData
                ? providersList : providersForm"
            :searchProvider="searchFilterProvider"
            :dialogLoaderData="provider.dialogLoaderData"
            @entersearch="eSearchProviders()"
            @clickclose="eCloseSearchProviders()"
            @clickprovider="eSelectProvider"
        />
        <EDialogProductPurchaseComp
            v-model="searchProduct.search"
            :valueDialog="dialogProducts"
            :productsList="productsList"
            :searchProduct="searchFilterProduct"
            :dataProductPurchase="dataProductPurchase"
            :dialogLoaderData="searchProduct.dialogLoaderData"
            ref="productpurchase"
            @entersearch="eSearchProducts()"
            @clickclose="eCloseSearchProduct()"
            @selectproduct="eSelectProduct"
        />
        <SDetailProductComp
            :value="dialogDetailProduct"
            :product="product"
            @clickclose="dialogDetailProduct=false"
        />
        <v-dialog
            v-model="dialogProductDetail"
            width="400"
            persistent
        >
            <v-card>
                <div class="s-card-client__header pl-2 pr-2 d-flex align-center">
                    <h1 class="app-title">
                        {{
                            isChangeDetailPurchase
                            ? 'Editar Producto'
                            : 'Editar compra Producto'
                        }}
                    </h1>
                    <v-spacer></v-spacer>
                    <OBtnCardComp
                        icon="mdi-close"
                        @click="eCloseEditProductDetail()"
                    />
                </div>
                <div class="client__line-header"></div>
                <div v-if="product.id != undefined" class="pa-2 pb-1 pt-1">
                    <div class="d-flex">
                        <h1 class="app-title mr-1">Producto:</h1>
                        <h1 class="app-subtitle">
                            {{product.product}}
                        </h1>
                    </div>
                </div>
                <v-divider></v-divider>
                <SFormProductPurchaseComp
                    class="pa-2 pt-3 pb-3"
                    :titleForm="isChangeDetailPurchase ? 'EDITAR' : 'GUARDAR'"
                    ref="formproductpurchasedialog"
                    :dataProductPurchase="dataProductPurchase"
                    @saveform="eConfirmEditProductDetail"
                />
            </v-card>
        </v-dialog>
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="eCloseDialogConfirm()"
            @clickyes="ePerformDatabaseAction()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MNoDataComp,
    MBtnNormalComp,
    MTextFieldComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSearchHeaderComp,
    OLoaderDataComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp,
    OBtnCardComp,
    OInfoPurchaseComp,
    OTotalesDetailPurchaseComp
} from "../../components/organisms";
import {
    SPaginationComp,
    STablePurchasesComp,
    SFormPurchaseComp,
    SDialogSearchProviderComp,
    STableProductPurchaseComp,
    SDetailProductComp,
    SFormProductPurchaseComp,
    SSheetPrintPurchaseComp,
    SCardPurchaseComp,
    SCardProductPurchaseComp,
    SFormProductComp
} from "../../components/species";
import {
    EFormCompletPurchaseComp,
    EDialogProductPurchaseComp
} from "../../components/ecosystems";
import {
    animationScrollMixin,
    responseServerMixin,
    dataGeneralCompsMixin,
    rulesMixin,
    datesMixin,
    printMixin,
    userMixin,
    purchaseMixin
} from "../../mixins";
import {
    Purchase,
    Provider,
    Product,
    ProductPurchase
} from "../../models";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        animationScrollMixin,
        responseServerMixin,
        dataGeneralCompsMixin,
        rulesMixin,
        datesMixin,
        printMixin,
        userMixin,
        purchaseMixin
    ],
    components: {
        MTextFieldComp,
        MBtnNormalComp,
        OBtnToolTipHeaderComp,
        OSearchHeaderComp,
        SPaginationComp,
        STablePurchasesComp,
        MNoDataComp,
        OLoaderDataComp,
        SFormPurchaseComp,
        EFormCompletPurchaseComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        SDialogSearchProviderComp,
        EDialogProductPurchaseComp,
        STableProductPurchaseComp,
        SDetailProductComp,
        SFormProductPurchaseComp,
        OBtnCardComp,
        SSheetPrintPurchaseComp,
        OInfoPurchaseComp,
        SCardPurchaseComp,
        SCardProductPurchaseComp,
        OTotalesDetailPurchaseComp,
        SFormProductComp
    },
    data: () => ({
        search: "",
        tab: 0,
        purchasesList: [],
        dataPurchase: new Purchase,
        detailProducts: [],
        dialogProviders: false,
        purchase: {'products': [], 'discount': 0, 'other_discount': 0},
        indexPurchase: -1,
        idPurchase: 0,
        provider: {
            id: 0,
            name: "",
            search: "",
            dialogLoaderData: false,
            filterData: false
        },
        providersList: [{ id:0, provider: "Todos" }],
        dialogDetailProduct: false,
        product: {},
        dialogProducts: false,
        searchProduct: {
            search: "",
            dialogLoaderData: false
        },
        productsList: [],
        isActionProductPurchase: false,
        dataProductPurchase: new ProductPurchase,
        indexProductPurchase: -1,
        isDeleteProductPurchase: false,
        dialogProductDetail: false,
        dateTwo: "",
        dateOne: "",
        isChangeDetailPurchase: false
    }),
    computed: {
        searchFilter: function() {
            return this.search !== null ? this.search : "";
        },
        providersForm: function() {
            return this.providersList.filter(provider => {
                return provider.id !== 0;
            });
        },
        searchFilterProvider: function() {
            return this.provider.search !== null ? this.provider.search : "";
        },
        searchFilterProduct: function() {
            return this.searchProduct.search !== null ? this.searchProduct.search : "";
        }
    },
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else {
                this.idPurchase = 0;
                this.dialogError = false;
                if (this.isActionProductPurchase) {
                    if (this.indexProductPurchase === -1)
                        this.dialogProducts = true;
                    else this.dialogProductDetail = true;
                }
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getPurchases(page, dateOne, dateTwo, idProvider, billReceipt) {
            this.dialogLoaderData = true;
            try {
                const response = await Purchase.getPurchase(page, dateOne, dateTwo, idProvider, billReceipt);
                this.purchasesList = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eBackTableList() {
            this.indexPurchase = -1;
            this.dataPurchase = new Purchase;
            if (this.tab === 1) {
                this.detailProducts = [];
                this.$refs.formcompletpuchase.resetForm();
            }
            if (this.tab === 3) this.$refs.formpurchaseedit.resetForm();
            this.tab = 0;
        },
        eConfirmFormAdd(information) {
            if (information.isValidForm) {
                const totalQuantity = this.mTotalQuantity(this.detailProducts);
                const discountTotal = parseFloat(this.dataPurchase.discount) + parseFloat(this.dataPurchase.other_discount);
                this.dataPurchase.unid_discount = discountTotal / totalQuantity;
                const totalPurchase = (this.mPTotalPurchase(this.detailProducts, false) - discountTotal).toFixed(2);
                this.smsConfirm = [`¿Está seguro(a) de registrar la compra por: ${totalPurchase} Bs.?`]
            } else {
                this.smsError = [information.smsError];
                this.actionError = true;
            }
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                if (information.isValidForm) this.dialogConfirm = true;
                else this.dialogError = true;
            }, time);
        },
        //products
        eOpenFormSelectProduct(state) {
            this.isActionProductPurchase = state;
            this.dialogProducts = true;
            setTimeout(() => {
                this.$refs.productpurchase.activateFocus();
            }, 150);
        },
        async eSearchProducts() {
            this.searchProduct.dialogLoaderData = true;
            if (this.mRuleEmptyField(this.searchProduct.search)) this.searchProduct.search = "";
            this.productsList = [];
            try {
                const response = await Product.getProducts(1, this.searchProduct.search, 1, 2, 0, 0);
                this.productsList = response.data;
                this.searchProduct.dialogLoaderData = false;
            } catch(error) {
                this.dialogProducts = false;
                this.processErrorServer(error);
            }
        },
        eCloseSearchProduct() {
            this.isActionProductPurchase = false;
            this.searchProduct.search = "";
            this.productsList = [];
            this.dataProductPurchase = new ProductPurchase;
            this.$refs.productpurchase.eBackListProducts();
            this.dialogProducts = false;
        },
        existProductDetail(id) {
            for(var i = 0; i < this.detailProducts.length; i++) {
                if (this.detailProducts[i].product_id === id) return true;
            }
            return false;
        },
        eSelectProduct(isValidForm) {
            if (isValidForm) {
                if (this.isActionProductPurchase) {
                    this.dialogProducts = false;
                    this.smsConfirm = [`¿Está seguro de añadir la compra de: ${this.dataProductPurchase.product}?`];
                    this.dialogConfirm = true;
                } else {
                    if (this.existProductDetail(this.dataProductPurchase.product_id))
                        this.mDataGeneralShowSmsAction(["Acción Erronea", "Ya se añadio el producto"], "error");
                    else {
                        this.detailProducts.unshift(this.dataProductPurchase);
                        this.eCloseSearchProduct();
                        this.mDataGeneralShowSmsAction(["Acción realizada", "Se añadio el producto"], "success");
                    }
                }
            } else
                this.mDataGeneralShowSmsAction(["Formulario Erroneo", "Corrija los campos rojos"], "error");
        },
        eDeleteProductDetail(product) {
            const index = this.detailProducts.indexOf(product);
            this.detailProducts.splice(index, 1);
            this.mDataGeneralShowSmsAction(["Acción realizada", "Se eliminó el producto"], "success");
        },
        //
        eOpenDialogProviders() {
            this.dialogProviders = true;
            setTimeout(() => {
                this.$refs.dialogproviders.activateFocus();
            }, 150);
        },
        eCloseSearchProviders() {
            if (!this.provider.filterData)
                this.dataPurchase.activate_error = true;
            this.provider.filterData = false;
            this.provider.search = "";
            this.providersList = [{ id: 0, povider: "Todos" }];
            this.dialogProviders = false;
        },
        async eSearchProviders() {
            this.provider.dialogLoaderData = true;
            if (this.mRuleEmptyField(this.provider.search)) this.provider.search = "";
            this.providersList = [{ id: 0, provider: "Todos" }];
            try {
                this.providersList = [];
                const response = await Provider.getProviders(1, this.provider.search);
                this.providersList = response.data;
                this.provider.dialogLoaderData = false;
            } catch(error) {
                this.dialogProviders = false;
                this.processErrorServer(error);
            }
        },
        eSelectProvider(provider) {
            this.dataPurchase.fk_provider = provider.id;
            this.dataPurchase.provider = provider;
            this.eCloseSearchProviders();
        },
        async addPurchase() {
            const parameters = {
                purchase: this.dataPurchase,
                detail: this.detailProducts
            }
            try {
                const response = await Purchase.addPurchase(parameters);
                this.purchasesList.unshift(response);
                this.total += 1;
                this.eBackTableList();
                this.dialogLoaderOperation = false;
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se registró la compra"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async editPurchase() {
            try {
                const response = await Purchase.editPurchase(this.dataPurchase);
                Object.assign(this.purchasesList[this.indexPurchase], response);
                this.eBackTableList();
                this.dialogLoaderOperation = false;
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se editó la compra"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async deleteProductDetail() {
            try {
                await ProductPurchase.deleteProductDetail(this.dataProductPurchase);
                const index = this.purchasesList.indexOf(this.purchase);
                this.purchasesList[index].products.splice(this.indexProductPurchase, 1);
                this.resetDataChangeProductDetail();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se eliminó la compra"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async updateProductDetail() {
            try {
                const response = await ProductPurchase.editProductDetail(this.dataProductPurchase);
                const index = this.purchasesList.indexOf(this.purchase);
                Object.assign(this.purchasesList[index].products[this.indexProductPurchase].pivot, response);
                this.resetDataChangeProductDetail();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se editó la compra del producto"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async addProductDetail() {
            this.dataProductPurchase.purchase_id = this.purchase.id;
            try {
                const response = await ProductPurchase.addProductDetail(this.dataProductPurchase);
                const index = this.purchasesList.indexOf(this.purchase);
                this.purchasesList[index].products.unshift(response);
                this.eCloseSearchProduct();
                this.dialogLoaderOperation = false;
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se agregó la compra del producto"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async deletePurchase() {
            try {
                await Purchase.deletePurchase({id: this.idPurchase});
                const index = this.purchasesList.indexOf(this.purchase);
                this.purchasesList.splice(index, 1);
                this.total -= 1;
                this.idPurchase = 0;
                this.dialogLoaderOperation = false;
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se eliminó la compra"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (this.isActionProductPurchase) {
                if (this.isDeleteProductPurchase)
                    this.deleteProductDetail();
                else if (this.indexProductPurchase !== -1)
                    this.updateProductDetail();
                else this.addProductDetail();
            } else {
                if (this.idPurchase > 0) this.deletePurchase();
                else if (this.indexPurchase !== -1) this.editPurchase();
                else this.addPurchase();
            }
        },
        eShowDetailPurchase(purchase) {
            this.purchase = Object.assign({}, purchase);
            this.tab = 2;
        },
        eShowDetailProduct(product) {
            this.product = product;
            this.dialogDetailProduct = true;
        },
        eOpenFormEditPurchase(purchase) {
            this.indexPurchase = this.purchasesList.indexOf(purchase);
            this.dataPurchase = Object.assign({}, purchase);
            this.dataPurchase.activate_error = true;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 3;
                setTimeout(() => {
                    this.$refs.formpurchaseedit.activateFocus();
                }, 150);
            }, time);
        },
        eConfirmFormPurchaseEdit(isValidForm) {
            const smsConfirm = [`¿Está seguro(a) de editar la compra?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
            }, time);
        },
        //operations detail
        eCofirmDeleteProductPurchase(productPurchase) {
            this.isActionProductPurchase = true;
            this.isDeleteProductPurchase = true;
            this.dataProductPurchase = Object.assign({}, productPurchase.pivot);
            this.indexProductPurchase = this.purchase.products.indexOf(productPurchase);
            this.smsConfirm = [`¿Está seguro(a) de eliminar la compra de: ${productPurchase.product}?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.dialogConfirm = true;
            }, time);
        },
        //
        resetDataChangeProductDetail() {
            this.isActionProductPurchase = false;
            this.dialogLoaderOperation = false;
            this.indexProductPurchase = -1;
            this.dataProductPurchase = new ProductPurchase;
            this.isDeleteProductPurchase = false;
        },
        eOpenFormProductDetail(productPurchase) {
            this.isActionProductPurchase = true;
            this.product = Object.assign({}, productPurchase);
            this.dataProductPurchase = Object.assign({}, productPurchase.pivot);
            this.indexProductPurchase = this.purchase.products.indexOf(productPurchase);
            this.dialogProductDetail = true;
            setTimeout(() => {
                this.$refs.formproductpurchasedialog.activateFocus();
            }, 150);
        },
        eConfirmEditProductDetail(isValidForm) {
            if (this.isChangeDetailPurchase) {
                const index = this.detailProducts.indexOf(this.product);
                Object.assign(this.detailProducts[index], this.dataProductPurchase);
                this.eCloseEditProductDetail();
            } else {
                const smsConfirm = [`¿Está seguro de editar la compra de: ${this.product.product}?`];
                this.dialogProductDetail = false;
                this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
            }
        },
        eCloseDialogConfirm() {
            this.idPurchase = 0;
            this.dialogConfirm = false;
            if (this.isActionProductPurchase) {
                if (this.isDeleteProductPurchase)
                    this.resetDataChangeProductDetail();
                else if (this.indexProductPurchase === -1)
                    this.dialogProducts = true;
                else this.dialogProductDetail = true;
            }
        },
        eCloseEditProductDetail() {
            this.isChangeDetailPurchase = false;
            this.dataProductPurchase = new ProductPurchase;
            this.$refs.formproductpurchasedialog.resetForm();
            this.dialogProductDetail = false;
        },
        ePrintPurchase(purchase) {
            if (window.innerWidth <= 600)
                this.mDataGeneralShowSmsAction(["Operación Denegada", "Imprima desde el PC porfavor"], "error");
            else {
                this.purchase = purchase;
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.mPrintShow(this.$refs.sheetpurchase);
                    }, 500);
                });
            }
        },
        //
        eConfirmDeletePurchase(purchase) {
            this.idPurchase = purchase.id;
            this.purchase = purchase;
            this.smsConfirm = [
                `Atención: Se verificará que los productos de la compra, no hayan tenido
                movimientos en el inventario y en las ventas para su eliminación del sistema.`,
                `¿Despues de leer el mensaje de advertencia, está seguro de eliminar la comprá?`
            ];
            this.dialogConfirm = true;
        },
        eChangeDateOne(dateOne) {
            if (dateOne === "" || this.dateTwo === "")
                this.mDataGeneralShowSmsAction(["Error de fechas", "Corrija las fechas porfavor"], "error");
            else {
                if (dateOne > this.dateTwo) this.dateTwo = dateOne;
                this.getPurchases(1, dateOne, this.dateTwo, 0, this.search);
            }
        },
        eChangeDateTwo(dateTwo) {
            if (dateTwo === "" || this.dateOne === "") {
                this.mDataGeneralShowSmsAction(["Error de fechas", "Corrija las fechas porfavor"], "error");
            } else {
                if (this.dateOne > dateTwo) this.dateOne = dateTwo;
                this.getPurchases(1, this.dateOne, dateTwo, 0, this.search);
            }
        },
        eSearchPurchasesBillReceipts() {
            if (this.mRuleEmptyField(this.search)) this.search = "";
            this.getPurchases(1, this.dateOne, this.dateTwo, 0, this.search);
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getPurchases(this.currentPage, this.dateOne, this.dateTwo, 0, this.search);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getPurchases(page, this.dateOne, this.dateTwo, 0, this.search);
                }, time);
            }
        },
        handleKeyUpPurchases(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 0) this.eCloseDialogConfirm();
                if (actionKeyBoard === 1) this.ePerformDatabaseAction();
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        //
        eOpenFormEditDetail(product) {
            this.isChangeDetailPurchase = true;
            this.product = product;
            this.dataProductPurchase = Object.assign({}, product);
            this.dialogProductDetail = true;
        }
    },
    mounted() {
        this.dateOne = this.dateTwo = this.mDateSystem(false);
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpPurchases);
                this.getPurchases(1, "", "", 0, "");
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpPurchases);
    }
}
</script>