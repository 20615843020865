<template>
    <div>
        <div class="client__header pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    {{`CLIENTES (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center client__header-block-second">
                <OSearchHeaderComp
                    label="Buscar"
                    v-model="search"
                    class="mr-1"
                    @entersearch="eSearchClients()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    smsToolTip="Volver a clientes"
                    @click="tab=0"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="clientsList.length==0 && !dialogLoaderData && mobile"
                />
                <STableClientsComp
                    v-if="!dialogLoaderData && !mobile"
                    :clientsList="clientsList"
                    :search="searchFilter"
                    @clickedit="eShowFormEdit"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderData && mobile"
                >
                    <SCardClientComp
                        class="ma-2"
                        :search="searchFilter"
                        v-for="client in clientsList"
                        :key="client.id"
                        :client="client"
                        @clickedit="eShowFormEdit(client)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="mobile && !dialogLoaderData && clientsList.length>0"
                ></v-divider>
                <SPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderData && clientsList.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center mt-3 pl-2 pr-2 pb-2">
                    <SFormClientComp
                        :dataClient="dataClient"
                        :mobile="mobile"
                        ref="formclientcomp"
                        :index="0"
                        @saveform="eConfirmEdit"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="dialogConfirm=false"
            @clickyes="eEditClient()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSearchHeaderComp,
    OSmsErrorComp,
    OSmsConfirmationComp,
    OSmsActionComp,
    OOperationLoaderComp,
    OLoaderDataComp
} from "../../components/organisms";
import {
    STableClientsComp,
    SFormClientComp,
    SPaginationComp,
    SCardClientComp
} from "../../components/species";
import { Client } from "../../models";
import {
    responseServerMixin,
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    userMixin,
    datesMixin
} from "../../mixins";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        responseServerMixin,
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        userMixin,
        datesMixin
    ],
    components: {
        OBtnToolTipHeaderComp,
        OSearchHeaderComp,
        STableClientsComp,
        OSmsErrorComp,
        OSmsConfirmationComp,
        OSmsActionComp,
        OOperationLoaderComp,
        SFormClientComp,
        OLoaderDataComp,
        MNoDataComp,
        SPaginationComp,
        SCardClientComp
    },
    data: () => ({
        search: "",
        clientsList: [],
        tab: 0,
        dataClient: new Client(),
        indexClient: -1
    }),
    computed: {
        searchFilter: function () {
            return this.search == null ? "" : this.search;
        }
    },
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else this.dialogError = false;
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getClients(page, this.search);
                }, time);
            }
        },
        eSearchClients() {
            if (this.mRuleEmptyField(this.search)) this.search = "";
            this.getClients(1, this.search);
        },
        handleKeyUpClients(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 1) this.eEditClient();
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getClients(page, search) {
            this.dialogLoaderData = true;
            try {
                const response = await Client.getClients(page, search);
                this.clientsList = response.data;
                this.currentPage = response.current_page;
                this.lastPage = response.last_page;
                this.total = response.total;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eShowFormEdit(client) {
            this.indexClient = this.clientsList.indexOf(client);
            this.dataClient = Object.assign({}, client);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
                setTimeout(() => {
                    this.$refs.formclientcomp.activateFocus();
                }, 150);
            }, time);
        },
        async eEditClient() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            try {
                const response = await Client.editClient(this.dataClient);
                Object.assign(this.clientsList[this.indexClient], response);
                this.dialogLoaderOperation = false;
                this.tab = 0;
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se editó el usuario"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getClients(this.currentPage, this.search);
            }, time);
        },
        eConfirmEdit(isValidForm) {
            const smsConfirm = [`¿Está seguro(a) de editar los datos de: ${this.dataClient.full_name}?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
            }, time);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpClients);
                this.getClients(1, "");
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpClients);
    }
}
</script>