<template>
    <div class="d-flex">
        <v-app-bar
            fixed
            class="s-menu-mobile"
            height="40px"
            elevation="2"
        >
            <div class="d-flex align-center" style="width:100%">
                <div class="d-flex align-center">
                    <v-img
                        src="/imgs/logo.png"
                        :height="sizeLogo"
                        :max-height="sizeLogo"
                        :width="sizeLogo"
                        :max-width="sizeLogo"
                    ></v-img>
                    <h1 class="app-title-menu-dark ml-2">Narazet</h1>
                </div>
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon
                    class="s-menu-mobile__icon-menu"
                    @click="drawer=true"
                ></v-app-bar-nav-icon>
            </div>
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawer"
            fixed
            temporary
            width="300"
            class="s-menu-pc__navegation"
        >
            <v-card
                class="ma-0 rounded-0 d-flex justify-center align-center"
                elevation="0"
                min-height="40"
            >
                <v-img
                    src="/imgs/logo.png"
                    :height="sizeLogo"
                    :max-height="sizeLogo"
                    :width="sizeLogo"
                    :max-width="sizeLogo"
                ></v-img>
                <h1 class="ml-3 app-title-menu">Nazaret</h1>
            </v-card>
            <v-list nav class="pb-0 pt-0 mt-2">
                <div
                    v-for="(option, index) in menu"
                    :key="index"
                >
                    <v-list-group
                        v-if="option.submenu.length>0"
                        :ripple="false"
                        color="white"
                        no-action
                    >
                        <template v-slot:activator>
                            <v-list-item
                                dense
                                :ripple="false"
                                class="ma-0 pl-0"
                            >
                                <v-icon class="s-menu-pc__list-icon mr-2">{{option.icon}}</v-icon>
                                <h1 class="app-subtitle-dark">{{option.title}}</h1>
                            </v-list-item>
                        </template>
                        <div
                            v-for="(child, subindex) in option.submenu"
                            :key="subindex"
                            class="pl-7 mb-3"
                        >
                            <v-list-item
                                :to="child.route"
                                dense
                                class="s-menu-pc__list-option "
                                :ripple="false"
                                active-class="s-menu-pc__list-option-activate"
                            >
                                <v-icon class="s-menu-pc__list-icon mr-2">{{child.icon}}</v-icon>
                                <h1 class="app-subtitle-dark">{{child.title}}</h1>
                            </v-list-item>
                        </div>
                    </v-list-group>
                    <v-list-item
                        v-else
                        dense
                        :to="option.route"
                        :ripple="false"
                        :class="(index+1)==menu.length
                            ? 's-menu-pc__list-option'
                            : 's-menu-pc__list-option mb-2'"
                        active-class="s-menu-pc__list-option-activate"
                    >
                        <v-icon class="s-menu-pc__list-icon mr-2">{{option.icon}}</v-icon>
                        <h1 class="app-subtitle-dark">{{option.title}}</h1>
                    </v-list-item>
                </div>
                <v-list-item-group v-if="validCashRegister()">
                    <v-list-item
                        class="mb-1"
                        :ripple="false"
                        @click="eCloseSession()"
                    >
                        <v-list-item-icon class="mr-2">
                            <v-icon class="menu-mobile__option-icon" color="#ffffffce">mdi-close</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <h1 class="app-subtitle-dark">Cerrar Sesión</h1>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <OOperationLoaderComp
            smsLoaderOperation="Cerrando Sesión"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    OOperationLoaderComp
} from "../organisms";
import {
    User
} from "../../models";
export default {
    components: {
        OOperationLoaderComp
    },
    props: {
        menu: Array,
    },
    data: () => ({
        drawer: false,
        sizeLogo: "30",
        dialogLoaderOperation: false
    }),
    methods: {
        async eCloseSession() {
            this.dialogLoaderOperation = true;
            try {
                await User.logout();
                this.dialogLoaderOperation = false;
                localStorage.removeItem("nazaret");
                setTimeout(() => {
                    this.$router.replace('/login');
                }, 150);
            } catch (error) {
                localStorage.removeItem("nazaret");
                this.$router.replace('/login');
            }
        },
        validCashRegister() {
            if (JSON.parse(localStorage.getItem("device"))) return false;
            return true;
        }
    }
}
</script>
