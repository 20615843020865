<template>
    <div>
        <div class="d-flex">
            <h1 class="app-title mr-1">N° Recibo/Factura:</h1>
            <h1 class="app-subtitle">
                {{purchase.bill_receipt}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="app-title mr-1">Fecha de Compra:</h1>
            <h1 class="app-subtitle">
                {{mDateFormat(purchase.date_purchase)}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="app-title mr-1">Proovedor:</h1>
            <h1 class="app-subtitle">
                {{purchase.provider.provider}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="app-title mr-1">Glosa:</h1>
            <h1 class="app-subtitle">
                {{purchase.gloss != null ? purchase.gloss : "S/G"}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="app-title mr-1">Fecha Registro:</h1>
            <h1 class="app-subtitle">
                {{`${purchase.hour} ${purchase.date}`}}
            </h1>
        </div>
    </div>
</template>
<script>
import {
    datesMixin
} from "../../mixins";
export default {
    mixins: [
        datesMixin
    ],
    props: {
        purchase: Object
    }
}
</script>