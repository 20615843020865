<template>
    <div>
        <div class="client__header pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    {{`INVENTARIO MANUAL`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center client__header-block-second">
                <OSearchHeaderComp
                    label="Buscar"
                    v-model="search"
                    class="mr-1"
                    @entersearch="eSearchProducts()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    smsToolTip="Volver a clientes"
                    @click="tab=0"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap">
                    <div
                        :style="mobile ? 'width:100%' : ''"
                        class="d-flex justify-center pt-2 pl-2 pr-2"
                    >
                        <div class="product__search" style="margin-right:2px">
                            <MSelectComp
                                label="Tipo Producto"
                                :items="typesProducts"
                                itemValue="id"
                                itemText="type_product"
                                :hideDetails="true"
                                v-model="idTypeProduct"
                                @change="eChangeTypeProduct"
                            />
                        </div>
                        <div class="product__search" style="margin-left:2px">
                            <div
                                class="product__search-laboratories d-flex align-center pl-3"
                                @click="eOpenSearchLaboratory()"
                            >
                                <h2>Laboratorio</h2>
                                <h1 class="app-subtitle">
                                    {{laboratory.id==0 ? "Todos" : laboratory.name}}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="products.length==0 && !dialogLoaderData && mobile"
                />
                <STableProductsLotComp
                    v-if="!dialogLoaderData && !mobile"
                    :products="products"
                    :search="searchFilter"
                    @clickdetail="eShowDetailProduct"
                    @clicklot="eOpenFormInventory"
                    @clickinventories="eShowInventories"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderData && mobile"
                >
                    <SCardProductLotComp
                        class="ma-2"
                        :search="searchFilter"
                        v-for="product in products"
                        :key="product.id"
                        :product="product"
                        @clickdetail="eShowDetailProduct(product)"
                        @clicklot="eOpenFormInventory(product)"
                        @clickinventories="eShowInventories(product)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="mobile && !dialogLoaderData && products.length>0"
                ></v-divider>
                <SPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderData && products.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2" v-if="product.id != undefined">
                    <div class="d-flex">
                        <h1 class="app-title mr-1">Producto:</h1>
                        <h1 class="app-subtitle">
                            {{product.product}}
                        </h1>
                    </div>
                    <MBtnNormalComp
                        class="mt-1"
                        @click="eSearchDatesLots()"
                    >BUSCAR POR FECHA</MBtnNormalComp>
                </div>
                <v-divider></v-divider>
                <div
                    class="d-flex justify-center flex-wrap"
                    :style="mobile ? 'width:100%' : ''"
                >
                    <div class="product__group-search d-flex justify-center pt-2 pl-2 pr-2">
                        <div class="product__search" style="margin-right:2px">
                            <MTextFieldComp
                                label="Inicio"
                                type="date"
                                :hideDetails="true"
                                :clearable="false"
                                v-model="dateOne"
                            />
                        </div>
                        <div class="product__search" style="margin-left:2px">
                            <MTextFieldComp
                                label="Fin"
                                type="date"
                                :hideDetails="true"
                                v-model="dateTwo"
                                :clearable="false"
                            />
                        </div>
                    </div>
                </div>
                <v-divider class="mt-2 mb-2"></v-divider>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderDataLots"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="lots.length==0 && !dialogLoaderDataLots && mobile"
                />
                <STableLotsComp
                    v-if="!dialogLoaderDataLots && !mobile"
                    :lots="lots"
                    @clickedit="eOpenFormEditLot"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderDataLots && mobile"
                >
                    <SCardLotComp
                        class="ma-2"
                        v-for="lot in lots"
                        :key="lot.id"
                        :lot="lot"
                        @clickedit="eOpenFormEditLot(lot)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="mobile && !dialogLoaderDataLots && lots.length>0"
                ></v-divider>
                <SPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataLots && products.length > 0"
                    :condicionGo="currentPageLot < lastPageLot"
                    :condicionBack="currentPageLot > 1"
                    :titlePagination="'Pag. '+currentPageLot+' de '+lastPageLot"
                    @clickback="eChangePageLot(false)"
                    @clickgo="eChangePageLot(true)"
                    @clickpage="eSearchForPageLot"
                />
            </v-tab-item>
        </v-tabs-items>
        <SFormLotComp
            :value="dialogoFormLot"
            :indexLot="indexLot"
            :dataLot="dataLot"
            ref="formlotcomp"
            @saveform="eValidForm"
            @clickclose="eCerrarFormLot"
        />
        <SDialogSearchLaboratoryComp
            :valueDialog="dialogLaboratories"
            v-model="laboratory.search"
            :filterData="true"
            ref="dialoglaboratory"
            :laboratoriesList="laboratoriesList"
            :searchLaboratory="searchFilterLaboratory"
            :dialogLoaderData="laboratory.dialogLoaderData"
            @entersearch="eSearchLaboratories()"
            @clickclose="eCloseSearchLaboratory()"
            @clicklaboratory="eSelectLaboratory"
        />
        <SDetailProductComp
            :value="dialogProduct"
            :product="product"
            @clickclose="dialogProduct=false"
        />
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="eCloseDialogConfirm()"
            @clickyes="ePerformDatabaseAction()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MNoDataComp,
    MSelectComp,
    MTextFieldComp,
    MBtnNormalComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSearchHeaderComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp,
    OLoaderDataComp
} from "../../components/organisms";
import {
    STableLotsComp,
    SDetailProductComp,
    STableProductsLotComp,
    SPaginationComp,
    SDialogSearchLaboratoryComp,
    SFormLotComp,
    SCardProductLotComp,
    SCardLotComp
} from "../../components/species";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    animationScrollMixin,
    rulesMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import {
    Inventory,
    Product,
    TypeProduct,
    Laboratory
} from "../../models";
export default {
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        animationScrollMixin,
        rulesMixin,
        userMixin,
        datesMixin
    ],
    props: {
        mini: Boolean
    },
    components: {
        OBtnToolTipHeaderComp,
        OSearchHeaderComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        OLoaderDataComp,
        MNoDataComp,
        STableLotsComp,
        SDetailProductComp,
        STableProductsLotComp,
        SPaginationComp,
        MSelectComp,
        SDialogSearchLaboratoryComp,
        SFormLotComp,
        MTextFieldComp,
        SCardProductLotComp,
        SCardLotComp,
        MBtnNormalComp
    },
    data: () => ({
        lots: [],
        search: "",
        dateOne: "",
        dateTwo: "",
        numberOperation: 0,
        typesProducts: [{ id: 0, type_product: "Todos"}],
        product: {},
        dialogProduct: false,
        tab: 0,
        products: [],
        dialogLoaderDataLots: false,
        idProduct: 0,
        currentPageLot: 0,
        lastPageLot: 0,
        totalLot: 0,
        idTypeProduct: 0,
        laboratory: {
            id: 0,
            name: "",
            search: "",
            dialogLoaderData: false,
            filterData: false
        },
        dialogLaboratories: false,
        laboratoriesList: [{ id:0, laboratory: "Todos" }],
        dialogoFormLot: false,
        indexLot: -1,
        dataLot: new Inventory,
        dateOne: "",
        dateTwo: ""
    }),
    computed: {
        searchFilter: function() {
            if (this.mRuleEmptyField(this.search)) return "";
            return this.search;
        },
        searchFilterLaboratory: function() {
            return this.laboratory.search == null ? "" : this.laboratory.search;
        }
    },
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else {
                this.dialogError = false;
                this.eCloseDialogConfirm();
            }
        },
        handleKeyUpInventorieManual(event) {
            if (!this.mobile) {
                if (this.dialogError) {
                    if (event.keyCode == 65) this.dialogError = false;
                }
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getRequest() {
            try {
                const products = Product.getProductsSearch(1, "", 0, 0);
                const typesProducts = TypeProduct.getTypesProducts();
                const responses = await Promise.all([products, typesProducts]);
                this.products = responses[0].data;
                this.lastPage = responses[0].last_page;
                this.currentPage = responses[0].current_page;
                this.total = responses[0].total;
                responses[1].forEach(typeProduct => {
                    this.typesProducts.push(typeProduct);
                });
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async getProducts(page, search, idTypeProduct, idLaboratory) {
            this.dialogLoaderData = true;
            try {
                const response = await Product.getProductsSearch(page, search, idTypeProduct, idLaboratory);
                this.products = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async getLots(page, search, dateOne, dateTwo) {
            this.dialogLoaderDataLots = true;
            try {
                const response = await Inventory.lots(page, search, dateOne, dateTwo);
                this.lots = response.data;
                this.currentPage = response.current_page;
                this.lastPage = response.last_page;
                this.total = response.total;
                this.dialogLoaderDataLots = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eCloseDialogConfirm() {
            this.dialogConfirm = false;
            if (this.numberOperation == 0 || this.numberOperation == 1)
                this.dialogoFormLot = true;
        },
        async addLotInventory() {
            this.dataLot.fk_product = this.product.id;
            try {
                const respuesta = await Inventory.addLot(this.dataLot);
                const indexProduct = this.products.indexOf(this.product);
                this.products[indexProduct].quantity += parseInt(respuesta);
                this.dialogLoaderOperation = false;
                this.eCerrarFormLot();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se registró el lote"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async editLotInventory() {
            try {
                const response = await Inventory.editLot(this.dataLot);
                const quantityOld = parseInt(this.lots[this.indexLot].quantity);
                const indexProduct = this.products.indexOf(this.product);
                this.products[indexProduct].quantity = this.products[indexProduct].quantity - quantityOld + parseInt(response.quantity);
                Object.assign(this.lots[this.indexLot], response);
                this.dialogLoaderOperation = false;
                this.eCerrarFormLot();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se registró el lote"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            switch(this.numberOperation) {
                case 0:
                    this.addLotInventory();
                    break;
                case 1:
                    this.editLotInventory();
                    break;
            }
        },
        eShowDetailProduct(product) {
            this.product = product;
            this.dialogProduct = true;
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getProducts(this.currentPage, this.search, this.idTypeProduct, this.laboratory.id);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getProducts(page, this.search, this.idTypeProduct, this.laboratory.id);
                }, time);
            }
        },
        eChangeTypeProduct(typeProduct) {
            this.getProducts(1, this.search, typeProduct, this.laboratory.id);
        },
        //search laboratory
        eOpenSearchLaboratory() {
            this.dialogLaboratories = true;
            setTimeout(() => {
                this.$refs.dialoglaboratory.activateFocus();
            }, 150);
        },
        async eSearchLaboratories() {
            this.laboratory.dialogLoaderData = true;
            if (this.mRuleEmptyField(this.laboratory.search)) this.laboratory.search = "";
            this.laboratoriesList = [{ id: 0, laboratory: "Todos" }];
            try {
                const response = await Laboratory.getLaboratories(1, this.laboratory.search);
                response.data.forEach(laboratory => {
                    this.laboratoriesList.push(laboratory);
                });
                this.laboratory.dialogLoaderData = false;
            } catch(error) {
                this.dialogLaboratories = false;
                this.processErrorServer(error);
            }
        },
        eCloseSearchLaboratory() {
            this.laboratory.search = "";
            this.laboratoriesList = [{ id: 0, laboratory: "Todos" }];
            this.dialogLaboratories = false;
        },
        eSelectLaboratory(laboratory) {
            this.laboratory.id = laboratory.id;
            this.laboratory.name = laboratory.laboratory;
            this.eCloseSearchLaboratory();
            setTimeout(() => {
                this.getProducts(1, this.search, this.idTypeProduct, laboratory.id);
            }, 150);
        },
        eSearchProducts() {
            this.tab = 0;
            if (this.mRuleEmptyField(this.search)) this.search = "";
            this.getProducts(1, this.search, this.idTypeProduct, this.laboratory.id);
        },
        eOpenFormInventory(product) {
            this.product = product;
            this.numberOperation = 0;
            this.dialogoFormLot = true;
        },
        eValidForm(isValidForm) {
            this.dialogoFormLot = false;
            const actionText = this.indexLot == -1 ? 'registrar' : 'editar';
            const smsConfirm = [
                `¿Está seguro(a) de ${actionText} el lote de:`,
                `producto: ${this.product.product}`,
                `cantidad: ${this.dataLot.quantity}?`
            ];
            /*const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {*/
                this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
            //}, time);
        },
        eCerrarFormLot() {
            this.dataLot = new Inventory;
            this.$refs.formlotcomp.resetForm();
            this.indexLot = -1;
            this.numberOperation = 0;
            this.dialogoFormLot = false;
        },
        async getLotsProduct(page, dateOne, dateTwo) {
            this.dialogLoaderDataLots = true;
            try {
                const response = await Inventory.lots(page, this.product.id, dateOne, dateTwo);
                this.lots = response.data;
                this.currentPageLot = response.current_page;
                this.lastPageLot = response.last_page;
                this.total = response.total;
                this.dialogLoaderDataLots = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eShowInventories(product) {
            this.product = product;
            this.tab = 1;
            this.dialogLoaderDataLots = true;
            this.getLotsProduct(1, "", "");
        },
        eChangePageLot(isGo) {
            if (isGo) ++this.currentPageLot;
            else --this.currentPageLot;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getLotsProduct(this.currentPageLot, this.dateOne, this.dateTwo);
            }, time);
        },
        searchLotForPage(page, isInteger) {
            if (!isInteger) {
                const smsError = ["Página Erronea", "El N° debe ser un número entero"];
                this.mDataGeneralShowSmsAction(smsError, "error");
                return false;
            } else {
                if (parseInt(page) > 0 && parseInt(page) <= this.lastPageLot) {
                    this.currentPageLot = page;
                    return true;
                } else {
                    const smsErrorNumber = this.lastPageLot === 1
                        ? "Solo tiene la página 1"
                        : `El N° debe estar entre 1-${this.lastPageLot}`;
                    const smsError = ["Página Erronea", smsErrorNumber];
                    this.mDataGeneralShowSmsAction(smsError, "error");
                    return false;
                }
            }
        },
        eSearchForPageLot(page) {
            const isNotValidPage = this.searchLotForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getLotsProduct(page, this.dateOne, this.dateTwo);
                }, time);
            }
        },
        eOpenFormEditLot(lot) {
            this.dataLot = Object.assign({}, lot);
            this.indexLot = this.lots.indexOf(lot);
            this.numberOperation = 1;
            this.dialogoFormLot = true;
        },
        eSearchDatesLots() {
            this.getLotsProduct(this.currentPageLot, this.dateOne, this.dateTwo);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpInventorieManual);
                this.getRequest();
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpInventorieManual);
    }
}
</script>