import { mapMutations, mapState } from "vuex";
export default {
    computed: {
        ...mapState("userStore", ["existHeader"])
    },
    methods: {
        ...mapMutations("userStore", ["saveHeader", "activateHeader"]),
        mUserSaveHeader(dateNow) {
            if (JSON.parse(localStorage.getItem("nazaret"))) {
                const dateLocalStore = JSON.parse(localStorage.getItem("nazaret")).date;
                if (dateLocalStore < dateNow) {
                    localStorage.removeItem("nazaret");
                    return false;
                } else {
                    if (!this.existHeader) {
                        var apitoken = JSON.parse(localStorage.getItem("nazaret")).token;
                        var header = {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + apitoken,
                        }
                        this.saveHeader(header);
                        this.activateHeader();
                    }
                    return true;
                }
            }
            return false;
        },
        mUserAuthorizedDevice() {
            if (JSON.parse(localStorage.getItem("device"))) return true;
            return false;
        },
        mUserCategorie() {
            return JSON.parse(localStorage.getItem("nazaret")).user.categorie;
        },
        mUserFullName() {
            return JSON.parse(localStorage.getItem("nazaret")).user.full_name;
        }
    }
}