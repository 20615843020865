var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-form-product"},[_c('h1',{staticClass:"app-title text-center mb-1"},[_vm._v(" "+_vm._s(_vm.titleForm)+" ")]),_c('v-form',{ref:"formpayment",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Pago a realizar:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Pago a realizar' : '',"rules":[
                    value => !this.mRuleEmptyField(value) || 'El pago es requerido',
                    value => this.mRuleNumberDecimal(value) || 'El pago debe ser un número'
                ]},model:{value:(_vm.dataPayment.payment),callback:function ($$v) {_vm.$set(_vm.dataPayment, "payment", $$v)},expression:"dataPayment.payment"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Glosa:"}},[_c('MTextAreaComp',{attrs:{"label":_vm.mobile ? 'Glosa' : '',"counter":150,"rules":[
                    value => !this.mRuleEmptyField(value) || 'La glosa es requerida',
                    value => (`${value}`).length<=150 || 'La glosa, no debe exceder los 150 dígitos'
                ]},model:{value:(_vm.dataPayment.glos),callback:function ($$v) {_vm.$set(_vm.dataPayment, "glos", $$v)},expression:"dataPayment.glos"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Fecha Destino:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Fecha Destino' : '',"type":"date","rules":[
                    value => !this.mRuleEmptyField(value) || 'La fecha es requerida'
                ]},model:{value:(_vm.dataPayment.destination_date),callback:function ($$v) {_vm.$set(_vm.dataPayment, "destination_date", $$v)},expression:"dataPayment.destination_date"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"N° Caja:"}},[_c('MSelectComp',{attrs:{"label":_vm.mobile ? 'N° Caja' : '',"items":_vm.cashRegisterList,"itemValue":"id","itemText":"cash_register","rules":[
                    value => !this.mRuleEmptyField(value) || 'El dato es requerido'
                ]},model:{value:(_vm.dataPayment.fk_cash_register),callback:function ($$v) {_vm.$set(_vm.dataPayment, "fk_cash_register", $$v)},expression:"dataPayment.fk_cash_register"}})],1)],1),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('MBtnNormalComp',{on:{"click":function($event){return _vm.eValidForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }