<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-center">Gasto (Bs.)</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-center">Descripción</h1>
                    </th>
                    <th width="80">
                        <h1 class="app-title-dark text-left">N° Caja</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Registrador(a)</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha/Hora</h1>
                    </th>
                    <th width="100px" v-if="!isInformation">
                        <h1 class="app-title-dark text-center">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(expense, index) in expensesList"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{expense.expense.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{expense.description!=null ? expense.description : "S/D"}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{expense.cash_register.cash_register}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{expense.user}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{`${expense.hour} ${expense.date}`}}
                        </h1>
                    </td>
                    <td
                        v-if="!isInformation"
                        class="d-flex justify-center flex-wrap"
                    >
                        <OBtnToolTipTableComp
                            smsToolTip="Editar Gasto"
                            icon="mdi-pencil"
                            @click="$emit('clickedit', expense)"
                        />
                    </td>
                </tr>
                <tr v-if="expensesList.length === 0">
                    <td :colspan="isInformation ? '6' : '7'">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { OBtnToolTipTableComp } from "../organisms";
export default {
    props: {
        expensesList: Array,
        isInformation: Boolean
    },
    components: {
        OBtnToolTipTableComp
    }
}
</script>