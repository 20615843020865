<template>
    <v-dialog
        persistent
        width="340px"
        :value="value"
    >
        <v-card class="pa-0 pb-3 ma-0">
            <div class="d-flex align-center pa-2">
                <h1 class="app-title">
                    BALANCE
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <v-divider></v-divider>
            <div class="pa-2 pb-0">
                <div class="d-flex">
                    <h1 class="app-subtitle">Total Ingresos (Bs.):</h1>
                    <v-spacer></v-spacer>
                    <div>
                        <h1 class="app-subtitle">{{totalSale.toFixed(2)}}</h1>
                    </div>
                </div>
                <div class="d-flex">
                    <h1 class="app-subtitle">Total Costo Prods. (Bs.):</h1>
                    <v-spacer></v-spacer>
                    <div>
                        <h1 class="app-subtitle">{{totalGroosIncome.toFixed(2)}}</h1>
                    </div>
                </div>
                <v-divider class="mb-1 mt-1"></v-divider>
                <div class="d-flex">
                    <h1 class="app-title">Utilidad Bruta (Bs.):</h1>
                    <v-spacer></v-spacer>
                    <div>
                        <h1 class="app-subtitle">{{(totalSale - totalGroosIncome).toFixed(2)}}</h1>
                    </div>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    OBtnCardComp
} from "../organisms";
export default {
    components: {
        OBtnCardComp
    },
    props: {
        value: Boolean,
        totalSale: Number,
        totalGroosIncome: Number
    }
}
</script>