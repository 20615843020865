<template>
    <v-text-field
        outlined
        dense
        hide-details
        :clearable="clearable"
        single-line
        :label="label"
        :reverse="reverse"
        ref="textfield"
        :value="value"
        class="text-field__header"
        @input="(e)=>(this.$emit('input', e))"
        @keyup.enter="$emit('click')"
    ></v-text-field>
</template>
<script>
export default {
    props: {
        label: String,
        value: [Number, String],
        clearable: {
            type: Boolean,
            default: true
        },
        reverse: Boolean
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    methods: {
        activateFocus() {
            this.$refs.textfield.focus();
        }
    }
}
</script>