<template>
    <div>
        <div class="client__header pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    {{`PRODUCTOS (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center client__header-block-second">
                <OSearchHeaderComp
                    label="Buscar"
                    v-model="search"
                    class="mr-1"
                    @entersearch="eSearchProducts()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-cart-arrow-right"
                    class="mr-1"
                    smsToolTip="Ver lista de pedido"
                    @click="tab=2"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    smsToolTip="Volver a productos"
                    @click="tab=0"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center pt-2 pl-2 pr-2">
                    <div class="product__search" style="margin-right:2px">
                        <MSelectComp
                            label="Tipo Producto"
                            :items="typesProducts"
                            itemValue="id"
                            itemText="type_product"
                            :hideDetails="true"
                            v-model="idTypeProduct"
                        />
                    </div>
                    <div class="product__search" style="margin-left:2px">
                        <div
                            class="product__search-laboratories d-flex align-center pl-3"
                            @click="eOpenDialogLaboratories()"
                        >
                            <h2>Laboratorio</h2>
                            <h1 class="app-subtitle">
                                {{laboratory.id==0 ? "Todos" : laboratory.name}}
                            </h1>
                        </div>
                    </div>
                </div>
                <v-divider class="mt-2"></v-divider>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="productsList.length==0 && !dialogLoaderData && mobile"
                />
                <STableInventoriesComp
                    v-if="!dialogLoaderData && !mobile"
                    :productsList="productsList"
                    :search="searchFilter"
                    :setting="setting"
                    :isOrder="true"
                    @clickcheck="eOpenFormSelect"
                    @clickdetail="eShowDetail"
                    @clickinventories="eShowInventories"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderData && mobile"
                >
                    <SCardInventoryComp
                        class="ma-2"
                        :search="searchFilter"
                        v-for="product in productsList"
                        :key="product.id"
                        :product="product"
                        :setting="setting"
                        :isOrder="true"
                        @clickcheck="eOpenFormSelect(product)"
                        @clickdetail="eShowDetail(product)"
                        @clickinventories="eShowInventories(product)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="lots.length==0"
                />
                <div class="d-flex justify-center flex-wrap">
                    <SCardLoteInventoryComp
                        class="ma-2"
                        v-for="lot in lots"
                        :key="lot.id"
                        :lot="lot"
                        @clickpurchase="eShowDetailPurchase(lot)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <MNoDataComp
                    smsNotData="SIN PRODUCTOS AGREGADOS"
                    class="ma-1"
                    v-if="detailOrder.length==0"
                />
                <STableOrderComp
                    v-if="!mobile"
                    :detailOrder="detailOrder"
                    @clickdetail="eShowDetail"
                    @clickdelete="eDeleteProductDetail"
                    @clickedit="eOpenFormOrderEdit"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="mobile"
                >
                    <SCardOrderComp
                        class="ma-2"
                        v-for="product in detailOrder"
                        :key="product.id"
                        :product="product"
                        @clickdetail="eShowDetail(product)"
                        @clickdelete="eDeleteProductDetail(product)"
                        @clickedit="eOpenFormOrderEdit(product)"
                    />
                </div>
                <div class="text-center">
                    <MBtnNormalComp
                        class="mt-1"
                        @click="dialogFormAddressedTo = true"
                    >
                        EXPORTAR PEDIDO
                    </MBtnNormalComp>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <SDialogSearchLaboratoryComp
            :valueDialog="dialogLaboratories"
            ref="dialoglaboratory"
            v-model="laboratory.search"
            :filterData="laboratory.filterData"
            :laboratoriesList="laboratoriesList"
            :searchLaboratory="searchFilterLaboratory"
            :dialogLoaderData="laboratory.dialogLoaderData"
            @entersearch="eSearchLaboratories()"
            @clickclose="eCloseSearchLaboratory()"
            @clicklaboratory="eSelectLaboratory"
        />
        <SDetailProductComp
            :product="product"
            :value="dialogDetailProduct"
            @clickclose="dialogDetailProduct=false"
        />
        <SDetailPurchaseLotComp
            :value="dialogLot"
            :lot="lot"
            @clickclose="dialogLot=false"
        />
        <SDialogAddOrderComp
            :indexDetail="indexDetail"
            :product="product"
            :value="dialogFormOrder"
            ref="addordercomp"
            @clickclose="eCloseDialogAddOrder()"
            @saveform="eAddOder"
        />
        <SDialogAddressToComp
            :value="dialogFormAddressedTo"
            ref="addresscomp"
            @clickclose="eCloseFormAddress()"
            @saveform="eGeneratePdf"
        />
        <div class="d-none">
            <OTableOrderPdfComp
                :detailOrder="detailOrder"
            />
        </div>
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MSelectComp,
    MNoDataComp,
    MBtnNormalComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSearchHeaderComp,
    OLoaderDataComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp,
    OTableOrderPdfComp
} from "../../components/organisms";
import {
    SDialogSearchLaboratoryComp,
    STableInventoriesComp,
    SCardInventoryComp,
    SDetailProductComp,
    SCardLoteInventoryComp,
    SDetailPurchaseLotComp,
    SDialogAddOrderComp,
    STableOrderComp,
    SCardOrderComp,
    SDialogAddressToComp
} from "../../components/species";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    rulesMixin,
    userMixin,
    datesMixin,
    animationScrollMixin
} from "../../mixins";
import {
    TypeProduct,
    Setting,
    Product,
    Laboratory
} from "../../models";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        rulesMixin,
        userMixin,
        datesMixin,
        animationScrollMixin
    ],
    components: {
        OBtnToolTipHeaderComp,
        OSearchHeaderComp,
        MSelectComp,
        SDialogSearchLaboratoryComp,
        MNoDataComp,
        OLoaderDataComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        STableInventoriesComp,
        SCardInventoryComp,
        SDetailProductComp,
        SCardLoteInventoryComp,
        SDetailPurchaseLotComp,
        SDialogAddOrderComp,
        STableOrderComp,
        SCardOrderComp,
        MBtnNormalComp,
        OTableOrderPdfComp,
        SDialogAddressToComp
    },
    data: () => ({
        search: "",
        tab: 0,
        typesProducts: [{id: 0, type_product: "Todos"}],
        idTypeProduct: 0,
        laboratory: {
            id: 0,
            name: "",
            search: "",
            dialogLoaderData: false
        },
        dialogLaboratories: false,
        laboratoriesList: [],
        productsList: [],
        dialogDetailProduct: false,
        product: {},
        lots: [],
        dialogLot: false,
        lot: {},
        setting: {},
        dialogFormOrder: false,
        detailOrder: [],
        indexDetail: -1,
        dialogFormAddressedTo: false
    }),
    computed: {
        searchFilter: function () {
            return this.mRuleEmptyField(this.search) ? "" : this.search;
        },
        searchFilterLaboratory: function() {
            return this.mRuleEmptyField(this.laboratory.search) ? "" : this.laboratory.search;
        }
    },
    methods: {
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getRequest() {
            this.dialogLoaderData = true;
            try {
                const getTypeProduct = TypeProduct.getTypesProducts();
                const getSetting = Setting.getSetting();
                const responses = await Promise.all([getTypeProduct, getSetting]);
                responses[0].forEach(typeProduct => {
                    this.typesProducts.push(typeProduct);
                });
                this.setting = responses[1];
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eShowDetail(product) {
            this.product = product;
            this.dialogDetailProduct = true;
        },
        eShowInventories(product) {
            this.lots = product.inventories;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eShowDetailPurchase(lot) {
            if (lot.id_purchase !== 0) {
                this.lot = lot;
                this.dialogLot = true;
            } else this.mDataGeneralShowSmsAction(
                ["Sin Registro de Compra", "El lote no fue registrado por compras"], "error");
        },
        async getProducts(search, idTypeProduct, idLaboratory) {
            this.dialogLoaderData = true;
            try {
                var response = await Product.searchOrders(search, idTypeProduct, idLaboratory);
                response.forEach(element => {
                    element.quantity_order = ""
                });
                this.productsList = response;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eSearchProducts() {
            if (this.mRuleEmptyField(this.search)) this.search = "";
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 0;
                this.getProducts(this.search, this.idTypeProduct, this.laboratory.id);
            }, time);
        },
        eOpenDialogLaboratories() {
            this.dialogLaboratories = true;
            setTimeout(() => {
                this.$refs.dialoglaboratory.activateFocus();
            }, 150);
        },
        async eSearchLaboratories() {
            this.laboratory.dialogLoaderData = true;
            if (this.mRuleEmptyField(this.laboratory.search)) this.laboratory.search = "";
            this.laboratoriesList = [{ id: 0, laboratory: "Todos" }];
            try {
                const response = await Laboratory.getLaboratories(1, this.laboratory.search);
                response.data.forEach(laboratory => {
                    this.laboratoriesList.push(laboratory);
                });
                this.laboratory.dialogLoaderData = false;
            } catch(error) {
                this.dialogLaboratories = false;
                this.processErrorServer(error);
            }
        },
        eCloseSearchLaboratory() {
            this.laboratory.search = "";
            this.laboratoriesList = [{ id: 0, laboratory: "Todos" }];
            this.dialogLaboratories = false;
        },
        eSelectLaboratory(laboratory) {
            this.laboratory.id = laboratory.id;
            this.laboratory.name = laboratory.laboratory;
            this.eCloseSearchLaboratory();
        },
        eOpenFormSelect(product) {
            this.product = JSON.parse(JSON.stringify(product));
            this.dialogFormOrder = true;
        },
        eCloseDialogAddOrder() {
            this.indexDetail = -1;
            this.$refs.addordercomp.resetForm();
            this.dialogFormOrder = false;
        },
        eAddOder(isValidForm) {
            if (isValidForm) {
                if (this.indexDetail === -1) {
                    this.detailOrder.push(this.product);
                    this.eCloseDialogAddOrder();
                    this.mDataGeneralShowSmsAction(
                        ["Agregado a detalle", `Se agregó ${this.product.product}`], "success");
                } else {
                    Object.assign(this.detailOrder[this.indexDetail], this.product);
                    this.eCloseDialogAddOrder();
                    this.mDataGeneralShowSmsAction(
                        ["Cantidad Editada", `Se editó ${this.product.product}`], "success");
                }
            } else this.mDataGeneralShowSmsAction(
                ["Erro de cantidad", "Ingrese un número entero"], "error");
        },
        eDeleteProductDetail(index) {
            this.detailOrder.splice(index, 1);
            this.mDataGeneralShowSmsAction(
                ["Operación realizada", "Se quitó el producto del detalle"], "success");
        },
        eOpenFormOrderEdit(product) {
            this.indexDetail = this.detailOrder.indexOf(product);
            this.product = JSON.parse(JSON.stringify(product));
            this.dialogFormOrder = true;
        },
        eCloseFormAddress() {
            this.$refs.addresscomp.resetForm();
            this.dialogFormAddressedTo = false;
        },
        eGeneratePdf(parameters) {
            if (parameters.isValidForm) {
                this.dialogFormAddressedTo = false;
                this.dialogLoaderOperation = true;
                this.exportPDF(parameters.addressedTo);
                setTimeout(() => {
                    this.$refs.addresscomp.resetForm();
                    this.dialogLoaderOperation = false;
                }, 500);
            } else this.mDataGeneralShowSmsAction(
                ["Error de Datos", "Corrija los datos en rojo"], "error");
        },
        exportPDF(addressedTo) {
            var logo = new Image();
            logo.src = '/imgs/logo.png';
            const doc = new jsPDF();
            doc.addImage(logo, 'PNG', 178, 5, 20, 20);
            doc.setFontSize(14);
            doc.text("NAZARET", 177, 30);
            doc.setFontSize(12);
            doc.text("Asunto: PEDIDO", 10, 15);
            doc.text("Dirigido a:", 10, 22);
            doc.text(addressedTo, 31, 22);
            doc.text("Fecha Realización:", 10, 29);
            doc.text(this.mDateSystem(true), 47, 29);
            doc.autoTable({
                html: '#tabla',
                margin: { top: 35, left: 10, right: 10 },
                theme: 'grid',
                styles: { 0: {fillColor: [255, 0, 0]} }
            });
            const dateSystem = this.mDateSystem(false);
            const nameDoc = `Pedido_${this.mDateFormatFile(dateSystem)}`;
            doc.save(`${nameDoc}.pdf`);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpOrders);
                this.getRequest();
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpOrders);
    }
}
</script>