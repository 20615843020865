<template>
    <div>
        <v-tooltip bottom content-class="color-tooltip">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    :ripple="false"
                    @click="$emit('click')"
                >
                    <v-icon class="color-icon">{{icon}}</v-icon>
                </v-btn>
            </template>
            <MSmsToolTipComp
                :smsToolTip="smsToolTip"
            />
        </v-tooltip>
    </div>
</template>
<script>
import { MSmsToolTipComp } from "../molecules";
export default {
    components: {
        MSmsToolTipComp
    },
    props: {
        icon: String,
        smsToolTip: String
    }
}
</script>
