import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';

class LowInventory {
    constructor(
        quantity = null
    ) {
        this.quantity = quantity;
    }
    static async getLowInventory(page, search, dateExpiration, dateOne, dateTwo, idProduct) {
        const response =  await axios.get(
            `${apiUrls.LOW_INVENTORY}/list?page=${page}&search=${search}&date_expiration=${dateExpiration}&date_one=${dateOne}&date_two=${dateTwo}&fk_product=${idProduct}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async reverseInventory(parameters) {
        const response =  await axios.post(
            `${apiUrls.LOW_INVENTORY}/reverse`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deleteLowInventory(parameters) {
        const response = await axios.delete(
            `${apiUrls.LOW_INVENTORY}/delete`,
            { headers: userStore.state.header, data: parameters }
        );
        return response.data;
    }
}
export default LowInventory;