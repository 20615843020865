<template>
    <div>
        <div class="pa-2 d-flex align-center">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    CONFIG. FACTURA
                </h1>
            </div>
            <v-spacer></v-spacer>
            <OBtnToolTipHeaderComp
                icono="mdi-database"
                class="mr-1"
                smsToolTip="Volver atras"
                @click="eBackList()"
            />
            <OBtnToolTipHeaderComp
                icono="mdi-plus"
                smsToolTip="Registrar configuraciones"
                @click="eOpenForm()"
            />
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN INFORMACIÓN"
                    class="ma-1"
                    v-if="!dialogLoaderData && settingBill.id==undefined"
                />
                <div
                    v-if="settingBill.id != undefined"
                    class="pa-2 animation-opacity"
                >
                    <div class="d-flex">
                        <h1 class="app-title">NIT:</h1>
                        <h1 class="app-subtitle ml-1">
                            {{settingBill.nit}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title">Razón Social:</h1>
                        <h1 class="app-subtitle ml-1">
                            {{settingBill.business_name}}</h1>
                    </div>
                    <div class="d-flex flex-wrap">
                        <h1 class="app-title mr-1">Actividad Principal:</h1>
                        <h1 class="app-subtitle">
                            {{settingBill.main_activity}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title">Dirección:</h1>
                        <h1 class="app-subtitle ml-1">
                            {{settingBill.direction}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title">Celular:</h1>
                        <h1 class="app-subtitle ml-1">
                            {{settingBill.cell_phone!=null ? settingBill.cell_phone : "S/N"}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title">Teléfono:</h1>
                        <h1 class="app-subtitle ml-1">
                            {{settingBill.phone!=null ? settingBill.phone : "S/T"}}</h1>
                    </div>
                    <div class="d-flex flex-wrap">
                        <h1 class="app-title mr-1">N° Autorización:</h1>
                        <h1 class="app-subtitle">
                            {{settingBill.authorization_number}}</h1>
                    </div>
                    <div class="d-flex flex-wrap">
                        <h1 class="app-title mr-1">Llave Dosificación:</h1>
                        <h1
                            v-for="(text, index) in listText()"
                            :key="index"
                            class="app-subtitle"
                        >
                            {{text}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title">Fecha Expiración:</h1>
                        <h1 class="app-subtitle ml-1">
                            {{mDateFormat(settingBill.expiration_date)}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title">N° Factura:</h1>
                        <h1 class="app-subtitle ml-1">
                            {{settingBill.number_bill}}</h1>
                    </div>
                    <MBtnNormalComp
                        class="mt-1"
                        @click="eOpenEditForm()"
                    >
                        ACTUALIZAR CONF. FACTURA
                    </MBtnNormalComp>
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center pa-2">
                    <SFormSettingBillComp
                        ref="formsettingcomp"
                        :mobile="mobile"
                        :dataSettingBill="dataSettingBill"
                        :title="isAdd ? 'REGISTRAR CONFIGURACIÓN' : 'ACTUALIZAR CONFIGURACIÓN'"
                        @saveform="eConfirmForm"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            width="650"
            @clickno="dialogConfirm=false"
            @clickyes="ePerformOperation()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MNoDataComp,
    MBtnNormalComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OLoaderDataComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp
} from "../../components/organisms";
import {
    SFormSettingBillComp
} from "../../components/species";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    animationScrollMixin,
    userMixin,
    datesMixin,
    rulesMixin,
} from "../../mixins";
import {
    SettingBill
} from "../../models";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        animationScrollMixin,
        userMixin,
        datesMixin,
        rulesMixin
    ],
    components: {
        OBtnToolTipHeaderComp,
        OLoaderDataComp,
        MNoDataComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        SFormSettingBillComp,
        MBtnNormalComp
    },
    data: () => ({
        settingBill: {},
        tab: 0,
        dataSettingBill: new SettingBill,
        isAdd: true
    }),
    methods: {
        handleKeyUpSettingBill(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 1) this.ePerformOperation();
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else this.dialogError = false;
        },
        listText() {
            if (window.innerWidth <= 600) {
                var text = this.settingBill.dosage_key;
                var formText = [];
                var textAltern = "";
                var count = 1;
                for (var i = 0; i < text.length; i++) {
                    textAltern += text[i];
                    if (count === 30) {
                        formText.push(textAltern);
                        textAltern = "";
                        count = 1;
                    }
                    ++count;
                }
                formText.push(textAltern);
                return formText;
            } else return [this.settingBill.dosage_key];
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getSettingBill() {
            this.dialogLoaderData = true;
            try {
                const response = await SettingBill.getSettingBill();
                this.settingBill = response.data;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eOpenForm() {
            if (this.settingBill.id === undefined) {
                this.tab = 1;
                setTimeout(() => {
                    this.$refs.formsettingcomp.activateFocus();
                }, 150);
            } else {
                const smsError = "Ya se realizó el registro de las configuraciones";
                this.mDataGeneralShowSmsAction(["Operación Denegada", smsError], "error");
            }
        },
        eBackList() {
            if (this.tab !== 0) {
                this.isAdd = true;
                this.tab = 0;
                this.dataSettingBill = new SettingBill;
                this.$refs.formsettingcomp.resetForm();
                this.dialogLoaderOperation = false;
            }
        },
        eConfirmForm(isValidForm) {
            const firstSms = this.isAdd ? 'registrar' : 'actualizar';
            const smsConfirm = [
                `¿${this.mUserFullName()}, está seguro(a) de ${firstSms} las configuraciones:`,
                `N° Autorización: ${this.dataSettingBill.authorization_number}`,
                `Llave Dosificación: ${this.dataSettingBill.dosage_key}`,
                `Fecha Expiración: ${this.mDateFormat(this.dataSettingBill.expiration_date)}?`
            ];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
            }, time);
        },
        async addSettingBill() {
            try {
                const response = await SettingBill.addSettingBill(this.dataSettingBill);
                this.settingBill = response;
                this.eBackList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se registró las configuraciones"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async editSettingBill() {
            try {
                const response = await SettingBill.editSettingBill(this.dataSettingBill);
                this.settingBill = response;
                this.eBackList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se actualizó las configuraciones"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        ePerformOperation() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (this.isAdd)
                this.addSettingBill();
            else this.editSettingBill();
        },
        eOpenEditForm() {
            this.dataSettingBill = Object.assign({}, this.settingBill);
            this.isAdd = false;
            this.tab = 1;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpSettingBill);
                this.getSettingBill();
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpSettingBill);
    }
}
</script>