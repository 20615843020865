import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';

class ProductPurchase {
    constructor(
        expiration_date = null,
        quantity = null,
        unit_price = null,
        commercial_discount = 0,
        other_discount = 0,
        product_id = 0,
        product = null,
        purchase_id = null
    ) {
        this.expiration_date = expiration_date;
        this.quantity = quantity;
        this.unit_price = unit_price;
        this.commercial_discount = commercial_discount;
        this.other_discount = other_discount;
        this.product_id = product_id;
        this.product = product;
        this.purchase_id = purchase_id;
    }
    static async deleteProductDetail(parameters) {
        const response =  await axios.post(
            `${apiUrls.PRODUCT_PURCHASE}/delete`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editProductDetail(parameters) {
        const response =  await axios.put(
            `${apiUrls.PRODUCT_PURCHASE}/edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addProductDetail(parameters) {
        const response =  await axios.post(
            `${apiUrls.PRODUCT_PURCHASE}/add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default ProductPurchase;