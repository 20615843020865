<template>
    <v-dialog
        persistent
        :value="value"
        max-width="600"
    >
        <v-card>
            <div class="s-detail-product__header pl-2 pr-2 d-flex align-center">
                <h1 class="app-title">
                    {{title}}
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <div class="client__line-header"></div>
            <div
                v-if="payment.id != undefined"
                class="pa-2 pb-1"
            >
                <div class="d-flex">
                    <h1 class="app-title mr-1">Pago a realizar:</h1>
                    <h1 class="app-subtitle">
                        {{`${payment.payment.toFixed(2)} Bs.`}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="app-title mr-1">Glosa:</h1>
                    <h1 class="app-subtitle">
                        {{payment.glos}}
                    </h1>
                </div>
            </div>
            <v-divider class="mb-2"></v-divider>
            <v-form
                v-model="valid"
                lazy-validation
                ref="formpayment"
                class="pa-2"
                @submit.prevent="eValidForm()"
            >
                <MTextFieldComp
                    label="Pago Realizado"
                    v-model="dataPaymentCompliance.payment_compliance"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El pago es requerido',
                        value => this.mRuleNumberDecimal(value) || 'El pago debe ser un número'
                    ]"
                />
            </v-form>
            <div class="d-flex justify-center pa-2 pt-0">
                <MBtnNormalComp
                    @click="eValidForm()"
                >
                    <v-icon>mdi-bookmark-check</v-icon>
                    GUARDAR
                </MBtnNormalComp>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp
} from "../molecules";
import {
    OBtnCardComp
} from "../organisms";
import {
    rulesMixin
} from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        OBtnCardComp,
        MTextFieldComp,
        MBtnNormalComp
    },
    props: {
        title: String,
        value: Boolean,
        dataPaymentCompliance: Object,
        payment: Object
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.$refs.formpayment.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.formpayment.resetValidation();
        }
    }
}
</script>