import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';
class Payment {
    constructor (
        payment = null,
        glos = null,
        destination_date = null,
        fk_cash_register = null
    )
    {
        this.payment = payment;
        this.glos = glos;
        this.destination_date = destination_date;
        this.fk_cash_register = fk_cash_register;
    }
    static async getPaymentsUser(fkCashRegister) {
        const response = await axios.get(
            `${apiUrls.PAYMENT}/list?fk_cash_register=${fkCashRegister}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getPayments(page, dateOne, dateTwo, fkCashRegister, fkUser = 0, createdAt = 'created_at') {
        const response = await axios.get(
            `${apiUrls.PAYMENT}/list-admin?page=${page}&date_one=${dateOne}&date_two=${dateTwo}&fk_cash_register=${fkCashRegister}&fk_user=${fkUser}&created_at=${createdAt}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addPayment(parameters) {
        const response = await axios.post(
            `${apiUrls.PAYMENT}/add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editPayment(parameters) {
        const response = await axios.put(
            `${apiUrls.PAYMENT}/edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deletePayment(parameters) {
        const response = await axios.delete(
            `${apiUrls.PAYMENT}/delete`,
            { headers: userStore.state.header, data: parameters }
        );
        return response.data;
    }
}
export default Payment;