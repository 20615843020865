<template>
    <v-dialog
        persistent
        width="340px"
        :value="value"
    >
        <v-card class="pa-0 pb-3 ma-0">
            <div class="d-flex align-center pa-2">
                <h1 class="app-title">
                    Ajustar Lotes
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <v-divider></v-divider>
            <div v-if="product.id != undefined" class="pa-2 pt-1 pb-1">
                <h1 class="app-subtitle">
                    {{product.product}}
                </h1>
                <div class="d-flex">
                    <h1 class="app-title mr-1">Cantidad:</h1>
                    <h1 class="app-subtitle">
                        {{product.select_quantity}}
                    </h1>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="pa-2 pt-0">
                <div
                    class="d-flex align-center mt-2"
                    v-for="inventory in product.inventory_outputs"
                    :key="inventory.id"
                >
                    <h1 class="app-subtitle mr-2">
                        {{`${mDateFormat(inventory.expiration_date)}:`}}
                    </h1>
                    <MTextFieldHeaderComp
                        label="Cantidad"
                        :clearable="false"
                        v-model="inventory.quantity"
                    />
                </div>
            </div>
            <div class="d-flex justify-center mt-2">
                <MBtnNormalComp
                    @click="eValidData()"
                >
                    <v-icon>mdi-bookmark-check</v-icon>
                    GUARDAR
                </MBtnNormalComp>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    MTextFieldComp,
    MTextFieldHeaderComp,
    MBtnNormalComp
} from "../molecules";
import {
    OBtnCardComp
} from "../organisms";
import {
    datesMixin,
    rulesMixin
} from "../../mixins";
export default {
    mixins: [
        datesMixin,
        rulesMixin
    ],
    components: {
        MTextFieldComp,
        OBtnCardComp,
        MBtnNormalComp,
        MTextFieldHeaderComp
    },
    props: {
        value: Boolean,
        product: Object
    },
    methods: {
        eValidData() {
            var isValidForm = true;
            var errorQuantity = false;
            var total = 0;
            for (var i = 0; i < this.product.inventory_outputs.length; i++) {
                if (this.mRuleEmptyField(this.product.inventory_outputs[i].quantity)
                    || !this.mRuleNumberInteger(this.product.inventory_outputs[i].quantity)) {
                        isValidForm = false;
                        break;
                } else total += parseInt(this.product.inventory_outputs[i].quantity);
            }
            if (total !== parseInt(this.product.select_quantity)) {
                console.log(total+'  '+this.product.select_quantity)
                isValidForm = false;
                errorQuantity = true;
            }
            this.$emit('clickedit', {
                'isValidForm': isValidForm,
                'errorQuantity': errorQuantity
            });
        }
    }
}
</script>