var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-form-product"},[_c('h1',{staticClass:"app-title text-center mb-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-form',{ref:"formsttingbill",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"NIT:"}},[_c('MTextFieldComp',{ref:"nit",attrs:{"label":_vm.mobile ? 'NIT' : '',"counter":50,"rules":[
                    value => !this.mRuleEmptyField(value) || 'Es requerido',
                    value => this.mRuleNumberInteger(value) || 'Debe ser un número',
                    value => !this.mRuleWithSpaces(value) || 'No debe contener espacios',
                    value => (`${value}`).length<=50 || 'No debe exceder los 50 dígitos'
                ]},model:{value:(_vm.dataSettingBill.nit),callback:function ($$v) {_vm.$set(_vm.dataSettingBill, "nit", $$v)},expression:"dataSettingBill.nit"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Razón Social:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Razón Social' : '',"counter":100,"rules":[
                    value => !this.mRuleEmptyField(value) || 'Es requerido',
                    value => (`${value}`).length<=100 || 'No debe exceder los 100 dígitos'
                ]},model:{value:(_vm.dataSettingBill.business_name),callback:function ($$v) {_vm.$set(_vm.dataSettingBill, "business_name", $$v)},expression:"dataSettingBill.business_name"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Actividad Principal:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Actividad Principa' : '',"counter":150,"rules":[
                    value => !this.mRuleEmptyField(value) || 'Es requerido',
                    value => (`${value}`).length<=150 || 'No debe exceder los 150 dígitos'
                ]},model:{value:(_vm.dataSettingBill.main_activity),callback:function ($$v) {_vm.$set(_vm.dataSettingBill, "main_activity", $$v)},expression:"dataSettingBill.main_activity"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Dirección:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Dirección' : '',"counter":150,"rules":[
                    value => !this.mRuleEmptyField(value) || 'Es requerido',
                    value => (`${value}`).length<=150 || 'No debe exceder los 150 dígitos'
                ]},model:{value:(_vm.dataSettingBill.direction),callback:function ($$v) {_vm.$set(_vm.dataSettingBill, "direction", $$v)},expression:"dataSettingBill.direction"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"N° Celular:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'N° Celular' : '',"counter":10,"rules":[
                    value => (`${value}`).length<=10 || 'No debe exceder los 10 dígitos'
                ]},model:{value:(_vm.dataSettingBill.cell_phone),callback:function ($$v) {_vm.$set(_vm.dataSettingBill, "cell_phone", $$v)},expression:"dataSettingBill.cell_phone"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"N° Teléfono:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'N° Teléfono' : '',"counter":10,"rules":[
                    value => (`${value}`).length<=10 || 'No debe exceder los 10 dígitos'
                ]},model:{value:(_vm.dataSettingBill.phone),callback:function ($$v) {_vm.$set(_vm.dataSettingBill, "phone", $$v)},expression:"dataSettingBill.phone"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"N° de autorización:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'N° de autorización' : '',"counter":30,"rules":[
                    value => !this.mRuleEmptyField(value) || 'Es requerido',
                    value => (`${value}`).length<=30 || 'No debe exceder los 30 dígitos'
                ]},model:{value:(_vm.dataSettingBill.authorization_number),callback:function ($$v) {_vm.$set(_vm.dataSettingBill, "authorization_number", $$v)},expression:"dataSettingBill.authorization_number"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Llave de dosificación:"}},[_c('MTextAreaComp',{attrs:{"label":_vm.mobile ? 'Llave de dosificación' : '',"counter":150,"height":"95","rules":[
                    value => !this.mRuleEmptyField(value) || 'Es requerido',
                    value => !this.mRuleWithSpaces(value) || 'No debe contener espacios',
                    value => (`${value}`).length<=150 || 'No debe exceder los 150 dígitos'
                ]},model:{value:(_vm.dataSettingBill.dosage_key),callback:function ($$v) {_vm.$set(_vm.dataSettingBill, "dosage_key", $$v)},expression:"dataSettingBill.dosage_key"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Fecha de Expiración:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Fecha de Expiració' : '',"type":"date","rules":[
                    value => !this.mRuleEmptyField(value) || 'Es requerido'
                ]},model:{value:(_vm.dataSettingBill.expiration_date),callback:function ($$v) {_vm.$set(_vm.dataSettingBill, "expiration_date", $$v)},expression:"dataSettingBill.expiration_date"}})],1)],1),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('MBtnNormalComp',{on:{"click":function($event){return _vm.eValidForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }