var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-form-product"},[_c('h1',{staticClass:"app-title text-center mb-2"},[_vm._v(" "+_vm._s(_vm.indexUser===-1 ? 'REGISTRAR' : 'EDITAR')+" ")]),_c('v-form',{ref:"formuser",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.actionForm!=2)?_c('div',[_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Nombre Completo:"}},[_c('MTextFieldComp',{ref:"name",attrs:{"label":_vm.mobile ? 'Nombre Completo' : '',"counter":50,"rules":[
                        value => !this.mRuleEmptyField(value) || 'El nombre es requerido',
                        value => (`${value}`).length<=50 || 'El nombre, no debe exceder los 50 dígitos'
                    ]},model:{value:(_vm.dataUser.full_name),callback:function ($$v) {_vm.$set(_vm.dataUser, "full_name", $$v)},expression:"dataUser.full_name"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Categoría:"}},[_c('MSelectComp',{attrs:{"label":_vm.mobile ? 'Categoría' : '',"items":_vm.itemsCategories,"itemValue":"value","itemText":"name","rules":[
                        value => !this.mRuleEmptyField(value) || 'La categoría es requerida',
                    ]},model:{value:(_vm.dataUser.categorie),callback:function ($$v) {_vm.$set(_vm.dataUser, "categorie", $$v)},expression:"dataUser.categorie"}})],1)],1):_vm._e(),(_vm.actionForm!=1)?_c('div',[_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Usuario:"}},[_c('MTextFieldComp',{ref:"user",attrs:{"label":_vm.mobile ? 'Usuario' : '',"counter":15,"rules":[
                        value => !this.mRuleEmptyField(value) || 'El usuario es requerido',
                        value => !this.mRuleWithSpaces(value) || 'El usuario no debe contener espacios',
                        value => (`${value}`).length<=15 || 'El usuario, no debe exceder los 30 dígitos'
                    ]},model:{value:(_vm.dataUser.username),callback:function ($$v) {_vm.$set(_vm.dataUser, "username", $$v)},expression:"dataUser.username"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Contraseña:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Contraseña' : '',"counter":15,"type":_vm.isText ? 'text' : 'password',"icon":_vm.isText ? 'mdi-eye-off' : 'mdi-eye',"rules":[
                        value => !this.mRuleEmptyField(value) || 'La contraseña es requerido',
                        value => !this.mRuleWithSpaces(value) || 'La contrseña no debe contener espacios',
                        value => (`${value}`).length<=15 || 'La contraseña, no debe exceder los 15 dígitos'
                    ]},on:{"clicksee":function($event){_vm.isText = !_vm.isText}},model:{value:(_vm.dataUser.password),callback:function ($$v) {_vm.$set(_vm.dataUser, "password", $$v)},expression:"dataUser.password"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Repita Contraseña:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Repita Contraseña' : '',"counter":15,"type":_vm.isText ? 'text' : 'password',"icon":_vm.isText ? 'mdi-eye-off' : 'mdi-eye',"rules":[
                        value => !this.mRuleEmptyField(value) || 'La contraseña es requerido',
                        value => this.eValidarRepitPassword(value, _vm.dataUser.password) || 'La contraseña no coincide con la anterior.'
                    ]},on:{"clicksee":function($event){_vm.isText = !_vm.isText}},model:{value:(_vm.dataUser.repit_password),callback:function ($$v) {_vm.$set(_vm.dataUser, "repit_password", $$v)},expression:"dataUser.repit_password"}})],1)],1):_vm._e()]),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('MBtnNormalComp',{on:{"click":function($event){return _vm.eValidForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }