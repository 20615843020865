<template>
    <div @click="$emit('click')" style="cursor:pointer">
        <v-snackbar
            :value="value"
            right
            :bottom="true"
            :timeout="timeSucess"
            :width="size"
            :min-width="size"
            :max-width="size"
            :color="colorMessage"
            content-class="o-sms-action"
        >
            <div class="d-block">
                <h1
                    class="app-title-dark mt-1"
                    v-for="(text, index) in smsAction"
                    :key="index"
                >{{text}}</h1>
            </div>
        </v-snackbar>
    </div>
</template>
<script>
export default {
    props: {
        value: Boolean,
        smsAction: Array,
        colorMessage: {
            default: "success",
            type: String
        },
        timeSucess: Number
    },
    data: () => ({
        size: 320
    })
}
</script>
