<template>
    <v-app>
        <router-view />
    </v-app>
    <!--VUE_APP_ROOT_API=http://127.0.0.1:8000/api/-->
</template>
<script>
export default {
    name: "App",
    mounted() {
        /*var nodoImg = document.createElement("img");
        nodoImg.setAttribute("src", '/imgs/logo.png');
        nodoImg.onload = function() {

        }*/
    }
}
</script>
<style src="./sass_styles/main.scss" lang="scss"></style>
<style lang="sass">
    @import '../node_modules/typeface-roboto/index.css'
</style>
