<template>
    <div>
        <div class="pa-2 d-flex align-center">
            <OBtnToolTipHeaderComp
                v-if="!mobile"
                :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                smsToolTip="Ocultar Menú"
                @click="$emit('clickmini')"
            />
            <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                CONFIGURACIONES
            </h1>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Información"
        />
        <div
            v-if="setting.id != undefined"
            class="pa-2 animation-opacity"
        >
            <div class="d-flex">
                <h1 class="app-title">Lim. Max. Bajo Inv.:</h1>
                <h1 class="app-subtitle ml-1">
                    {{setting.low_inventory}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title">Lim. Max. Moderado Inv.:</h1>
                <h1 class="app-subtitle ml-1">
                    {{setting.regular_inventory}}</h1>
            </div>
            <div>
                <MBtnNormalComp
                    class="mt-1"
                    @click="eOpenForm()"
                >
                    ACTUALIZAR VALORES
                </MBtnNormalComp>
            </div>
            <MBtnNormalComp
                v-if="iExistDevice()"
                class="mt-2"
                @click="eConfirmDeleteCashRegister()"
            >
                ANULAR CAJA REG.
            </MBtnNormalComp>
        </div>
        <v-dialog
            persistent
            v-model="dialogForm"
            width="500"
        >
            <v-card>
                <div class="s-card-client__header pl-2 pr-2 d-flex align-center">
                    <h1 class="app-title">
                        ACTUALIZAR VALORES
                    </h1>
                    <v-spacer></v-spacer>
                    <OBtnCardComp
                        icon="mdi-close"
                        @click="dialogForm=false"
                    />
                </div>
                <div class="client__line-header"></div>
                <v-form
                    v-model="valid"
                    lazy-validation
                    ref="formsetting"
                    class="pa-2 pt-4"
                >
                    <MTextFieldComp
                        label="Lim. Mín. Bajo Inventario"
                        v-model="editSetting.low_inventory"
                        :rules="[
                            value => !this.mRuleEmptyField(value) || 'El campo es requerido',
                            value => this.mRuleNumberInteger(value) || 'El valor debe ser un número entero'
                        ]"
                    />
                    <MTextFieldComp
                        label="Lim. Max. Moderado Inv."
                        v-model="editSetting.regular_inventory"
                        :rules="[
                            value => !this.mRuleEmptyField(value) || 'El campo es requerido',
                            value => this.mRuleNumberInteger(value) || 'El valor debe ser un número entero'
                        ]"
                    />
                    <div class="d-flex justify-center">
                        <MBtnNormalComp
                            @click="eValidForm()"
                        >
                            <v-icon>mdi-bookmark-check</v-icon>
                            GUARDAR
                        </MBtnNormalComp>
                    </div>
                </v-form>
            </v-card>
        </v-dialog>
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="eCloseDialogConfirm()"
            @clickyes="ePerformOperation()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MBtnNormalComp,
    MTextFieldComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OLoaderDataComp,
    OBtnCardComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp
} from "../../components/organisms";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    animationScrollMixin,
    userMixin,
    datesMixin,
    rulesMixin
} from "../../mixins";
import {
    Setting
} from "../../models";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        animationScrollMixin,
        userMixin,
        datesMixin,
        rulesMixin
    ],
    components: {
        OBtnToolTipHeaderComp,
        OLoaderDataComp,
        MBtnNormalComp,
        OBtnCardComp,
        MTextFieldComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp
    },
    data: () => ({
        setting: {},
        dialogForm: false,
        editSetting: new Setting,
        valid: true,
        isDeleteCashRegister: false
    }),
    methods: {
        handleKeyUpSetting(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 0) this.eCloseDialogConfirm();
                if (actionKeyBoard === 1) this.eUpdateSetting();
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else {
                this.dialogError = false;
                this.dialogForm = true;
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getSetting() {
            this.dialogLoaderData = true;
            try {
                const response = await Setting.getSetting();
                this.setting = response;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eOpenForm() {
            this.editSetting = Object.assign({}, this.setting);
            this.dialogForm = true;
        },
        eValidForm() {
            this.dialogForm = false;
            const isValidForm = this.$refs.formsetting.validate()
            const smsConfirm = [`¿Está seguro(a) de actualizar los valores?`];
            this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
        },
        eCloseDialogConfirm() {
            this.isDeleteCashRegister = false;
            this.dialogConfirm = false;
            this.dialogForm = true;
        },
        async eUpdateSetting() {
            try {
                const response = await Setting.updateSetting(this.editSetting);
                this.setting = response;
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se actualizó los valores"], "success");
                this.dialogLoaderOperation = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        iExistDevice() {
            if (JSON.parse(localStorage.getItem("device"))) return true;
            return false;
        },
        eConfirmDeleteCashRegister() {
            this.isDeleteCashRegister = true;
            this.smsConfirm = [`¿Está seguro(a) de anular la caja registradora para este dispositivo?`];
            this.dialogConfirm = true;
        },
        deleteCashRegister() {
            localStorage.removeItem("device");
            localStorage.removeItem("nazaret");
            setTimeout(() => {
                this.$router.replace('/login');
            }, 300);
        },
        ePerformOperation() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (this.isDeleteCashRegister) this.deleteCashRegister();
            else this.eUpdateSetting();
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpSetting);
                this.getSetting();
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpSetting);
    }
}
</script>