<template>
    <div class="s-menu-pc">
        <v-navigation-drawer
            permanent
            :mini-variant="mini"
            class="s-menu-pc__navegation"
            width="300"
            fixed
        >
            <v-card
                class="pa-1 ma-0 rounded-0 d-flex justify-center align-center"
                elevation="0"
                min-height="48"
            >
                <v-img
                    src="/imgs/logo.png"
                    :height="mini ? sizeLogoMini : sizeLogo"
                    :max-height="mini ? sizeLogoMini : sizeLogo"
                    :width="mini ? sizeLogoMini : sizeLogo"
                    :max-width="mini ? sizeLogoMini : sizeLogo"
                ></v-img>
                <h1 v-if="!mini" class="ml-3 app-title-menu">Nazaret</h1>
            </v-card>
            <v-list
                nav
            >
                <div
                    v-for="(option, index) in menu"
                    :key="index"
                >
                    <div v-if="option.submenu.length>0">
                        <v-list-group
                            no-action
                            :ripple="false"
                            color="white"
                            v-if="!mini"
                            :class="(index+1)==menu.length ? '' : 'mb-2'"
                        >
                            <template v-slot:activator>
                                <v-list-item
                                    :ripple="false"
                                    class="ma-0 pl-0"
                                >
                                    <v-icon class="s-menu-pc__list-icon mr-2">{{option.icon}}</v-icon>
                                    <h1 class="app-subtitle-dark">{{option.title}}</h1>
                                </v-list-item>
                            </template>
                            <div
                                v-for="(child, subindex) in option.submenu"
                                :key="subindex"
                                class="pl-7"
                            >
                                <v-list-item
                                    :to="child.route"
                                    class="s-menu-pc__list-option"
                                    :ripple="false"
                                    active-class="s-menu-pc__list-option-activate"
                                >
                                    <v-icon class="s-menu-pc__list-icon mr-2">{{child.icon}}</v-icon>
                                    <h1 class="app-subtitle-dark">{{child.title}}</h1>
                                </v-list-item>
                            </div>
                        </v-list-group>
                        <v-menu
                            v-else
                            transition="slide-x-transition"
                            bottom
                            right
                            offset-x
                            z-index="60"
                            open-on-hover
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item
                                    :ripple="false"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="s-menu-pc__list-option"
                                >
                                    <v-icon class="s-menu-pc__list-icon">
                                        {{option.icon}}</v-icon>
                                </v-list-item>
                            </template>
                            <v-card class="pa-1">
                                <v-list-item
                                    v-for="(child, index) in option.submenu"
                                    :key="index"
                                    :to="child.route"
                                    class="s-menu-pc__menu-option"
                                    :ripple="false"
                                    active-class="s-menu-pc__menu-option-activate"
                                >
                                    <v-icon class="color-icon mr-2">{{child.icon}}</v-icon>
                                    <h1 class="app-subtitle">{{child.title}}</h1>
                                </v-list-item>
                            </v-card>
                        </v-menu>
                    </div>
                    <div v-else>
                        <div v-if="mini">
                            <v-tooltip right color="#010d14">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item
                                        v-bind="attrs"
                                        v-on="on"
                                        :to="option.route"
                                        :ripple="false"
                                        :class="(index+1)==menu.length
                                            ? 's-menu-pc__list-option'
                                            : 's-menu-pc__list-option mb-2'"
                                    >
                                        <v-icon class="s-menu-pc__list-icon">{{option.icon}}</v-icon>
                                    </v-list-item>
                                </template>
                                <div class="d-flex">
                                    <v-icon class="s-menu-pc__list-icon mr-2">mdi-hand-pointing-right</v-icon>
                                    <h1 class="app-title-dark">{{option.title}}</h1>
                                </div>
                            </v-tooltip>
                        </div>
                        <v-list-item
                            v-else
                            :to="option.route"
                            :ripple="false"
                            :class="(index+1)==menu.length
                                ? 's-menu-pc__list-option'
                                : 's-menu-pc__list-option mb-2'"
                            active-class="s-menu-pc__list-option-activate"
                        >
                            <v-icon class="s-menu-pc__list-icon mr-2">{{option.icon}}</v-icon>
                            <h1 class="app-subtitle-dark">{{option.title}}</h1>
                        </v-list-item>
                    </div>
                </div>
                <div v-if="validCashRegister()">
                    <div v-if="mini">
                        <v-tooltip right color="#010d14">
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="eCloseSession()"
                                    :ripple="false"
                                >
                                    <v-icon class="s-menu-pc__list-icon">
                                        mdi-arrow-right-bold-box-outline
                                    </v-icon>
                                </v-list-item>
                            </template>
                            <div class="d-flex">
                                <v-icon class="s-menu-pc__list-icon mr-2">mdi-hand-pointing-right</v-icon>
                                <h1 class="app-title-dark">Cerrar Sesión</h1>
                            </div>
                        </v-tooltip>
                    </div>
                    <v-list-item
                        v-else
                        class="mt-2"
                        :ripple="false"
                        @click="eCloseSession()"
                        active-class="s-menu-pc__list-option-activate"
                    >
                        <v-icon class="s-menu-pc__list-icon mr-2">
                            mdi-arrow-right-bold-box-outline
                        </v-icon>
                        <h1 class="app-subtitle-dark">Cerrar Sesión</h1>
                    </v-list-item>
                </div>
            </v-list>
        </v-navigation-drawer>
        <OOperationLoaderComp
            smsLoaderOperation="Cerrando Sesión"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    OOperationLoaderComp
} from "../organisms";
import {
    User
} from "../../models";
export default {
    components: {
        OOperationLoaderComp
    },
    props: {
        menu: Array,
        mini: Boolean
    },
    data: () => ({
        sizeLogo: '35',
        sizeLogoMini: '30',
        dialogLoaderOperation: false
    }),
    methods: {
        async eCloseSession() {
            this.dialogLoaderOperation = true;
            try {
                await User.logout();
                this.dialogLoaderOperation = false;
                localStorage.removeItem("nazaret");
                setTimeout(() => {
                    this.$router.replace('/login');
                }, 150);
            } catch (error) {
                localStorage.removeItem("nazaret");
                this.$router.replace('/login');
            }
        },
        validCashRegister() {
            if (JSON.parse(localStorage.getItem("device"))) return false;
            return true;
        }
    }
}
</script>