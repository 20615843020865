<template>
    <v-dialog
        persistent
        width="500"
        :value="dialogTypeProduct"
    >
        <v-card>
            <div class="s-card-client__header pl-2 pr-2 d-flex align-center">
                <h1 class="app-title">
                    NUEVO TIPO PRODUCTO
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <div class="client__line-header"></div>
            <SFormTypeProductComp
                ref="formtypeproductcomp"
                :dataTypeProduct="dataTypeProduct"
                :mobile="mobile"
                :indexTypeProduct="-1"
                :isAloneForm="true"
                textBtn="SELECCIONAR"
                @saveform="eSelectTypeProduct"
            />
        </v-card>
    </v-dialog>
</template>
<script>
import { OBtnCardComp } from "../organisms";
import { SFormTypeProductComp } from "../species";
export default {
    components: {
        SFormTypeProductComp,
        OBtnCardComp
    },
    props: {
        dialogTypeProduct: Boolean,
        dataTypeProduct: Object,
        mobile: Boolean
    },
    methods: {
        eSelectTypeProduct(isValidForm) {
            this.$emit('clickselect', isValidForm);
        },
        resetForm() {
            this.$refs.formtypeproductcomp.resetForm();
        }
    }
}
</script>