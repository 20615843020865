<template>
    <div class="s-form-product">
        <h1 class="app-title text-center mb-2">
            {{title}}
        </h1>
        <v-form
            v-model="valid"
            lazy-validation
            ref="formsttingbill"
        >
            <ORowFormComp
                :mobile="mobile"
                informationRow="NIT:"
            >
                <MTextFieldComp
                    :label="mobile ? 'NIT' : ''"
                    v-model="dataSettingBill.nit"
                    :counter="50"
                    ref="nit"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'Es requerido',
                        value => this.mRuleNumberInteger(value) || 'Debe ser un número',
                        value => !this.mRuleWithSpaces(value) || 'No debe contener espacios',
                        value => (`${value}`).length<=50 || 'No debe exceder los 50 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Razón Social:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Razón Social' : ''"
                    v-model="dataSettingBill.business_name"
                    :counter="100"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'Es requerido',
                        value => (`${value}`).length<=100 || 'No debe exceder los 100 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Actividad Principal:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Actividad Principa' : ''"
                    v-model="dataSettingBill.main_activity"
                    :counter="150"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'Es requerido',
                        value => (`${value}`).length<=150 || 'No debe exceder los 150 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Dirección:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Dirección' : ''"
                    v-model="dataSettingBill.direction"
                    :counter="150"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'Es requerido',
                        value => (`${value}`).length<=150 || 'No debe exceder los 150 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="N° Celular:"
            >
                <MTextFieldComp
                    :label="mobile ? 'N° Celular' : ''"
                    v-model="dataSettingBill.cell_phone"
                    :counter="10"
                    :rules="[
                        value => (`${value}`).length<=10 || 'No debe exceder los 10 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="N° Teléfono:"
            >
                <MTextFieldComp
                    :label="mobile ? 'N° Teléfono' : ''"
                    v-model="dataSettingBill.phone"
                    :counter="10"
                    :rules="[
                        value => (`${value}`).length<=10 || 'No debe exceder los 10 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="N° de autorización:"
            >
                <MTextFieldComp
                    :label="mobile ? 'N° de autorización' : ''"
                    v-model="dataSettingBill.authorization_number"
                    :counter="30"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'Es requerido',
                        value => (`${value}`).length<=30 || 'No debe exceder los 30 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Llave de dosificación:"
            >
                <MTextAreaComp
                    :label="mobile ? 'Llave de dosificación' : ''"
                    v-model="dataSettingBill.dosage_key"
                    :counter="150"
                    height="95"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'Es requerido',
                        value => !this.mRuleWithSpaces(value) || 'No debe contener espacios',
                        value => (`${value}`).length<=150 || 'No debe exceder los 150 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Fecha de Expiración:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Fecha de Expiració' : ''"
                    v-model="dataSettingBill.expiration_date"
                    type="date"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'Es requerido'
                    ]"
                />
            </ORowFormComp>
        </v-form>
        <div class="d-flex justify-center mt-1">
            <MBtnNormalComp
                @click="eValidForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import {
    MBtnNormalComp,
    MTextFieldComp,
    MTextAreaComp
} from "../molecules";
import { ORowFormComp } from "../organisms";
import { rulesMixin } from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        MBtnNormalComp,
        ORowFormComp,
        MTextFieldComp,
        MTextAreaComp
    },
    props: {
        title: String,
        mobile: Boolean,
        dataSettingBill: Object
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.$refs.formsttingbill.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.formsttingbill.resetValidation();
        },
        activateFocus() {
            this.$refs.nit.activateFocus();
        }
    }
}
</script>