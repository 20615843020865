<template>
    <div>
        <div class="client__header pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    PAGOS
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center client__header-block-second">
                <MSelectComp
                    v-model="idCashRegister"
                    :items="cashRegisterList"
                    class="mr-1 text-field__header"
                    itemText="cash_register"
                    itemValue="id"
                    :hideDetails="true"
                    @change="eChangeCashRegister"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-plus"
                    class="mr-1"
                    smsToolTip="Registrar pago"
                    @click="eOpenForm()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    smsToolTip="Volver a pagos"
                    @click="eBackList()"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="product__group-search d-flex justify-center pt-2 pl-2 pr-2">
                    <div class="product__search" style="margin-right:2px">
                        <MTextFieldComp
                            type="date"
                            label="Fecha Inicio"
                            :hideDetails="true"
                            v-model="dateOne"
                            :clearable="false"
                            @change="eChangeDateOne"
                        />
                    </div>
                    <div class="product__search" style="margin-left:2px">
                        <MTextFieldComp
                            type="date"
                            label="Fecha Final"
                            :hideDetails="true"
                            v-model="dateTwo"
                            :clearable="false"
                            @change="eChangeDateTwo"
                        />
                    </div>
                </div>
                <v-divider class="mt-2"></v-divider>
                <div class="pa-2 pt-1 pb-0">
                    <div class="d-flex">
                        <h1 class="app-title mr-1">Cantidad Pagos:</h1>
                        <h1 class="app-subtitle">
                            {{total}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title mr-1">Total Pagos:</h1>
                        <h1 class="app-subtitle">
                            {{`${totalPayments.toFixed(2)} Bs.`}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title mr-1">Total Cancelado:</h1>
                        <h1 class="app-subtitle">
                            {{`${totalPaymentsCompliance.toFixed(2)} Bs.`}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title mr-1">Total Saldo:</h1>
                        <h1 class="app-subtitle">
                            {{`${(totalPayments-totalPaymentsCompliance).toFixed(2)} Bs.`}}
                        </h1>
                    </div>
                </div>
                <v-divider></v-divider>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="paymentsList.length==0 && !dialogLoaderData && mobile"
                />
                <STablePaymentComp
                    v-if="!dialogLoaderData && !mobile"
                    :paymentsList="paymentsList"
                    @clickdetail="eShowDetail"
                    @clickedit="eOpenFormEdit"
                    @clickdelete="eOpenFormDelete"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                     v-if="!dialogLoaderData && mobile"
                >
                    <SCardPaymentComp
                        class="ma-2"
                        v-for="payment in paymentsList"
                        :key="payment.id"
                        :payment="payment"
                        @clickdetail="eShowDetail(payment)"
                        @clickedit="eOpenFormEdit(payment)"
                        @clickdelete="eOpenFormDelete(payment)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="mobile && !dialogLoaderData && paymentsList.length>0"
                ></v-divider>
                <SPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderData && paymentsList.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center pa-2">
                    <SFormPaymentComp
                        :dataPayment="dataPayment"
                        :titleForm="indexPayment===-1 ? 'REGISTRAR' : 'EDITAR'"
                        :mobile="mobile"
                        ref="paymentformcomp"
                        :cashRegisterList="cashRegisterListFilter"
                        @saveform="eConfirmForm"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <SDetailPaymentComp
            :payment="payment"
            :value="dialogDetailPayment"
            @clickclose="dialogDetailPayment=false"
        />
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="eCloseDialogConfirm()"
            @clickyes="ePerformDatabaseAction()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MNoDataComp,
    MSelectComp,
    MTextFieldComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp,
    OLoaderDataComp
} from "../../components/organisms";
import {
    STablePaymentComp,
    SFormPaymentComp,
    SPaginationComp,
    SDetailPaymentComp,
    SCardPaymentComp
} from "../../components/species";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    animationScrollMixin,
    userMixin,
    datesMixin,
    rulesMixin
} from "../../mixins";
import {
    CashRegister,
    Payment
} from "../../models";
export default {
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        animationScrollMixin,
        userMixin,
        datesMixin,
        rulesMixin
    ],
    props: {
        mini: Boolean
    },
    components: {
        OBtnToolTipHeaderComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        OLoaderDataComp,
        MNoDataComp,
        MSelectComp,
        STablePaymentComp,
        SFormPaymentComp,
        MTextFieldComp,
        SPaginationComp,
        SDetailPaymentComp,
        SCardPaymentComp
    },
    data: () => ({
        cashRegisterList: [{id: 0, cash_register: "Todos"}],
        idCashRegister: 0,
        paymentsList: [],
        totalPayments: 0,
        totalPaymentsCompliance: 0,
        dateOne: "",
        dateTwo: "",
        tab: 0,
        dataPayment: new Payment,
        indexPayment: -1,
        idPayment: 0,
        isDelete: false,
        payment: {},
        dialogDetailPayment: false
    }),
    computed: {
        cashRegisterListFilter: function() {
            return this.cashRegisterList.filter(cashRegister => {
                return cashRegister.id !== 0;
            });
        }
    },
    methods: {
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        handleKeyUpPaymentAdmin(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 0) this.eCloseDialogConfirm();
                if (actionKeyBoard === 1) this.ePerformDatabaseAction();
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        resetDataDelete() {
            this.isDelete = false;
            this.idPayment = 0;
            this.indexPayment = -1;
        },
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else {
                if (this.isDelete) this.resetDataDelete();
                this.dialogError = false;
            }
        },
        eOpenForm() {
            this.dataPayment.destination_date = this.mDateSystem(false);
            this.tab = 1;
        },
        eBackList() {
            if (this.tab !== 0) {
                this.indexPayment = -1;
                this.dataPayment = new Payment;
                this.$refs.paymentformcomp.resetForm();
                this.dialogLoaderOperation = false;
                this.tab = 0;
            }
        },
        async getRequest() {
            this.dialogLoaderData = true;
            try {
                const getCashRegisters = CashRegister.getCashRegisters();
                const getPayments = Payment.getPayments(1, "", "", 0);
                const responses = await Promise.all([getCashRegisters, getPayments]);
                responses[0].forEach(element => {
                    this.cashRegisterList.push(element); 
                });
                this.paymentsList = responses[1].data;
                this.lastPage = responses[1].last_page;
                this.currentPage = responses[1].current_page;
                this.total = responses[1].total;
                this.totalPayments = responses[1].total_payments;
                this.totalPaymentsCompliance = responses[1].total_payments_compliance;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eConfirmForm(isValidForm) {
            const smsConfirm = this.indexPayment === -1
                ? [`¿Está seguro(a) de registrar el pago a realizar de
                    ${parseFloat(this.dataPayment.payment).toFixed(2)} Bs.?`]
                : [`¿Está seguro(a) de editar el pago a realizar?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
            }, time);
        },
        eCloseDialogConfirm() {
            if (this.isDelete) {
                this.isDelete = false;
                this.indexPayment = -1;
                this.idPayment = 0;
            }
            this.dialogConfirm = false;
        },
        eOpenFormEdit(payment) {
            if (payment.payment_compliance != null) {
                const sms = ["Operación anulada", "El pago tiene una cancelación realizada y no puede ser editada"];
                this.mDataGeneralShowSmsAction(sms, "error");
            } else {
                this.dataPayment = Object.assign({}, payment);
                this.indexPayment = this.paymentsList.indexOf(payment);
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.tab = 1;
                }, time);
            }
        },
        eOpenFormDelete(payment) {
            if (payment.payment_compliance != null) {
                const sms = ["Operación anulada", "El pago tiene una cancelación realizada y no puede ser eliminada"];
                this.mDataGeneralShowSmsAction(sms, "error");
            } else {
                this.isDelete = true;
                this.indexPayment = this.paymentsList.indexOf(payment);
                this.idPayment = payment.id;
                this.smsConfirm = [`¿Está seguro(a) de eliminar el pago a realizar?`];
                this.dialogConfirm = true;
            }
        },
        async addPayment() {
            try {
                const response = await Payment.addPayment(this.dataPayment);
                this.paymentsList.unshift(response);
                this.total += 1;
                this.totalPayments += response.payment;
                this.eBackList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se registró el pago a realizar"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async editPayment() {
            try {
                const response = await Payment.editPayment(this.dataPayment);
                const newTotalPayments = this.totalPayments - this.paymentsList[this.indexPayment].payment + response.payment;
                Object.assign(this.paymentsList[this.indexPayment], response);
                this.totalPayments = newTotalPayments;
                this.eBackList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se editó el pago a realizar"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async deletePayment() {
            try {
                const response = await Payment.deletePayment({id: this.idPayment});
                this.paymentsList.splice(this.indexPayment, 1);
                this.total -= 1;
                this.totalPayments -= response;
                this.resetDataDelete();
                this.dialogLoaderOperation = false;
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se eliminó el pago a realizar"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (this.isDelete)
                this.deletePayment();
            else {
                if (this.indexPayment === -1)
                    this.addPayment();
                else this.editPayment();
            }
        },
        async getPayments(page, dateOne, dateTwo, idCashRegister) {
            this.dialogLoaderData = true;
            try {
                const response = await Payment.getPayments(page, dateOne, dateTwo, idCashRegister);
                this.paymentsList = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.totalPayments = response.total_payments;
                this.totalPaymentsCompliance = response.total_payments_compliance;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eChangeCashRegister(idCashRegister) {
            this.getPayments(this.currentPage, this.dateOne, this.dateTwo, idCashRegister);
        },
        eChangeDateOne(dateOne) {
            if (dateOne == "" || this.dateTwo == "")
                this.mDataGeneralShowSmsAction(["Error de Fechas", "Seleccione bien las fechas"], "error");
            else {
                if (dateOne > this.dateTwo) this.dateTwo = dateOne;
                this.getPayments(this.currentPage, dateOne, this.dateTwo, this.idCashRegister);
            }
        },
        eChangeDateTwo(dateTwo) {
            if (dateTwo == "" || this.dateOne == "")
                this.mDataGeneralShowSmsAction(["Error de Fechas", "Seleccione bien las fechas"], "error");
            else {
                if (dateTwo < this.dateOne) this.dateOne = dateTwo;
                this.getPayments(this.currentPage, this.dateOne, dateTwo, this.idCashRegister);
            }
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getPayments(this.currentPage, this.dateOne, this.dateTwo, this.idCashRegister);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getPayments(page, this.dateOne, this.dateTwo, this.idCashRegister);
                }, time);
            }
        },
        eShowDetail(payment) {
            this.payment = payment;
            this.dialogDetailPayment = true;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.dateOne = this.dateTwo = this.mDateSystem(false);
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpPaymentAdmin);
                this.getRequest();
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpPaymentAdmin);
    }
}
</script>