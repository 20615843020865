<template>
    <v-dialog
        persistent
        :value="value"
        max-width="400"
    >
        <v-card>
            <div class="s-detail-product__header pl-2 pr-2 d-flex align-center">
                <h1 class="app-title">
                    DIRIGIDO A
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <div class="client__line-header"></div>
            <v-form
                v-model="valid"
                lazy-validation
                ref="formaddress"
                class="pa-2"
                @submit.prevent="eValidForm()"
            >
                <MTextFieldComp
                    label="Dirigido a"
                    class="mt-2"
                    v-model="addressedTo"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'Dato requerido'
                    ]"
                    @click="eValidForm()"
                />
            </v-form>
            <div class="d-flex justify-center pa-2 pt-0">
                <MBtnNormalComp
                    @click="eValidForm()"
                >
                    <v-icon>mdi-bookmark-check</v-icon>
                    GUARDAR
                </MBtnNormalComp>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp
} from "../molecules";
import {
    OBtnCardComp
} from "../organisms";
import {
    rulesMixin
} from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        OBtnCardComp,
        MTextFieldComp,
        MBtnNormalComp
    },
    props: {
        value: Boolean
    },
    data: () => ({
        valid: true,
        addressedTo: ""
    }),
    methods: {
        eValidForm() {
            const parameters = {
                'isValidForm': this.$refs.formaddress.validate(),
                'addressedTo': this.addressedTo
            }
            this.$emit('saveform', parameters);
        },
        resetForm() {
            this.addressedTo = "";
            this.$refs.formaddress.resetValidation();
        }
    }
}
</script>