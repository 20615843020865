<template>
    <v-dialog
        :value="value"
        persistent
        width="340"
    >
        <v-card color="#FAFAFA">
            <v-progress-linear
                indeterminate
                color="#AD214B"
                height="15"
            ></v-progress-linear>
            <div class="d-flex justify-center mt-2">
                <div>
                    <v-img
                        src="/imgs/logo.png"
                        width="130"
                    ></v-img>
                </div>
            </div>
            <div class="pa-2 pt-0">
                <h1 class="app-title text-center">
                    {{smsLoaderOperation}}
                </h1>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        smsLoaderOperation: String
    }
}
</script>
