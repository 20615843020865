<template>
    <div>
        <div class="client__header pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    {{`INVENTARIO (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center client__header-block-second">
            <OSearchHeaderComp
                label="Buscar Producto"
                v-model="search"
                @entersearch="eSearchInventories()"
            />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <div class="product__group-search d-flex justify-center pt-2 pl-2 pr-2">
            <div class="product__search" style="margin-right:2px">
                <MTextFieldComp
                    type="date"
                    label="Fecha Inicio"
                    :hideDetails="true"
                    v-model="dateOne"
                    :clearable="false"
                    @change="eChangeDateOne"
                />
            </div>
            <div class="product__search" style="margin-left:2px">
                <MTextFieldComp
                    type="date"
                    label="Fecha Final"
                    :hideDetails="true"
                    v-model="dateTwo"
                    :clearable="false"
                    @change="eChangeDateTwo"
                />
            </div>
        </div>
        <v-divider class="mt-2"></v-divider>
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Información"
        />
        <MNoDataComp
            smsNotData="SIN DATOS"
            class="ma-1"
            v-if="entriesInventories.length==0 && !dialogLoaderData && mobile"
        />
        <STableEntriesInventoriesComp
            v-if="!dialogLoaderData && !mobile"
            :entriesInventories="entriesInventories"
            :search="searchFilter"
            :isExpiringInventory="true"
            @clickdetail="eShowDetailProduct"
            @clickpurchase="eShowDetailPurchase"
        />
        <div
            class="d-flex justify-center flex-wrap"
            v-if="!dialogLoaderData && mobile"
        >
            <SCardEntrieInventoryComp
                class="ma-2"
                :search="searchFilter"
                v-for="entrie in entriesInventories"
                :key="entrie.id"
                :entrie="entrie"
                :isExpiringInventory="true"
                @clickdetail="eShowDetailProduct(entrie)"
                @clickpurchase="eShowDetailPurchase(entrie)"
            />
        </div>
        <v-divider
            class="mt-1"
            v-if="mobile && !dialogLoaderData && entriesInventories.length>0"
        ></v-divider>
        <SPaginationComp
            class="mt-2 mb-2"
            v-if="!dialogLoaderData && entriesInventories.length > 0"
            :condicionGo="currentPage < lastPage"
            :condicionBack="currentPage > 1"
            :titlePagination="'Pag. '+currentPage+' de '+lastPage"
            @clickback="eChangePage(false)"
            @clickgo="eChangePage(true)"
            @clickpage="eSearchForPage"
        />
        <SDetailProductComp
            :value="dialogProduct"
            :product="product"
            @clickclose="dialogProduct=false"
        />
        <SDetailPurchaseLotComp
            :value="dialogPurchase"
            :lot="lot"
            @clickclose="dialogPurchase=false"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MNoDataComp,
    MTextFieldComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSmsErrorComp,
    OSmsActionComp,
    OLoaderDataComp,
    OSearchHeaderComp
} from "../../components/organisms";
import {
    STableEntriesInventoriesComp,
    SCardEntrieInventoryComp,
    SPaginationComp,
    SDetailProductComp,
    SDetailPurchaseLotComp
} from "../../components/species";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    animationScrollMixin,
    userMixin,
    datesMixin,
    rulesMixin
} from "../../mixins";
import {
    Inventory
} from "../../models";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        animationScrollMixin,
        userMixin,
        datesMixin,
        rulesMixin
    ],
    components: {
        STableEntriesInventoriesComp,
        SCardEntrieInventoryComp,
        SPaginationComp,
        OBtnToolTipHeaderComp,
        OSmsErrorComp,
        OSmsActionComp,
        MNoDataComp,
        OLoaderDataComp,
        SDetailProductComp,
        SDetailPurchaseLotComp,
        OSearchHeaderComp,
        MTextFieldComp
    },
    data: () => ({
        search: "",
        productsList: [{ id: 0, product: "Todos" }],
        idProduct: 0,
        lot: {},
        product: {},
        dialogSearchProducts: false,
        entriesInventories: [],
        dialogProduct: false,
        dialogPurchase: false,
        dateOne: "",
        dateTwo: ""
    }),
    computed: {
        searchFilter: function() {
            return this.search == null ? "" : this.search;
        }
        /*searchFilterProduct: function() {
            return this.productSearch.search == null ? "" : this.productSearch.search;
        }*/
    },
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else this.dialogError = false;
        },
        handleKeyUpExpiredInventorie(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getExpiringInventory(page, dateOne, dateTwo, search, idProduct) {
            this.dialogLoaderData = true;
            try {
                const response = await Inventory.getExpiringInventory(page, dateOne, dateTwo, search, idProduct);
                this.entriesInventories = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eShowDetailProduct(inventory) {
            this.product = inventory.product;
            this.dialogProduct = true;
        },
        eShowDetailPurchase(inventory) {
            if (inventory.id_purchase !== 0) {
                this.lot = inventory;
                this.dialogPurchase = true;
            } else this.mDataGeneralShowSmsAction(
                ["Sin Registro de Compra", "El lote no fue registrado por compras"], "error");
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getExpiringInventory(this.currentPage, this.dateOne, this.dateTwo, this.search, this.idProduct);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getExpiringInventory(page, this.search, this.dateOne, this.dateTwo, this.idProduct);
                }, time);
            }
        },
        eSearchInventories() {
            if (this.mRuleEmptyField(this.search)) this.search = "";
            this.getExpiringInventory(this.currentPage, this.dateOne, this.dateTwo, this.search, this.idProduct);
        },
        eChangeDateOne(dateOne) {
            if (dateOne == "" || this.dateTwo == "")
                this.mDataGeneralShowSmsAction(["Error de Fechas", "Seleccione bien las fechas"], "error");
            else {
                if (dateOne > this.dateTwo) this.dateTwo = dateOne;
                this.getExpiringInventory(1, dateOne, this.dateTwo, this.search, this.idProduct);
            }
        },
        eChangeDateTwo(dateTwo) {
            if (dateTwo == "" || this.dateOne == "")
                this.mDataGeneralShowSmsAction(["Error de Fechas", "Seleccione bien las fechas"], "error");
            else {
                if (dateTwo < this.dateOne) this.dateOne = dateTwo;
                this.getExpiringInventory(1, this.dateOne, dateTwo, this.search, this.idProduct);
            }
        }
    },
    mounted() {
        this.dialogLoaderData = false;
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpExpiredInventorie);
                //this.getExpiringInventory(1, "", 0);
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpExpiredInventorie);
    }
}
</script>