<template>
    <div>
        <v-form
            v-model="valid"
            lazy-validation
            ref="formproductpurchase"
        >
            <MTextFieldComp
                label="Cantidad"
                class="mt-2"
                ref="quantity"
                v-model="dataProductPurchase.quantity"
                :rules="[
                    value => !this.mRuleEmptyField(value) || 'La cantidad es requerido',
                    value => this.mRuleNumberInteger(value) || 'La cantidad debe ser un número',
                    value => value > 0 || 'La cantidad debe ser mayor a 0'
                ]"
            />
            <MTextFieldComp
                label="P.U."
                class="mt-2"
                v-model="dataProductPurchase.unit_price"
                :rules="[
                    value => !this.mRuleEmptyField(value) || 'El P.U. es requerido',
                    value => this.mRuleNumberDecimal(value) || 'El P.U. debe ser un número',
                    value => value > 0 || 'El P.U. debe ser mayor a 0'
                ]"
            />
            <MTextFieldComp
                label="Descuento Comercial"
                class="mt-2"
                v-model="dataProductPurchase.commercial_discount"
                :rules="[
                    value => !this.mRuleEmptyField(value) || 'El Dcto. Comercial es requerido',
                    value => this.mRuleNumberDecimal(value) || 'El Dcto. Comercial debe ser un número'
                ]"
            />
            <MTextFieldComp
                label="Otro Descuento"
                class="mt-2"
                v-model="dataProductPurchase.other_discount"
                :rules="[
                    value => !this.mRuleEmptyField(value) || 'Otro Dcto. es requerido',
                    value => this.mRuleNumberDecimal(value) || 'Otro Dcto. debe ser un número'
                ]"
            />
            <MTextFieldComp
                label="Fecha de Expiración"
                v-model="dataProductPurchase.expiration_date"
                type="date"
                :rules="[
                    value => !this.mRuleEmptyField(value) || 'La fecha expiración es requerido'
                ]"
            />
            <div class="d-flex justify-center mt-2">
                <MBtnNormalComp
                    @click="eValidForm()"
                >
                    <v-icon>mdi-bookmark-check</v-icon>
                    {{titleForm}}
                </MBtnNormalComp>
            </div>
        </v-form>
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp
} from "../molecules";
import { rulesMixin } from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        MTextFieldComp,
        MBtnNormalComp
    },
    props: {
        dataProductPurchase: Object,
        titleForm: {
            type: String,
            default: "GUARDAR"
        }
    },
    data: () => ({
        valid: true,
    }),
    methods: {
        eValidForm() {
            if (this.$refs.formproductpurchase.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.formproductpurchase.resetValidation();
        },
        activateFocus() {
            this.$refs.quantity.activateFocus();
        }
    }
}
</script>