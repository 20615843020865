<template>
    <div class="s-table-detail-sale animation-opacity">
        <table class="s-table-detail-sale__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="app-sale-title-dark text-center">PRODUCTO</h1>
                    </th>
                    <th width="55">
                        <h1 class="app-sale-title-dark text-center">P.U.</h1>
                    </th>
                    <th width="34">
                        <h1 class="app-sale-title-dark text-center">C.</h1>
                    </th>
                    <th width="60">
                        <h1 class="app-sale-title-dark text-center">Total</h1>
                    </th>
                    <th width="55">
                        <h1 class="app-sale-title-dark text-center">Ub.</h1>
                    </th>
                    <th width="108">
                        <h1 class="app-sale-title-dark text-center">Mov. Lotes</h1>
                    </th>
                    <th width="50px"></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(product, index) in detailList"
                    :key="index"
                >
                    <td>
                        <h1 class="app-sale-subtitle text-right">
                            {{product.product}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-sale-subtitle text-right">
                            {{parseFloat(product.select_sale_price).toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-sale-subtitle text-right">
                            {{product.select_quantity}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-sale-subtitle text-right">
                            {{iTotalSaleProduct(product).toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-sale-subtitle text-right">
                            {{product.location}}
                        </h1>
                    </td>
                    <td>
                        <div
                            class="d-flex"
                            v-for="(date_expiration, index) in inventoryOutputsFilter(product.inventory_outputs)"
                            :key="index"
                        >
                            <h1 class="app-sale-subtitle text-right">
                                {{mDateFormat(date_expiration.expiration_date)}}
                            </h1>
                            <h1 class="app-sale-title text-right ml-1">
                                {{`(${date_expiration.quantity})`}}
                            </h1>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex align-center justify-center">
                            <div>
                                <v-menu
                                    transition="slide-x-transition"
                                    bottom
                                    right
                                    offset-y
                                    open-on-hover
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            x-small
                                            v-on="on"
                                            v-bind="attrs"
                                            outlined
                                            class="color-border-btn"
                                        >
                                            <v-icon x-small>mdi-text-box</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card class="pa-1">
                                        <v-list-item
                                            v-for="(option, index) in options"
                                            :key="index"
                                            class="s-menu-pc__menu-option"
                                            @click="$emit(option.nameEmit, product)"
                                            :ripple="false"
                                        >
                                            <v-icon class="color-icon mr-2">{{option.icon}}</v-icon>
                                            <h1 class="app-subtitle">{{option.title}}</h1>
                                        </v-list-item>
                                    </v-card>
                                </v-menu>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-if="detailList.length === 0">
                    <td colspan="7">
                        <h1 class="app-sale-subtitle text-center">
                            SIN PRODUCTOS
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
        <v-card
            class="pa-2 rounded-0"
            outlined
            width="100%"
            elevation="0"
        >
            <div class="d-flex">
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <h1 class="app-title mr-1">Total (Bs.):</h1>
                    <v-card
                        class="pa-0 ma-0"
                        color="transparent"
                        outlined
                        width="110"
                    >
                        <h1 class="app-subtitle text-right">
                            {{calculateTotalDetail.toFixed(2)}}
                        </h1>
                    </v-card>
                </div>
            </div>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <h1 class="app-title mr-1">Cancelado (Bs.):</h1>
                    <v-card
                        class="pa-0 ma-0"
                        color="transparent"
                        outlined
                        width="110"
                    >
                        <MTextFieldHeaderComp
                            :clearable="false"
                            v-model="cancelled"
                            :reverse="true"
                            :rules="[
                                value => !this.mRuleEmptyField(value) || '',
                                value => !this.mRuleNumberDecimal(value) || ''
                            ]"
                        />
                    </v-card>
                </div>
            </div>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <h1 class="app-title mr-1">Cambio (Bs.):</h1>
                    <v-card
                        class="pa-0 ma-0"
                        color="transparent"
                        outlined
                        width="110"
                    >
                        <h1 class="app-subtitle text-right">
                            {{calculateTotalChange.toFixed(2)}}
                        </h1>
                    </v-card>
                </div>
            </div>
            <v-divider
                v-if="detailList.length > 0"
                class="mt-2 mb-2"
            ></v-divider>
            <div
                v-if="detailList.length > 0"
                class="d-flex justify-center"
            >
                <OBtnToolTipHeaderComp
                    icono="mdi-window-maximize"
                    class="mr-1"
                    smsToolTip="Limpiar detalle"
                    @click="eClearDetail()"
                />
                <OBtnToolTipHeaderComp
                    color="green"
                    class="mr-1"
                    icono="mdi-cash"
                    smsToolTip="Venta + Pago Efectivo"
                    @click="eSendDataSale('e')"
                />
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :width="sizeBtn"
                            :max-width="sizeBtn"
                            :min-width="sizeBtn"
                            :height="sizeBtn"
                            :min-height="sizeBtn"
                            :max-height="sizeBtn"
                            v-bind="attrs"
                            v-on="on"
                            color="green"
                            depressed
                            :ripple="false"
                        >
                            <v-icon small class="s-menu-pc__list-icon">
                                mdi-qrcode-plus
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(coin, index) in optionsCoins"
                            :key="index"
                            @click="eSendDataSale(coin.value)"
                        >
                            <v-list-item-title>
                                <h1 class="app-subtitle">{{ coin.title }}</h1>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :width="sizeBtn"
                            :max-width="sizeBtn"
                            :min-width="sizeBtn"
                            :height="sizeBtn"
                            :min-height="sizeBtn"
                            :max-height="sizeBtn"
                            v-bind="attrs"
                            v-on="on"
                            color="blue"
                            class="ml-1"
                            depressed
                            :ripple="false"
                        >
                            <v-icon small class="s-menu-pc__list-icon">
                                mdi-account-cash
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(coin, index) in optionsClientCoins"
                            :key="index"
                            @click="eSendDataSaleClient(coin.value)"
                        >
                            <h1 class="app-subtitle">{{ coin.title }}</h1>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <!--<OBtnToolTipHeaderComp
                    class="mr-1"
                    color="green"
                    icono="mdi-qrcode-plus"
                    smsToolTip="Venta + Pago QR"
                    @click="eSendDataSale(false)"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-account-cash"
                    class="mr-1"
                    smsToolTip="Venta + Cliente + Pago Efectivo"
                    @click="eSendDataSaleClient(true)"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-account-credit-card"
                    smsToolTip="Venta + Cliente + Pago QR"
                    @click="eSendDataSaleClient(true)"
                />-->
            </div>
        </v-card>
        <div class="d-none">
            {{updateComp}}
        </div>
    </div>
</template>
<script>
import {
    MTextFieldHeaderComp
} from "../molecules";
import {
    OBtnToolTipHeaderComp
} from "../organisms";
import {
    rulesMixin,
    datesMixin
} from "../../mixins";
export default {
    mixins: [
        rulesMixin,
        datesMixin
    ],
    components: {
        MTextFieldHeaderComp,
        OBtnToolTipHeaderComp
    },
    props: {
        detailList: Array,
        indexProductDetail: Number
    },
    data: () => ({
        sizeBtn: '32',
        cancelled: "",
        discount: "0",
        options: [
            {
                icon: "mdi-pencil",
                title: "Editar",
                nameEmit: "clickedit"
            },
            {
                icon: "mdi-chart-timeline-variant-shimmer",
                title: "Ajustar lotes",
                nameEmit: "clicklots"
            },
            {
                icon: "mdi-list-box",
                title: "Detalle",
                nameEmit: "clickdetail"
            },
            {
                icon: "mdi-format-list-text",
                title: "Inventario",
                nameEmit: "clickinventory"
            },
            {
                icon: "mdi-delete",
                title: "Quitar",
                nameEmit: "clickdelete"
            }
        ],
        numChanges: 0,
        optionsCoins: [
            { title: "Venta Qr", value: "q" },
            { title: "Venta Tarjeta", value: "t" }
        ],
        optionsClientCoins: [
            { title: "Venta Efectivo + Cliente", value: "e" },
            { title: "Venta Qr + Cliente", value: "q" },
            { title: "Venta Tarjeta + Cliente", value: "t" }
        ]
    }),
    computed: {
        calculateTotalDetail: function() {
            if (this.mRuleEmptyField(this.discount) || !this.mRuleNumberDecimal(this.discount))
                var valueDiscount = 0;
            else var valueDiscount = parseFloat(this.discount);
            const valueTotal = parseFloat(this.iTotalDetail().toFixed(2));
            return valueTotal - valueDiscount;
        },
        updateComp: function() {
            if (this.numChanges > 0) return 'si';
            return 'no';
        },
        calculateTotalChange: function() {
            if (this.mRuleEmptyField(this.cancelled) || !this.mRuleNumberDecimal(this.cancelled))
                return 0;
            const totalSale = this.calculateTotalDetail.toFixed(2);
            return parseFloat(this.cancelled) - parseFloat(totalSale);
        }
    },
    methods: {
        resetCancelled() {
            this.cancelled = "";
        },
        changeStateComp() {
            this.discount = "0";
            this.numChanges++;
        },
        iTotalSaleProduct(product) {
            return parseFloat(product.select_sale_price) * parseInt(product.select_quantity);
        },
        iTotalDetail() {
            var total = 0;
            for (var i = 0; i < this.detailList.length; i++) {
                total += this.iTotalSaleProduct(this.detailList[i]);
            }
            return total;
        },
        eClearDetail() {
            this.discount = "0";
            this.$emit('clickclear');
        },
        inventoryOutputsFilter(inventory_outputs) {
            return inventory_outputs.filter(element => {
                return element.quantity != 0;
            });
        },
        generarDetailDB() {
            var detail = [];
            this.detailList.forEach(element => {
                const newElement = {
                    'product_id': element.id,
                    'quantity': parseInt(element.select_quantity),
                    'unit_price': parseFloat(element.select_sale_price),
                    'discount': element.sale_price - parseFloat(element.select_sale_price),
                    'inventory': this.inventoryOutputsFilter(element.inventory_outputs)
                };
                detail.push(newElement);
            });
            return detail;
        },
        generateSendParametersSale(typeCoin, no_client) {
            return {
                'discount': parseFloat(this.discount).toFixed(2),
                'sale': this.calculateTotalDetail.toFixed(2),
                'type_sale': typeCoin,
                'no_client': no_client,
                'fk_cash_register': JSON.parse(localStorage.getItem("device")).id_cash_register
            };
        },
        eSendDataSale(typeCoin) {
            if (this.mRuleEmptyField(this.discount) || !this.mRuleNumberDecimal(this.discount))
                this.$emit('clickConfirmSale', {
                    'isValidForm': false
                });
            else
                this.$emit('clickConfirmSale', {
                    'isValidForm': true,
                    'sale': this.generateSendParametersSale(typeCoin, 1),
                    'detail': this.generarDetailDB()
                });
        },
        eSendDataSaleClient(typeCoin) {
            if (this.mRuleEmptyField(this.discount) || !this.mRuleNumberDecimal(this.discount))
                this.$emit('clickConfirmSaleClient', {
                    'isValidForm': false
                });
            else
                this.$emit('clickConfirmSaleClient', {
                    'isValidForm': true,
                    'sale': this.generateSendParametersSale(typeCoin, 0),
                    'detail': this.generarDetailDB()
                });
        }
    }
}
</script>