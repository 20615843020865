export default {
    methods: {
        mPTotalPrice(product) {
            return parseFloat(product.unit_price) * parseInt(product.quantity);
        },
        mPTotalPriceWithDiscount(product) {
            return this.mPTotalPrice(product) - parseFloat(product.commercial_discount) - parseFloat(product.other_discount);
        },
        mPSumOfTotalsPrice(detailProducts, isDataBase = false) {
            var total = 0;
            for (var i = 0; i < detailProducts.length; i++) {
                if (isDataBase) total += this.mPTotalPrice(detailProducts[i].pivot);
                else total += this.mPTotalPrice(detailProducts[i]);
            }
            return total;
        },
        mPSumTotalDiscountCommercial(detailProducts, isDataBase = false) {
            var total = 0;
            for (var i = 0; i < detailProducts.length; i++) {
                if (isDataBase) total += parseFloat(detailProducts[i].pivot.commercial_discount);
                else total += parseFloat(detailProducts[i].commercial_discount);
            }
            return total;
        },
        mPSumTotalOtherDiscount(detailProducts, isDataBase = false) {
            var total = 0;
            for (var i = 0; i < detailProducts.length; i++) {
                if (isDataBase) total += parseFloat(detailProducts[i].pivot.other_discount);
                else total += parseFloat(detailProducts[i].other_discount);
            }
            return total;
        },
        mPTotalPurchase(detailProducts, isDataBase = false) {
            const sumTotalPrice = this.mPSumOfTotalsPrice(detailProducts, isDataBase);
            const sumTotalDctoCommercial = this.mPSumTotalDiscountCommercial(detailProducts, isDataBase);
            const sumTotalOtherDcto = this.mPSumTotalOtherDiscount(detailProducts, isDataBase);
            return sumTotalPrice - sumTotalDctoCommercial - sumTotalOtherDcto;
        },
        mTotalQuantity(detailProducts) {
            var totalQuantity = 0;
            for (var i = 0; i < detailProducts.length; i++)
                totalQuantity += parseInt(detailProducts[i].quantity);
            return totalQuantity;
        },
        mPTotalPurchaseWithDiscount(purchase) {
            const totalProds = this.mPTotalPurchase(purchase.products, true);
            return totalProds - purchase.discount - purchase.other_discount;
        },
        mPTotalGain(purchase) {
            const totalPurchase = this.mPTotalPurchaseWithDiscount(purchase);
            var totalPriceProducts = 0;
            for(var i = 0; i < purchase.products.length; i++)
                totalPriceProducts += (purchase.products[i].sale_price * purchase.products[i].pivot.quantity);
            return totalPriceProducts - totalPurchase;
        }
    }
}