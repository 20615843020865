<template>
    <table id="tabla">
        <thead>
            <tr>
                <th>Producto</th>
                <th>Composición</th>
                <th>Vía Administración</th>
                <th>Cantidad</th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="(product, index) in detailOrder"
                :key="index"
            >
                <td>{{product.product}}</td>
                <td>
                    {{product.composition != null
                        ? product.composition : "S/C"}}
                </td>
                <td>
                    {{product.via_administration != null
                        ? product.via_administration
                        : "S/A"
                    }}</td>
                <td>{{product.quantity_order}}</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
export default {
    props: {
        detailOrder: Array
    }
}
</script>