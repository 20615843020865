<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <h1 class="app-title">
                {{lot.lot_number}}
            </h1>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Cantidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{lot.quantity}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha Expiración:</h1>
                <h1 class="app-subtitle-dark">
                    {{mDateFormat(lot.expiration_date)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha Registro:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${lot.hour} ${lot.date}`}}
                </h1>
            </div>
        </div>
        <div
            class="s-card-client__actions d-flex justify-center flex-wrap"
        >
            <OBtnCardComp
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
        </div>
    </div>
</template>
<script>
import { OBtnCardComp } from "../organisms";
import {
    datesMixin
} from "../../mixins";
export default {
    mixins: [
        datesMixin
    ],
    props: {
        lot: Object,
        search: {
            type: String,
            default: ''
        }
    },
    components: {
        OBtnCardComp
    }
}
</script>