<template>
    <v-card
        class="pa-2 rounded-0"
        outlined
        width="100%"
        elevation="0"
    >
        <div class="d-flex">
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <h1 class="app-title mr-1">Total (Bs.):</h1>
                <v-card
                    class="pa-0 ma-0"
                    color="transparent"
                    outlined
                    width="100"
                >
                    <h1 class="app-subtitle text-right">
                        {{iTotalDetail().toFixed(2)}}
                    </h1>
                </v-card>
            </div>
        </div>
        <div class="d-flex">
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <h1 class="app-title mr-1">Cancelado (Bs.):</h1>
                <v-card
                    class="pa-0 ma-0"
                    color="transparent"
                    outlined
                    width="100"
                >
                    <MTextFieldHeaderComp
                        :clearable="false"
                        v-model="cancelled"
                        :reverse="true"
                        :rules="[
                            value => !this.mRuleEmptyField(value) || '',
                            value => !this.mRuleNumberDecimal(value) || ''
                        ]"
                    />
                </v-card>
            </div>
        </div>
        <v-divider class="mt-2 mb-1"></v-divider>
        <div class="d-flex">
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <h1 class="app-title mr-1">Cambio (Bs.):</h1>
                <v-card
                    class="pa-0 ma-0"
                    color="transparent"
                    outlined
                    width="100"
                >
                    <h1 class="app-subtitle text-right">
                        {{calculateTotalChange.toFixed(2)}}
                    </h1>
                </v-card>
            </div>
        </div>
        <v-divider
            v-if="detailList.length > 0"
            class="mt-2 mb-2"
        ></v-divider>
        <div
            v-if="detailList.length > 0"
            class="d-flex justify-center"
        >
            <OBtnToolTipHeaderComp
                icono="mdi-window-maximize"
                class="mr-1"
                smsToolTip="Limpiar detalle"
                @click="eClearDetail()"
            />
            <OBtnToolTipHeaderComp
                class="mr-1"
                icono="mdi-cash"
                color="green"
                smsToolTip="Venta + Pago Efectivo"
                @click="eSendDataSale('e')"
            />
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :width="sizeBtn"
                        :max-width="sizeBtn"
                        :min-width="sizeBtn"
                        :height="sizeBtn"
                        :min-height="sizeBtn"
                        :max-height="sizeBtn"
                        v-bind="attrs"
                        v-on="on"
                        color="green"
                        depressed
                        :ripple="false"
                    >
                        <v-icon small class="s-menu-pc__list-icon">
                            mdi-qrcode-plus
                        </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(coin, index) in optionsCoins"
                        :key="index"
                        @click="eSendDataSale(coin.value)"
                    >
                        <v-list-item-title>
                            <h1 class="app-subtitle">{{ coin.title }}</h1>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :width="sizeBtn"
                        :max-width="sizeBtn"
                        :min-width="sizeBtn"
                        :height="sizeBtn"
                        :min-height="sizeBtn"
                        :max-height="sizeBtn"
                        v-bind="attrs"
                        v-on="on"
                        color="blue"
                        class="ml-1"
                        depressed
                        :ripple="false"
                    >
                        <v-icon small class="s-menu-pc__list-icon">
                            mdi-account-cash
                        </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(coin, index) in optionsClientCoins"
                        :key="index"
                        @click="eSendDataSaleClient(coin.value)"
                    >
                        <h1 class="app-subtitle">{{ coin.title }}</h1>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </v-card>
</template>
<script>

import {
    MTextFieldHeaderComp
} from "../molecules";
import {
    OBtnToolTipHeaderComp
} from "../organisms";
import {
    rulesMixin,
    datesMixin
} from "../../mixins";
export default {
    mixins: [
        rulesMixin,
        datesMixin
    ],
    components: {
        MTextFieldHeaderComp,
        OBtnToolTipHeaderComp
    },
    props: {
        detailList: Array
    },
    data: () => ({
        discount: "0",
        cancelled: "",
        sizeBtn: '32',
        optionsCoins: [
            { title: "Venta Qr", value: "q" },
            { title: "Venta Tarjeta", value: "t" }
        ],
        optionsClientCoins: [
            { title: "Venta Efectivo + Cliente", value: "e" },
            { title: "Venta Qr + Cliente", value: "q" },
            { title: "Venta Tarjeta + Cliente", value: "t" }
        ]
    }),
    computed: {
        calculateTotalDetail: function() {
            if (this.mRuleEmptyField(this.discount) || !this.mRuleNumberDecimal(this.discount))
                var valueDiscount = 0;
            else var valueDiscount = parseFloat(this.discount);
            const valueTotal = parseFloat(this.iTotalDetail().toFixed(2));
            return valueTotal - valueDiscount;
        },
        calculateTotalChange: function() {
            if (this.mRuleEmptyField(this.cancelled) || !this.mRuleNumberDecimal(this.cancelled))
                return 0;
            const totalSale = this.calculateTotalDetail.toFixed(2);
            return parseFloat(this.cancelled) - parseFloat(totalSale);
        }
    },
    methods: {
        resetCancelled() {
            this.cancelled = "";
        },
        iTotalSaleProduct(product) {
            return parseFloat(product.select_sale_price) * parseInt(product.select_quantity);
        },
        iTotalDetail() {
            var total = 0;
            for (var i = 0; i < this.detailList.length; i++) {
                total += this.iTotalSaleProduct(this.detailList[i]);
            }
            return total;
        },
        eClearDetail() {
            this.discount = "0";
            this.$emit('clickclear');
        },
        inventoryOutputsFilter(inventory_outputs) {
            return inventory_outputs.filter(element => {
                return element.quantity != 0;
            });
        },
        generarDetailDB() {
            var detail = [];
            this.detailList.forEach(element => {
                const newElement = {
                    'product_id': element.id,
                    'quantity': parseInt(element.select_quantity),
                    'unit_price': parseFloat(element.select_sale_price),
                    'discount': element.sale_price - parseFloat(element.select_sale_price),
                    'inventory': this.inventoryOutputsFilter(element.inventory_outputs)
                };
                detail.push(newElement);
            });
            return detail;
        },
        generateSendParametersSale(typeCpin, no_client) {
            return {
                'discount': parseFloat(this.discount).toFixed(2),
                'sale': this.calculateTotalDetail.toFixed(2),
                'type_sale': typeCpin,
                'no_client': no_client,
                'fk_cash_register': JSON.parse(localStorage.getItem("device")).id_cash_register
            };
        },
        eSendDataSale(typeCpin) {
            if (this.mRuleEmptyField(this.discount) || !this.mRuleNumberDecimal(this.discount))
                this.$emit('clickConfirmSale', {
                    'isValidForm': false
                });
            else
                this.$emit('clickConfirmSale', {
                    'isValidForm': true,
                    'sale': this.generateSendParametersSale(typeCpin, 1),
                    'detail': this.generarDetailDB()
                });
        },
        eSendDataSaleClient(typeCpin) {
            if (this.mRuleEmptyField(this.discount) || !this.mRuleNumberDecimal(this.discount))
                this.$emit('clickConfirmSaleClient', {
                    'isValidForm': false
                });
            else
                this.$emit('clickConfirmSaleClient', {
                    'isValidForm': true,
                    'sale': this.generateSendParametersSale(typeCpin, 0),
                    'detail': this.generarDetailDB()
                });
        }
    }
}
</script>