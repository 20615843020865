<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th width="105">
                        <h1 class="app-title-dark text-left">Total Venta</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Total Desc.</h1>
                    </th>
                    <th width="30">
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Tipo Pago</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Cliente</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">N° Caja</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Registrador</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha Registro</h1>
                    </th>
                    <th width="150px">
                        <h1 class="app-title-dark text-center">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(sale, index) in salesList"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{`${sale.sale.toFixed(2)} Bs.`}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{`${mSaleTotalDiscount(sale).toFixed(2)} Bs.`}}
                        </h1>
                    </td>
                    <td>
                        <div
                            :class="mSaleTotalDiscount(sale)>0
                                ? 'client__ornament-disabled'
                                : 'client__ornament-enabled'"
                        >
                        </div>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{sale.type_sale == "e"
                                ? "Efectivo" : sale.type_sale == "t"
                                    ? "Tarjeta" : "Qr"}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{sale.client.full_name}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{sale.cash_register.cash_register}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{sale.user.full_name}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{`${sale.hour} ${sale.date}`}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            v-if="!isReport && !isCanceledSales && !isCloseBox"
                            smsToolTip="Imprimir factura"
                            icon="mdi-printer"
                            class="mr-1"
                            @click="$emit('clickprint', sale)"
                        />
                        <OBtnToolTipTableComp
                            smsToolTip="Ver información detallada"
                            icon="mdi-information-outline"
                            class="mr-1"
                            @click="$emit('clickinfo', sale)"
                        />
                        <OBtnToolTipTableComp
                            smsToolTip="Ver detalle"
                            icon="mdi-list-box-outline"
                            class="mr-1"
                            @click="$emit('clickdetail', sale)"
                        />
                        <OBtnToolTipTableComp
                            v-if="!isCanceledSales && !isCloseBox && !isBill"
                            smsToolTip="Anular Venta"
                            icon="mdi-trash-can"
                            @click="$emit('clickdelete', sale)"
                        />
                    </td>
                </tr>
                <tr v-if="salesList.length === 0">
                    <td colspan="10">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { OBtnToolTipTableComp } from "../organisms";
import {
    saleMixin
} from "../../mixins";
export default {
    mixins: [ saleMixin ],
    components: {
        OBtnToolTipTableComp
    },
    props: {
        salesList: Array,
        isCanceledSales: {
            type: Boolean,
            default: false
        },
        isCloseBox: Boolean,
        isBill: Boolean,
        isReport: Boolean
    }
}
</script>