<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Pago</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Glosa</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha Destino</h1>
                    </th>
                    <th width="80">
                        <h1 class="app-title-dark text-left">N° Caja</h1>
                    </th>
                    <th width="30"></th>
                    <th>
                        <h1 class="app-title-dark text-left">Canc.</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Saldo</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-center">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(payment, index) in paymentsList"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{payment.payment.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{payment.glos != null ? payment.glos : "S/D"}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{mDateFormat(payment.destination_date)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{payment.cash_register.cash_register}}
                        </h1>
                    </td>
                    <td>
                        <div
                            :class="payment.payment_compliance==null
                                ? 'client__ornament-disabled'
                                : 'client__ornament-enabled'"
                        >
                        </div>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{payment.payment_compliance==null
                                ? "0.00"
                                : payment.payment_compliance.payment_compliance.toFixed(2)
                            }}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{totalBalance(payment).toFixed(2)}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            :smsToolTip="`Ver detalle`"
                            icon="mdi-list-box"
                            class="mr-1"
                            @click="$emit('clickdetail', payment)"
                        />
                        <OBtnToolTipTableComp
                            v-if="isUser"
                            class="mr-1"
                            :smsToolTip="`Registrar pago`"
                            icon="mdi-account-cash"
                            @click="$emit('clickadd', payment)"
                        />
                        <OBtnToolTipTableComp
                            v-if="!isInfo"
                            :class="!isUser ? 'mr-1' : ''"
                            :smsToolTip="isUser
                                ? 'Editar pago realizado'
                                : 'Editar pago a realizar'"
                            icon="mdi-pencil"
                            @click="$emit('clickedit', payment)"
                        />
                        <OBtnToolTipTableComp
                            v-if="!isUser && !isInfo"
                            smsToolTip="Eliminar pago a realizar"
                            icon="mdi-delete"
                            @click="$emit('clickdelete', payment)"
                        />
                    </td>
                </tr>
                <tr v-if="paymentsList.length === 0">
                    <td colspan="9">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { OBtnToolTipTableComp } from "../organisms";
import { datesMixin } from "../../mixins";
export default {
    mixins: [ datesMixin ],
    components: {
        OBtnToolTipTableComp
    },
    props: {
        paymentsList: [],
        isUser: Boolean,
        isInfo: Boolean
    },
    methods: {
        totalBalance(payment) {
            if (payment.payment_compliance == null)
                return payment.payment;
            return payment.payment - payment.payment_compliance.payment_compliance;
        }
    }
}
</script>