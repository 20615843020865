<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <h1 class="app-title">{{typeProduct.type_product}}</h1>
        </div>
        <v-card
            elevation="0"
            class="pl-1 pr-1"
        >
            <v-divider></v-divider>
        </v-card>
        <div
            v-if="mobile"
            class="s-card-client__actions d-flex justify-center flex-wrap"
        >
            <OBtnCardComp
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
            <OBtnCardComp
                icon="mdi-delete"
                style="margin:2px"
                @click="$emit('clickdelete')"
            />
        </div>
        <div
            v-else
            class="s-card-client__actions d-flex justify-center flex-wrap p1-1 pb-1"
        >
            <OBtnToolTipTableComp
                :smsToolTip="`Editar: ${typeProduct.type_product}`"
                icon="mdi-pencil"
                @click="$emit('clickedit')"
            />
            <OBtnToolTipTableComp
                :smsToolTip="`Eliminar: ${typeProduct.type_product}`"
                icon="mdi-delete"
                class="ml-1"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import {
    OBtnCardComp,
    OBtnToolTipTableComp
} from "../organisms";
export default {
    components: {
        OBtnCardComp,
        OBtnToolTipTableComp
    },
    props: {
        mobile: Boolean,
        typeProduct: Object
    }
}
</script>