import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';

class Sale {
    constructor(
        sale = null,
        control_code = null,
        select_client = 1,
        no_client = 0,
        client = {},
        type_sale = null,
        discount = null,
        fk_client = null,
        fk_cash_register = null
    ) {
        this.sale = sale;
        this.control_code = control_code;
        this.select_client = select_client;
        this.no_client = no_client;
        this.client = client;
        this.type_sale = type_sale;
        this.discount = discount;
        this.fk_client = fk_client;
        this.fk_cash_register = fk_cash_register;
    }
    static async reportSales(page, dateOne, dateTwo, typeSale, idCashRegister, disabled) {
        const response =  await axios.get(
            `${apiUrls.SALE}/report?page=${page}&date_one=${dateOne}&date_two=${dateTwo}&type_sale=${typeSale}&id_cash_register=${idCashRegister}&disabled=${disabled}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async salesCashRegister(page, fk_cash_register, disabled = 2, typeSale = "t") {
        const response =  await axios.get(
            `${apiUrls.SALE}/cash-register?page=${page}&fk_cash_register=${fk_cash_register}&disabled=${disabled}&type_sale=${typeSale}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addSale(parameters) {
        const response =  await axios.post(
            `${apiUrls.SALE}/add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async changeState(parameters) {
        const response =  await axios.patch(
            `${apiUrls.SALE}/change-state`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async changeTypePayment(parameters) {
        const response =  await axios.patch(
            `${apiUrls.SALE}/change-type-payment`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async closeBox(page, dateOne, dateTwo, idUser, typeSale, idCashRegister, disabled) {
        const response =  await axios.get(
            `${apiUrls.SALE}/close-box?page=${page}&date_one=${dateOne}&date_two=${dateTwo}&fk_user=${idUser}&type_sale=${typeSale}&fk_cash_register=${idCashRegister}&disabled=${disabled}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async cashRegisterResume(fk_cash_register) {
        const response =  await axios.get(
            `${apiUrls.SALE}/cash-register-resume?fk_cash_register=${fk_cash_register}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async billsReport(page, dateOne, dateTwo) {
        const response = await axios.get(
            `${apiUrls.SALE}/bills-report?page=${page}&date_one=${dateOne}&date_two=${dateTwo}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async grossIncome(dateOne, dateTwo, typeSale, idCashRegister) {
        const response =  await axios.get(
            `${apiUrls.SALE}/gross-income?date_one=${dateOne}&date_two=${dateTwo}&type_sale=${typeSale}&id_cash_register=${idCashRegister}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Sale;