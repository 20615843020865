<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Proveedor</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Celular</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Dirección</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Correo</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Descripción</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha registro</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(provider, index) in providersList"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <text-highlight
                            class="app-subtitle"
                            :queries="search"
                        >
                            {{provider.provider}}
                        </text-highlight>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{provider.phone!=null ? provider.phone : 'S/N'}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{provider.address!=null ? provider.address : 'S/D'}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{provider.e_mail!=null ? provider.e_mail : 'S/C'}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{provider.description!=null ? provider.description : 'S/D'}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{`${provider.hour} ${provider.date}`}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            :smsToolTip="`Editar a: ${provider.provider}`"
                            icon="mdi-pencil"
                            class="mr-1"
                            @click="$emit('clickedit', provider)"
                        />
                        <OBtnToolTipTableComp
                            :smsToolTip="`Eliminar a: ${provider.provider}`"
                            icon="mdi-delete"
                            @click="$emit('clickdelete', provider)"
                        />
                    </td>
                </tr>
                <tr v-if="providersList.length === 0">
                    <td colspan="8">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import { OBtnToolTipTableComp } from "../organisms";
export default {
    components: {
        OBtnToolTipTableComp,
        TextHighlight
    },
    props: {
        providersList: Array,
        search: {
            type: String,
            default: ''
        }
    }
}
</script>