<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <text-highlight
                class="app-title"
                :queries="search"
            >
                {{product.product}}
            </text-highlight>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Código Barra:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.barcore}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Cantidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.quantity}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Ubicación:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.location}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Tipo Producto:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.type_product.type_product}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Laboratorio:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.laboratory.laboratory}}
                </h1>
            </div>
        </div>
        <div
            class="s-card-client__actions d-flex justify-center flex-wrap"
        >
            <OBtnCardComp
                icon="mdi-list-box"
                style="margin:2px"
                @click="$emit('clickdetail')"
            />
            <OBtnCardComp
                icon="mdi-medication"
                style="margin:2px"
                @click="$emit('clickinventories')"
            />
            <OBtnCardComp
                icon="mdi-folder-plus"
                style="margin:2px"
                @click="$emit('clicklot')"
            />
        </div>
    </div>
</template>
<script>
import { OBtnCardComp } from "../organisms";
import TextHighlight from "vue-text-highlight";
export default {
    props: {
        product: Object,
        search: {
            type: String,
            default: ''
        }
    },
    components: {
        OBtnCardComp,
        TextHighlight
    }
}
</script>