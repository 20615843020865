<template>
    <div class="s-form-product animation-opacity">
        <h1 class="app-title text-center mb-3">
            {{title}}
        </h1>
        <div class="d-flex align-center">
            <div :style="`width:${sizeStyle}`">
                <h1 class="app-title text-right">Caja Inicial (+):</h1>
            </div>
            <div :style="`width:${sizeStyleSecond}`">
                <h1 class="app-subtitle text-right">
                    {{`${infoReport.money_box.toFixed(2)} Bs.`}}</h1>
            </div>
        </div>
        <div class="d-flex align-center mt-2">
            <div :style="`width:${sizeStyle}`">
                <h1 class="app-title text-right">Total Venta (+):</h1>
            </div>
            <div :style="`width:${sizeStyleSecond}`" class="mr-1">
                <h1 class="app-subtitle text-right">
                    {{`${infoReport.total_sales_enabled_e.toFixed(2)} Bs.`}}</h1>
            </div>
            <OBtnCircleToolTipComp
                :mobile="mobile"
                smsToolTip="Ver ventas efectivo"
                @click="$emit(
                    'clickshowsales',
                    sendInfo(0, 'e', 'Efectivo', infoReport.total_sales_enabled_e))"
            >
                <v-icon class="s-menu-pc__list-icon">mdi-cash</v-icon>
            </OBtnCircleToolTipComp>
        </div>
        <div class="d-flex align-center mt-1 mb-1">
            <div :style="`width:${sizeStyle}`">
                <h1 class="app-title text-right">Total Gastos (-):</h1>
            </div>
            <div :style="`width:${sizeStyleSecond}`" class="mr-1">
                <h1 class="app-subtitle text-right">
                    {{`${infoReport.total_expenses.toFixed(2)} Bs.`}}</h1>
            </div>
            <OBtnCircleToolTipComp
                :mobile="mobile"
                smsToolTip="Ver gastos"
                @click="$emit('clickexpenses')"
            >
                <v-icon class="s-menu-pc__list-icon">mdi-cash-minus</v-icon>
            </OBtnCircleToolTipComp>
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="d-flex align-center line-card">
            <div :style="`width:${sizeStyle}`">
                <h1 class="app-title text-right">Total Efectivo Ventas:</h1>
            </div>
            <div :style="`width:${sizeStyleSecond}`">
                <h1 class="app-subtitle text-right">
                    {{`${calculateReport.toFixed(2)} Bs.`}}</h1>
            </div>
        </div>
        <v-divider class="mb-2 mt-1"></v-divider>
        <div class="d-flex align-center">
            <div :style="`width:${sizeStyle}`">
                <h1 class="app-title text-right">Total Efectivo Pagos (+):</h1>
            </div>
            <div :style="`width:${sizeStyleSecond}`" class="mr-1">
                <h1 class="app-subtitle text-right">
                    {{`${infoReport.total_payments.toFixed(2)} Bs.`}}</h1>
            </div>
            <OBtnCircleToolTipComp
                :mobile="mobile"
                smsToolTip="Ver pagos a realizar"
                @click="$emit('clickpayments')"
            >
                <v-icon class="s-menu-pc__list-icon">mdi-cash-refund</v-icon>
            </OBtnCircleToolTipComp>
        </div>
        <div class="d-flex align-center">
            <div :style="`width:${sizeStyle}`">
                <h1 class="app-title text-right">Total Pagos Canc. (-):</h1>
            </div>
            <div :style="`width:${sizeStyleSecond}`">
                <h1 class="app-subtitle text-right">
                    {{`${infoReport.total_payments_compliance.toFixed(2)} Bs.`}}</h1>
            </div>
        </div>
        <v-divider class="mt-1 mb-1"></v-divider>
        <div class="d-flex align-center line-card">
            <div :style="`width:${sizeStyle}`">
                <h1 class="app-title text-right">Total Efectivo Pagos:</h1>
            </div>
            <div :style="`width:${sizeStyleSecond}`">
                <h1 class="app-subtitle text-right">
                    {{`${(infoReport.total_payments-infoReport.total_payments_compliance).toFixed(2)} Bs.`}}</h1>
            </div>
        </div>
        <v-divider class="mt-1 mb-1"></v-divider>
        <div class="d-flex align-center">
            <div :style="`width:${sizeStyle}`">
                <h1 class="app-title text-right">Total Qr:</h1>
            </div>
            <div :style="`width:${sizeStyleSecond}`" class="mr-1">
                <h1 class="app-subtitle text-right">
                    {{`${infoReport.total_sales_enabled_q.toFixed(2)} Bs.`}}</h1>
            </div>
            <OBtnCircleToolTipComp
                :mobile="mobile"
                smsToolTip="Ver ventas Qr"
                @click="$emit(
                    'clickshowsales', 
                    sendInfo(0, 'q', 'Qr', infoReport.total_sales_enabled_q))"
            >
                <v-icon class="s-menu-pc__list-icon">mdi-home-silo</v-icon>
            </OBtnCircleToolTipComp>
        </div>
        <div class="d-flex align-center mt-1">
            <div :style="`width:${sizeStyle}`">
                <h1 class="app-title text-right">Total Tarjeta:</h1>
            </div>
            <div :style="`width:${sizeStyleSecond}`" class="mr-1">
                <h1 class="app-subtitle text-right">
                    {{`${infoReport.total_sales_enabled_t.toFixed(2)} Bs.`}}</h1>
            </div>
            <OBtnCircleToolTipComp
                :mobile="mobile"
                smsToolTip="Ver ventas tarjeta"
                @click="$emit(
                    'clickshowsales', 
                    sendInfo(0, 't', 'Tarjeta', infoReport.total_sales_enabled_t))"
            >
                <v-icon class="s-menu-pc__list-icon">mdi-home-silo</v-icon>
            </OBtnCircleToolTipComp>
        </div>
        <v-divider class="mt-1 mb-2"></v-divider>
        <div class="d-flex align-center">
            <div :style="`width:${sizeStyle}`">
                <h1 class="app-title text-right">Información:</h1>
            </div>
            <div :style="`width:${sizeStyleSecond}`" class="mr-1">
                <h1 class="app-subtitle text-right">
                    {{infoReport.quantity_info}}</h1>
            </div>
            <OBtnCircleToolTipComp
                :mobile="mobile"
                smsToolTip="Ver información"
                @click="$emit('clickinfo')"
            >
                <v-icon class="s-menu-pc__list-icon">mdi-information</v-icon>
            </OBtnCircleToolTipComp>
        </div>
        <div class="d-flex align-center mt-1 mb-1">
            <div :style="`width:${sizeStyle}`">
                <h1 class="app-title text-right">V. Anuladas :</h1>
            </div>
            <div :style="`width:${sizeStyleSecond}`" class="mr-1">
                <h1 class="app-subtitle text-right">
                    {{infoReport.quantity_sales_disabled}}</h1>
            </div>
            <OBtnCircleToolTipComp
                :mobile="mobile"
                smsToolTip="Ver ventas anuladas"
                @click="$emit(
                    'clickshowsales',
                    sendInfo(1, 't', 'Anuladas', infoReport.quantity_sales_disabled))"
            >
                <v-icon class="s-menu-pc__list-icon">mdi-cash-remove</v-icon>
            </OBtnCircleToolTipComp>
        </div>
        <div class="d-flex align-center">
            <div :style="`width:${sizeStyle}`">
                <h1 class="app-title text-right">Total Descuento:</h1>
            </div>
            <div :style="`width:${sizeStyleSecond}`">
                <h1 class="app-subtitle text-right">
                    {{`${infoReport.total_discount.toFixed(2)} Bs.`}}</h1>
            </div>
        </div>
    </div>
</template>
<script>
import {
    OBtnCircleToolTipComp
} from "../organisms";
export default {
    components: {
        OBtnCircleToolTipComp
    },
    props: {
        infoReport: Object,
        title: String,
        mobile: Boolean
    },
    computed: {
        calculateReport: function() {
            return (this.infoReport.total_sales_enabled_e + this.infoReport.money_box) - this.infoReport.total_expenses;
        }
    },
    data: () => ({
        sizeStyle: "220px",
        sizeStyleSecond: "135px"
    }),
    methods: {
        sendInfo(disabled, typeSale, titleTypeSale, value) {
            return {
                'disabled': disabled,
                'typeSale': typeSale,
                'titleTypeSale': titleTypeSale,
                'value': value
            }
        }
    }
}
</script>