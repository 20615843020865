<template>
    <div class="s-form-product">
        <h1 class="app-title text-center mb-2">
            {{indexCashRegister===-1 ? 'REGISTRAR' : 'EDITAR'}}
        </h1>
        <v-form
            v-model="valid"
            lazy-validation
            ref="formcashregister"
        >
            <ORowFormComp
                :mobile="mobile"
                informationRow="Número de caja:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Número de caja' : ''"
                    v-model="dataCashRegister.cash_register"
                    :counter="3"
                    ref="cashregister"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El n° de caja es requerido',
                        value => this.mRuleNumberInteger(value) || 'El n° debe ser un número',
                        value => (`${value}`).length<=3 || 'El n° caja, no debe exceder los 3 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Monto caja inicial:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Monto Caja Inicial' : ''"
                    v-model="dataCashRegister.money_box"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El monto de caja es requerido',
                        value => this.mRuleNumberDecimal(value) || 'El monto de caja debe ser un número'
                    ]"
                />
            </ORowFormComp>
        </v-form>
        <div class="d-flex justify-center mt-1">
            <MBtnNormalComp
                @click="eValidForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import { MBtnNormalComp, MTextFieldComp } from "../molecules";
import { ORowFormComp } from "../organisms";
import { rulesMixin } from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        MBtnNormalComp,
        ORowFormComp,
        MTextFieldComp
    },
    props: {
        indexCashRegister: Number,
        mobile: Boolean,
        dataCashRegister: Object
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.$refs.formcashregister.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.formcashregister.resetValidation();
        },
        activateFocus() {
            this.$refs.cashregister.activateFocus();
        }
    }
}
</script>