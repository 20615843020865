<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <h1 class="app-title">
                {{`Venta: ${sale.sale.toFixed(2)} Bs.`}}
            </h1>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex align-center">
                <h1 class="app-title-dark mr-1">Total Descuento:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${mSaleTotalDiscount(sale).toFixed(2)} Bs.`}}
                </h1>
                <div
                    :class="mSaleTotalDiscount(sale)>0
                        ? 'client__ornament-disabled'
                        : 'client__ornament-enabled'"
                >
                </div>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Tipo Pago:</h1>
                <h1 class="app-subtitle-dark">
                    {{sale.type_sale == "e" ? "Efectivo"
                        : sale.type_sale == "t"
                            ? "Tarjeta" : "Qr"}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Cliente:</h1>
                <h1 class="app-subtitle-dark">
                    {{sale.client.full_name}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">N° Caja:</h1>
                <h1 class="app-subtitle-dark">
                    {{sale.cash_register.cash_register}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Registrador:</h1>
                <h1 class="app-subtitle-dark">
                    {{sale.user.full_name}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha Reg.:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${sale.hour} ${sale.date}`}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                v-if="!isReport && !isCanceledSales && !isCloseBox"
                icon="mdi-printer"
                style="margin:2px"
                @click="$emit('clickprint')"
            />
            <OBtnCardComp
                icon="mdi-information-outline"
                style="margin:2px"
                @click="$emit('clickinfo')"
            />
            <OBtnCardComp
                icon="mdi-list-box-outline"
                style="margin:2px"
                @click="$emit('clickdetail')"
            />
            <OBtnCardComp
                v-if="!isCanceledSales && !isCloseBox && !isBill"
                icon="mdi-trash-can"
                style="margin:2px"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import { OBtnCardComp } from "../organisms";
import {
    saleMixin
} from "../../mixins";
export default {
    mixins: [ saleMixin ],
    props: {
        sale: Object,
        isCanceledSales: {
            type: Boolean,
            default: false
        },
        isCloseBox: Boolean,
        isBill: Boolean,
        isReport: Boolean
    },
    components: {
        OBtnCardComp
    }
}
</script>