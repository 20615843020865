<template>
    <v-textarea
        outlined
        :label="label"
        clearable
        :hideDetails="hideDetails"
        dense
        ref="textarea"
        @change="e => $emit('change', e)"
        @input="e => $emit('input', e)"
        :rules="rules"
        :height="height"
        :value="value"
    ></v-textarea>
</template>
<script>
export default {
    props: {
        value: [Number, String],
        label: String,
        hideDetails: Boolean,
        rules: Array,
        height: {
            type: String,
            default: "70"
        }
    },
    model: {
        event: 'input'
    },
    methods: {
        activateFocus() {
            this.$refs.textarea.focus();
        }
    }
}
</script>