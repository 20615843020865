import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';
class Laboratory {
    constructor(
        laboratory = null,
        location = null
    ) {
        this.laboratory = laboratory;
        this.location = location;
    }
    static async getLaboratories(page, search) {
        const response = await axios.get(
            `${apiUrls.LABORATORY}/list?page=${page}&search=${search}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addLaboratory(parameters){
        const response = await axios.post(
            `${apiUrls.LABORATORY}/add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editLaboratory(parameters){
        const response = await axios.put(
            `${apiUrls.LABORATORY}/edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deleteLaboratory(parameters) {
        const response = await axios.delete(
            `${apiUrls.LABORATORY}/delete`,
            { headers: userStore.state.header, data: parameters }
        );
        return response.data;
    }
}
export default Laboratory;