<template>
    <div class="s-form-product">
        <h1 class="app-title text-center mb-2">
            {{indexUser===-1 ? 'REGISTRAR' : 'EDITAR'}}
        </h1>
        <v-form
            v-model="valid"
            lazy-validation
            ref="formuser"
        >
            <div v-if="actionForm!=2">
                <ORowFormComp
                    :mobile="mobile"
                    informationRow="Nombre Completo:"
                >
                    <MTextFieldComp
                        :label="mobile ? 'Nombre Completo' : ''"
                        v-model="dataUser.full_name"
                        ref="name"
                        :counter="50"
                        :rules="[
                            value => !this.mRuleEmptyField(value) || 'El nombre es requerido',
                            value => (`${value}`).length<=50 || 'El nombre, no debe exceder los 50 dígitos'
                        ]"
                    />
                </ORowFormComp>
                <ORowFormComp
                    :mobile="mobile"
                    informationRow="Categoría:"
                >
                    <MSelectComp
                        :label="mobile ? 'Categoría' : ''"
                        v-model="dataUser.categorie"
                        :items="itemsCategories"
                        itemValue="value"
                        itemText="name"
                        :rules="[
                            value => !this.mRuleEmptyField(value) || 'La categoría es requerida',
                        ]"
                    />
                </ORowFormComp>
            </div>
            <div v-if="actionForm!=1">
                <ORowFormComp
                    :mobile="mobile"
                    informationRow="Usuario:"
                >
                    <MTextFieldComp
                        :label="mobile ? 'Usuario' : ''"
                        v-model="dataUser.username"
                        ref="user"
                        :counter="15"
                        :rules="[
                            value => !this.mRuleEmptyField(value) || 'El usuario es requerido',
                            value => !this.mRuleWithSpaces(value) || 'El usuario no debe contener espacios',
                            value => (`${value}`).length<=15 || 'El usuario, no debe exceder los 30 dígitos'
                        ]"
                    />
                </ORowFormComp>
                <ORowFormComp
                    :mobile="mobile"
                    informationRow="Contraseña:"
                >
                    <MTextFieldComp
                        :label="mobile ? 'Contraseña' : ''"
                        v-model="dataUser.password"
                        :counter="15"
                        :type="isText ? 'text' : 'password'"
                        :icon="isText ? 'mdi-eye-off' : 'mdi-eye'"
                        @clicksee="isText = !isText"
                        :rules="[
                            value => !this.mRuleEmptyField(value) || 'La contraseña es requerido',
                            value => !this.mRuleWithSpaces(value) || 'La contrseña no debe contener espacios',
                            value => (`${value}`).length<=15 || 'La contraseña, no debe exceder los 15 dígitos'
                        ]"
                    />
                </ORowFormComp>
                <ORowFormComp
                    :mobile="mobile"
                    informationRow="Repita Contraseña:"
                >
                    <MTextFieldComp
                        :label="mobile ? 'Repita Contraseña' : ''"
                        v-model="dataUser.repit_password"
                        :counter="15"
                        :type="isText ? 'text' : 'password'"
                        :icon="isText ? 'mdi-eye-off' : 'mdi-eye'"
                        @clicksee="isText = !isText"
                        :rules="[
                            value => !this.mRuleEmptyField(value) || 'La contraseña es requerido',
                            value => this.eValidarRepitPassword(value, dataUser.password) || 'La contraseña no coincide con la anterior.'
                        ]"
                    />
                </ORowFormComp>
            </div>
        </v-form>
        <div class="d-flex justify-center mt-1">
            <MBtnNormalComp
                @click="eValidForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import {
    MBtnNormalComp,
    MTextFieldComp,
    MSelectComp
} from "../molecules";
import { ORowFormComp } from "../organisms";
import { rulesMixin } from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        MBtnNormalComp,
        ORowFormComp,
        MTextFieldComp,
        MSelectComp
    },
    props: {
        indexUser: Number,
        mobile: Boolean,
        dataUser: Object,
        actionForm: Number
    },
    data: () => ({
        valid: true,
        itemsCategories: [
            { name: "Administrador", value: "0" },
            { name: "Vendedor", value: "1" }
        ],
        isText: false
    }),
    methods: {
        eValidarRepitPassword(repit_password, password) {
            return password === repit_password;
        },
        eValidForm() {
            if (this.$refs.formuser.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.isText = false;
            this.$refs.formuser.resetValidation();
        },
        activateFocus() {
            this.$refs.name.activateFocus();
        },
        activateUserFocus() {
            this.$refs.user.activateFocus();
        }
    }
}
</script>