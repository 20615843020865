<template>
    <v-dialog
        persistent
        :value="value"
        max-width="500"
    >
        <div class="s-detail-product">
            <div v-if="lot.id != undefined">
                <div class="s-detail-product__header d-flex align-center">
                    <h1 class="app-title">
                        {{`Lote N°: ${lot.lot_number}`}}
                    </h1>
                    <v-spacer></v-spacer>
                    <OBtnCardComp
                        icon="mdi-close"
                        @click="$emit('clickclose')"
                    />
                </div>
                <div class="s-detail-product__body">
                    <div class="d-flex align-center">
                        <h1 class="app-title-dark mr-1">Fecha expiración:</h1>
                        <h1 class="app-subtitle-dark">
                            {{mDateFormat(lot.expiration_date)}}
                        </h1>
                        <div
                            :class="lot.expired
                                ? 'client__ornament-disabled ml-1'
                                : 'client__ornament-enabled ml-1'"
                            style="margin-top:-2px;"
                        >
                        </div>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Cantidad:</h1>
                        <h1 class="app-subtitle-dark">
                            {{lot.quantity}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">N° Factura:</h1>
                        <h1 class="app-subtitle-dark">
                            {{lot.purchase.bill_receipt}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Fecha de Compra:</h1>
                        <h1 class="app-subtitle-dark">
                            {{mDateFormat(lot.purchase.date_purchase)}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Glosa:</h1>
                        <h1 class="app-subtitle-dark">
                            {{lot.purchase.gloss!=null ? lot.purchase.gloss : 'S/I'}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Proovedor:</h1>
                        <h1 class="app-subtitle-dark">
                            {{lot.purchase.provider.provider}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Celular:</h1>
                        <h1 class="app-subtitle-dark">
                            {{lot.purchase.provider.phone!=null ? lot.purchase.provider.phone : 'S/N'}}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { datesMixin } from "../../mixins";
import { OBtnCardComp } from "../organisms";
export default {
    components: {
        OBtnCardComp
    },
    mixins: [ datesMixin ],
    props: {
        lot: Object,
        value: Boolean
    }
}
</script>