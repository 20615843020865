export default {
    methods: {
        mProductTotalProducts(products) {
            var total = 0;
            for (var i = 0; i < products.length; i++)
                total += products[i].quantity;
            return total;
        },
        mProductDataToSend(product) {
            return {
                "id": product.id,
                "product": product.product,
                "barcore": product.barcore,
                "health_register": product.health_register,
                "sale_price": product.sale_price,
                "location": product.location,
                "composition": product.composition,
                "via_administration": product.via_administration,
                "contraindications": product.contraindications,
                "laboratory": product.laboratory,
                "description": product.description
            }
        },
        mProductTotalInventory(product) {
            var total = 0;
            product.inventories.forEach(element => {
                total += element.quantity;
            });
            return total;
        },
        mProductGenerateOutPuts(product) {
            var quantity = parseInt(product.select_quantity);
            var inventoryOutputs = [];
            for (var i = 0; i < product.inventories.length; i++) {
                var newQuantity = 0;
                if (quantity !== 0) {
                    if (product.inventories[i].quantity >= quantity) {
                        newQuantity = quantity;
                        quantity = 0;
                    } else {
                        newQuantity = product.inventories[i].quantity;
                        quantity -= product.inventories[i].quantity;
                    }
                }
                const parameters = {
                    'id': product.inventories[i].id,
                    'expiration_date': product.inventories[i].expiration_date,
                    'lot_number': product.inventories[i].lot_number,
                    'purchase_price': product.inventories[i].purchase_price,
                    'quantity': newQuantity,
                    'id_purchase': product.inventories[i].id_purchase,
                    'created_at': product.inventories[i].created_at
                };
                inventoryOutputs.push(parameters);
            }
            return inventoryOutputs;
        }
    }
}