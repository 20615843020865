<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header d-flex align-center">
            <h1 class="app-title">
                {{information.information}}
            </h1>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">N° Caja:</h1>
                <h1 class="app-subtitle-dark">
                    {{information.cash_register.cash_register}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Registrador(a):</h1>
                <h1 class="app-subtitle-dark">
                    {{information.user}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha/Hora:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${information.hour} ${information.date}`}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
        </div>
    </div>
</template>
<script>
import {
    OBtnCardComp
} from "../organisms";
export default {
    components: {
        OBtnCardComp
    },
    props: {
        information: Object
    }
}
</script>