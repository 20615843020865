<template>
    <v-dialog
        persistent
        :width="width"
        :value="value"
    >
        <v-card class="pa-0" color="#FAFAFA">
            <div class="teal darken-3 ma-0 pa-2" style="position:relative">
                <v-card
                    v-if="disabledBill"
                    width="80px"
                    max-width="80px"
                    style="position:absolute; left:0px; top:13px;"
                    class="rounded-0"
                    color="red"
                    height="13px"
                ></v-card>
                <h1 class="app-text--space app-title-dark text-center">CONFIRMACIÓN</h1>
            </div>
            <div class="d-flex justify-center pt-2">
                <v-icon x-large class="animation-icon-rotate" color="#00695C">
                    mdi-message-check-outline</v-icon>
            </div>
            <div class="d-block pa-2 pb-0">
                <h1
                    class="text-center app-subtitle"
                    v-for="(sms, index) in smsConfirm"
                    :key="index"
                >{{sms}}</h1>
            </div>
            <div class="d-flex justify-center pa-3">
                <v-btn
                    small
                    color="red"
                    outlined
                    class="mr-2"
                    :ripple="false"
                    @click="$emit('clickno')"
                >
                    {{mobile ? 'NO' : 'NO (n)'}}
                </v-btn>
                <v-btn
                    small
                    color="green"
                    outlined
                    :ripple="false"
                    @click="$emit('clickyes')"
                >
                    {{mobile ? 'SI' : 'SI (s)'}}
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        value: Boolean,
        smsConfirm: Array,
        mobile: Boolean,
        width: {
            type: String,
            default: "400"
        },
        disabledBill: false
    }
}
</script>