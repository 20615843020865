<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Producto</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Tipo Producto</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Laboratorio</h1>
                    </th>
                    <th width="80">
                        <h1 class="app-title-dark text-left">Cantidad</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-center">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(product, index) in detailOrder"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{product.product}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.type_product.type_product}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.laboratory.laboratory}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.quantity_order}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            :smsToolTip="`Ver detalle: ${product.product}`"
                            icon="mdi-list-box"
                            class="mr-1"
                            @click="$emit('clickdetail', product)"
                        />
                        <OBtnToolTipTableComp
                            :smsToolTip="`Editar cantidad: ${product.product}`"
                            icon="mdi-pencil"
                            class="mr-1"
                            @click="$emit('clickedit', product)"
                        />
                        <OBtnToolTipTableComp
                            :smsToolTip="`Eliminar: ${product.product}`"
                            icon="mdi-delete"
                            class="mr-1"
                            @click="$emit('clickdelete', index)"
                        />
                    </td>
                </tr>
                <tr v-if="detailOrder.length === 0">
                    <td colspan="6">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { OBtnToolTipTableComp } from "../organisms";
export default {
    components: {
        OBtnToolTipTableComp
    },
    props: {
        detailOrder: []
    }
}
</script>