<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <text-highlight
                class="app-title"
                :queries="search"
            >
                {{entrie.product.product}}
            </text-highlight>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">N° Lote:</h1>
                <h1 class="app-subtitle-dark">
                    {{entrie.lot_number}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Cantidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{entrie.quantity}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">P.U.C.:</h1>
                <h1 class="app-subtitle-dark">
                    {{entrie.purchase_price.toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha expiración:</h1>
                <h1 class="app-subtitle-dark">
                    {{mDateFormat(entrie.expiration_date)}}
                </h1>
                <div
                    :class="entrie.expired
                        ? 'client__ornament-disabled ml-1'
                        : 'client__ornament-enabled ml-1'"
                    style="margin-top:3.2px;"
                >
                </div>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Diferencia Días:</h1>
                <h1 class="app-subtitle-dark">
                    {{entrie.diff_days}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha Registro:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${entrie.hour} ${entrie.date}`}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-list-box"
                style="margin:2px"
                @click="$emit('clickdetail')"
            />
            <OBtnCardComp
                icon="mdi-receipt-text"
                style="margin:2px"
                @click="$emit('clickpurchase')"
            />
            <OBtnCardComp
                v-if="!isExpiringInventory"
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickupdate')"
            />
            <OBtnCardComp
                v-if="!isExpiringInventory"
                icon="mdi-delete-sweep"
                style="margin:2px"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import { OBtnCardComp } from "../organisms";
import { datesMixin } from "../../mixins";
export default {
    mixins: [ datesMixin ],
    props: {
        entrie: Object,
        search: {
            type: String,
            default: ''
        },
        isExpiringInventory: Boolean
    },
    components: {
        TextHighlight,
        OBtnCardComp
    }
}
</script>