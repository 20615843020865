<template>
    <div class="s-form-product">
        <h1
            v-if="!aloneForm"
            class="app-title text-center mb-2"
        >
            {{indexPurchase===-1 ? 'REGISTRAR' : 'EDITAR'}}
        </h1>
        <v-form
            v-model="valid"
            lazy-validation
            ref="formpurchase"
        >
            <ORowFormComp
                :mobile="mobile"
                informationRow="Descuento Comercial:"
                classText="s-form-purchase__input"
                ref="billreceipt"
            >
                <MTextFieldComp
                    :label="mobile ? 'Descuento Comercial' : ''"
                    v-model="dataPurchase.discount"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'Es requerido',
                        value => this.mRuleNumberDecimal(value) || 'Debe ser un número'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Otro Descuento:"
                classText="s-form-purchase__input"
            >
                <MTextFieldComp
                    :label="mobile ? 'Otro Descuento' : ''"
                    v-model="dataPurchase.other_discount"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'Es requerido',
                        value => this.mRuleNumberDecimal(value) || 'Debe ser un número'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="N° Recibo/Factura:"
                classText="s-form-purchase__input"
            >
                <MTextFieldComp
                    :label="mobile ? 'N° Recibo/Factura' : ''"
                    v-model="dataPurchase.bill_receipt"
                    :counter="30"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El N° Recibo/Factura es requerido',
                        value => (`${value}`).length<=30 || 'El N° Recibo/Factura, no debe exceder los 30 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Fecha Compra:"
                classText="s-form-purchase__input"
            >
                <MTextFieldComp
                    :label="mobile ? 'Fecha Compra' : ''"
                    v-model="dataPurchase.date_purchase"
                    type="date"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El descuento es requerido'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Glosa:"
                classText="s-form-purchase__input"
            >
                <MTextAreaComp
                    :label="mobile ? 'Glosa' : ''"
                    v-model="dataPurchase.gloss"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Proveedor:"
                classText="s-form-purchase__input"
            >
                <div
                    :class="dataPurchase.activate_error && dataPurchase.fk_provider == null
                        ? 'product__search-laboratories product__search-error d-flex align-center pl-3'
                        : 'product__search-laboratories d-flex align-center pl-3 mb-2'"
                    @click="$emit('clickprovider')"
                >
                    <h2 v-if="mobile">Proveedor</h2>
                    <h1 class="app-subtitle">
                        {{dataPurchase.fk_provider==null ? 'Sin elección' : dataPurchase.provider.provider}}
                    </h1>
                </div>
            </ORowFormComp>
            <div
                v-if="dataPurchase.activate_error && dataPurchase.fk_provider == null"
                class="d-flex justify-end"
            >
                <div :class="!mobile ? 's-form-purchase__input' : ''">
                    <h3 class="app-error-form">
                        El proveedor es requerido
                    </h3>
                </div>
            </div>
        </v-form>
        <div class="d-flex justify-center mt-2">
            <MBtnNormalComp
                @click="eValidForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MTextAreaComp,
    MBtnNormalComp
} from "../../components/molecules";
import {
    ORowFormComp
} from "../../components/organisms";
import { rulesMixin } from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        ORowFormComp,
        MTextFieldComp,
        MTextAreaComp,
        MBtnNormalComp
    },
    props: {
        mobile: Boolean,
        dataPurchase: Object,
        indexPurchase: Number,
        aloneForm: Boolean
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.$refs.formpurchase.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.formpurchase.resetValidation();
        },
        activateFocus() {
            this.$refs.billreceipt.activateFocus();
        }
    }
}
</script>