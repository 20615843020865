import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';
class CashRegister {
    constructor (
        cash_register = null,
        money_box = null
    )
    {
        this.cash_register = cash_register;
        this.money_box = money_box;
    }
    static async getCashRegisters() {
        const response = await axios.get(
            `${apiUrls.CASH_REGISTER}/list`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addCashRegisters(parameters) {
        const response = await axios.post(
            `${apiUrls.CASH_REGISTER}/add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editCashRegisters(parameters) {
        const response = await axios.put(
            `${apiUrls.CASH_REGISTER}/edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deleteCashRegister(parameters) {
        const response = await axios.delete(
            `${apiUrls.CASH_REGISTER}/delete`,
            { headers: userStore.state.header, data: parameters }
        );
        return response.data;
    }
    static async changeStateCashRegister(parameters) {
        const response = await axios.patch(
            `${apiUrls.CASH_REGISTER}/change-state`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getStateDiscount(idCashRegister) {
        const response = await axios.get(
            `${apiUrls.CASH_REGISTER}/state-activate-discount?id=${idCashRegister}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async changeStateDiscount(parameters) {
        const response = await axios.patch(
            `${apiUrls.CASH_REGISTER}/change-state-discount`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default CashRegister;