<template>
    <div>
        <div class="d-flex pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    CIERRE DE CAJA
                </h1>
            </div>
            <v-spacer></v-spacer>
            <OBtnToolTipHeaderComp
                icono="mdi-database"
                smsToolTip="Volver a cierre de caja"
                @click="eBackList()"
            />
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap">
                    <div class="product__group-search d-flex justify-center pt-2 pl-2 pr-2">
                        <div class="product__search" style="margin-right:2px">
                            <MSelectComp
                                label="Usuario"
                                :items="userList"
                                itemValue="id"
                                itemText="full_name"
                                :hideDetails="true"
                                v-model="idUser"
                                @change="eChangeUser"
                            />
                        </div>
                        <div class="product__search" style="margin-left:2px">
                            <MSelectComp
                                label="Caja Registradora"
                                :items="cashRegisterList"
                                itemValue="id"
                                itemText="cash_register"
                                :hideDetails="true"
                                v-model="idCashRegister"
                                @change="eChangeCashRegister"
                            />
                        </div>
                    </div>
                    <div class="product__group-search d-flex justify-center pt-2 pl-2 pr-2">
                        <div class="product__search" style="margin-right:2px">
                            <MTextFieldComp
                                type="date"
                                label="Fecha Inicio"
                                :hideDetails="true"
                                :clearable="false"
                                v-model="dateOne"
                                @change="eChangeDateOne"
                            />
                        </div>
                        <div class="product__search" style="margin-left:2px">
                            <MTextFieldComp
                                type="date"
                                label="Fecha Final"
                                :hideDetails="true"
                                :clearable="false"
                                v-model="dateTwo"
                                @change="eChangeDateTwo"
                            />
                        </div>
                    </div>
                </div>
                <v-divider class="mt-2"></v-divider>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <v-card
                    color="purple"
                    v-if="!dialogLoaderData && parametersSetting.isWarning"
                    class="animation-opacity ma-2 mb-1 pa-1"
                >
                    <h1 class="app-title-dark text-center">
                        {{`!!! ${parametersSetting.message}`}}
                    </h1>
                </v-card>
                <div class="d-flex justify-center pa-2">
                    <SInfoCloseBoxComp
                        v-if="!dialogLoaderData"
                        :infoReport="infoReport"
                        @clickshowsales="eShowSales"
                        @clickexpenses="eShowExpenses()"
                        @clickinfo="eShowInformation()"
                        @clickpayments="eShowPayments()"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2 pb-1">
                    <div class="d-flex">
                        <h1 class="app-title mr-1">Tipo Venta:</h1>
                        <h1 class="app-subtitle">{{titleTypeSale}}</h1>
                    </div>
                    <div class="d-flex" v-if="titleTypeSale != 'Anuladas'">
                        <h1 class="app-title mr-1">Total:</h1>
                        <h1 class="app-subtitle">{{totalSalesView.toFixed(2)}}</h1>
                    </div>
                    <div class="d-flex" v-else>
                        <h1 class="app-title mr-1">Cantidad:</h1>
                        <h1 class="app-subtitle">{{totalSalesView}}</h1>
                    </div>
                </div>
                <v-divider></v-divider>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderDataSale"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="salesList.length==0 && !dialogLoaderDataSale && mobile"
                />
                <STableSaleComp
                    v-if="!dialogLoaderDataSale && !mobile"
                    :salesList="salesList"
                    :isCloseBox="true"
                    @clickinfo="eShowInfoSale"
                    @clickdetail="eShowDetailSale"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderDataSale && mobile"
                >
                    <SCardReportSaleComp
                        class="ma-2"
                        v-for="sale in salesList"
                        :key="sale.id"
                        :sale="sale"
                        :isCloseBox="true"
                        @clickdetail="eShowDetailSale(sale)"
                        @clickinfo="eShowInfoSale(sale)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="mobile && !dialogLoaderDataSale && salesList.length>0"
                ></v-divider>
                <SPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataSale && salesList.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2 pb-0 pt-1">
                    <OInfoSaleComp
                        :sale="sale"
                    />
                </div>
                <v-divider class="mt-1"></v-divider>
                <STableDetailReportSaleComp
                    v-if="!mobile"
                    :detailSale="sale.detail"
                    @clickinventory="eShowDetailExpirationDate"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-else
                >
                    <SCardDetailSaleComp
                        class="ma-2"
                        v-for="product in sale.detail"
                        :key="product.id"
                        :product="product"
                        @clickinventory="eShowDetailExpirationDate(product)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderDataSale"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="expensesList.length==0 && !dialogLoaderDataSale && mobile"
                />
                <STableExpensesComp
                    v-if="!dialogLoaderDataSale && !mobile"
                    :expensesList="expensesList"
                    :isInformation="true"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderDataSale && mobile"
                >
                    <SCardExpenseComp
                        class="ma-2"
                        v-for="expense in expensesList"
                        :key="expense.id"
                        :expense="expense"
                        :isInformation="true"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="mobile && !dialogLoaderDataSale && expensesList.length>0"
                ></v-divider>
                <SPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataSale && expensesList.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePageExpense(false)"
                    @clickgo="eChangePageExpense(true)"
                    @clickpage="eSearchForPageExpense"
                />
            </v-tab-item>
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderDataSale"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="informationsList.length==0 && !dialogLoaderDataSale && mobile"
                />
                <STableInformationsComp
                    v-if="!dialogLoaderDataSale && !mobile"
                    :informationsList="informationsList"
                    :isInformation="true"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderDataSale && mobile"
                >
                    <SCardInformationComp
                        class="ma-2"
                        v-for="information in informationsList"
                        :key="information.id"
                        :information="information"
                        :isInformation="true"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="mobile && !dialogLoaderDataSale && informationsList.length>0"
                ></v-divider>
                <SPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataSale && informationsList.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePageInfo(false)"
                    @clickgo="eChangePageInfo(true)"
                    @clickpage="eSearchForPageInfo"
                />
            </v-tab-item>
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderDataSale"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="paymentsList.length==0 && !dialogLoaderDataSale && mobile"
                />
                <STablePaymentComp
                    v-if="!dialogLoaderDataSale && !mobile"
                    :paymentsList="paymentsList"
                    :isInfo="true"
                    @clickdetail="eShowDetailPayment"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderDataSale && mobile"
                >
                    <SCardPaymentComp
                        class="ma-2"
                        :isInfo="true"
                        v-for="payment in paymentsList"
                        :key="payment.id"
                        :payment="payment"
                        @clickdetail="eShowDetailPayment(payment)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="mobile && !dialogLoaderDataSale && paymentsList.length>0"
                ></v-divider>
                <SPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataSale && paymentsList.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePagePayments(false)"
                    @clickgo="eChangePagePayments(true)"
                    @clickpage="eSearchForPagePayments"
                />
            </v-tab-item>
        </v-tabs-items>
        <SInfomationSaleComp
            :value="dialogInfoSale"
            :sale="sale"
            @clickclose="dialogInfoSale=false"
        />
        <SDetailPaymentComp
            :payment="payment"
            :value="dialogDetailPayment"
            @clickclose="dialogDetailPayment=false"
        />
        <SDialogInventoriesMoveComp
            :value="dialogExpirationDate"
            :product="product"
            @clickclose="dialogExpirationDate=false"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MNoDataComp,
    MTextFieldComp,
    MSelectComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSmsErrorComp,
    OLoaderDataComp,
    OInfoSaleComp,
    OSmsActionComp
} from "../../components/organisms";
import {
    SInfoCloseBoxComp,
    SCardReportSaleComp,
    STableSaleComp,
    SInfomationSaleComp,
    STableDetailReportSaleComp,
    SCardDetailSaleComp,
    SDialogInventoriesMoveComp,
    SPaginationComp,
    STableExpensesComp,
    SCardExpenseComp,
    STableInformationsComp,
    SCardInformationComp,
    STablePaymentComp,
    SCardPaymentComp,
    SDetailPaymentComp
} from "../../components/species";
import {
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    dataGeneralCompsMixin,
    datesMixin,
    rulesMixin
} from "../../mixins";
import {
    Report,
    Sale,
    Expense,
    Information,
    User,
    CashRegister,
    Payment,
    SettingBill
} from "../../models";
export default {
    mixins: [
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        dataGeneralCompsMixin,
        datesMixin,
        rulesMixin
    ],
    components: {
        OBtnToolTipHeaderComp,
        OSmsErrorComp,
        OLoaderDataComp,
        SInfoCloseBoxComp,
        MNoDataComp,
        SCardReportSaleComp,
        STableSaleComp,
        SInfomationSaleComp,
        OInfoSaleComp,
        STableDetailReportSaleComp,
        SCardDetailSaleComp,
        SDialogInventoriesMoveComp,
        SPaginationComp,
        OSmsActionComp,
        STableExpensesComp,
        SCardExpenseComp,
        STableInformationsComp,
        SCardInformationComp,
        MTextFieldComp,
        MSelectComp,
        STablePaymentComp,
        SCardPaymentComp,
        SDetailPaymentComp
    },
    props: {
        mini: Boolean
    },
    data: () => ({
        tab: 0,
        infoReport: {
            "total_sales_enabled_e": 0,
            "total_sales_enabled_q": 0,
            "quantity_sales_disabled": 0,
            "total_expenses": 0,
            "quantity_info": 0,
            "money_box": 0,
            "total_discount": 0
        },
        salesList: [],
        dialogLoaderDataSale: false,
        dialogInfoSale: false,
        sale: {},
        dialogExpirationDate: false,
        product: {},
        disabled: 2,
        typeSale: "a",
        expensesList: [],
        informationsList: [],
        //
        dateOne: "",
        dateTwo: "",
        userList: [{ id: 0, full_name: "Todos" }],
        idUser: 0,
        cashRegisterList: [{ id: 0, cash_register: "Todos" }],
        idCashRegister: 0,
        lastPageExpense: 0,
        currentPageExpense: 0,
        lastPageInfo: 0,
        currentPageInfo: 0,
        paymentsList: [],
        payment: {},
        dialogDetailPayment: false,
        totalPayments: 0,
        totalPaymentsCompliance: 0,
        parametersSetting: {
            isWarning: false,
            message: ""
        },
        titleTypeSale: "",
        totalSalesView: 0
    }),
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else this.dialogError = false;
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        eBackList() {
            if (this.tab === 2) {
                this.tab = 1;
                return;
            } this.tab = 0;
            setTimeout(() => {
                this.salesList = [];
                this.expensesList = [];
                this.informationsList = [];
                this.paymentsList = [];
            }, 200);
        },
        async getSales(page, dateOne, dateTwo, idUser, typeSale, idCashRegister, disabled) {
            this.dialogLoaderDataSale = true;
            try {
                const response = await Sale.closeBox(page, dateOne, dateTwo, idUser, typeSale, idCashRegister, disabled);
                this.salesList = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderDataSale = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eShowSales(parameters) {
            this.disabled = parameters.disabled;
            this.typeSale = parameters.typeSale;
            this.titleTypeSale = parameters.titleTypeSale;
            this.totalSalesView = parameters.value;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
                this.getSales(1, this.dateOne, this.dateTwo, this.idUser, parameters.typeSale, this.idCashRegister, parameters.disabled);
            }, time);
        },
        //
        eShowInfoSale(sale) {
            this.sale = sale;
            this.dialogInfoSale = true;
        },
        eShowDetailSale(sale) {
            this.sale = sale;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 2;
            }, time);
        },
        eShowDetailExpirationDate(product) {
            this.product = product;
            this.dialogExpirationDate = true;
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getSales(this.currentPage, this.dateOne, this.dateTwo, this.idUser, this.typeSale, this.idCashRegister, this.disabled);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getSales(page, this.dateOne, this.dateTwo, this.idUser, this.typeSale, this.idCashRegister, this.disabled);
                }, time);
            }
        },
        async getExpenses(page, dateOne, dateTwo, idUser, idCashRegister) {
            this.dialogLoaderDataSale = true;
            this.tab = 3;
            try {
                const response = await Expense.getExpensesAdmin(page, dateOne, dateTwo, idUser, idCashRegister);
                this.expensesList = response.data;
                this.currentPage = response.current_page;
                this.lastPage = response.last_page;
                this.total = response.total;
                this.dialogLoaderDataSale = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eShowExpenses() {
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 3;
                this.getExpenses(1, this.dateOne, this.dateTwo, this.idUser, this.idCashRegister);
            }, time);
        },
        eChangePageExpense(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getExpenses(this.currentPage, this.dateOne, this.dateTwo, this.idUser, this.idCashRegister);
            }, time);
        },
        eSearchForPageExpense(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getExpenses(page, this.dateOne, this.dateTwo, this.idUser, this.idCashRegister);
                }, time);
            }
        },
        async getInformation(page, dateOne, dateTwo, idUser, idCashRegister) {
            this.dialogLoaderDataSale = true;
            try {
                const response = await Information.getInfoAdmin(page, dateOne, dateTwo, idUser, idCashRegister);
                this.informationsList = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderDataSale = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eShowInformation() {
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 4;
                this.getInformation(1, this.dateOne, this.dateTwo, this.idUser, this.idCashRegister);
            }, time);
        },
        eChangePageInfo(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getInformation(this.currentPage, this.dateOne, this.dateTwo, this.idUser, this.idCashRegister);
            }, time);
        },
        eSearchForPageInfo(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getInformation(page, this.dateOne, this.dateTwo, this.idUser, this.idCashRegister);
                }, time);
            }
        },
        //search
        async getReport(dateOne, dateTwo, idUser, idCashRegister) {
            this.dialogLoaderData = true;
            try {
                const response =  await Report.closeBoxAdmin(dateOne, dateTwo, idUser, idCashRegister);
                this.infoReport = response;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eChangeDateOne(dateOne) {
            if (dateOne == "" || this.dateTwo == "")
                this.mDataGeneralShowSmsAction(["Error de Fechas", "Seleccione bien las fechas"], "error");
            else {
                if (dateOne > this.dateTwo) this.dateTwo = dateOne;
                this.getReport(dateOne, this.dateTwo, this.idUser, this.idCashRegister);
            }
        },
        eChangeDateTwo(dateTwo) {
            if (dateTwo == "" || this.dateOne == "")
                this.mDataGeneralShowSmsAction(["Error de Fechas", "Seleccione bien las fechas"], "error");
            else {
                if (dateTwo < this.dateOne) this.dateOne = dateTwo;
                this.getReport(this.dateOne, dateTwo, this.idUser, this.idCashRegister);
            }
        },
        eChangeUser(idUser) {
            this.getReport(this.dateOne, this.dateTwo, idUser, this.idCashRegister);
        },
        eChangeCashRegister(idCashRegister) {
            this.getReport(this.dateOne, this.dateTwo, this.idUser, idCashRegister);
        },
        handleKeyUpCloseBoxAdmin(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        //
        showWarningExpired(diffDays, expiratedDate) {
            if (diffDays <= 15) {
                this.parametersSetting.isWarning = true;
                this.parametersSetting.message = `La validez de la facturación expira el ${this.mDateFormat(expiratedDate)}`;
            }
        },
        async getRequests() {
            this.dialogLoaderData = true;
            try {
                const report = Report.closeBoxAdmin("", "", 0, 0);
                const cashRegister = CashRegister.getCashRegisters();
                const users = User.getUsers(0, 0);
                const getSettingBill = SettingBill.getSettingBill();
                const responses = await Promise.all([users, cashRegister, report, getSettingBill]);
                responses[0].forEach(user => {
                    this.userList.push(user);
                });
                responses[1].forEach(cashRegister => {
                    this.cashRegisterList.push(cashRegister);
                });
                this.infoReport = responses[2];
                if (responses[3].id != undefined)
                    this.showWarningExpired(responses[3].diff_days, responses[3].expiration_date);
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        //
        async getPayments(page, dateOne, dateTwo, idCashRegister, idUser) {
            this.dialogLoaderDataSale = true;
            this.tab = 5;
            try {
                const response = await Payment.getPayments(page, dateOne, dateTwo, idCashRegister, idUser, 'destination_date');
                this.paymentsList = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.totalPayments = response.total_payments;
                this.totalPaymentsCompliance = response.total_payments_compliance;
                this.dialogLoaderDataSale = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eShowPayments() {
            this.dialogLoaderDataSale = true;
            this.tab = 5;
            this.getPayments(this.currentPage, this.dateOne, this.dateTwo, this.idCashRegister, this.idUser);
        },
        eShowDetailPayment(payment) {
            this.payment = payment;
            this.dialogDetailPayment = true;
        },
        eChangePagePayments(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getInformation(this.currentPage, this.dateOne, this.dateTwo, this.idUser, this.idCashRegister);
            }, time);
        },
        eSearchForPagePayments(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getInformation(page, this.dateOne, this.dateTwo, this.idUser, this.idCashRegister);
                }, time);
            }
        }
    },
    mounted() {
        this.dateOne = this.dateTwo = this.mDateSystem(false);
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpCloseBoxAdmin);
                this.getRequests();
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpCloseBoxAdmin);
    }
}
</script>