var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"e-table-product animation-opacity"},[_c('table',{staticClass:"e-table-product__table"},[_c('thead',[_c('tr',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('th',{attrs:{"width":_vm.productsList.length > 0 ? '180px' : ''}},[_c('h1',{staticClass:"app-title-dark text-center"},[_vm._v("Acciones")])])])]),_c('tbody',[_vm._l((_vm.productsList),function(product,index){return _c('tr',{key:index},[_c('td',[_c('h1',{staticClass:"app-subtitle text-right"},[_vm._v(" "+_vm._s((index+1))+" ")])]),_c('td',[(_vm.searchType!=1)?_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(product.product)+" ")]):_c('text-highlight',{staticClass:"app-subtitle",attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(product.product)+" ")])],1),_c('td',[_c('div',{class:product.disabled==1
                            ? 'client__ornament-disabled'
                            : 'client__ornament-enabled'})]),_c('td',[_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(product.barcore!=null ? product.barcore : "S/CB")+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(product.sale_price.toFixed(2))+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(product.max_discount.toFixed(2))+" ")])]),_c('td',[(_vm.searchType!=2)?_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(product.location!=null ? product.location : "S/U")+" ")]):_c('text-highlight',{staticClass:"app-subtitle",attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(product.location!=null ? product.location : "S/U")+" ")])],1),_c('td',[_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(product.type_product.type_product)+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(product.laboratory.laboratory)+" ")])]),_c('td',{staticClass:"d-flex justify-center flex-wrap"},[_c('OBtnToolTipTableComp',{attrs:{"smsToolTip":`Actualizar precio: ${product.product}`,"icon":"mdi-cash-100"},on:{"click":function($event){return _vm.$emit('clickprice', product)}}}),_c('OBtnToolTipTableComp',{attrs:{"smsToolTip":`Ver detalle de: ${product.product}`,"icon":"mdi-list-box"},on:{"click":function($event){return _vm.$emit('clickdetail', product)}}}),_c('OBtnToolTipTableComp',{attrs:{"smsToolTip":`Editar: ${product.product}`,"icon":"mdi-pencil"},on:{"click":function($event){return _vm.$emit('clickedit', product)}}}),_c('OBtnToolTipTableComp',{attrs:{"smsToolTip":product.disabled==1
                            ? `Habilitar: ${product.product}`
                            : `Deshabilitar: ${product.product}`,"icon":product.disabled==1 ? 'mdi-eye' : 'mdi-eye-off'},on:{"click":function($event){return _vm.$emit('clickstate', product)}}}),_c('OBtnToolTipTableComp',{attrs:{"smsToolTip":`Eliminar: ${product.product}`,"icon":"mdi-delete"},on:{"click":function($event){return _vm.$emit('clickdelete', product)}}})],1)])}),(_vm.productsList.length === 0)?_c('tr',[_vm._m(9)]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"50px"}},[_c('h1',{staticClass:"app-title-dark text-center"},[_vm._v("N°")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Producto")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"20"}},[_c('h1',{staticClass:"app-title-dark text-center"},[_vm._v("E")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Código Barra")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Precio Venta")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Desc. Máximo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Ubicación")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Tipo Producto")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Laboratorio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"10"}},[_c('h1',{staticClass:"app-subtitle text-center"},[_vm._v(" SIN INFORMACIÓN ")])])
}]

export { render, staticRenderFns }