var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"e-table-product animation-opacity"},[_c('table',{staticClass:"e-table-product__table"},[_c('thead',[_c('tr',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),(!_vm.isInformation)?_c('th',{attrs:{"width":"100px"}},[_c('h1',{staticClass:"app-title-dark text-center"},[_vm._v("Acciones")])]):_vm._e()])]),_c('tbody',[_vm._l((_vm.expensesList),function(expense,index){return _c('tr',{key:index},[_c('td',[_c('h1',{staticClass:"app-subtitle text-right"},[_vm._v(" "+_vm._s((index+1))+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle text-right"},[_vm._v(" "+_vm._s(expense.expense.toFixed(2))+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(expense.description!=null ? expense.description : "S/D")+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(expense.cash_register.cash_register)+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(expense.user)+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(`${expense.hour} ${expense.date}`)+" ")])]),(!_vm.isInformation)?_c('td',{staticClass:"d-flex justify-center flex-wrap"},[_c('OBtnToolTipTableComp',{attrs:{"smsToolTip":"Editar Gasto","icon":"mdi-pencil"},on:{"click":function($event){return _vm.$emit('clickedit', expense)}}})],1):_vm._e()])}),(_vm.expensesList.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":_vm.isInformation ? '6' : '7'}},[_c('h1',{staticClass:"app-subtitle text-center"},[_vm._v(" SIN INFORMACIÓN ")])])]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"50px"}},[_c('h1',{staticClass:"app-title-dark text-center"},[_vm._v("N°")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-center"},[_vm._v("Gasto (Bs.)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-center"},[_vm._v("Descripción")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"80"}},[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("N° Caja")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Registrador(a)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Fecha/Hora")])])
}]

export { render, staticRenderFns }