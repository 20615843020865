<template>
    <v-dialog
        persistent
        :value="value"
        max-width="500"
    >
        <v-card
            min-height="300"
        >
            <div class="s-detail-product__header pl-2 pr-2 d-flex align-center">
                <h1 class="app-title">
                    {{tab==0 ? 'SELECCIONAR CLIENTE' : 'NUEVO CLIENTE'}}
                </h1>
                <v-spacer></v-spacer>
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    class="mr-1"
                    smsToolTip="Volver a lista clientes"
                    @click="eBackList()"
                />
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <div class="client__line-header"></div>
            <v-tabs-items
                style="background:transparent !important"
                v-model="tab"
                :touchless="true"
            >
                <v-tab-item>
                    <div class="pa-2 d-flex">
                        <OSearchHeaderComp
                            label="Buscar cliente"
                            :clearable="false"
                            class="mr-1"
                            v-model="search"
                            @entersearch="$emit('entersearch', search)"
                        />
                        <OBtnToolTipHeaderComp
                            icono="mdi-plus"
                            smsToolTip="Crear nuevo cliente"
                            @click="tab=1"
                        />
                    </div>
                    <v-divider></v-divider>
                    <MNoDataComp
                        smsNotData="SIN DATOS"
                        class="ma-1"
                        v-if="clientsList.length==0 && !dialogLoaderData"
                    />
                    <div class="pa-2 pt-0">
                        <OLoaderDataComp
                            class="mt-2"
                            v-if="dialogLoaderData"
                            smsLoaderData="Obteniendo Información"
                        />
                        <div v-else>
                            <div
                                class="mt-2"
                                v-for="client in clientsList"
                                :key="client.id"
                            >
                                <v-hover
                                    v-slot="{ hover }"
                                >
                                    <v-card
                                        class="pa-1 pl-2 pr-2 d-flex"
                                        :color="hover ? '#28282821' : ''"
                                        :elevation="hover ? 1 : 0"
                                        outlined
                                        :ripple="false"
                                        @click="$emit('clickclient', client)"
                                    >
                                        <text-highlight
                                            :queries="search"
                                        >
                                            {{client.full_name}}
                                        </text-highlight>
                                        <text-highlight
                                            :queries="search"
                                        >
                                            {{client.ci_nit}}
                                        </text-highlight>
                                    </v-card>
                                </v-hover>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <v-form
                        v-model="valid"
                        lazy-validation
                        ref="formclient"
                        class="pa-2 pt-4"
                    >
                        <MTextFieldComp
                            label="Nombre/Razon Social"
                            v-model="dataClient.full_name"
                            :counter="70"
                            :rules="[
                                value => !this.mRuleEmptyField(value) || 'El nombre/empresa es requerido',
                                value => (`${value}`).length<=70 || 'El nombre/empresa no debe exceder los 70 dígitos'
                            ]"
                        />
                        <MTextFieldComp
                            label="CI/NIT"
                            class="mt-2"
                            v-model="dataClient.ci_nit"
                            :counter="50"
                            :rules="[
                                value => !this.mRuleEmptyField(value) || 'Es ci/nit es requerido',
                                value => !this.mRuleWithSpaces(value) || 'El ci/nit no debe contener espacios',
                                value => (`${value}`).length<=50 || 'El ci/nit no debe exceder los 50 dígitos'
                            ]"
                        />
                        <MTextFieldComp
                            label="Complemento"
                            class="mt-2"
                            v-model="dataClient.complement"
                            :counter="10"
                            :rules="[
                                value => (`${value}`).length<=10 || 'El complemento no debe exceder los 10 dígitos'
                            ]"
                        />
                        <MSelectComp
                            class="mt-2"
                            label="Entidad"
                            v-model="dataClient.entity"
                            :items="items"
                            :hideDetails="true"
                            itemValue="value"
                            itemText="name"
                        />
                        <MTextFieldComp
                            label="Celular"
                            class="mt-5"
                            v-model="dataClient.phone"
                            :counter="10"
                            :rules="[
                                value => (`${value}`).length<=10 || 'El celular no debe exceder los 10 dígitos',
                                value => !eIsValidPhone(value) || 'El celular solo debe contener números'
                            ]"
                        />
                        <div class="d-flex justify-center mt-1">
                            <MBtnNormalComp
                                @click="eValidFormClient()"
                            >
                                <v-icon>mdi-bookmark-check</v-icon>
                                GUARDAR
                            </MBtnNormalComp>
                        </div>
                    </v-form>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    MNoDataComp,
    MTextFieldComp,
    MBtnNormalComp,
    MSelectComp
} from "../molecules";
import {
    OBtnCardComp,
    OSearchHeaderComp,
    OLoaderDataComp,
    OBtnToolTipHeaderComp
} from "../organisms";
import TextHighlight from "vue-text-highlight";
import {
    Client
} from "../../models";
import {
    rulesMixin
} from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        OBtnCardComp,
        OSearchHeaderComp,
        MNoDataComp,
        TextHighlight,
        OLoaderDataComp,
        OBtnToolTipHeaderComp,
        MTextFieldComp,
        MBtnNormalComp,
        MSelectComp
    },
    props: {
        value: Boolean,
        clientsList: [],
        dialogLoaderData: Boolean
    },
    data: () => ({
        tab: 0,
        search: "",
        valid: true,
        dataClient: new Client,
        items: [
            { name: "Persona", value: 0 },
            { name: "Empresa", value: 1 }
        ]
    }),
    methods: {
        resetData() {
            if (this.tab === 1) this.eBackList()
            this.search = "";
        },
        eIsValidPhone(input) {
            if (this.mRuleEmptyField(input)) return false;
            return !this.mRuleNumberInteger(input);
        },
        eBackList() {
            setTimeout(() => {
                this.dataClient = new Client;
                this.$refs.formclient.resetValidation();
                this.tab = 0;
            }, 150);
        },
        eValidFormClient() {
            const isValidForm = this.$refs.formclient.validate();
            this.$emit('newclient', {
                'isValidForm': isValidForm,
                'client': this.dataClient
            });
        }
    }
}
</script>