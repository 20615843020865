<template>
    <v-card
        class="d-flex rounded-0"
        elevation="0"
    >
        <v-spacer></v-spacer>
        <div>
            <div class="d-flex">
                <v-card
                    :width="widthText"
                    elevation="0"
                >
                    <h1 class="app-subtitle text-right">Total Prods (+):</h1>
                </v-card>
                <v-card
                    :width="widthValue"
                    elevation="0"
                >
                    <h1 class="app-subtitle text-right">
                        {{mPSumOfTotalsPrice(detailProducts, isDataBase).toFixed(2)}}
                    </h1>
                </v-card>
            </div>
            <div class="d-flex">
                <v-card
                    :width="widthText"
                    elevation="0"
                >
                    <h1 class="app-subtitle text-right">Total Dcto. Prods. (-):</h1>
                </v-card>
                    <v-card
                    :width="widthValue"
                    elevation="0"
                >
                    <h1 class="app-subtitle text-right">
                        {{mPSumTotalDiscountCommercial(detailProducts, isDataBase).toFixed(2)}}
                    </h1>
                </v-card>
            </div>
            <div class="d-flex">
                <v-card
                    :width="widthText"
                    elevation="0"
                >
                    <h1 class="app-subtitle text-right">Total otro Dcto. (-):</h1>
                </v-card>
                <v-card
                    :width="widthValue"
                    elevation="0"
                >
                    <h1 class="app-subtitle text-right">
                        {{mPSumTotalOtherDiscount(detailProducts, isDataBase).toFixed(2)}}
                    </h1>
                </v-card>
            </div>
            <div class="d-flex mt-1">
                <v-card
                    :width="widthText"
                    elevation="0"
                >
                    <h1 class="app-title text-right">SUBTOTAL (+):</h1>
                </v-card>
                <v-card
                    :width="widthValue"
                    elevation="0"
                >
                    <h1 class="app-title text-right">
                        {{mPTotalPurchase(detailProducts, isDataBase).toFixed(2)}}
                    </h1>
                </v-card>
            </div>
            <div class="d-flex">
                <v-card
                    :width="widthText"
                    elevation="0"
                >
                    <h1 class="app-title text-right">Descuento (-):</h1>
                </v-card>
                <v-card
                    :width="widthValue"
                    elevation="0"
                >
                    <h1 class="app-title text-right">
                        {{
                            isDataBase
                                ? dataPurchase.discount.toFixed(2)
                                : iValueOtherDiscount.toFixed(2)
                        }}
                    </h1>
                </v-card>
            </div>
            <div class="d-flex">
                <v-card
                    :width="widthText"
                    elevation="0"
                >
                    <h1 class="app-title text-right">Otro Descuento (-):</h1>
                </v-card>
                <v-card
                    :width="widthValue"
                    elevation="0"
                >
                    <h1 class="app-title text-right">
                        {{
                            isDataBase
                                ? dataPurchase.other_discount.toFixed(2)
                                : iValueOtherDiscount.toFixed(2)
                        }}
                    </h1>
                </v-card>
            </div>
            <div class="d-flex">
                <v-card
                    :width="widthText"
                    elevation="0"
                >
                    <h1 class="app-title text-right">TOTAL:</h1>
                </v-card>
                <v-card
                    :width="widthValue"
                    elevation="0"
                >
                    <h1 class="app-title text-right">
                        {{
                            isDataBase
                                ? iTotalWithDiscount().toFixed(2)
                                : iTotalPurchase.toFixed(2)
                        }}
                    </h1>
                </v-card>
            </div>
        </div>
    </v-card>
</template>
<script>
import {
    purchaseMixin,
    rulesMixin
} from "../../mixins";
export default {
    mixins: [ purchaseMixin, rulesMixin ],
    props: {
        detailProducts: Array,
        dataPurchase: Object,
        isDataBase: {
            default: false,
            type: Boolean
        }
    },
    data: () => ({
        widthText: '174',
        widthValue: '80'
    }),
    computed: {
        iValueDiscount: function() {
            if (!this.mRuleEmptyField(this.dataPurchase.discount)
                && this.mRuleNumberDecimal(this.dataPurchase.discount))
                return parseFloat(this.dataPurchase.discount);
            else return 0;
        },
        iValueOtherDiscount: function() {
            if (!this.mRuleEmptyField(this.dataPurchase.other_discount)
                && this.mRuleNumberDecimal(this.dataPurchase.other_discount))
                return parseFloat(this.dataPurchase.other_discount);
            else return 0;
        },
        iTotalPurchase: function() {
            const totalPurchase = this.mPTotalPurchase(this.detailProducts, this.isDataBase);
            return totalPurchase - this.iValueDiscount - this.iValueOtherDiscount;
        }
    },
    methods: {
        iTotalWithDiscount() {
            const totalPurchase = this.mPTotalPurchase(this.detailProducts, this.isDataBase);
            return totalPurchase - this.dataPurchase.discount - this.dataPurchase.other_discount;
        }
    }
}
</script>