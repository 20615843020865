<template>
    <div>
        <v-tooltip bottom content-class="color-tooltip">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    :disabled="disabled"
                    outlined
                    small
                    :width="size"
                    :max-width="size"
                    :min-width="size"
                    :ripple="false"
                    @click="$emit('click')"
                    class="color-icon"
                >
                    <v-icon small class="color-icon">{{icon}}</v-icon>
                </v-btn>
            </template>
            <MSmsToolTipComp
                :smsToolTip="smsToolTip"
            />
        </v-tooltip>
    </div>
</template>
<script>
import { MSmsToolTipComp } from "../molecules";
export default {
    components: {
        MSmsToolTipComp
    },
    props: {
        icon: String,
        smsToolTip: String,
        disabled: Boolean
    },
    data: () => ({
        size: 42
    })
}
</script>
