<template>
    <v-dialog
        persistent
        :value="value"
        max-width="400"
    >
        <div class="s-card-client__header">
            <div class="d-flex align-center">
                <h1 class="app-title">
                    {{title}}
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <v-divider class="mt-1"></v-divider>
            <div v-if="lot.product != undefined">
                <div class="d-flex mt-1">
                    <h1 class="app-title mr-1">Producto:</h1>
                    <h1 class="app-subtitle">
                        {{lot.product.product}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="app-title mr-1">Cantidad:</h1>
                    <h1 class="app-subtitle">
                        {{lot.quantity}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="app-title mr-1">Fecha expiración:</h1>
                    <h1 class="app-subtitle">
                        {{mDateFormat(lot.expiration_date)}}
                    </h1>
                    <div
                        :class="lot.expired
                            ? 'client__ornament-disabled ml-1'
                            : 'client__ornament-enabled ml-1'"
                        style="margin-top:3px;"
                    >
                    </div>
                </div>
                <v-divider class="mb-4 mt-1"></v-divider>
                <v-form
                    v-model="valid"
                    lazy-validation
                    ref="forminventory"
                >
                    <MTextFieldComp
                        label="Cantidad"
                        v-model="quantity"
                        ref="deletequantity"
                        :rules="[
                            value => !this.mRuleEmptyField(value) || 'La cantidad es requerido',
                            value => this.mRuleNumberInteger(value) || 'La cantidad debe ser un número',
                            value => !this.mRuleExceededNumber(value, lot.quantity) || 'La cantidad a dar de baja no puede ser superior a la del inventario'
                        ]"
                    />
                </v-form>
            </div>
            <div class="d-flex justify-center mt-1 mb-2">
                <MBtnNormalComp
                    @click="eValidForm()"
                >
                    <v-icon>mdi-bookmark-check</v-icon>
                    GUARDAR
                </MBtnNormalComp>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import {
    MBtnNormalComp,
    MTextFieldComp
} from "../molecules";
import { OBtnCardComp } from "../organisms";
import { rulesMixin, datesMixin } from "../../mixins";
export default {
    mixins: [
        rulesMixin,
        datesMixin
    ],
    components: {
        MBtnNormalComp,
        MTextFieldComp,
        OBtnCardComp
    },
    props: {
        value: Boolean,
        lot: Object,
        title: String
    },
    data: () => ({
        valid: true,
        quantity: null
    }),
    methods: {
        eValidForm() {
            const stateValidForm = this.$refs.forminventory.validate();
            var dataSend = {
                "state": stateValidForm,
                "quantity": this.quantity
            }
            this.$emit('saveform', dataSend);
        },
        resetForm() {
            this.quantity = null;
            this.$refs.forminventory.resetValidation();
        },
        activateFocus() {
            this.$refs.deletequantity.activateFocus();
        }
    }
}
</script>