export default {
    namespaced: true,
    state: {
        header: {},
        existHeader: false
    },
    getters: {},
    mutations: {
        saveHeader(state, payload) {
            state.header = payload;
        },
        activateHeader(state) {
            state.existHeader = true;
        }
    }
}