<template>
    <div>
        <div class="client__header pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    {{`ENTRADAS INVENTARIO (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center client__header-block-second">
                <OSearchHeaderComp
                    label="Buscar Producto"
                    v-model="search"
                    @entersearch="eSearchInventories()"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <div class="d-flex justify-center flex-wrap">
            <div class="product__group-search d-flex justify-center pt-2 pl-2 pr-2">
                <div class="product__search" style="margin-right:2px">
                    <MTextFieldComp
                        label="Fecha expiración"
                        :hideDetails="true"
                        :clearable="false"
                        v-model="expiration_date"
                    />
                </div>
                <div class="product__search" style="margin-left:2px">
                    <div
                        class="product__search-laboratories d-flex align-center pl-3"
                        @click="eOpenDialogProducts()"
                    >
                        <h2>Producto</h2>
                        <h1 class="app-subtitle">
                            {{productSearch.id==0 ? "Todos" : productSearch.name}}
                        </h1>
                    </div>
                </div>
            </div>
            <div class="product__group-search d-flex justify-center pt-2 pl-2 pr-2">
                <div class="product__search" style="margin-right:2px">
                    <MTextFieldComp
                        label="Inicio"
                        type="date"
                        :hideDetails="true"
                        :clearable="false"
                        v-model="dateOne"
                    />
                </div>
                <div class="product__search" style="margin-left:2px">
                    <MTextFieldComp
                        label="Fin"
                        type="date"
                        :hideDetails="true"
                        :clearable="false"
                        v-model="dateTwo"
                    />
                </div>
            </div>
        </div>
        <v-divider class="mt-2"></v-divider>
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Información"
        />
        <MNoDataComp
            smsNotData="SIN DATOS"
            class="ma-1"
            v-if="entriesInventories.length==0 && !dialogLoaderData && mobile"
        />
        <STableEntriesInventoriesComp
            v-if="!dialogLoaderData && !mobile"
            :entriesInventories="entriesInventories"
            :search="searchFilter"
            @clickdetail="eShowDetailProduct"
            @clickpurchase="eShowDetailPurchase"
            @clickdelete="eShowFormDelete"
            @clickupdate="eShowFormUpdate"
        />
        <div
            class="d-flex justify-center flex-wrap"
            v-if="!dialogLoaderData && mobile"
        >
            <SCardEntrieInventoryComp
                class="ma-2"
                :search="searchFilter"
                v-for="entrie in entriesInventories"
                :key="entrie.id"
                :entrie="entrie"
                @clickdetail="eShowDetailProduct(entrie)"
                @clickpurchase="eShowDetailPurchase(entrie)"
                @clickdelete="eShowFormDelete(entrie)"
                @clickupdate="eShowFormUpdate(entrie)"
            />
        </div>
        <v-divider
            class="mt-1"
            v-if="mobile && !dialogLoaderData && entriesInventories.length>0"
        ></v-divider>
        <SPaginationComp
            class="mt-2 mb-2"
            v-if="!dialogLoaderData && entriesInventories.length > 0"
            :condicionGo="currentPage < lastPage"
            :condicionBack="currentPage > 1"
            :titlePagination="'Pag. '+currentPage+' de '+lastPage"
            @clickback="eChangePage(false)"
            @clickgo="eChangePage(true)"
            @clickpage="eSearchForPage"
        />
        <SDialogSearchProductComp
            :valueDialog="dialogSearchProducts"
            v-model="productSearch.search"
            ref="dialogproducts"
            :productsList="productsList"
            :searchProduct="searchFilterProduct"
            :dialogLoaderData="productSearch.dialogLoaderData"
            @clickclose="eCloseSearchProducts()"
            @entersearch="eSearchProducts()"
            @clickproduct="eSelectProduct"
        />
        <SDetailProductComp
            :value="dialogProduct"
            :product="product"
            @clickclose="dialogProduct=false"
        />
        <SDetailPurchaseLotComp
            :value="dialogPurchase"
            :lot="lot"
            @clickclose="dialogPurchase = false"
        />
        <SFormDeleteInventoryComp
            title="Dar de Baja"
            :value="dialogFormInventory"
            :lot="lot"
            ref="forminventorycomp"
            @clickclose="eCloseFormLot()"
            @saveform="eConfirmDeleteInventory"
        />
        <SDialogUpdateInventoryComp
            :value="dialogUpdateInventory"
            :lot="lot"
            ref="dialogupdateinventory"
            :updateLot="updateLot"
            @clickclose="dialogUpdateInventory=false"
            @saveform="eConfirmUpdateLot"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="eCloseDialogConfirm()"
            @clickyes="ePerformOperation()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSearchHeaderComp,
    OLoaderDataComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp,
    OSmsConfirmationComp
} from "../../components/organisms";
import {
    STableEntriesInventoriesComp,
    SDetailProductComp,
    SDetailPurchaseLotComp,
    SDialogSearchProductComp,
    SFormDeleteInventoryComp,
    SCardEntrieInventoryComp,
    SPaginationComp,
    SDialogUpdateInventoryComp
} from "../../components/species";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    rulesMixin,
    animationScrollMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import {
    Inventory,
    Product
} from "../../models";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        rulesMixin,
        animationScrollMixin,
        userMixin,
        datesMixin
    ],
    components: {
        MNoDataComp,
        OBtnToolTipHeaderComp,
        OSearchHeaderComp,
        STableEntriesInventoriesComp,
        MTextFieldComp,
        SDetailProductComp,
        SDetailPurchaseLotComp,
        OLoaderDataComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        SDialogSearchProductComp,
        SFormDeleteInventoryComp,
        OSmsConfirmationComp,
        SCardEntrieInventoryComp,
        SPaginationComp,
        SDialogUpdateInventoryComp
    },
    data: () => ({
        search: "",
        entriesInventories: [],
        expiration_date: "",
        dialogSearchProducts: false,
        productSearch: {
            id: 0,
            name: "Todos",
            dialogLoaderData: false,
            search: ""
        },
        productsList: [{ id: 0, product: "Todos" }],
        dateOne: "",
        dateTwo: "",
        product: {},
        dialogProduct: false,
        dialogPurchase: false,
        lot: {},
        dialogFormInventory: false,
        quantity: 0,
        dialogUpdateInventory: false,
        updateLot: {},
        indexInventory: -1
    }),
    computed: {
        searchFilter: function() {
            return this.search == null ? "" : this.search;
        },
        searchFilterProduct: function() {
            return this.productSearch.search == null ? "" : this.productSearch.search;
        }
    },
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else {
                this.dialogError = false;
                if (this.indexInventory !== -1)
                    this.dialogUpdateInventory = true;
                else this.dialogFormInventory = true;
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getInventories(page, search, dateExpiration, dateOne, dateTwo, idProduct) {
            this.dialogLoaderData = true;
            try {
                const response = await Inventory.getInventory(page, search, dateExpiration, dateOne, dateTwo, idProduct);
                this.entriesInventories = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        validateDateRange() {
            if ((this.dateOne !== "" && this.dateTwo === "") || (this.dateOne === "" && this.dateTwo !== "")) {
                this.mDataGeneralShowSmsAction(["Error Fechas", "Debe selecciona ambas fechas"], "error");
                return false;
            }
            if (this.dateOne > this.dateTwo) {
                this.mDataGeneralShowSmsAction(["Error Fechas", "La fecha de inicio no puede ser mayor a la fecha final"], "error");
                return false;
            }
            return true;
        },
        eSearchInventories() {
            if (this.validateDateRange()) {
                if (this.mRuleEmptyField(this.search)) this.search = "";
                this.getInventories(1, this.search, this.expiration_date, this.dateOne, this.dateTwo, this.productSearch.id);
            }
        },
        eShowDetailProduct(inventory) {
            this.product = inventory.product;
            this.dialogProduct = true;
        },
        eShowDetailPurchase(inventory) {
            if (inventory.id_purchase !== 0) {
                this.lot = inventory;
                this.dialogPurchase = true;
            } else this.mDataGeneralShowSmsAction(
                ["Sin Registro de Compra", "El lote no fue registrado por compras"], "error");
        },
        eCloseSearchProducts() {
            this.dialogSearchProducts = false;
            setTimeout(() => {
                this.productSearch.search = "";
                this.productsList = [{ id: 0, product: "Todos" }];
            }, 150)
        },
        async eSearchProducts() {
            this.productSearch.dialogLoaderData = true;
            if (this.mRuleEmptyField(this.productSearch.search)) this.productSearch.search = "";
            this.productsList = [{ id: 0, product: "Todos" }];
            try {
                const response = await Product.getProducts(1, this.productSearch.search, 1, 2, 0, 0);
                response.data.forEach(product => {
                    this.productsList.push(product);
                });
                this.productSearch.dialogLoaderData = false;
            } catch(error) {
                this.dialogSearchProducts = false;
                this.processErrorServer(error);
            }
        },
        eSelectProduct(product) {
            this.productSearch.id = product.id;
            this.productSearch.name = product.product;
            this.eCloseSearchProducts();
        },
        eShowFormDelete(lot) {
            this.lot = lot;
            this.dialogFormInventory = true;
            setTimeout(() => {
                this.$refs.forminventorycomp.activateFocus();
            }, 150);
        },
        eCloseFormLot() {
            this.$refs.forminventorycomp.resetForm();
            this.dialogFormInventory = false;
        },
        eCloseDialogConfirm() {
            this.dialogConfirm = false;
            if (this.indexInventory !== -1)
                this.dialogUpdateInventory = true;
            else this.dialogFormInventory = true;
        },
        eConfirmDeleteInventory(info) {
            this.quantity = parseInt(info.quantity);
            this.dialogFormInventory = false;
            const smsConfirm = [`¿Está seguro de dar de baja la cantidad de: ${info.quantity}
                , del producto: ${this.lot.product.product}?`]
            this.mDataGeneralConfirmForm(info.state, smsConfirm);
        },
        async deleteInventory() {
            const parameters = {
                "id": this.lot.id,
                "quantity": this.quantity
            }
            try {
                await Inventory.deleteInventory(parameters);
                const index = this.entriesInventories.indexOf(this.lot);
                if (this.lot.quantity === this.quantity) {
                    this.entriesInventories.splice(index, 1);
                    this.total -= 1;
                } else this.entriesInventories[index].quantity -= this.quantity;
                this.$refs.forminventorycomp.resetForm();
                this.dialogLoaderOperation = false;
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se dió de baja el inventario seleccionado"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async updateInventory() {
            try {
                const response = await Inventory.updateInventory(this.updateLot);
                Object.assign(this.entriesInventories[this.indexInventory], response);
                this.indexInventory = -1;
                this.dialogLoaderOperation = false;
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se actualizó el lote"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        ePerformOperation() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (this.indexInventory !== -1)
                this.updateInventory();
            else this.deleteInventory();
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getInventories(this.currentPage, this.search, this.expiration_date, this.dateOne, this.dateTwo, this.productSearch.id);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getInventories(page, this.search, this.expiration_date, this.dateOne, this.dateTwo, this.productSearch.id);
                }, time);
            }
        },
        handleKeyUpEntrieInventorie(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 0) this.eCloseDialogConfirm();
                if (actionKeyBoard === 1) this.ePerformOperation();
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        //
        eOpenDialogProducts() {
            this.dialogSearchProducts = true;
            setTimeout(() => {
                this.$refs.dialogproducts.activateFocus();
            }, 150);
        },
        eShowFormUpdate(lot) {
            if (lot.id_purchase == 0) {
                this.indexInventory = this.entriesInventories.indexOf(lot);
                this.lot = Object.assign({}, lot);
                this.updateLot = Object.assign({}, lot);
                this.dialogUpdateInventory = true;
                setTimeout(() => {
                    this.$refs.dialogupdateinventory.activateFocus();
                }, 150);
            } else {
                this.mDataGeneralShowSmsAction(
                    ["Operación Cancelada", "El lote proviene de compras"], "error");
            }
        },
        eConfirmUpdateLot(isValidForm) {
            this.dialogUpdateInventory = false;
            const smsConfirm = [`¿Está seguro(a) de actualizar el lote?`];
            this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpEntrieInventorie);
                this.dialogLoaderData = false;
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpEntrieInventorie);
    }
}
</script>