<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <text-highlight
                class="app-title"
                :queries="search"
            >
                {{product.product}}
            </text-highlight>
        </div>
        <div class="s-card-client__body">
            <OFirstPartDataProductComp
                :product="product"
            />
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-cash-100"
                style="margin:2px"
                @click="$emit('clickprice')"
            />
            <OBtnCardComp
                icon="mdi-list-box"
                style="margin:2px"
                @click="$emit('clickdetail')"
            />
            <OBtnCardComp
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
            <OBtnCardComp
                :icon="product.disabled==1 ? 'mdi-eye' : 'mdi-eye-off'"
                style="margin:2px"
                @click="$emit('clickstate')"
            />
            <OBtnCardComp
                icon="mdi-delete"
                style="margin:2px"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import {
    OBtnCardComp,
    OFirstPartDataProductComp
} from "../organisms";
export default {
    props: {
        product: Object,
        search: {
            type: String,
            default: ''
        }
    },
    components: {
        TextHighlight,
        OBtnCardComp,
        OFirstPartDataProductComp
    }
}
</script>