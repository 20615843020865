<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Producto</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">N° Lote</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Cantidad</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha Expiración</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">
                            Diferencia días
                        </h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha Registro</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-center">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(low_lot, index) in lowsInventories"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <text-highlight
                            class="app-subtitle"
                            :queries="search"
                        >
                            {{low_lot.product.product}}
                        </text-highlight>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{low_lot.lot_number}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{low_lot.quantity}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <h1 class="app-subtitle">
                                {{mDateFormat(low_lot.expiration_date)}}
                            </h1>
                            <div
                                :class="low_lot.expired
                                    ? 'client__ornament-disabled ml-1'
                                    : 'client__ornament-enabled ml-1'"
                                style="margin-top:-2px;"
                            >
                            </div>
                        </div>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{low_lot.diff_days}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{`${low_lot.hour} ${low_lot.date}`}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            :smsToolTip="`Ver detalle de: ${low_lot.product.product}`"
                            icon="mdi-list-box"
                            @click="$emit('clickdetail', low_lot)"
                        />
                        <OBtnToolTipTableComp
                            :smsToolTip="`Detalle compra de: ${low_lot.product.product}`"
                            icon="mdi-receipt-text"
                            class="ml-1 mr-1"
                            @click="$emit('clickpurchase', low_lot)"
                        />
                        <OBtnToolTipTableComp
                            :smsToolTip="`Revertir inventario: ${low_lot.product.product}`"
                            icon="mdi-delete-forever"
                            @click="$emit('clickreverse', low_lot)"
                        />
                        <OBtnToolTipTableComp
                            :smsToolTip="`Eliminar inventario de: ${low_lot.product.product}`"
                            icon="mdi-trash-can"
                            @click="$emit('clickdelete', low_lot)"
                        />
                    </td>
                </tr>
                <tr v-if="lowsInventories.length === 0">
                    <td colspan="8">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import { OBtnToolTipTableComp } from "../organisms";
import { datesMixin } from "../../mixins";
export default {
    mixins: [ datesMixin ],
    components: {
        OBtnToolTipTableComp,
        TextHighlight
    },
    props: {
        lowsInventories: Array,
        search: {
            type: String,
            default: ''
        }
    }
}
</script>