<template>
    <div>
        <div class="pa-2 d-flex align-center">
            <OBtnToolTipHeaderComp
                v-if="!mobile"
                :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                smsToolTip="Ocultar Menú"
                @click="$emit('clickmini')"
            />
            <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                ESTADÍSTICA LABS. MÁS VENDIDOS
            </h1>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <div class="d-flex justify-center pt-2 pl-2 pr-2">
            <div class="product__search" style="margin-right:2px">
                <MTextFieldComp
                    type="date"
                    label="Fecha Inicio"
                    :hideDetails="true"
                    v-model="dateOne"
                    :clearable="false"
                    @change="eChangeDateOne"
                />
            </div>
            <div class="product__search" style="margin-left:2px">
                <MTextFieldComp
                    type="date"
                    label="Fecha Final"
                    :hideDetails="true"
                    v-model="dateTwo"
                    :clearable="false"
                    @change="eChangeDateTwo"
                />
            </div>
        </div>
        <v-divider class="mt-2"></v-divider>
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Información"
        />
        <div
            v-else
            class="animation-opacity statistics__container"
        >
            <div class="statistics__container-draw">
                <div id="container" style="width:100%; height:400px;"></div>
            </div>
        </div>
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSmsErrorComp,
    OSmsActionComp,
    OLoaderDataComp
} from "../../components/organisms";
import Highcharts from "../../plugins/highcharts";
import {
    datesMixin,
    responseServerMixin,
    dataGeneralCompsMixin,
    userMixin
} from "../../mixins";
import {
    Report
} from "../../models";
export default {
    mixins: [
        responseServerMixin,
        dataGeneralCompsMixin,
        userMixin,
        datesMixin
    ],
    components: {
        OBtnToolTipHeaderComp,
        OSmsErrorComp,
        OSmsActionComp,
        OLoaderDataComp,
        MTextFieldComp
    },
    props: {
        mini: Boolean
    },
    data: () => ({
        mobile: false,
        dateOne: "",
        dateTwo: "",
        chartOptions: {}
    }),
    methods: {
        handleKeyUpStatisticsLab(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else this.dialogError = false;
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getStatistics(dateOne, dateTwo) {
            this.dialogLoaderData = true;
            try {
                const response = await Report.laboratoryStatistics(dateOne, dateTwo);
                this.generateStatistics(response);
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        getSubtitleStatistics() {
            if (this.dateOne !== this.dateTwo)
                return `Del ${this.mDateFormat(this.dateOne)} al ${this.mDateFormat(this.dateTwo)}`;
            else return `${this.mDateFormat(this.dateOne)}`;
        },
        generateStatistics(response) {
            var data = [];
            response.forEach(element => {
                const newElement = {
                    name: element.laboratory,
                    y: parseInt(element.quantity),
                    total_sale: element.total_sale
                }
                data.push(newElement);
            });
            this.drawStatistics(data);
        },
        drawStatistics(newData) {
            setTimeout(() => {
                this.chartOptions = Highcharts.chart('container', {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        align: 'center',
                        text: 'LABORATORIOS QUE MÁS SE VENDE'
                    },
                    subtitle: {
                        align: 'center',
                        text: this.getSubtitleStatistics()
                    },
                    accessibility: {
                        announceNewData: {
                            enabled: true
                        }
                    },
                    xAxis: {
                        type: 'category'
                    },
                    yAxis: {
                        title: {
                            text: 'Cantidad de ventas'
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: true,
                                format: '{point.y:.0f}'
                            }
                        }
                    },
                    tooltip: {
                        //headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
                        //pointFormat: '<span style="color:{point.color}">Ventas: {point.test} Bs.</span><br> <b>{point.y:.2f}</b><br/>'
                        pointFormat: 'Ventas: {point.total_sale} Bs.<br>Cantidad: {point.y:.0f}'
                    },
                    series: [
                        {
                            name: "Browsers",
                            colorByPoint: true,
                            data: newData
                        }
                    ]
                });
            }, 200);
            this.dialogLoaderData = false;
        },
        eChangeDateOne(dateOne) {
            if (dateOne == "" || this.dateTwo == "")
                this.mDataGeneralShowSmsAction(["Error de Fechas", "Seleccione bien las fechas"], "error");
            else {
                if (dateOne > this.dateTwo) this.dateTwo = dateOne;
                this.getStatistics(dateOne, this.dateTwo);
            }
        },
        eChangeDateTwo(dateTwo) {
            if (dateTwo == "" || this.dateOne == "")
                this.mDataGeneralShowSmsAction(["Error de Fechas", "Seleccione bien las fechas"], "error");
            else {
                if (dateTwo < this.dateOne) this.dateOne = dateTwo;
                this.getStatistics(dateOne, this.dateTwo);
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        this.dateOne = this.dateTwo = this.mDateSystem(false);
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpStatisticsLab);
                this.getStatistics("", "");
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpStatisticsLab);
    }
}
</script>