<template>
    <v-dialog
        persistent
        :value="value"
        max-width="400"
    >
        <v-card
            min-height="300"
        >
            <div class="s-detail-product__header pl-2 pr-2 d-flex align-center">
                <h1 class="app-title">
                    SELECCIONAR PRODUCTO
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <div class="client__line-header"></div>
            <div class="pa-2 pt-1">
                <h1 class="app-title">{{product.product}}</h1>
                <v-divider></v-divider>
                <div v-if="product.id != undefined">
                    <div class="d-flex">
                        <h1 class="app-title">Lab.:</h1>
                        <h1 class="app-subtitle ml-1">{{product.laboratory.laboratory}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title">Ubicación:</h1>
                        <h1 class="app-subtitle ml-1">{{product.location}}</h1>
                    </div>
                    <div class="d-flex" v-if="product.categorie_user=='0'">
                        <h1 class="app-title">Precio Costo:</h1>
                        <h1 class="app-subtitle ml-1">
                            {{getPurchasePrices()}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title">Precio Venta:</h1>
                        <h1 class="app-subtitle ml-1">{{product.sale_price.toFixed(2)}}</h1>
                    </div>
                    <div class="d-flex" v-if="product.categorie_user=='0'">
                        <h1 class="app-title">Precio Min. V.:</h1>
                        <h1 class="app-subtitle ml-1">{{(product.sale_price-product.max_discount).toFixed(2)}}</h1>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="app-title">Cantidad:</h1>
                        <h1 class="app-subtitle ml-1">{{product.quantity}}</h1>
                        <div style="margin-top:-2px" class="ml-1">
                            <div
                                :class="product.quantity > 0
                                    ? 'client__ornament-enabled'
                                    : 'client__ornament-disabled'"
                            >
                            </div>
                        </div>
                    </div>
                </div>
                <v-divider class="mt-1 mb-3"></v-divider>
                <v-form
                    v-model="valid"
                    lazy-validation
                    ref="formproduct"
                >
                    <div v-if="product.categorie_user == '0' || isValidDiscount">
                        <MTextFieldComp
                            label="Precio Venta"
                            ref="pricesale"
                            v-model="product.select_sale_price"
                            :clearable="false"
                            @click="eValidForm()"
                            :rules="[
                                value => !this.mRuleEmptyField(value) || 'Requerido',
                                value => this.mRuleNumberDecimal(value) || 'No número',
                            ]"
                        />
                    </div>
                    <MTextFieldComp
                        label="Cantidad"
                        v-model="product.select_quantity"
                        :clearable="false"
                        @click="eValidForm()"
                        :rules="[
                            value => !this.mRuleEmptyField(value) || 'Requerido',
                            value => this.mRuleNumberInteger(value) || 'No número',
                            value => !this.mRuleExceededNumber(value, product.quantity) || 'Error Cant.'
                        ]"
                    />
                    <div class="d-flex justify-center">
                        <MBtnNormalComp
                            @click="eValidForm()"
                        >
                            {{indexProductDetail==-1 ? 'AGREGAR' : 'EDITAR'}}
                        </MBtnNormalComp>
                        <MBtnNormalComp
                            class="ml-1"
                            @click="$emit('clickdetail', product)"
                        >
                            DETALLE
                        </MBtnNormalComp>
                    </div>
                </v-form>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp
} from "../molecules";
import {
    OBtnCardComp
} from "../organisms";
import {
    rulesMixin,
    datesMixin
} from "../../mixins";
export default {
    mixins: [
        rulesMixin,
        datesMixin
    ],
    components:{
        OBtnCardComp,
        MTextFieldComp,
        MBtnNormalComp
    },
    props: {
        value: Boolean,
        product: Object,
        indexProductDetail: Number,
        isValidDiscount: Boolean
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.$refs.formproduct.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.formproduct.resetValidation();
        },
        activateFocus() {
            this.$refs.pricesale.activateFocus();
        },
        getPurchasePrices() {
            if (this.product.id == undefined) return "";
            else {
                var text = "";
                for (var i = 0; i < this.product.inventories.length; i++)
                    text += `(${this.product.inventories[i].purchase_price.toFixed(2)} Bs.) `;
                return text;
            }
        }
    }
}
</script>