import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';

class Product {
    constructor(
        product = null,
        barcore = null,
        health_register = null,
        sale_price = null,
        max_discount = 0,
        location = null,
        composition = null,
        via_administration = null,
        contraindications = null,
        description = null,
        fk_type_product = null,
        fk_laboratory = null,
        activate_error = false
    ) {
        this.product = product;
        this.barcore = barcore;
        this.health_register = health_register;
        this.sale_price = sale_price;
        this.max_discount = max_discount;
        this.location = location;
        this.composition = composition;
        this.via_administration = via_administration;
        this.contraindications = contraindications;
        this.description = description;
        this.fk_type_product = fk_type_product;
        this.fk_laboratory = fk_laboratory;
        this.activate_error = activate_error;
    }
    static async getProducts(page, search, searchType, searchState, fkTypeProduct, fkLaboratory) {
        const response =  await axios.get(
            `${apiUrls.PRODUCT}/list?page=${page}&search=${search}&search_type=${searchType}&search_state=${searchState}&fk_type_product=${fkTypeProduct}&fk_laboratory=${fkLaboratory}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addProduct(parameters) {
        const response =  await axios.post(
            `${apiUrls.PRODUCT}/add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editProduct(parameters) {
        const response =  await axios.put(
            `${apiUrls.PRODUCT}/edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deleteProduct(parameters) {
        const response = await axios.delete(
            `${apiUrls.PRODUCT}/delete`,
            { headers: userStore.state.header, data: parameters }
        );
        return response.data;
    }
    static async changeStateProduct(parameters) {
        const response =  await axios.patch(
            `${apiUrls.PRODUCT}/change-state`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async updatePriceProduct(parameters) {
        const response = await axios.put(
            `${apiUrls.PRODUCT}/update-price`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getInventories(page, search, searchState, fkTypeProduct, fkLaboratory, typeInventory) {
        const response =  await axios.get(
            `${apiUrls.PRODUCT}/inventories?page=${page}&search=${search}&search_state=${searchState}&fk_type_product=${fkTypeProduct}&fk_laboratory=${fkLaboratory}&type_inventory=${typeInventory}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async searchSale(search) {
        const response = await axios.get(
            `${apiUrls.PRODUCT}/search-sale?search=${search}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async searchOrders(search, idTypeProduct, idLaboratory) {
        const response = await axios.get(
            `${apiUrls.PRODUCT}/search-orders?search=${search}&fk_type_product=${idTypeProduct}&fk_laboratory=${idLaboratory}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getProductsSearch(page, search, fkTypeProduct, fkLaboratory) {
        const response =  await axios.get(
            `${apiUrls.PRODUCT}/search?page=${page}&search=${search}&fk_type_product=${fkTypeProduct}&fk_laboratory=${fkLaboratory}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }

    //funciones de migraciones
    static async listMigration(search) {
        const response = await axios.get(
            `${apiUrls.MIGRATION}/list?search=${search}`
        );
        return response.data;
    }
    static async laboratoriosMigrate() {
        const response = await axios.get(
            `${apiUrls.MIGRATION}/laboratories`
        );
        return response.data;
    }
    static async typeProductsMigrate() {
        const response = await axios.get(
            `${apiUrls.MIGRATION}/type-products`
        );
        return response.data;
    }
    static async addMigrate(parameters) {
        const response = await axios.post(
            `${apiUrls.MIGRATION}/add`,
            parameters
        );
        return response.data;
    }
    static async addLotMigrate(parameters) {
        const response = await axios.post(
            `${apiUrls.MIGRATION}/add-inventory`,
            parameters
        );
        return response.data;
    }
    static async getTotalInventories() {
        const response =  await axios.get(
            `${apiUrls.PRODUCT}/products-list`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Product;