import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';

class Report {
    static async closeBoxCashRegister(idCashRegister) {
        const response = await axios.get(
            `${apiUrls.REPORT}/close-box-cash-register?fk_cash_register=${idCashRegister}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async closeBoxAdmin(dateOne, dateTwo, idUser, idCashRegister) {
        const response = await axios.get(
            `${apiUrls.REPORT}/close-box-admin?date_one=${dateOne}&date_two=${dateTwo}&fk_user=${idUser}&fk_cash_register=${idCashRegister}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async laboratoryStatistics(dateOne, dateTwo) {
        const response = await axios.get(
            `${apiUrls.REPORT}/statistical-laboratories?date_one=${dateOne}&date_two=${dateTwo}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async mostSelledProducts(dateOne, dateTwo, fk_type_product, fk_laboratory) {
        const response = await axios.get(
            `${apiUrls.REPORT}/most-selled-products?date_one=${dateOne}&date_two=${dateTwo}&fk_type_product=${fk_type_product}&fk_laboratory=${fk_laboratory}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async monthlySales(year) {
        const response = await axios.get(
            `${apiUrls.REPORT}/annual-statistics?year=${year}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async reportBillSale(dateOne, dateTwo) {
        const response = await axios.get(
            `${apiUrls.REPORT}/report-salse?date_one=${dateOne}&date_two=${dateTwo}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Report;