<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Información</h1>
                    </th>
                    <th width="80">
                        <h1 class="app-title-dark text-left">N° Caja</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Registrador(a)</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha/Hora</h1>
                    </th>
                    <th width="100px" v-if="!isInformation">
                        <h1 class="app-title-dark text-center">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(information, index) in informationsList"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{information.information}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{information.cash_register.cash_register}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{information.user}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{`${information.hour} ${information.date}`}}
                        </h1>
                    </td>
                    <td
                        class="d-flex justify-center flex-wrap"
                        v-if="!isInformation"
                    >
                        <OBtnToolTipTableComp
                            smsToolTip="Editar Información"
                            icon="mdi-pencil"
                            @click="$emit('clickedit', information)"
                        />
                    </td>
                </tr>
                <tr v-if="informationsList.length === 0">
                    <td :colspan="isInformation ? '5' : '6'">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { OBtnToolTipTableComp } from "../organisms";
export default {
    props: {
        informationsList: Array,
        isInformation: Boolean
    },
    components: {
        OBtnToolTipTableComp
    }
}
</script>