<template>
    <div class="s-form-product">
        <h1 class="app-title text-center mb-2">
            {{indexExpense===-1 ? 'REGISTRAR' : 'EDITAR'}}
        </h1>
        <v-form
            v-model="valid"
            lazy-validation
            ref="formexpense"
        >
            <ORowFormComp
                :mobile="mobile"
                informationRow="Gasto (Bs.):"
            >
                <MTextFieldComp
                    :label="mobile ? 'Gasto (Bs.)' : ''"
                    v-model="dataExpense.expense"
                    ref="expense"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El gasto es requerido',
                        value => this.mRuleNumberDecimal(value) || 'El gasto debe ser un número'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Descripción:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Descripción' : ''"
                    v-model="dataExpense.description"
                    :counter="150"
                    :rules="[
                        value => (`${value}`).length<=150 || 'La descripción no debe exceder los 150 dígitos'
                    ]"
                />
            </ORowFormComp>
        </v-form>
        <div class="d-flex justify-center mt-1">
            <MBtnNormalComp
                @click="eValidForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import { MBtnNormalComp, MTextFieldComp } from "../molecules";
import { ORowFormComp } from "../organisms";
import { rulesMixin } from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        MBtnNormalComp,
        ORowFormComp,
        MTextFieldComp
    },
    props: {
        indexExpense: Number,
        mobile: Boolean,
        dataExpense: Object
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.$refs.formexpense.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.formexpense.resetValidation();
        },
        activateFocus() {
            this.$refs.expense.activateFocus();
        }
    }
}
</script>