import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';

class Provider {
    constructor (
        provider = null,
        address = null,
        phone = null,
        e_mail = null,
        description = null
    )
    {
        this.provider = provider;
        this.address = address;
        this.phone = phone;
        this.e_mail = e_mail;
        this.description = description;
    }
    static async getProviders(page, search) {
        const response = await axios.get(
            `${apiUrls.PROVIDER}/list?page=${page}&search=${search}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addProvider(parameters) {
        const response = await axios.post(
            `${apiUrls.PROVIDER}/add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editProvider(parameters) {
        const response = await axios.put(
            `${apiUrls.PROVIDER}/edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deleteProvider(parameters) {
        const response = await axios.delete(
            `${apiUrls.PROVIDER}/delete`,
            { headers: userStore.state.header, data: parameters }
        );
        return response.data;
    }
}
export default Provider;