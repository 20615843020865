<template>
    <div>
        <div class="d-flex align-center">
            <h1 class="app-title-dark mr-1">Habilitado:</h1>
            <h1 class="app-subtitle-dark">
                {{product.disabled==1 ? "NO" : "SI"}}
            </h1>
            <div
                :class="product.disabled==1
                    ? 'client__ornament-disabled ml-2'
                    : 'client__ornament-enabled ml-2'"
                style="margin-top:-2px;"
            >
            </div>
        </div>
        <div class="d-flex">
            <h1 class="app-title-dark mr-1">Código Barra:</h1>
            <h1 class="app-subtitle-dark">
                {{product.barcore!=null ? product.barcore : "S/CB"}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="app-title-dark mr-1">Precio Venta:</h1>
            <h1 class="app-subtitle-dark">
                {{product.sale_price.toFixed(2)}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="app-title-dark mr-1">Máximo Descuento:</h1>
            <h1 class="app-subtitle-dark">
                {{product.max_discount.toFixed(2)}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="app-title-dark mr-1">Ubicación:</h1>
            <h1 class="app-subtitle-dark">
                {{product.location!=null ? product.location : "S/U"}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="app-title-dark mr-1">Tipo Producto:</h1>
            <h1 class="app-subtitle-dark">
                {{product.type_product.type_product}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="app-title-dark mr-1">Laboratorio:</h1>
            <h1 class="app-subtitle-dark">
                {{product.laboratory.laboratory}}
            </h1>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        product: Object
    }
}
</script>