<template>
    <v-select
        outlined
        :label="label"
        :menu-props="{ bottom: true, offsetY: true }"
        dense
        :items="items"
        :hide-details="hideDetails"
        no-data-text="Sin opciones"
        :item-text="itemText"
        :item-value="itemValue"
        @change="e => $emit('change', e)"
        @input="e => $emit('input', e)"
        :rules="rules"
        :value="value"
    ></v-select>
</template>
<script>
export default {
    props: {
        value: [Number, String],
        items: Array,
        itemText: String,
        itemValue: String,
        label: String,
        hideDetails: Boolean,
        rules: Array
    },
    model: {
        event: 'change',
        event: 'input'
    }
}
</script>