<template>
    <v-dialog
        persistent
        width="340px"
        :value="value"
    >
        <div
            v-if="sale.id != undefined"
            class="s-card-client"
        >
            <div class="s-card-client__header pl-2 pr-2 d-flex align-center">
                <h1 class="app-title">
                    Información Venta
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <div class="s-card-client__body">
                <div class="d-flex">
                    <h1 class="app-title-dark mr-1">Total Venta:</h1>
                    <h1 class="app-subtitle-dark">
                        {{`${sale.sale.toFixed(2)} Bs.`}}
                    </h1>
                </div>
                <div class="d-flex align-center">
                    <h1 class="app-title-dark mr-1">Total Desc.:</h1>
                    <h1 class="app-subtitle-dark mr-1">
                        {{`${mSaleTotalDiscount(sale).toFixed(2)} Bs.`}}
                    </h1>
                    <div
                        :class="mSaleTotalDiscount(sale)>0
                            ? 'client__ornament-disabled'
                            : 'client__ornament-enabled'"
                    >
                    </div>
                </div>
                <div class="d-flex">
                    <h1 class="app-title-dark mr-1">Tipo Pago:</h1>
                    <h1 class="app-subtitle-dark">
                        {{sale.type_sale == "e" ? "Efectivo" : "QR"}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="app-title-dark mr-1">Cliente:</h1>
                    <h1 class="app-subtitle-dark">
                        {{sale.client.full_name}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="app-title-dark mr-1">CI/NIT:</h1>
                    <h1 class="app-subtitle-dark">
                        {{sale.client.ci_nit}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="app-title-dark mr-1">N° Caja:</h1>
                    <h1 class="app-subtitle-dark">
                        {{sale.cash_register.cash_register}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="app-title-dark mr-1">Registrador:</h1>
                    <h1 class="app-subtitle-dark">
                        {{sale.user.full_name}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="app-title-dark mr-1">Fecha Reg.:</h1>
                    <h1 class="app-subtitle-dark">
                        {{`${sale.hour} ${sale.date}`}}
                    </h1>
                </div>
                <div class="d-flex" v-if="!isUser">
                    <h1 class="app-title-dark mr-1">Margen de Ganancia:</h1>
                    <h1 class="app-subtitle-dark">
                        {{`${mSaleProfitEstimate(sale).toFixed(2)} Bs.`}}
                    </h1>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import {
    OBtnCardComp
} from "../organisms";
import {
    saleMixin
} from "../../mixins";
export default {
    mixins: [ saleMixin ],
    props: {
        sale: Object,
        value: Boolean,
        isUser: Boolean
    },
    components: {
        OBtnCardComp
    }
}
</script>