<template>
    <div>
        <v-tooltip
            bottom
            content-class="color-tooltip"
            v-if="!mobile"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    v-bind="attrs"
                    v-on="on"
                    :ripple="false"
                    x-small
                    class="fondo-btn-cabecera"
                    :disabled="disabled"
                    depressed
                    @click="$emit('click')"
                >
                    <slot></slot>
                </v-btn>
            </template>
            <MSmsToolTipComp
                :smsToolTip="smsToolTip"
            />
        </v-tooltip>
        <v-btn
            v-else
            fab
            :ripple="false"
            x-small
            class="fondo-btn-cabecera"
            :disabled="disabled"
            depressed
            @click="$emit('click')"
        >
            <slot></slot>
        </v-btn>
    </div>
</template>
<script>
import { MSmsToolTipComp } from "../molecules";
export default {
    components: {
        MSmsToolTipComp
    },
    props: {
        smsToolTip: String,
        disabled: Boolean,
        mobile: Boolean
    }
}
</script>