var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"450","persistent":"","value":_vm.value}},[_c('v-card',[_c('div',{staticClass:"s-detail-product__header pl-2 pr-2 d-flex align-center"},[_c('h1',{staticClass:"app-title"},[_vm._v(" "+_vm._s(_vm.indexLot == -1 ? "Registrar Lote" : "Actualizar Lote")+" ")]),_c('v-spacer'),_c('OBtnCardComp',{attrs:{"icon":"mdi-close"},on:{"click":function($event){return _vm.$emit('clickclose')}}})],1),_c('div',{staticClass:"client__line-header"}),_c('div',{staticClass:"pa-2 pt-5 pb-3"},[_c('v-form',{ref:"formlot",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('MTextFieldComp',{attrs:{"label":"Cantidad","rules":[
                        value => !this.mRuleEmptyField(value) || 'La cantidad es requerida',
                        value => this.mRuleNumberInteger(value) || 'La cantidad debe ser un número'
                    ]},model:{value:(_vm.dataLot.quantity),callback:function ($$v) {_vm.$set(_vm.dataLot, "quantity", $$v)},expression:"dataLot.quantity"}}),_c('MTextFieldComp',{staticClass:"mt-3",attrs:{"label":"Fecha Expiración","type":"date","rules":[
                        value => !this.mRuleEmptyField(value) || 'La fecha es requerida'
                    ]},model:{value:(_vm.dataLot.expiration_date),callback:function ($$v) {_vm.$set(_vm.dataLot, "expiration_date", $$v)},expression:"dataLot.expiration_date"}}),_c('MTextFieldComp',{attrs:{"label":"Precio Compra","rules":[
                        value => !this.mRuleEmptyField(value) || 'El precio es requerido',
                        value => this.mRuleNumberDecimal(value) || 'El precio debe ser un número'
                    ]},model:{value:(_vm.dataLot.purchase_price),callback:function ($$v) {_vm.$set(_vm.dataLot, "purchase_price", $$v)},expression:"dataLot.purchase_price"}})],1),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('MBtnNormalComp',{on:{"click":function($event){return _vm.eValidForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }