<template>
    <div class="pa-2">
        <div
            v-if="saleBill.id!=undefined && settingBill.id!=undefined"
        >
            <h1 class="text-center app-bill">FARMACIA NAZARET</h1>
            <h1 class="text-center app-bill">
                {{`De: ${settingBill.business_name}`}}
            </h1>
            <h1 class="text-center app-bill">CASA MATRIZ</h1>
            <h1 class="text-center app-bill">
                {{settingBill.direction}}</h1>
            <h1
                v-if="settingBill.cell_phone!=null"
                class="text-center app-bill"
            >
                {{`Celular: ${settingBill.cell_phone}`}}</h1>
            <h1
                v-if="settingBill.phone!=null"
                class="text-center app-bill"
            >
                {{`Telf.: ${settingBill.phone}`}}</h1>
            <h1 class="text-center app-bill">SUCRE - BOLIVIA</h1>
            <h1 class="text-center app-bill">FACTURA - ORIGINAL</h1>
            <v-divider class="mb-1"></v-divider>
            <h1 class="text-center app-bill">
                {{settingBill.nit}}
            </h1>
            <h1 class="text-center app-bill">
                {{`NRO. FACTURA: ${saleBill.bill.bill_number}`}}
            </h1>
            <h1 class="text-center app-bill">
                {{`AUTORIZACIÓN: ${saleBill.bill.authorization_code}`}}
            </h1>
            <h1 class="text-center app-bill">
                {{`ACTIVIDAD PRINCIPAL: ${settingBill.main_activity}`}}
            </h1>
            <v-divider class="mb-1"></v-divider>
            <h1 class="app-bill">
                {{`Fecha Emisión: ${saleBill.date}   ${saleBill.hour}`}}
            </h1>
            <h1 class="app-bill">
                {{`Nombre/Razón Social:`}}
            </h1>
            <h1 class="app-bill ml-2">
                {{`${saleBill.client.full_name}`}}
            </h1>
            <h1 class="app-bill">
                {{`NIT/CI/CEX: ${saleBill.bill.ci_nit}`}}
            </h1>
            <h1 class="app-bill" v-if="saleBill.client.complement!=null">
                {{`Complemento: ${saleBill.client.complement}`}}
            </h1>
            <v-divider class="mt-1"></v-divider>
            <table class="mt-1">
                <thead>
                    <tr>
                        <td>
                            <h1 class="app-bill">Concepto</h1>
                        </td>
                        <td>
                            <h1 class="app-bill">Cant.</h1>
                        </td>
                        <td>
                            <h1 class="app-bill">P.U.</h1>
                        </td>
                        <td>
                            <h1 class="app-bill">Total</h1>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="product in saleBill.detail"
                        :key="product.id"
                    >
                        <td>
                            <h1 class="app-bill">{{product.product}}</h1>
                        </td>
                        <td>
                            <h1 class="app-bill text-right">
                                {{product.pivot.unit_price.toFixed(2)}}</h1>
                        </td>
                        <td>
                            <h1 class="app-bill text-right">
                                {{product.pivot.quantity}}</h1>
                        </td>
                        <td>
                            <h1 class="app-bill text-right">
                                {{(product.pivot.quantity*product.pivot.unit_price).toFixed(2)}}
                            </h1>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" class="mr-2">
                            <h1 class="app-bill text-right">TOTAL (Bs.):</h1>
                        </td>
                        <td>
                            <h1 class="app-bill text-right">
                                {{saleBill.sale.toFixed(2)}}
                            </h1>
                        </td>
                    </tr>
                </tbody>
            </table>
            <v-divider class="mb-1"></v-divider>
            <h1 class="app-bill">
                {{`SON: ${textTotalSale()}`}}
            </h1>
            <h1 class="app-bill">
                BOLIVIANOS
            </h1>
            <h1 class="app-bill">
                {{`CODIGO DE CONTROL: ${saleBill.bill.control_code}`}}
            </h1>
            <h1 class="app-bill">
                {{`FECHA LÍMITE DE EMISIÓN: ${mDateFormat(settingBill.expiration_date)}`}}
            </h1>
            <div class="d-flex justify-center">
                <vue-qr
                    :text="saleBill.bill.qr"
                    :size="160"
                >
                </vue-qr>
            </div>
            <h1 class="app-bill-title text-center">
                "ESTA FACTURA CONTRIBUYE AL DESARROLLO DEL PAÍS. EL USO ILÍCITO
                DE ÉSTA SERÁ SANCIONADO DE ACUERDO A LEY"
            </h1>
            <v-divider class="mt-1"></v-divider>
            <h1 class="app-bill text-center mt-1">
                GRACIAS POR SU COMPRA!!!!!
            </h1>
            <h1 class="app-bill">
                {{`RESPONSABLE: ${saleBill.user.full_name}`}}
            </h1>
        </div>
    </div>
</template>
<script>
import convertNumberToText from "../../plugins/convertNumberToText";
import VueQr from 'vue-qr';
import {
    datesMixin
} from "../../mixins";
export default {
    mixins: [ datesMixin ],
    components: {
        VueQr
    },
    props: {
        saleBill: Object,
        settingBill: Object
    },
    methods: {
        textTotalSale() {
            return convertNumberToText.textPrice(this.saleBill.sale.toFixed(2));
        }
    }
}
</script>