export default {
    methods: {
        mSaleTotalDiscount(sale) {
            var total = 0;
            total += sale.discount;
            sale.detail.forEach(element => {
                total += (element.pivot.discount * element.pivot.quantity);
            });
            return total;
        },
        mSaleProfitEstimate(sale) {
            //var discount = sale.discount;
            var totalPurchase = 0;
            sale.detail.forEach(element => {
                //discount += (element.pivot.discount * element.pivot.quantity);
                element.inventory_outputs.forEach(inventory => {
                    totalPurchase += (inventory.purchase_price * inventory.quantity);
                });
            });
            return sale.sale - totalPurchase;
        }
    }
}