<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <text-highlight
                class="app-title"
                :queries="search"
            >
                {{provider.provider}}
            </text-highlight>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Celular:</h1>
                <h1 class="app-subtitle-dark">
                    {{provider.phone!=null ? provider.phone : 'S/N'}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Dirección:</h1>
                <h1 class="app-subtitle-dark">
                    {{provider.address!=null ? provider.address : 'S/D'}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Correo:</h1>
                <h1 class="app-subtitle-dark">
                    {{provider.e_mail!=null ? provider.e_mail : 'S/C'}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Descripción:</h1>
                <h1 class="app-subtitle-dark">
                    {{provider.description!=null ? provider.description : 'S/D'}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha Reg.:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${provider.hour} ${provider.date}`}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
            <OBtnCardComp
                icon="mdi-delete"
                style="margin:2px"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import { OBtnCardComp } from "../organisms";
export default {
    props: {
        provider: Object,
        search: {
            type: String,
            default: ''
        }
    },
    components: {
        TextHighlight,
        OBtnCardComp
    }
}
</script>