<template>
    <div class="s-form-select-product">
        <div class="s-form-select-product__container">
            <div class="s-form-select-product__form">
                <v-form
                    v-model="valid"
                    lazy-validation
                    ref="formproduct"
                >
                    <div v-if="product.categorie_user == '0' || isValidDiscount">
                        <MTextFieldComp
                            label="Precio Venta"
                            ref="pricesale"
                            v-model="product.select_sale_price"
                            :clearable="false"
                            @click="eValidForm()"
                            :rules="[
                                value => !this.mRuleEmptyField(value) || 'Requerido',
                                value => this.mRuleNumberDecimal(value) || 'No número',
                            ]"
                        />
                    </div>
                    <MTextFieldComp
                        label="Cantidad"
                        ref="quantity"
                        v-model="product.select_quantity"
                        :clearable="false"
                        @click="eValidForm()"
                        :rules="[
                            value => !this.mRuleEmptyField(value) || 'Requerido',
                            value => this.mRuleNumberInteger(value) || 'No número',
                            value => !this.mRuleExceededNumber(value, product.quantity) || 'Error Cant.'
                        ]"
                    />
                    <div>
                        <MBtnNormalComp
                            :small="false"
                            :xSmall="true"
                            @click="eValidForm()"
                        >
                            {{indexProductDetail==-1 ? 'AGREGAR' : 'EDITAR'}}
                        </MBtnNormalComp>
                        <MBtnNormalComp
                            :small="false"
                            :xSmall="true"
                            class="ml-1"
                            @click="$emit('clickdetail', product)"
                        >
                            DETALLE
                        </MBtnNormalComp>
                    </div>
                </v-form>
            </div>
            <div class="ml-4">
                <h1 class="app-title">{{product.product}}</h1>
                <v-divider></v-divider>
                <div class="d-flex">
                    <h1 class="app-title">Lab.:</h1>
                    <h1 class="app-subtitle ml-1">{{product.laboratory.laboratory}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="app-title">Ubicación:</h1>
                    <h1 class="app-subtitle ml-1">{{product.location}}</h1>
                </div>
                <div class="d-flex" v-if="product.categorie_user=='0'">
                    <h1 class="app-title">P.C.:</h1>
                    <h1 class="app-subtitle ml-1">
                        {{getPurchasePrices()}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="app-title">P.V.:</h1>
                    <h1 class="app-subtitle ml-1">
                        {{`${product.sale_price.toFixed(2)} Bs.`}}</h1>
                </div>
                <!--<div class="d-flex" v-if="product.categorie_user=='0'">
                    <h1 class="app-title">P.V.Min.:</h1>
                    <h1 class="app-subtitle ml-1">
                        {{`${(product.sale_price-product.max_discount).toFixed(2)} Bs.`}}
                    </h1>
                </div>-->
                <div class="d-flex align-center">
                    <h1 class="app-title">Cantidad:</h1>
                    <h1 class="app-subtitle ml-1">{{product.quantity}}</h1>
                    <div style="margin-top:-2px" class="ml-1">
                        <div
                            :class="product.quantity > 0
                                ? 'client__ornament-enabled'
                                : 'client__ornament-disabled'"
                        >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="s-form-select-product__lots">
            <h1 class="app-title text-center mt-1 mb-1">
                LOTES
            </h1>
            <v-divider></v-divider>
            <div class="pa-2 pt-1">
                <div
                    v-for="inventory in product.inventories"
                    :key="inventory.id"
                >
                    <v-hover v-slot="{ hover }">
                        <v-card
                            elevation="0"
                            :color="hover ? '#e8e8e8' : ''"
                            class="mt-1"
                        >
                            <div class="d-flex align-center">
                                <h1 class="app-sale-subtitle mr-1">
                                    {{mDateFormat(inventory.expiration_date)}}
                                </h1>
                                <div
                                    :class="inventory.expired
                                        ? 'client__ornament-disabled'
                                        : 'client__ornament-enabled'"
                                >
                                </div>
                                <h1 class="app-sale-title ml-1">
                                    {{`(${inventory.quantity})`}}
                                </h1>
                            </div>
                            <div class="s-form-select-product--line"></div>
                        </v-card>
                    </v-hover>
                </div>
                <h1
                    v-if="product.inventories.length == 0"
                    class="app-sale-subtitle text-center"
                >Sin Inventario</h1>
            </div>
        </div>
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp
} from "../molecules";
import {
    productMixin,
    rulesMixin,
    datesMixin
} from "../../mixins";
export default {
    components: {
        MTextFieldComp,
        MBtnNormalComp
    },
    mixins: [
        productMixin,
        rulesMixin,
        datesMixin
    ],
    props: {
        product: Object,
        indexProductDetail: Number,
        isValidDiscount: Boolean
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.$refs.formproduct.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.formproduct.resetValidation();
        },
        activateFocus() {
            if (this.product.categorie_user === "1")
                this.$refs.quantity.activateFocus();
            else this.$refs.pricesale.activateFocus();
        },
        getPurchasePrices() {
            if (this.product.inventories.length === 0) return "0.00 Bs."
            if (this.product.id == undefined) return "";
            else {
                var text = "";
                for (var i = 0; i < this.product.inventories.length; i++)
                    text += `(${this.product.inventories[i].purchase_price.toFixed(2)} Bs.) `;
                return text;
            }
        }
    }
}
</script>