<template>
    <div class="s-form-product">
        <h1
            class="app-title text-center mb-2"
            v-if="!isAloneForm"
        >
            {{indexLaboratory===-1 ? 'REGISTRAR' : 'EDITAR'}}
        </h1>
        <v-form
            v-model="valid"
            lazy-validation
            ref="formlaboratory"
        >
            <ORowFormComp
                :mobile="mobile"
                informationRow="Laboratorio:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Laboratorio' : ''"
                    v-model="dataLaboratory.laboratory"
                    :counter="50"
                    ref="laboratory"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El laboratorio es requerido',
                        value => (`${value}`).length<=50 || 'El laboratorio, no debe exceder los 50 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Ubicación:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Ubicación' : ''"
                    v-model="dataLaboratory.location"
                    :counter="50"
                    :rules="[
                        value => (`${value}`).length<=50 || 'La ubicación, no debe exceder los 50 dígitos'
                    ]"
                />
            </ORowFormComp>
        </v-form>
        <div class="d-flex justify-center mt-1">
            <MBtnNormalComp
                @click="eValidForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                {{textBtn}}
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import { MBtnNormalComp, MTextFieldComp } from "../molecules";
import { ORowFormComp } from "../organisms";
import { rulesMixin } from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        MBtnNormalComp,
        ORowFormComp,
        MTextFieldComp
    },
    props: {
        indexLaboratory: Number,
        mobile: Boolean,
        dataLaboratory: Object,
        textBtn: {
            type: String,
            default: "GUARDAR"
        },
        isAloneForm: Boolean
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.$refs.formlaboratory.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.formlaboratory.resetValidation();
        },
        activateFocus() {
            this.$refs.laboratory.activateFocus();
        }
    }
}
</script>