import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';

class SettingBill {
    constructor (
        nit = null,
        business_name = null,
        cell_phone = null,
        phone = null,
        direction = null,
        main_activity = null,
        authorization_number = null,
        dosage_key = null,
        expiration_date = null
    ) {
        this.nit = nit;
        this.business_name = business_name;
        this.cell_phone = cell_phone;
        this.phone = phone;
        this.direction = direction;
        this.main_activity = main_activity;
        this.authorization_number = authorization_number;
        this.dosage_key = dosage_key;
        this.expiration_date = expiration_date;
    }
    static async getSettingBill() {
        const response =  await axios.get(
            `${apiUrls.SETTING_BILL}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addSettingBill(parameters) {
        const response =  await axios.post(
            `${apiUrls.SETTING_BILL}/add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editSettingBill(parameters) {
        const response =  await axios.put(
            `${apiUrls.SETTING_BILL}/edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default SettingBill;