<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th width="105">
                        <h1 class="app-title-dark text-left">Product</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">P.U.</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Des.</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">P.V.</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Cant.</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Total Precio</h1>
                    </th>
                    <th width="30"></th>
                    <th v-if="!isInfo">
                        <h1 class="app-title-dark text-left">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(product, index) in detailSale"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{product.product}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{`${(product.pivot.unit_price+product.pivot.discount).toFixed(2)} Bs.`}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{`${product.pivot.discount.toFixed(2)} Bs.`}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{`${product.pivot.unit_price.toFixed(2)} Bs.`}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{product.pivot.quantity}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{`${(product.pivot.quantity * product.pivot.unit_price).toFixed(2)} Bs.`}}
                        </h1>
                    </td>
                    <td>
                        <div
                            :class="product.pivot.discount>0
                                ? 'client__ornament-disabled'
                                : 'client__ornament-enabled'"
                        >
                        </div>
                    </td>
                    <td
                        v-if="!isInfo"
                        class="d-flex justify-center flex-wrap"
                    >
                        <OBtnToolTipTableComp
                            smsToolTip="Ver movimiento inventario"
                            icon="mdi-format-list-text"
                            @click="$emit('clickinventory', product)"
                        />
                    </td>
                </tr>
                <tr v-if="detailSale.length === 0">
                    <td :colspan="isInfo ? '5' : '6'">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    OBtnToolTipTableComp
} from "../organisms";
export default {
    props: {
        detailSale: Array,
        isInfo: Boolean
    },
    components: {
        OBtnToolTipTableComp
    }
}
</script>
