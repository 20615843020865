<template>
    <div class="sales">
        <div class="d-none">
            <div ref="sheetbill">
                <SSheetPrintBillComp
                    :saleBill="saleBill"
                    :settingBill="settingBill"
                />
            </div>
        </div>
        <div class="section-print">
            <div class="sales__header d-flex align-center pa-2">
                <div class="d-flex align-center">
                    <OBtnToolTipHeaderComp
                        v-if="!mobile"
                        :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                        smsToolTip="Ocultar Menú"
                        @click="$emit('clickmini')"
                    />
                    <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                        VENDER
                    </h1>
                </div>
                <v-spacer></v-spacer>
                <OBtnToolTipHeaderComp
                    v-if="iIsAdmin()"
                    icono="mdi-cash-check"
                    class="mr-1"
                    smsToolTip="Obtener Autorización de descuento"
                    @click="eGetActiveDiscount()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-account-remove"
                    smsToolTip="Cerrar Caja"
                    @click="eConfirmCloseBox()"
                />
            </div>
            <div class="client__line-header" id="position-scroll"></div>
            <div class="sales__container">
                <div class="sales__container-body">
                    <div class="sales__container-products">
                        <div class="d-flex align-center">
                            <MTextFieldHeaderComp
                                label="Buscar Producto"
                                :hideDetails="true"
                                :singleLine="true"
                                @click="getProducts()"
                                v-model="search"
                            /><!--@input="iSearchProduct()"-->
                            <OBtnToolTipHeaderComp
                                class="ml-1"
                                icono="mdi-database"
                                smsToolTip="Volver a los productos"
                                @click="eBackProductList()"
                            />
                        </div>
                        <div class="mt-1 sales__products-search">
                            <v-tabs-items
                                style="background:transparent !important;"
                                v-model="tab"
                                :touchless="true"
                            >
                                <v-tab-item>
                                    <OLoaderDataComp
                                        class="mt-2"
                                        v-if="dialogLoaderData"
                                        smsLoaderData="Obteniendo Información"
                                    />
                                    <MNoDataComp
                                        smsNotData="SIN DATOS"
                                        v-if="productsList.length==0 && !dialogLoaderData"
                                    />
                                    <SGroupProductsSaleComp
                                        v-if="!dialogLoaderData"
                                        class="mt-1"
                                        :productsList="productsList"
                                        :search="searchFilter"
                                        @clickproduct="eSelectProduct"
                                    />
                                </v-tab-item>
                                <v-tab-item>
                                    <SFormSelectProductComp
                                        ref="formproductcomp"
                                        :product="product"
                                        :inventories="inventories"
                                        @saveform="eProcessForm"
                                        @clickdetail="eShowDetailProduct"
                                        :isValidDiscount="isValidDiscount"
                                        :indexProductDetail="indexProductDetail"
                                    />
                                </v-tab-item>
                            </v-tabs-items>
                        </div>
                        <v-divider class="mt-1 mb-1"></v-divider>
                        <h1 class="app-title text-center">{{titleSales}}</h1>
                        <v-divider class="mb-1"></v-divider>
                        <div class="sales__group-sales">
                            <STableSalesCashRegisterComp
                                :salesList="salesList"
                                @clickinfo="eShowInfoSale"
                                @clickdetail="eShowDetailSale"
                                @clickchangestate="eConfirmChangeState"
                                @clicktypesale="eChangeTypePayment"
                            />
                        </div>
                    </div>
                    <div class="sales__detail">
                        <div class="sales__detail-container">
                            <!--<v-card
                                color="purple"
                                v-if="parametersSetting.isWarning"
                                class="animation-opacity pa-1 rounded-0"
                                elevation="0"
                            >
                                <h1 class="app-title-dark text-center">
                                    {{`!!! ${parametersSetting.message}`}}
                                </h1>
                            </v-card>-->
                            <STableDetailSaleComp
                                :detailList="detailList"
                                ref="tabledetailsale"
                                @clickdelete="eDeleteDetailProduct"
                                @clickdetail="eShowDetailProduct"
                                @clickedit="eShowFormEditProduct"
                                @clickinventory="eShowInventoriesProduct"
                                @clickclear="eClearDetailSale"
                                @clicklots="eShowDialogExpiratesDates"
                                @clickConfirmSale="eConfirmSaleDirect"
                                @clickConfirmSaleClient="eShowDialogClient"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <SDetailProductComp
                :product="product"
                :value="dialogDetailProduct"
                @clickclose="dialogDetailProduct=false"
            />
            <SDetailInventoriesProductComp
                :product="product"
                :value="dialogInventories"
                @clickclose="dialogInventories=false"
            />
            <SDialogExpirationDateComp
                :value="dialogExpiratesDates"
                :product="product"
                @clickclose="eDialogCloseExpirationDate()"
                @clickedit="eEditExpiratesDates"
            />
            <SDialogClientSaleComp
                :clientsList="clientsList"
                :dialogLoaderData="dialogLoaderDataClient"
                :value="dialogClient"
                ref="dialogclientsale"
                @entersearch="eSearchClients"
                @clickclose="closeDialogClient"
                @clickclient="eSelectClient"
                @newclient="eConfirmNewClient"
            />
            <SInfomationSaleComp
                :value="dialogInfoSale"
                :sale="sale"
                :isUser="true"
                @clickclose="dialogInfoSale=false"
            />
            <EDialogDetailSaleComp
                :value="dialogDetailSale"
                :sale="sale"
                :mobile="mobile"
                @clickclose="dialogDetailSale=false"
            />
            <OSmsConfirmationComp
                :value="dialogConfirm"
                :smsConfirm="smsConfirm"
                :disabledBill="disabledBill"
                :mobile="mobile"
                @clickno="eCloseDialogConfirm()"
                @clickyes="ePerformDatabaseAction()"
            />
            <OSmsErrorComp
                :smsError="smsError"
                :value="dialogError"
                :actionError="actionError"
                :mobile="mobile"
                @click="eCloseError()"
            />
            <OSmsActionComp
                :value="frameCorrect"
                :colorMessage="colorMessage"
                :smsAction="smsAction"
                :timeSucess="timeSucess"
                @click="mDataGeneralFrameTimeReset()"
            />
            <OOperationLoaderComp
                smsLoaderOperation="Realizando Operación"
                :value="dialogLoaderOperation"
            />
        </div>
    </div>
</template>
<script>
import {
    MTextFieldHeaderComp,
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp,
    OLoaderDataComp
} from "../../components/organisms";
import {
    STableDetailSaleComp,
    SGroupProductsSaleComp,
    SFormSelectProductComp,
    SDetailProductComp,
    SDetailInventoriesProductComp,
    SDialogExpirationDateComp,
    SDialogClientSaleComp,
    STableSalesCashRegisterComp,
    SInfomationSaleComp,
    SSheetPrintBillComp
} from "../../components/species";
import {
    EDialogDetailSaleComp
} from "../../components/ecosystems";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    userMixin,
    datesMixin,
    animationScrollMixin,
    productMixin,
    rulesMixin,
    printMixin
} from "../../mixins";
import {
    Product,
    Sale,
    Client,
    PersonalControl,
    SettingBill,
    CashRegister,
    Bill
} from "../../models";
export default {
    components: {
        OBtnToolTipHeaderComp,
        STableDetailSaleComp,
        MTextFieldHeaderComp,
        MNoDataComp,
        SGroupProductsSaleComp,
        SFormSelectProductComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        SDetailProductComp,
        SDetailInventoriesProductComp,
        SDialogExpirationDateComp,
        SDialogClientSaleComp,
        STableSalesCashRegisterComp,
        SInfomationSaleComp,
        EDialogDetailSaleComp,
        OLoaderDataComp,
        SSheetPrintBillComp
    },
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        userMixin,
        datesMixin,
        animationScrollMixin,
        productMixin,
        rulesMixin,
        printMixin
    ],
    props: {
        mini: Boolean
    },
    data: () => ({
        detailList: [],
        productsList: [],
        search: "",
        setTimeOutSearch: "",
        product: {},
        inventories: [],
        tab: 0,
        dialogDetailProduct: false,
        indexProductDetail: -1,
        dialogInventories: false,
        parameters: {
            sale: new Sale,
            detail: [],
            client: {},
            id_cash_register: 0,
            no_print: false,
            bill: new Bill
        },
        dialogExpiratesDates: false,
        dialogLoaderDataClient: false,
        clientsList: [],
        dialogClient: false,
        salesList: [],
        titleSales: "",
        idCashRegister: 0,
        dialogInfoSale: false,
        sale: { 'detail': [] },
        dialogDetailSale: false,
        isCloseBox: false,
        indexSale: -1,
        isChangeState: false,
        settingBill: {},
        //noBillSetup: false,
        parametersSetting: {
            isWarning: false,
            message: "",
            isExired: true
        },
        isValidDiscount: false,
        dateSystem: "",
        disabledBill: false,
        saleBill: {},
        setTimeOutSearch: ""
    }),
    computed: {
        searchFilter: function() {
            return this.mRuleEmptyField(this.search) ? "" : this.search;
        }
    },
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else {
                if (this.idCashRegister === 0)
                    this.$router.replace('/home');
                else this.dialogError = false;
            }
        },
        async iSearchProduct() {
            if (this.tab !== 0) this.tab = 0;
            clearTimeout(this.setTimeOutSearch);
            this.setTimeOutSearch = setTimeout(() => {
                //if (this.search != "" && this.search != null)
                this.getProducts();
            }, 200);
        },
        async getProducts() {
            if (this.mRuleEmptyField(this.search)) {
                this.productsList = [];
                return;
            }
            this.productsList = [];
            this.dialogLoaderData = true;
            //this.tab = 0;
            try {
                var response = await Product.searchSale(this.search);
                response.forEach(element => {
                    element.select_sale_price = element.sale_price;
                    element.select_quantity = 1;
                });
                this.productsList = response;
                this.dialogLoaderData = false;
            } catch(error) {
                console.log(error);
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        /*iSearchProduct() {
            clearTimeout(this.setTimeOutSearch);
            this.setTimeOutSearch = setTimeout(() => {
                if (this.search != "" && this.search != null)
                    this.getProducts();
            }, 200);
        },*/
        eBackProductList() {
            this.indexProductDetail = -1;
            if (this.tab != 0) {
                this.$refs.formproductcomp.resetForm();
                this.tab = 0;
            }
        },
        eSelectProduct(product) {
            this.product = JSON.parse(JSON.stringify(product));
            this.tab = 1;
            setTimeout(() => {
                this.$refs.formproductcomp.activateFocus();
            }, 150);
        },
        existIdDetail(id) {
            for (var i = 0; i < this.detailList.length; i++) {
                if (this.detailList[i].id === id) return true;
            }
            return false;
        },
        eProcessForm(state) {
            if (state) {
                if (this.indexProductDetail === -1) {
                    if (this.existIdDetail(this.product.id))
                        this.mDataGeneralShowSmsAction(["Producto ya agregado",
                            `${this.product.product}, ya está agregado`], "error");                
                    else {
                        this.productsList = [];
                        const listInventoryOutputs = this.mProductGenerateOutPuts(this.product);
                        this.product.inventory_outputs = listInventoryOutputs;
                        this.detailList.push(this.product);
                        this.tab = 0;
                        this.mDataGeneralShowSmsAction(["Agregado",
                            "Se agregó el producto"], "success");
                    }
                } else {
                    this.productsList = [];
                    const listInventoryOutputs = this.mProductGenerateOutPuts(this.product);
                    this.product.inventory_outputs = listInventoryOutputs;
                    Object.assign(this.detailList[this.indexProductDetail], this.product);
                    this.indexProductDetail = -1;
                    this.mDataGeneralShowSmsAction(["Editado",
                        "Se editó el producto"], "success");
                    this.tab = 0;
                }
            } else this.mDataGeneralShowSmsAction(["Error de datos",
                "Corrija los errores en rojo"], "error");
        },
        eDeleteDetailProduct(index) {
            this.detailList.splice(index, 1);
        },
        eShowDetailProduct(product) {
            this.product = Object.assign({}, product);
            this.dialogDetailProduct = true;
        },
        eShowFormEditProduct(product) {
            this.indexProductDetail = this.detailList.indexOf(product);
            this.product = Object.assign({}, product);
            this.tab = 1;
        },
        eShowInventoriesProduct(product) {
            this.product = Object.assign({}, product);
            this.dialogInventories = true;
        },
        eClearDetailSale() {
            this.$refs.tabledetailsale.changeStateComp();
            this.$refs.tabledetailsale.resetCancelled();
            this.detailList = [];
        },
        eShowDialogExpiratesDates(product) {
            this.tab = 0;
            this.indexProductDetail = this.detailList.indexOf(product);
            this.product = JSON.parse(JSON.stringify(product));
            this.dialogExpiratesDates = true;
        },
        eDialogCloseExpirationDate() {
            this.indexProductDetail = -1;
            this.dialogExpiratesDates = false;
        },
        eEditExpiratesDates(parameters) {
            if (parameters.isValidForm) {
                Object.assign(this.detailList[this.indexProductDetail], this.product);
                this.indexProductDetail = -1;
                this.$refs.tabledetailsale.changeStateComp();
                this.dialogExpiratesDates = false;
            } else {
                if (parameters.errorQuantity)
                    this.mDataGeneralShowSmsAction(["La suma de cantidad",
                        "Debe ser igual al cantidad del producto"], "error");
                else
                    this.mDataGeneralShowSmsAction(["Todas las cantidades",
                        "Deben ser números"], "error");
            }
        },
        handleKeyUpSale(event) {
            if (!this.mobile && (this.dialogConfirm || this.dialogError)) {
                if (this.dialogConfirm) {
                    if (this.indexSale === -1 && !this.isCloseBox && !this.parametersSetting.isExired) {
                        if (event.keyCode === 81
                            && event.ctrlKey
                            && this.settingBill.id !== undefined
                            && !this.settingBill.expired
                        ) {
                            this.disabledBill = true;
                            this.parameters.no_print = true;
                        }
                    }
                    if (event.keyCode == 78)
                        this.eCloseDialogConfirm();
                    if (event.keyCode == 83)
                        this.ePerformDatabaseAction();
                } else {
                    if (event.keyCode == 65)
                        this.eCloseError();
                }
            }
        },
        getNameTypeSale(typeCoin) {
            if (typeCoin == "e") return "efectivo";
            if (typeCoin == "t") return "tarjeta";
            return "qr"
        },
        //sale detail
        eConfirmSaleDirect(parameters) {
            if (!parameters.isValidForm)
                this.mDataGeneralShowSmsAction(["Error en el Detalle",
                    "Corrija el descuento porfavor"], "error");
            else {
                this.parameters.bill.ci_nit = "0";
                this.parameters.id_cash_register = this.idCashRegister;
                this.parameters.sale = parameters.sale;
                this.parameters.detail = parameters.detail;
                this.smsConfirm = [
                    `¿${this.mUserFullName()}: estás seguro(a) de registrar la venta por:
                    ${this.parameters.sale.sale} Bs., mediante pago: 
                    ${this.getNameTypeSale(parameters.sale.type_sale)}?`
                ];
                this.dialogConfirm = true;
            }
        },
        eShowDialogClient(parameters) {
            if (!parameters.isValidForm)
                this.mDataGeneralShowSmsAction(["Error en el Detalle",
                    "Corrija el descuento porfavor"], "error");
            else {
                this.closeDialogClient();
                this.parameters.id_cash_register = this.idCashRegister
                this.parameters.sale = parameters.sale;
                this.parameters.detail = parameters.detail;
                this.dialogClient = true;
            }
        },
        //client
        async eSearchClients(search) {
            this.dialogLoaderDataClient = true;
            try {
                const response = await Client.getClientsSale(search);
                this.clientsList = response;
                this.dialogLoaderDataClient = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        closeDialogClient() {
            this.$refs.dialogclientsale.resetData();
            this.clientsList = [];
            this.dialogClient = false
        },
        eSelectClient(client) {
            this.parameters.bill.ci_nit = client.ci_nit;
            this.parameters.id_cash_register = this.idCashRegister;
            this.parameters.sale.fk_client = client.id;
            this.parameters.sale.select_client = 1;
            const smsTypeSale = this.parameters.sale.type_sale === "e" ? "efectivo" : "transacción QR";
            this.smsConfirm = [
                `¿${this.mUserFullName()}: estás seguro(a) de registrar la venta por:
                ${this.parameters.sale.sale} Bs., mediante pago: 
                ${this.getNameTypeSale(this.parameters.sale.type_sale)}, para: ${client.full_name}?`
            ];
            this.dialogClient = false;
            this.dialogConfirm = true;
        },
        eConfirmNewClient(parameters) {
            if (parameters.isValidForm) {
                this.parameters.bill.ci_nit = parameters.client.ci_nit;
                this.parameters.id_cash_register = this.idCashRegister;
                this.parameters.client = Object.assign({}, parameters.client);
                this.parameters.sale.select_client = 0;
                const smsTypeSale = this.parameters.sale.type_sale === "e" ? "efectivo" : "transacción QR";
                this.smsConfirm = [
                    `¿${this.mUserFullName()}: estás seguro(a) de registrar la venta por:
                    ${this.parameters.sale.sale} Bs., mediante pago: 
                    ${this.getNameTypeSale(this.parameters.sale.type_sale)}, para: ${parameters.client.full_name}?`
                ];
                this.dialogClient = false;
                this.dialogConfirm = true;
            } else this.mDataGeneralShowSmsAction(["Error de datos",
                "Corrija los campos rojos"], "error");
        },
        //
        eCloseDialogConfirm() {
            if (!this.parametersSetting.isExired)
                this.disabledBill = this.parameters.no_print = false;
            if (this.indexSale !== -1) {
                this.indexSale = -1;
                this.isChangeState = false;
                this.dialogConfirm = false;
            } else {
                this.indexSale = -1;
                this.dialogConfirm = false;
                this.isChangeState = false;
                if (!this.isCloseBox) {
                    if (this.parameters.sale.no_client === 0)
                        this.dialogClient = true;
                } else this.isCloseBox = false;
            }
        },
        async addSale() {
            try {
                const response = await Sale.addSale(this.parameters);
                this.parameters = {
                    'sale': new Sale(),
                    'detail': [],
                    'client': {},
                    'id_cash_register': 0,
                    'activate_print': false,
                    'bill': new Bill()
                };
                this.disabledBill = false;
                this.eClearDetailSale();
                this.dialogLoaderOperation = false;
                this.salesList.unshift(response);
                this.productsList = [];
                this.isValidDiscount = false;
                this.$refs.tabledetailsale.resetCancelled();
                this.mDataGeneralShowSmsAction(["Registro Exitoso",
                    "Se registró la venta"], "success");
                if (response.with_bill) {
                    this.saleBill = response;
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.mPrintShow(this.$refs.sheetbill);
                        }, 500);
                    });
                }
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async closeBoxUser() {
            this.dialogLoaderOperation = true;
            try {
                await PersonalControl.closeUser({id_cash_register: this.idCashRegister});
                this.dialogLoaderOperation = false;
                localStorage.removeItem("nazaret");
                setTimeout(() => {
                    this.$router.replace("/login");
                }, 150);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async changeStateSale() {
            this.dialogLoaderOperation = true;
            try {
                await Sale.changeState({id: this.sale.id});
                const valueState = this.sale.disabled === 1 ? 0 : 1;
                this.salesList[this.indexSale].disabled = valueState;
                this.dialogLoaderOperation = false;
                this.isChangeState = false;
                this.indexSale = -1;
                this.mDataGeneralShowSmsAction(["Operación Exitosa",
                    "Se anuló la venta"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async changeTypePayment() {
            try {
                await Sale.changeTypePayment({id: this.sale.id});
                const valueTypePayment = this.sale.type_sale === 'e' ? 'q' : 'e';
                this.salesList[this.indexSale].type_sale = valueTypePayment;
                this.dialogLoaderOperation = false;
                this.indexSale = -1;
                const smsTypePayment = this.sale.type_sale === 'e' ? 'por banco' : 'en efectivo';
                this.mDataGeneralShowSmsAction(["Operación Exitosa",
                    `Pago cambiado a pago ${smsTypePayment}`], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (this.indexSale === -1) {
                if (this.isCloseBox)
                    this.closeBoxUser();
                else this.addSale();
            } else {
                if (this.isChangeState)
                    this.changeStateSale();
                else this.changeTypePayment();
            }
        },
        showWarningExpired(diffDays, expiratedDate) {
            if (diffDays <= 15) {
                this.parametersSetting.isWarning = true;
                this.parametersSetting.message = `La validez de la facturación expira el ${this.mDateFormat(expiratedDate)}`;
            } else {
                this.parametersSetting.isWarning = true;
                this.parametersSetting.message = `Facturación habilitada`;
            }
        },
        async getRequest() {
            try {
                const getSales = Sale.cashRegisterResume(this.idCashRegister);
                const getSettingsBill = SettingBill.getSettingBill();
                const responses = await Promise.all([getSales, getSettingsBill]);
                if (responses[1].data.id == undefined) {
                    this.parameters.no_print = true;
                    this.parametersSetting.isWarning = true;
                    this.parametersSetting.message = "Facturación inhabilitada";
                } else if (responses[1].data.expired) {
                    this.parameters.no_print = true;
                    this.parametersSetting.isWarning = true;
                    this.parametersSetting.message = `Facturación inhabilitada por vencimiento de trámite
                        en fecha: ${this.mDateFormat(responses[1].data.expiration_date)}`;
                } else {
                    this.showWarningExpired(responses[1].data.diff_days, responses[1].data.expiration_date);
                    this.parametersSetting.isExired = false;
                }
                this.salesList = responses[0];
                this.settingBill = responses[1].data.id == undefined ? {} : responses[1].data;
                this.dateSystem = responses[1].date_system;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        validateOptions() {
            if (JSON.parse(localStorage.getItem("device"))) {
                this.titleSales = `TRANSACCIONES - CAJA N° ${JSON.parse(localStorage.getItem("device")).cash_register}`;
                this.idCashRegister = JSON.parse(localStorage.getItem("device")).id_cash_register;
                this.getRequest();
            } else {
                this.smsError = [
                    `Este dispositivo no fue habilitado para funcionar como caja registradora de ventas`,
                    `Habilite este dispositivos como caja registradora y podrá realizar ventas`
                ];
                this.actionError = true;
                this.dialogError = true;
            }
        },
        eShowInfoSale(sale) {
            this.sale = sale;
            this.dialogInfoSale = true;
        },
        eShowDetailSale(sale) {
            this.sale = sale;
            this.dialogDetailSale = true;
        },
        eConfirmCloseBox() {
            this.isCloseBox = true;
            this.smsConfirm = [`¿${this.mUserFullName()}, está seguro(a) de cerrar su caja?`];
            this.dialogConfirm = true;
        },
        //
        eConfirmChangeState(sale) {
            if (sale.disabled === 0) {
                this.isChangeState = true;
                this.sale = sale;
                this.indexSale = this.salesList.indexOf(sale);
                this.smsConfirm = [`¿Está seguro de anular la venta de ${sale.client.full_name}, por:
                    ${sale.sale.toFixed(2)} Bs.?`];
                this.dialogConfirm = true;
            } else
                this.mDataGeneralShowSmsAction(["Venta ya anulada",
                    `La operación ya se realizó`], "error");
        },
        eChangeTypePayment(sale) {
            this.sale = sale;
            this.indexSale = this.salesList.indexOf(sale);
            const smsTypePayment = sale.type_sale === 'e' ? 'por banco' : 'en efectivo';
            this.smsConfirm = [`¿Está seguro de cambiar el tipo de pago a: venta ${smsTypePayment}?`];
            this.dialogConfirm = true;
        },
        //
        async eGetActiveDiscount() {
            this.dialogLoaderOperation = true;
            try {
                const response = await CashRegister.getStateDiscount(this.idCashRegister);
                this.isValidDiscount = response;
                if (response)
                    this.mDataGeneralShowSmsAction(["Descuento Habilitado",
                        `El admin habilitó el descuento para la presente venta`], "success");
                else this.mDataGeneralShowSmsAction(["Operación Denegada",
                    `El admin no habilitó el descuento para la venta`], "error");
                this.dialogLoaderOperation = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        iIsAdmin() {
            if (!JSON.parse(localStorage.getItem("nazaret"))) return false;
            return this.mUserCategorie() != 0;
        }
    },
    mounted() {
        if (window.innerWidth <= 600)
            this.$router.replace('/sale-mobile');
        else {
            this.dialogLoaderData = false;
            if (this.mUserSaveHeader(this.mDateSystem(false))) {
                window.addEventListener('keyup', this.handleKeyUpSale);
                this.validateOptions();
            } else this.$router.replace('/login');
        }
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpSale);
    }
}
</script>