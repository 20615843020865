<template>
    <v-btn
        :ripple="false"
        depressed
        class="color-border-btn"
        :small="small"
        :x-small="xSmall"
        outlined
        :loading="loading"
        @click="$emit('click')"
    >
        <slot></slot>
    </v-btn>
</template>
<script>
export default {
    props: {
        outlined: Boolean,
        loading: Boolean,
        xSmall: Boolean,
        small: {
            type: Boolean,
            default: true
        }
    }
}
</script>