<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header d-flex align-center">
            <h1 class="app-title">CAJA N° {{cashRegister.cash_register}}</h1>
            <v-spacer></v-spacer>
            <div
                :class="cashRegister.disabled==1
                    ? 'client__ornament-disabled'
                    : 'client__ornament-enabled'"
            >
            </div>
        </div>
        <v-card
            elevation="0"
            class="pl-1 pr-1 rounded-0"
        >
            <v-divider></v-divider>
            <div class="d-flex mt-1">
                <h1 class="app-title mr-1">Monto Caja Inicial:</h1>
                <h1 class="app-subtitle">
                    {{`${parseFloat(cashRegister.money_box).toFixed(2)} Bs.`}}
                </h1>
            </div>
            <div class="d-flex align-center mb-1">
                <h1 class="app-title mr-1">Descuento Activo:</h1>
                <h1 class="app-subtitle">
                    {{cashRegister.activate_discount ? "SI" : "NO"}}
                </h1>
                <div
                    :class="cashRegister.activate_discount
                        ? 'client__ornament-enabled ml-1'
                        : 'client__ornament-disabled ml-1'"
                    style="margin-top:-2px;"
                >
                </div>
            </div>
            <v-divider></v-divider>
        </v-card>
        <div
            v-if="mobile"
            class="s-card-client__actions d-flex justify-center flex-wrap"
        >
            <OBtnCardComp
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
            <OBtnCardComp
                :icon="cashRegister.activate_discount ? 'mdi-cash-remove' : 'mdi-cash-minus'"
                style="margin:2px"
                @click="$emit('clickcash')"
            />
            <OBtnCardComp
                :icon="cashRegister.disabled==1 ? 'mdi-eye' : 'mdi-eye-off'"
                style="margin:2px"
                @click="$emit('clickstate')"
            />
            <OBtnCardComp
                icon="mdi-cash-register"
                style="margin:2px"
                @click="$emit('clickenabled')"
            />
            <OBtnCardComp
                icon="mdi-delete"
                style="margin:2px"
                @click="$emit('clickdelete')"
            />
        </div>
        <div
            v-else
            class="s-card-client__actions d-flex justify-center flex-wrap p1-1 pb-1"
        >
            <OBtnToolTipTableComp
                :smsToolTip="`Editar caja n° ${cashRegister.cash_register}`"
                icon="mdi-pencil"
                @click="$emit('clickedit')"
            />
            <OBtnToolTipTableComp
                :smsToolTip="cashRegister.activate_discount ? 'Inhabilitar Descuento' : 'Habilitar Descuento'"
                :icon="cashRegister.activate_discount ? 'mdi-cash-remove' : 'mdi-cash-minus'"
                @click="$emit('clickcash')"
            />
            <OBtnToolTipTableComp
                class="ml-1"
                :smsToolTip="cashRegister.disabled==1
                    ? `Habilitar caja n° ${cashRegister.cash_register}`
                    : `Deshabilitar caja n° ${cashRegister.cash_register}`"
                :icon="cashRegister.disabled==1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click="$emit('clickstate')"
            />
            <OBtnToolTipTableComp
                :smsToolTip="`Habilitar dispositivo para, Caja N° ${cashRegister.cash_register}`"
                icon="mdi-cash-register"
                class="ml-1"
                @click="$emit('clickenabled')"
            />
            <OBtnToolTipTableComp
                :smsToolTip="`Eliminar caja n° ${cashRegister.cash_register}`"
                icon="mdi-delete"
                class="ml-1"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import {
    OBtnCardComp,
    OBtnToolTipTableComp
} from "../organisms";
export default {
    components: {
        OBtnCardComp,
        OBtnToolTipTableComp
    },
    props: {
        mobile: Boolean,
        cashRegister: Object
    }
}
</script>