<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">N° Lote</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Cantidad</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha Expiración</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha Registro</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-center">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(lot, index) in lots"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{lot.lot_number}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{lot.quantity}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <h1 class="app-subtitle">
                                {{mDateFormat(lot.expiration_date)}}
                            </h1>
                            <div
                                :class="lot.expired
                                    ? 'client__ornament-disabled ml-1'
                                    : 'client__ornament-enabled ml-1'"
                                style="margin-top:-2px;"
                            >
                            </div>
                        </div>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{`${lot.hour} ${lot.date}`}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            :smsToolTip="`Actualizar lote de: ${lot.product.product}`"
                            icon="mdi-pencil"
                            @click="$emit('clickedit', lot)"
                        />
                    </td>
                </tr>
                <tr v-if="lots.length === 0">
                    <td colspan="7">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { OBtnToolTipTableComp } from "../organisms";
import { datesMixin } from "../../mixins";
export default {
    mixins: [ datesMixin ],
    components: {
        OBtnToolTipTableComp
    },
    props: {
        lots: Array,
        search: {
            type: String,
            default: ''
        }
    }
}
</script>