<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <text-highlight
                class="app-title"
                :queries="search"
            >
                {{laboratory.laboratory}}
            </text-highlight>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Ubicación:</h1>
                <h1 class="app-subtitle-dark">
                    {{laboratory.location!=null ? laboratory.location : "S/U"}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
            <OBtnCardComp
                icon="mdi-delete"
                style="margin:2px"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import {
    OBtnCardComp
} from "../organisms";
export default {
    components: {
        OBtnCardComp,
        TextHighlight
    },
    props: {
        mobile: Boolean,
        laboratory: Object,
        search: {
            type: String,
            default: ''
        }
    }
}
</script>