var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-form-select-product"},[_c('div',{staticClass:"s-form-select-product__container"},[_c('div',{staticClass:"s-form-select-product__form"},[_c('v-form',{ref:"formproduct",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.product.categorie_user == '0' || _vm.isValidDiscount)?_c('div',[_c('MTextFieldComp',{ref:"pricesale",attrs:{"label":"Precio Venta","clearable":false,"rules":[
                            value => !this.mRuleEmptyField(value) || 'Requerido',
                            value => this.mRuleNumberDecimal(value) || 'No número',
                        ]},on:{"click":function($event){return _vm.eValidForm()}},model:{value:(_vm.product.select_sale_price),callback:function ($$v) {_vm.$set(_vm.product, "select_sale_price", $$v)},expression:"product.select_sale_price"}})],1):_vm._e(),_c('MTextFieldComp',{ref:"quantity",attrs:{"label":"Cantidad","clearable":false,"rules":[
                        value => !this.mRuleEmptyField(value) || 'Requerido',
                        value => this.mRuleNumberInteger(value) || 'No número',
                        value => !this.mRuleExceededNumber(value, _vm.product.quantity) || 'Error Cant.'
                    ]},on:{"click":function($event){return _vm.eValidForm()}},model:{value:(_vm.product.select_quantity),callback:function ($$v) {_vm.$set(_vm.product, "select_quantity", $$v)},expression:"product.select_quantity"}}),_c('div',[_c('MBtnNormalComp',{attrs:{"small":false,"xSmall":true},on:{"click":function($event){return _vm.eValidForm()}}},[_vm._v(" "+_vm._s(_vm.indexProductDetail==-1 ? 'AGREGAR' : 'EDITAR')+" ")]),_c('MBtnNormalComp',{staticClass:"ml-1",attrs:{"small":false,"xSmall":true},on:{"click":function($event){return _vm.$emit('clickdetail', _vm.product)}}},[_vm._v(" DETALLE ")])],1)],1)],1),_c('div',{staticClass:"ml-4"},[_c('h1',{staticClass:"app-title"},[_vm._v(_vm._s(_vm.product.product))]),_c('v-divider'),_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"app-title"},[_vm._v("Lab.:")]),_c('h1',{staticClass:"app-subtitle ml-1"},[_vm._v(_vm._s(_vm.product.laboratory.laboratory))])]),_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"app-title"},[_vm._v("Ubicación:")]),_c('h1',{staticClass:"app-subtitle ml-1"},[_vm._v(_vm._s(_vm.product.location))])]),(_vm.product.categorie_user=='0')?_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"app-title"},[_vm._v("P.C.:")]),_c('h1',{staticClass:"app-subtitle ml-1"},[_vm._v(" "+_vm._s(_vm.getPurchasePrices())+" ")])]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"app-title"},[_vm._v("P.V.:")]),_c('h1',{staticClass:"app-subtitle ml-1"},[_vm._v(" "+_vm._s(`${_vm.product.sale_price.toFixed(2)} Bs.`))])]),_c('div',{staticClass:"d-flex align-center"},[_c('h1',{staticClass:"app-title"},[_vm._v("Cantidad:")]),_c('h1',{staticClass:"app-subtitle ml-1"},[_vm._v(_vm._s(_vm.product.quantity))]),_c('div',{staticClass:"ml-1",staticStyle:{"margin-top":"-2px"}},[_c('div',{class:_vm.product.quantity > 0
                            ? 'client__ornament-enabled'
                            : 'client__ornament-disabled'})])])],1)]),_c('div',{staticClass:"s-form-select-product__lots"},[_c('h1',{staticClass:"app-title text-center mt-1 mb-1"},[_vm._v(" LOTES ")]),_c('v-divider'),_c('div',{staticClass:"pa-2 pt-1"},[_vm._l((_vm.product.inventories),function(inventory){return _c('div',{key:inventory.id},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"mt-1",attrs:{"elevation":"0","color":hover ? '#e8e8e8' : ''}},[_c('div',{staticClass:"d-flex align-center"},[_c('h1',{staticClass:"app-sale-subtitle mr-1"},[_vm._v(" "+_vm._s(_vm.mDateFormat(inventory.expiration_date))+" ")]),_c('div',{class:inventory.expired
                                    ? 'client__ornament-disabled'
                                    : 'client__ornament-enabled'}),_c('h1',{staticClass:"app-sale-title ml-1"},[_vm._v(" "+_vm._s(`(${inventory.quantity})`)+" ")])]),_c('div',{staticClass:"s-form-select-product--line"})])]}}],null,true)})],1)}),(_vm.product.inventories.length == 0)?_c('h1',{staticClass:"app-sale-subtitle text-center"},[_vm._v("Sin Inventario")]):_vm._e()],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }