export default {
    data: () => ({
        dialogLoaderOperation: false,
        smsLoaderOperation: 'Realizando Operación',
        //sms loader data
        smsLoaderData: '',
        dialogLoaderData: true,
        //sms confirm
        dialogConfirm: false,
        smsConfirm: [],
        //sms error
        noAccess: {
            goLogin: false,
            withoutAutorization: false
        },
        dialogError: false,
        smsError: [],
        actionError: true,
        //sms correct
        smsAction: [],
        frameCorrect: false,
        colorMessage: 'success',
        timeSucess: 3500,
        //data responsive
        mobile: false,
        //pagination
        currentPage: 0,
        lastPage: 0,
        total: 0
    }),
    methods: {
        mDataGeneralFrameTimeReset() {
            clearTimeout(this.timeSucess);
            this.frameCorrect = false;
        },
        mDataGeneralShowSmsAction(message, colorSms) {
            this.mDataGeneralFrameTimeReset();
            setTimeout(() => {
                this.smsAction = message;
                this.frameCorrect = true;
                this.colorMessage = colorSms;
                this.timeSucess = 3500;
                setTimeout(() => {
                    this.frameCorrect = false;
                }, 3500);
            }, 150);
        },
        mDataGeneralSearchForPage(page, isInteger) {
            if (!isInteger) {
                const smsError = ["Página Erronea", "El N° debe ser un número entero"];
                this.mDataGeneralShowSmsAction(smsError, "error");
                return false;
            } else {
                if (parseInt(page) > 0 && parseInt(page) <= this.lastPage) {
                    this.currentPage = page;
                    return true;
                } else {
                    const smsErrorNumber = this.lastPage === 1
                        ? "Solo tiene la página 1"
                        : `El N° debe estar entre 1-${this.lastPage}`;
                    const smsError = ["Página Erronea", smsErrorNumber];
                    this.mDataGeneralShowSmsAction(smsError, "error");
                    return false;
                }
            }
        },
        mDataGeneralConfirmForm(isValidForm, smsConfirm) {
            if (isValidForm) {
                this.smsConfirm = smsConfirm;
                this.dialogConfirm = true;
            } else {
                this.smsError = ['Corrija todos los errores con rojo del formulario porfavor'];
                this.actionError = true;
                this.dialogError = true;
            }
        },
        mDataGeneralActionKeyboard(keyCode) {
            if (this.dialogConfirm) {
                if (keyCode == 78) {
                    this.dialogConfirm = false;
                    return 0;
                }
                if (keyCode == 83) return 1;
                return 3;
            }
            if (this.dialogError) {
                if (keyCode == 65) {
                    this.dialogError = false;
                    return 2;
                }
            }
            return 3;
        },
        mDataGeneralActionWithOutAutorization(goRedirect = true) {
            if (goRedirect) {
                if (this.noAccess.goLogin) {
                    localStorage.removeItem("nazaret");
                    this.$router.replace('/login');
                } else this.$router.replace('/home');
            } else this.dialogError = false;
        }
    }
}