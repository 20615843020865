<template>
    <div>
        <div class="pa-2 d-flex align-center">
            <OBtnToolTipHeaderComp
                v-if="!mobile"
                :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                smsToolTip="Ocultar Menú"
                @click="$emit('clickmini')"
            />
            <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                BIENVENIDO
            </h1>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <div class="d-flex justify-center pa-2">
            <v-card
                width="600"
                class="pa-2"
            >
                <div class="d-flex">
                    <div class="client__ornament-enabled mt-1 mr-1">
                    </div>
                    <h1 class="app-subtitle">
                        Utilice con responsabilidad el sistema.
                    </h1>
                </div>
                <div class="d-flex mt-1">
                    <div class="client__ornament-enabled mt-1 mr-1">
                    </div>
                    <h1 class="app-subtitle">
                        Todas las operaciones realizadas quedaran registradas en la
                        base de datos
                    </h1>
                </div>
                <div class="d-flex mt-1">
                    <div class="client__ornament-enabled mt-1 mr-1">
                    </div>
                    <h1 class="app-subtitle">
                        Porfavor lea todos los mensajes de confirmación antes de realizar
                        una acción en el sistema.
                    </h1>
                </div>
                <div class="d-flex mt-1">
                    <div class="client__ornament-enabled mt-1 mr-1">
                    </div>
                    <h1 class="app-subtitle">
                        Cierre sesión una vez termine de realizar operaciones en el sistema.
                    </h1>
                </div>
            </v-card>
        </div>
    </div>
</template>
<script>
import {
    OBtnToolTipHeaderComp
} from "../../components/organisms";
export default {
    components: {
        OBtnToolTipHeaderComp
    },
    props: {
        mini: Boolean
    },
    data: () => ({
        mobile: false
    })
}
</script>