<template>
    <div class="app-private__btn-floting">
        <v-btn
            class="fondo-btn-cabecera"
            fab
            dark
            :ripple="false"
            :width="sizeBtn"
            :max-width="sizeBtn"
            :min-width="sizeBtn"
            :height="sizeBtn"
            :min-height="sizeBtn"
            :max-height="sizeBtn"
            @click="$emit('click')"
        >
            <v-icon small class="s-menu-pc__list-icon">
                mdi-arrow-up-bold</v-icon>
        </v-btn>
    </div>
</template>
<script>
export default {
    data: () => ({
        sizeBtn: '32'
    })
}
</script>