<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Producto</h1>
                    </th>
                    <th width="20">
                        <h1 class="app-title-dark text-center">E</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Tipo Producto</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Laboratorio</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">N° Lotes</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Cant. Unid.</h1>
                    </th>
                    <th :width="isOrder ? '120px' : '100px'">
                        <h1 class="app-title-dark text-center">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(product, index) in productsList"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <text-highlight
                            class="app-subtitle"
                            :queries="search"
                        >
                            {{product.product}}
                        </text-highlight>
                    </td>
                    <td>
                        <div
                            :class="product.disabled==1
                                ? 'client__ornament-disabled'
                                : 'client__ornament-enabled'"
                        >
                        </div>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.type_product.type_product}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.laboratory.laboratory}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.inventories.length}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <div
                                :class="getClassInventory(product.quantity)"
                                style="margin-top:-2px;"
                            >
                            </div>
                            <h1 class="app-subtitle ml-2">
                                {{product.quantity}}
                            </h1>
                        </div>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            :smsToolTip="`Ver detalle de: ${product.product}`"
                            icon="mdi-list-box"
                            @click="$emit('clickdetail', product)"
                        />
                        <OBtnToolTipTableComp
                            :smsToolTip="`Ver inventario de: ${product.product}`"
                            icon="mdi-medication"
                            @click="$emit('clickinventories', product)"
                        />
                        <OBtnToolTipTableComp
                            v-if="isOrder"
                            :smsToolTip="`Seleccionar: ${product.product}`"
                            icon="mdi-check-bold"
                            @click="$emit('clickcheck', product)"
                        />
                    </td>
                </tr>
                <tr v-if="productsList.length === 0">
                    <td colspan="9">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import { OBtnToolTipTableComp } from "../organisms";
export default {
    props: {
        productsList: Array,
        search: {
            type: String,
            default: ''
        },
        setting: Object,
        isOrder: Boolean
    },
    components: {
        OBtnToolTipTableComp,
        TextHighlight
    },
    methods: {
        getClassInventory(quantity) {
            if (quantity >= 0 && quantity <= this.setting.low_inventory)
                return 'client__ornament-disabled';
            if (quantity > this.setting.low_inventory && quantity <= this.setting.regular_inventory)
                return 'client__ornament-warning';
            return 'client__ornament-enabled';
        }
    }
}
</script>