import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';
class Expense {
    constructor (
        expense = null,
        description = null,
        fk_cash_register  = null
    )
    {
        this.expense = expense;
        this.description = description;
        this.fk_cash_register = fk_cash_register;
    }
    static async getExpenses(fkCashRegister) {
        const response = await axios.get(
            `${apiUrls.EXPENSE}/list?fk_cash_register=${fkCashRegister}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addExpense(parameters) {
        const response = await axios.post(
            `${apiUrls.EXPENSE}/add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editExpense(parameters) {
        const response = await axios.put(
            `${apiUrls.EXPENSE}/edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getExpensesAdmin(page, dateOne, dateTwo, idUser, idCashRegister) {
        const response = await axios.get(
            `${apiUrls.EXPENSE}/list-admin?page=${page}&date_one=${dateOne}&date_two=${dateTwo}&fk_user=${idUser}&fk_cash_register=${idCashRegister}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Expense;