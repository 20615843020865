<template>
    <div>
        <div class="pa-2 d-flex align-center">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    {{`INFORMACIONES (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    icono="mdi-plus"
                    class="mr-1"
                    smsToolTip="Registrar información"
                    @click="eOpenFormInfo()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    smsToolTip="Volver a informaciones"
                    @click="eBackTableList()"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="informationsList.length==0 && !dialogLoaderData && mobile"
                />
                <STableInformationsComp
                    v-if="!dialogLoaderData && !mobile"
                    :informationsList="informationsList"
                    @clickedit="eOpenFormEdit"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderData && mobile"
                >
                    <SCardInformationComp
                        class="ma-2"
                        v-for="information in informationsList"
                        :key="information.id"
                        :information="information"
                        @clickedit="eOpenFormEdit(information)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center mt-3 pl-2 pr-2 pb-2">
                    <SFormInformationComp
                        ref="forminformationcomp"
                        :dataInformation="dataInformation"
                        :mobile="mobile"
                        :indexInformation="indexInformation"
                        @saveform="eConfirmForm"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="dialogConfirm=false"
            @clickyes="ePerformDatabaseAction()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OLoaderDataComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp
} from "../../components/organisms";
import {
    STableInformationsComp,
    SFormInformationComp,
    SCardInformationComp
} from "../../components/species";
import {
    Information
} from "../../models";
import {
    dataGeneralCompsMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
export default {
    mixins: [
        dataGeneralCompsMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    components: {
        OBtnToolTipHeaderComp,
        OLoaderDataComp,
        MNoDataComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        STableInformationsComp,
        SFormInformationComp,
        SCardInformationComp
    },
    props: {
        mini: Boolean
    },
    data: () => ({
        tab: 0,
        informationsList: [],
        dataInformation: new Information,
        indexInformation: -1,
        idCashRegister: 0
    }),
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else {
                if (this.idCashRegister === 0)
                    this.$router.replace('/home');
                else this.dialogError = false;
            }
        },
        handleKeyUpInfo(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 1) this.ePerformDatabaseAction();
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getInformations() {
            this.dialogLoaderData = true;
            try {
                const response = await Information.getInformations(this.idCashRegister);
                this.informationsList = response;
                this.total = response.length;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eBackTableList() {
            if (this.tab !== 0) {
                this.tab = 0;
                this.indexInformation = -1;
                this.dataInformation = new Information;
                this.$refs.forminformationcomp.resetForm();
                this.dialogLoaderOperation = false;
            }
        },
        eConfirmForm(isValidForm) {
            this.dataInformation.fk_cash_register = 1;
            const smsConfirm = this.indexInformation === -1
                ? [`¿Está seguro(a) de registrar la información?`]
                : [`¿Está seguro(a) de editar la información?`];
            this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
        },
        async addInformation() {
            try {
                const response = await Information.addInformation(this.dataInformation);
                this.informationsList.unshift(response);
                this.total += 1;
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se registró la información"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async editInformation() {
            try {
                const response = await Information.editInformation(this.dataInformation);
                Object.assign(this.informationsList[this.indexInformation], response);
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se editó la información"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (this.indexInformation === -1)
                this.addInformation();
            else this.editInformation();
        },
        eOpenFormEdit(information) {
            this.indexInformation = this.informationsList.indexOf(information);
            this.dataInformation = Object.assign({}, information);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
                setTimeout(() => {
                    this.$refs.forminformationcomp.activateFocus();
                }, 150);
            }, time);
        },
        validateOptions() {
            if (JSON.parse(localStorage.getItem("device"))) {
                this.idCashRegister = JSON.parse(localStorage.getItem("device")).id_cash_register;
                this.getInformations();
            } else {
                this.smsError = [
                    `Este dispositivo no fue habilitado para funcionar como caja registradora de ventas`,
                    `La información que quiere acceder puede revisarse en la opción "CONTROL VENTAS ADMIN"`
                ];
                this.actionError = true;
                this.dialogError = true;
            }
        },
        eOpenFormInfo() {
            this.tab = 1;
            setTimeout(() => {
                this.$refs.forminformationcomp.activateFocus();
            }, 150);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            window.addEventListener('keyup', this.handleKeyUpInfo);
            this.validateOptions();
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpInfo);
    }
}
</script>