<template>
    <div>
        <div class="client__header pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    {{`INVENTARIO (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center client__header-block-second">
                <OSearchHeaderComp
                    label="Buscar"
                    v-model="search"
                    class="mr-1"
                    @entersearch="eSearchProducts()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-microsoft-excel"
                    class="mr-1"
                    smsToolTip="Generar reporte"
                    @click="eGetDataInventory()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    smsToolTip="Volver a clientes"
                    @click="tab=0"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap">
                    <div class="product__group-search d-flex justify-center pt-2 pl-2 pr-2">
                        <div class="product__search" style="margin-right:2px">
                            <MSelectComp
                                label="Inventario"
                                :items="levelItems"
                                itemValue="value"
                                itemText="name"
                                :hideDetails="true"
                                v-model="valueQuantity"
                                @change="eChangeQuantity"
                            />
                        </div>
                        <div class="product__search" style="margin-left:2px">
                            <div
                                class="product__search-laboratories d-flex align-center pl-3"
                                @click="eOpenSearchLaboratory()"
                            >
                                <h2>Laboratorio</h2>
                                <h1 class="app-subtitle">
                                    {{laboratory.id==0 ? "Todos" : laboratory.name}}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="product__group-search d-flex justify-center pt-2 pl-2 pr-2">
                        <div class="product__search" style="margin-right:2px">
                            <MSelectComp
                                label="Tipo Producto"
                                :items="typesProducts"
                                itemValue="id"
                                itemText="type_product"
                                :hideDetails="true"
                                v-model="idTypeProduct"
                                @change="eChangeTypeProduct"
                            />
                        </div>
                        <div class="product__search" style="margin-left:2px">
                            <MSelectComp
                                label="Estado"
                                :items="itemsSearchState"
                                itemValue="value"
                                itemText="name"
                                :hideDetails="true"
                                v-model="searchState"
                                @change="eChangeState"
                            />
                        </div>
                    </div>
                </div>
                <v-divider class="mt-2"></v-divider>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="productsList.length==0 && !dialogLoaderData && mobile"
                />
                <STableInventoriesComp
                    v-if="!dialogLoaderData && !mobile"
                    :productsList="productsList"
                    :search="searchFilter"
                    :setting="setting"
                    @clickdetail="eShowDetail"
                    @clickinventories="eShowInventories"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderData && mobile"
                >
                    <SCardInventoryComp
                        class="ma-2"
                        :search="searchFilter"
                        v-for="product in productsList"
                        :key="product.id"
                        :product="product"
                        :setting="setting"
                        @clickdetail="eShowDetail(product)"
                        @clickinventories="eShowInventories(product)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="mobile && !dialogLoaderData && productsList.length>0"
                ></v-divider>
                <SPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderData && productsList.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="lots.length==0"
                />
                <div class="d-flex justify-center flex-wrap">
                    <SCardLoteInventoryComp
                        class="ma-2"
                        v-for="lot in lots"
                        :key="lot.id"
                        :lot="lot"
                        @clickpurchase="eShowDetailPurchase(lot)"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <SDetailProductComp
            :product="product"
            :value="dialogDetailProduct"
            @clickclose="dialogDetailProduct=false"
        />
        <SDialogSearchLaboratoryComp
            :valueDialog="dialogLaboratories"
            v-model="laboratory.search"
            :filterData="true"
            ref="dialoglaboratory"
            :laboratoriesList="laboratoriesList"
            :searchLaboratory="searchFilterLaboratory"
            :dialogLoaderData="laboratory.dialogLoaderData"
            @entersearch="eSearchLaboratories()"
            @clickclose="eCloseSearchLaboratory()"
            @clicklaboratory="eSelectLaboratory"
        />
        <SDetailPurchaseLotComp
            :value="dialogLot"
            :lot="lot"
            @clickclose="dialogLot=false"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MNoDataComp,
    MSelectComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSearchHeaderComp,
    OLoaderDataComp,
    OSmsErrorComp,
    OSmsActionComp
} from "../../components/organisms";
import {
    STableInventoriesComp,
    SCardInventoryComp,
    SPaginationComp,
    SDetailProductComp,
    SDialogSearchLaboratoryComp,
    SCardLoteInventoryComp,
    SDetailPurchaseLotComp
} from "../../components/species";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    animationScrollMixin,
    rulesMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import {
    Product,
    TypeProduct,
    Setting,
    Laboratory
} from "../../models";
import XLSX from "xlsx-js-style";
export default {
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        animationScrollMixin,
        rulesMixin,
        userMixin,
        datesMixin
    ],
    props: {
        mini: Boolean
    },
    components: {
        OBtnToolTipHeaderComp,
        OSearchHeaderComp,
        MNoDataComp,
        OLoaderDataComp,
        STableInventoriesComp,
        SCardInventoryComp,
        SPaginationComp,
        MSelectComp,
        SDetailProductComp,
        SDialogSearchLaboratoryComp,
        SCardLoteInventoryComp,
        SDetailPurchaseLotComp,
        OSmsErrorComp,
        OSmsActionComp
    },
    data: () => ({
        tab: 0,
        search: "",
        product: {},
        productsList: [],
        itemsSearchState: [
            { name: "Todos", value: 2 },
            { name: "Habilitados", value: 0 },
            { name: "Deshabilitados", value: 1 },
        ],
        searchState: 2,
        typesProducts: [{ id: 0, type_product: "Todos"}],
        idTypeProduct: 0,
        laboratoriesList: [{ id:0, laboratory: "Todos" }],
        laboratory: {
            id: 0,
            name: "",
            search: "",
            dialogLoaderData: false
        },
        dialogLaboratories: false,
        levelItems: [],
        setting: {},
        valueQuantity: "e",
        dialogDetailProduct: false,
        lots: [],
        lot: {},
        dialogLot: false
    }),
    computed: {
        searchFilter: function() {
            return this.search == null ? "" : this.search;
        },
        searchFilterLaboratory: function() {
            return this.laboratory.search == null ? "" : this.laboratory.search;
        }
    },
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else this.dialogError = false;
        },
        handleKeyUpInventorie(event) {
            if (!this.mobile) {
                if (this.dialogError) {
                    if (event.keyCode == 65) this.dialogError = false;
                }
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getRequest() {
            try {
                const products = Product.getInventories(1, "", 2, 0, 0, "e");
                const typesProducts = TypeProduct.getTypesProducts();
                const setting = Setting.getSetting();
                const responses = await Promise.all([products, typesProducts, setting]);
                this.productsList = responses[0].data;
                this.lastPage = responses[0].last_page;
                this.currentPage = responses[0].current_page;
                this.total = responses[0].total;
                responses[1].forEach(typeProduct => {
                    this.typesProducts.push(typeProduct);
                });
                this.setting = responses[2];
                this.levelItems = [
                    { name: "Todos", value: "e" },
                    { name: `Bajo (0-${responses[2].low_inventory})`, value: "s" },
                    { name: `Moderado (${(responses[2].low_inventory+1)}-${responses[2].regular_inventory})`, value: "m" },
                    { name: `Alto (${(responses[2].low_inventory+1)}-∞)`, value: "l"}
                ];
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async getProducts(page, search, searchState, idTypeProduct, idLaboratory, typeInventory) {
            this.dialogLoaderData = true;
            try {
                const response = await Product.getInventories(page, search, searchState, idTypeProduct, idLaboratory, typeInventory);
                this.productsList = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eChangeQuantity(inventory) {
            this.getProducts(1, this.search, this.searchState, this.idTypeProduct, this.laboratory.id, inventory);
        },
        eChangeTypeProduct(typeProduct) {
            this.getProducts(1, this.search, this.searchState, typeProduct, this.laboratory.id, this.valueQuantity);
        },
        eChangeState(state) {
            this.getProducts(1, this.search, state, this.idTypeProduct, this.laboratory.id, this.valueQuantity);
        },
        //search laboratory
        eOpenSearchLaboratory() {
            this.dialogLaboratories = true;
            setTimeout(() => {
                this.$refs.dialoglaboratory.activateFocus();
            }, 150);
        },
        async eSearchLaboratories() {
            this.laboratory.dialogLoaderData = true;
            if (this.mRuleEmptyField(this.laboratory.search)) this.laboratory.search = "";
            this.laboratoriesList = [{ id: 0, laboratory: "Todos" }];
            try {
                const response = await Laboratory.getLaboratories(1, this.laboratory.search);
                response.data.forEach(laboratory => {
                    this.laboratoriesList.push(laboratory);
                });
                this.laboratory.dialogLoaderData = false;
            } catch(error) {
                this.dialogLaboratories = false;
                this.processErrorServer(error);
            }
        },
        eCloseSearchLaboratory() {
            this.laboratory.search = "";
            this.laboratoriesList = [{ id: 0, laboratory: "Todos" }];
            this.dialogLaboratories = false;
        },
        eSelectLaboratory(laboratory) {
            this.laboratory.id = laboratory.id;
            this.laboratory.name = laboratory.laboratory;
            this.eCloseSearchLaboratory();
            setTimeout(() => {
                this.getProducts(1, this.search, this.searchState, this.idTypeProduct, laboratory.id, this.valueQuantity);
            }, 150);
        },
        //
        eShowDetail(product) {
            this.product = product;
            this.dialogDetailProduct = true;
        },
        eShowInventories(product) {
            this.lots = product.inventories;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eShowDetailPurchase(lot) {
            if (lot.id_purchase !== 0) {
                this.lot = lot;
                this.dialogLot = true;
            } else this.mDataGeneralShowSmsAction(
                ["Sin Registro de Compra", "El lote no fue registrado por compras"], "error");
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getProducts(this.currentPage, this.search, this.searchState, this.idTypeProduct, this.laboratory.id, this.valueQuantity);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getProducts(page, this.search, this.searchState, this.idTypeProduct, this.laboratory.id, this.valueQuantity);
                }, time);
            }
        },
        eSearchProducts() {
            if (this.mRuleEmptyField(this.search)) this.search = "";
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 0;
                this.getProducts(1, this.search, this.searchState, this.idTypeProduct, this.laboratory.id, this.valueQuantity);
            }, time);
        },
        async eGetDataInventory() {
            this.dialogLoaderOperation = true;
            try {
                const response = await Product.getTotalInventories();
                this.generateExcel(response);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        generateExcel(products) {
            let totalQuantity = 0;
            let totalCost = 0;
            const nameColums = [
                'N°', 'Producto', 'Linea', 'Cantidad', 'Precio Unidad (Bs.)'];
            var columns = [];

            nameColums.forEach(column => {
                columns.push({
                    v: column,
                    s: {
                        border: {
                            top: { style: "thin", color: { rgb: "000000" } },
                            left: { style: "thin", color: { rgb: "000000" } },
                            bottom: { style: "thin", color: { rgb: "000000" } },
                            right: { style: "thin", color: { rgb: "000000" } }
                        },
                        alignment: { horizontal: "center", vertical: "center" }
                    }
                })
            });

            var finalList = [columns];
            products.forEach((product, index) => {
                totalQuantity += product.quantity;
                totalCost += product.sale_price;
                finalList.push([
                    {
                        v: (index + 1),
                        s: {
                            alignment: { horizontal: "right"},
                            border: {
                                top: { style: "thin", color: { rgb: "000000" } },
                                left: { style: "thin", color: { rgb: "000000" } },
                                bottom: { style: "thin", color: { rgb: "000000" } },
                                right: { style: "thin", color: { rgb: "000000" } }
                            }
                        }
                    },
                    {
                        v: product.product,
                        s: {
                            alignment: { horizontal: "right"},
                            border: {
                                top: { style: "thin", color: { rgb: "000000" } },
                                left: { style: "thin", color: { rgb: "000000" } },
                                bottom: { style: "thin", color: { rgb: "000000" } },
                                right: { style: "thin", color: { rgb: "000000" } }
                            }
                        }
                    },
                    {
                        v: product.laboratory,
                        s: {
                            alignment: { horizontal: "right"},
                            border: {
                                top: { style: "thin", color: { rgb: "000000" } },
                                left: { style: "thin", color: { rgb: "000000" } },
                                bottom: { style: "thin", color: { rgb: "000000" } },
                                right: { style: "thin", color: { rgb: "000000" } }
                            }
                        }
                    },
                    {
                        v: product.quantity,
                        t: 'n',
                        s: {
                            alignment: { horizontal: "right"},
                            border: {
                                top: { style: "thin", color: { rgb: "000000" } },
                                left: { style: "thin", color: { rgb: "000000" } },
                                bottom: { style: "thin", color: { rgb: "000000" } },
                                right: { style: "thin", color: { rgb: "000000" } }
                            }
                        }
                    },
                    {
                        v: product.sale_price.toFixed(2),
                        t: 'n',
                        s: {
                            alignment: { horizontal: "right"},
                            border: {
                                top: { style: "thin", color: { rgb: "000000" } },
                                left: { style: "thin", color: { rgb: "000000" } },
                                bottom: { style: "thin", color: { rgb: "000000" } },
                                right: { style: "thin", color: { rgb: "000000" } }
                            }
                        }
                    }
                ]);
            });
            
            finalList.push([
                {}, {}, {},
                {
                    v: totalQuantity,
                    t: 'n',
                    s: {
                        alignment: { horizontal: "right"},
                        border: {
                            top: { style: "thin", color: { rgb: "000000" } },
                            left: { style: "thin", color: { rgb: "000000" } },
                            bottom: { style: "thin", color: { rgb: "000000" } },
                            right: { style: "thin", color: { rgb: "000000" } }
                        }
                    }
                },
                {
                    v: totalCost.toFixed(2),
                    t: 'n',
                    s: {
                        alignment: { horizontal: "right"},
                        border: {
                            top: { style: "thin", color: { rgb: "000000" } },
                            left: { style: "thin", color: { rgb: "000000" } },
                            bottom: { style: "thin", color: { rgb: "000000" } },
                            right: { style: "thin", color: { rgb: "000000" } }
                        }
                    }
                }
            ]);

            const wb = XLSX.utils.book_new();
            var ws = XLSX.utils.aoa_to_sheet(finalList);
            ws["!cols"] = [
                { wch: 8 }, { wch: 35 }, { wch: 35 },
                { wch: 15 }, { wch: 15 }
            ];
            XLSX.utils.book_append_sheet(wb, ws, "Inventario");
            if (this.dateOne !== this.dateTwo)
                var nameFileExcel = `Reporte_inventario`;
            else var nameFileExcel = `Reporte_inventario`;
            XLSX.writeFile(wb, `${nameFileExcel}.xlsx`, { bookType: 'xlsx', type: 'buffer' });
            this.dialogLoaderOperation = false;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpInventorie);
                this.getRequest();
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpInventorie);
    }
}
</script>