import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';

class Inventory {
    constructor(
        quantity = null,
        expiration_date = null,
        purchase_price = null,
        fk_product = null
    ) {
        this.quantity = quantity;
        this.expiration_date = expiration_date;
        this.purchase_price = purchase_price;
        this.fk_product = fk_product;
    }
    static async getInventory(page, search, dateExpiration, dateOne, dateTwo, idProduct) {
        const response =  await axios.get(
            `${apiUrls.INVENTORY}/entries?page=${page}&search=${search}&date_expiration=${dateExpiration}&date_one=${dateOne}&date_two=${dateTwo}&fk_product=${idProduct}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deleteInventory(parameters) {
        const response =  await axios.post(
            `${apiUrls.INVENTORY}/unsubscribe`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getExpiringInventory(page, dateOne, dateTwo, search, idProduct) {
        const response =  await axios.get(
            `${apiUrls.INVENTORY}/expiring-inventory?page=${page}&date_one=${dateOne}&date_two=${dateTwo}&fk_product=${idProduct}&search=${search}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async updateInventory(parameters) {
        const response =  await axios.put(
            `${apiUrls.INVENTORY}/update`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addLot(parameters) {
        const response =  await axios.post(
            `${apiUrls.INVENTORY}/add-lot`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async lots(page, idProduct, dateOne, dateTwo) {
        const response =  await axios.get(
            `${apiUrls.INVENTORY}/lots?page=${page}&id_product=${idProduct}&date_one=${dateOne}&date_two=${dateTwo}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editLot(parameters) {
        const response =  await axios.put(
            `${apiUrls.INVENTORY}/edit-lot`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Inventory;