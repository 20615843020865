var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-form-product"},[_c('h1',{staticClass:"app-title text-center mb-2"},[_vm._v(" "+_vm._s(_vm.indexProvider===-1 ? 'REGISTRAR' : 'EDITAR')+" ")]),_c('v-form',{ref:"formprovider",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Proveedor:"}},[_c('MTextFieldComp',{ref:"provider",attrs:{"label":_vm.mobile ? 'Proveedor' : '',"counter":50,"rules":[
                    value => !this.mRuleEmptyField(value) || 'El proveedor es requerido',
                    value => (`${value}`).length<=50 || 'El proveedor, no debe exceder los 50 dígitos'
                ]},model:{value:(_vm.dataProvider.provider),callback:function ($$v) {_vm.$set(_vm.dataProvider, "provider", $$v)},expression:"dataProvider.provider"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Celular:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Celular' : '',"counter":10,"rules":[
                    value => (`${value}`).length<=10 || 'El celular no debe exceder los 10 dígitos',
                    value => !_vm.eIsValidPhone(value) || 'El celular solo debe contener números'
                ]},model:{value:(_vm.dataProvider.phone),callback:function ($$v) {_vm.$set(_vm.dataProvider, "phone", $$v)},expression:"dataProvider.phone"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Dirección:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Dirección' : '',"counter":50,"rules":[
                    value => (`${value}`).length<=50 || 'La dirección no debe exceder los 50 dígitos'
                ]},model:{value:(_vm.dataProvider.address),callback:function ($$v) {_vm.$set(_vm.dataProvider, "address", $$v)},expression:"dataProvider.address"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Correo:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Correo' : '',"counter":50,"rules":[
                    value => (`${value}`).length<=50 || 'El correo no debe exceder los 50 dígitos'
                ]},model:{value:(_vm.dataProvider.e_mail),callback:function ($$v) {_vm.$set(_vm.dataProvider, "e_mail", $$v)},expression:"dataProvider.e_mail"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Descripción:"}},[_c('MTextAreaComp',{attrs:{"label":_vm.mobile ? 'Descripción' : '',"counter":200,"rules":[
                    value => (`${value}`).length<=200 || 'La descripción no debe exceder los 200 dígitos'
                ]},model:{value:(_vm.dataProvider.description),callback:function ($$v) {_vm.$set(_vm.dataProvider, "description", $$v)},expression:"dataProvider.description"}})],1)],1),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('MBtnNormalComp',{on:{"click":function($event){return _vm.eValidForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }