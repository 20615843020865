<template>
    <div class="s-sheet-print-purchase">
        <div class="d-flex align-center">
            <h1>FARMACIA NAZARET</h1>
            <v-spacer></v-spacer>
            <img
                src="/imgs/logo.png"
            />
        </div>
        <div v-if="purchase.id!=undefined">
            <OInfoPurchaseComp
                :purchase="purchase"
            />
            <table class="mt-2">
                <thead>
                    <th width="50px">
                        <h1 class="app-sale-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-sale-title-dark text-left">Product</h1>
                    </th>
                    <th>
                        <h1 class="app-sale-title-dark text-left">N° Lote</h1>
                    </th>
                    <th>
                        <h1 class="app-sale-title-dark text-left">Fecha Exp.</h1>
                    </th>
                    <th>
                        <h1 class="app-sale-title-dark text-left">P.U.</h1>
                    </th>
                    <th width="60">
                        <h1 class="app-sale-title-dark text-left">Cant.</h1>
                    </th>
                    <th>
                        <h1 class="app-sale-title-dark text-left">Total</h1>
                    </th>
                    <th>
                        <h1 class="app-sale-title-dark text-left">Dcto. Cial.</h1>
                    </th>
                    <th>
                        <h1 class="app-sale-title-dark text-left">Otro Dcto.</h1>
                    </th>
                    <th>
                        <h1 class="app-sale-title-dark text-left">Total con Dcto.</h1>
                    </th>
                </thead>
                <tbody>
                    <tr
                        v-for="(productPurchase, index) in purchase.products"
                        :key="productPurchase.id"
                    >
                        <td>
                            <h1 class="app-sale-subtitle text-right">
                                {{(index+1)}}
                            </h1>
                        </td>
                        <td>
                            <h1 class="app-sale-subtitle">
                                {{productPurchase.product}}
                            </h1>
                        </td>
                        <td>
                            <h1 class="app-sale-subtitle">
                                {{productPurchase.pivot.lot_number}}
                            </h1>
                        </td>
                        <td>
                            <h1 class="app-sale-subtitle">
                                {{mDateFormat(productPurchase.pivot.expiration_date)}}
                            </h1>
                        </td>
                        <td>
                            <h1 class="app-sale-subtitle text-right">
                                {{productPurchase.pivot.unit_price.toFixed(2)}}
                            </h1>
                        </td>
                        <td>
                            <h1 class="app-sale-subtitle text-right">
                                {{productPurchase.pivot.quantity}}
                            </h1>
                        </td>
                        <td>
                            <h1 class="app-sale-subtitle text-right">
                                {{mPTotalPrice(productPurchase.pivot).toFixed(2)}}
                            </h1>
                        </td>
                        <td>
                            <h1 class="app-sale-subtitle text-right">
                                {{productPurchase.pivot.commercial_discount.toFixed(2)}}
                            </h1>
                        </td>
                        <td>
                            <h1 class="app-sale-subtitle text-right">
                                {{productPurchase.pivot.other_discount.toFixed(2)}}
                            </h1>
                        </td>
                        <td>
                            <h1 class="app-sale-subtitle text-right">
                                {{mPTotalPriceWithDiscount(productPurchase.pivot).toFixed(2)}}
                            </h1>
                        </td>
                    </tr>
                </tbody>
            </table>
            <OTotalesDetailPurchaseComp
                class="pa-2 pt-2"
                :detailProducts="purchase.products"
                :dataPurchase="purchase"
                :isDataBase="true"
            />
        </div>
    </div>
</template>
<script>
import {
    OInfoPurchaseComp,
    OTotalesDetailPurchaseComp
} from "../organisms";
import {
    datesMixin,
    purchaseMixin
} from "../../mixins";
export default {
    mixins: [
        datesMixin,
        purchaseMixin
    ],
    components: {
        OInfoPurchaseComp,
        OTotalesDetailPurchaseComp
    },
    props: {
        purchase: Object
    }
}
</script>