import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';

class Information {
    constructor (
        information = null,
        fk_cash_register  = null
    )
    {
        this.information = information;
        this.fk_cash_register = fk_cash_register;
    }
    static async getInformations(fkCashRegister) {
        const response = await axios.get(
            `${apiUrls.INFORMATION}/list?fk_cash_register=${fkCashRegister}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addInformation(parameters) {
        const response = await axios.post(
            `${apiUrls.INFORMATION}/add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editInformation(parameters) {
        const response = await axios.put(
            `${apiUrls.INFORMATION}/edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getInfoAdmin(page, dateOne, dateTwo, idUser, idCashRegister) {
        const response = await axios.get(
            `${apiUrls.INFORMATION}/list-admin?page=${page}&date_one=${dateOne}&date_two=${dateTwo}&fk_user=${idUser}&fk_cash_register=${idCashRegister}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Information;