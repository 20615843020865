var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"formproductpurchase",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('MTextFieldComp',{ref:"quantity",staticClass:"mt-2",attrs:{"label":"Cantidad","rules":[
                value => !this.mRuleEmptyField(value) || 'La cantidad es requerido',
                value => this.mRuleNumberInteger(value) || 'La cantidad debe ser un número',
                value => value > 0 || 'La cantidad debe ser mayor a 0'
            ]},model:{value:(_vm.dataProductPurchase.quantity),callback:function ($$v) {_vm.$set(_vm.dataProductPurchase, "quantity", $$v)},expression:"dataProductPurchase.quantity"}}),_c('MTextFieldComp',{staticClass:"mt-2",attrs:{"label":"P.U.","rules":[
                value => !this.mRuleEmptyField(value) || 'El P.U. es requerido',
                value => this.mRuleNumberDecimal(value) || 'El P.U. debe ser un número',
                value => value > 0 || 'El P.U. debe ser mayor a 0'
            ]},model:{value:(_vm.dataProductPurchase.unit_price),callback:function ($$v) {_vm.$set(_vm.dataProductPurchase, "unit_price", $$v)},expression:"dataProductPurchase.unit_price"}}),_c('MTextFieldComp',{staticClass:"mt-2",attrs:{"label":"Descuento Comercial","rules":[
                value => !this.mRuleEmptyField(value) || 'El Dcto. Comercial es requerido',
                value => this.mRuleNumberDecimal(value) || 'El Dcto. Comercial debe ser un número'
            ]},model:{value:(_vm.dataProductPurchase.commercial_discount),callback:function ($$v) {_vm.$set(_vm.dataProductPurchase, "commercial_discount", $$v)},expression:"dataProductPurchase.commercial_discount"}}),_c('MTextFieldComp',{staticClass:"mt-2",attrs:{"label":"Otro Descuento","rules":[
                value => !this.mRuleEmptyField(value) || 'Otro Dcto. es requerido',
                value => this.mRuleNumberDecimal(value) || 'Otro Dcto. debe ser un número'
            ]},model:{value:(_vm.dataProductPurchase.other_discount),callback:function ($$v) {_vm.$set(_vm.dataProductPurchase, "other_discount", $$v)},expression:"dataProductPurchase.other_discount"}}),_c('MTextFieldComp',{attrs:{"label":"Fecha de Expiración","type":"date","rules":[
                value => !this.mRuleEmptyField(value) || 'La fecha expiración es requerido'
            ]},model:{value:(_vm.dataProductPurchase.expiration_date),callback:function ($$v) {_vm.$set(_vm.dataProductPurchase, "expiration_date", $$v)},expression:"dataProductPurchase.expiration_date"}}),_c('div',{staticClass:"d-flex justify-center mt-2"},[_c('MBtnNormalComp',{on:{"click":function($event){return _vm.eValidForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" "+_vm._s(_vm.titleForm)+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }