<template>
    <div class="s-form-product">
        <h1 class="app-title text-center mb-2">
            {{indexInformation===-1 ? 'REGISTRAR' : 'EDITAR'}}
        </h1>
        <v-form
            v-model="valid"
            lazy-validation
            ref="forminformation"
        >
            <ORowFormComp
                :mobile="mobile"
                informationRow="Información:"
            >
                <MTextAreaComp
                    ref="info"
                    :label="mobile ? 'Información' : ''"
                    v-model="dataInformation.information"
                    :counter="150"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'La información es requerida',
                    ]"
                />
            </ORowFormComp>
        </v-form>
        <div class="d-flex justify-center mt-1">
            <MBtnNormalComp
                @click="eValidForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import { MBtnNormalComp, MTextAreaComp } from "../molecules";
import { ORowFormComp } from "../organisms";
import { rulesMixin } from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        MBtnNormalComp,
        ORowFormComp,
        MTextAreaComp
    },
    props: {
        indexInformation: Number,
        mobile: Boolean,
        dataInformation: Object
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.$refs.forminformation.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.forminformation.resetValidation();
        },
        activateFocus() {
            this.$refs.info.activateFocus();
        }
    }
}
</script>