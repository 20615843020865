export default {
    methods: {
        mRuleEmptyField(input) {
            if (input === null || input === '') return true;
            var filterSpaces = /\s/g;
            var myArraySpaces = (input+'').match(filterSpaces);
            if (myArraySpaces === null) return false;
            return input.length === myArraySpaces.length;
        },
        mRuleWithSpaces(input) {
            var spaces = (`${input}`).match(/\s+/g);
            return !(spaces === null);
        },
        /*mRuleOnlyLetters(input) {
            var filter = /[a-zA-Z]+\s/g;
            var list = (`${input}`).match(filter);
            console.log(list);
        },*/
        mRuleNumberInteger(input) {
            if (isNaN(input)) return false;
            var number = parseFloat(input);
            return number%1 === 0;
        },
        mRuleNumberDecimal(input) {
            if (isNaN(input)) return false;
            return !(input < 0);
        },
        mRuleExceededNumber(input, limit) {
            return parseFloat(input) > parseFloat(limit);
        },
        mRuleIsNotZero(input) {
            return parseInt(input) !== 0;
        }
    }
}