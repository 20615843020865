<template>
    <div>
        <div class="client__header pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    {{`PRODUCTOS (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center client__header-block-second">
                <OSearchHeaderComp
                    label="Buscar"
                    v-model="search"
                    class="mr-1"
                    @entersearch="eSearchProducts()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-plus"
                    class="mr-1"
                    smsToolTip="Registrar producto"
                    @click="eOpenFormProduct()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    smsToolTip="Volver a productos"
                    @click="eBackTableList()"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap">
                    <div
                        :style="mobile ? 'width:100%' : ''"
                        class="d-flex justify-center pt-2 pl-2 pr-2"
                    >
                        <div class="product__search" style="margin-right:2px">
                            <MSelectComp
                                label="Filtrar"
                                :items="itemsSearchType"
                                itemValue="value"
                                itemText="name"
                                :hideDetails="true"
                                v-model="searchType"
                            />
                        </div>
                        <div class="product__search" style="margin-left:2px">
                            <div
                                class="product__search-laboratories d-flex align-center pl-3"
                                @click="eOpenDialogLaboratories()"
                            >
                                <h2>Laboratorio</h2>
                                <h1 class="app-subtitle">
                                    {{laboratory.id==0 ? "Todos" : laboratory.name}}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-center pt-2 pl-2 pr-2">
                        <div class="product__search" style="margin-right:2px">
                            <MSelectComp
                                label="Tipo Producto"
                                :items="typesProducts"
                                itemValue="id"
                                itemText="type_product"
                                :hideDetails="true"
                                v-model="idTypeProduct"
                                @change="eChangeTypeProduct"
                            />
                        </div>
                        <div class="product__search" style="margin-left:2px">
                            <MSelectComp
                                label="Estado"
                                :items="itemsSearchState"
                                itemValue="value"
                                itemText="name"
                                :hideDetails="true"
                                v-model="searchState"
                                @change="eChangeState"
                            />
                        </div>
                    </div>
                </div>
                <v-divider class="mt-2"></v-divider>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="productsList.length==0 && !dialogLoaderData && mobile"
                />
                <STableProductsComp
                    v-if="!dialogLoaderData && !mobile"
                    :productsList="productsList"
                    :search="searchFilter"
                    :searchType="searchType"
                    @clickdetail="eShowDetail"
                    @clickedit="eShowFormEdit"
                    @clickdelete="eConfirmDelete"
                    @clickstate="eConfirmChangeState"
                    @clickprice="eOpenFormPrice"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderData && mobile"
                >
                    <SCardProductComp
                        class="ma-2"
                        :search="searchFilter"
                        v-for="product in productsList"
                        :key="product.id"
                        :product="product"
                        @clickdetail="eShowDetail(product)"
                        @clickedit="eShowFormEdit(product)"
                        @clickdelete="eConfirmDelete(product)"
                        @clickstate="eConfirmChangeState(product)"
                        @clickprice="eOpenFormPrice(product)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="mobile && !dialogLoaderData && productsList.length>0"
                ></v-divider>
                <SPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderData && productsList.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center mt-3 pl-2 pr-2 pb-2">
                    <SFormProductComp
                        :dataProduct="dataProduct"
                        :mobile="mobile"
                        :indexProduct="indexProduct"
                        :typesProducts="typesProductForm"
                        :isNewTypeProduct="isNewTypeProduct"
                        :isNewLaboratory="isNewLaboratory"
                        :dataTypeProduct="dataTypeProduct"
                        :dataLaboratory="dataLaboratory"
                        ref="formproductcomp"
                        @saveform="eConfirmForm"
                        @clicklaboratoryform="dialogLaboratories=true"
                        @clicktypeproduct="dialogTypeProduct=true"
                        @clickdeletetypeproduct="eDeleteNewTypeProduct()"
                        @clicknewlaboratory="dialogFormLaboratory=true"
                        @clickdeletelaboratory="eDeleteNewLaboratory()"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center mt-3 pl-2 pr-2 pb-2">
                    <SFormPriceProductComp
                        :dataPrice="dataPrice"
                        :mobile="mobile"
                        ref="formpricecomp"
                        @saveformprice="eConfirmFormPrice"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <EDialogFormTypeProductComp
            :dialogTypeProduct="dialogTypeProduct"
            :dataTypeProduct="dataTypeProduct"
            :mobile="mobile"
            ref="dialogformtypeproduct"
            @clickselect="eSelectTypeProduct"
            @clickclose="eCloseFormTypeProduct"
        />
        <EDialogFormLaboratoryComp
            :dialogLaboratory="dialogFormLaboratory"
            :dataLaboratory="dataLaboratory"
            :mobile="mobile"
            ref="dialogformlaboratory"
            @clickselect="eSelectFormLaboratory"
            @clickclose="eCloseFormLaboratory"
        />
        <SDetailProductComp
            :product="product"
            :value="dialogDetailProduct"
            @clickclose="dialogDetailProduct=false"
        />
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="eCloseDialogConfirm()"
            @clickyes="ePerformDatabaseAction()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
        <SDialogSearchLaboratoryComp
            :valueDialog="dialogLaboratories"
            ref="dialoglaboratory"
            v-model="laboratory.search"
            :filterData="laboratory.filterData"
            :laboratoriesList="laboratory.filterData
                ? laboratoriesList : laboratoriesForm"
            :searchLaboratory="searchFilterLaboratory"
            :dialogLoaderData="laboratory.dialogLoaderData"
            @entersearch="eSearchLaboratories()"
            @clickclose="eCloseSearchLaboratory()"
            @clicklaboratory="eSelectLaboratory"
        />
    </div>
</template>
<script>
import {
    MSelectComp,
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSearchHeaderComp,
    OLoaderDataComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp
} from "../../components/organisms";
import {
    SPaginationComp,
    STableProductsComp,
    SDetailProductComp,
    SCardProductComp,
    SFormProductComp,
    SFormPriceProductComp,
    SDialogSearchLaboratoryComp
} from "../../components/species";
import {
    EDialogFormTypeProductComp,
    EDialogFormLaboratoryComp
} from "../../components/ecosystems";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    animationScrollMixin,
    rulesMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import {
    Product,
    TypeProduct,
    Laboratory
} from '../../models';
export default {
    props: {
        mini: Boolean
    },
    components: {
        OSearchHeaderComp,
        OBtnToolTipHeaderComp,
        OLoaderDataComp,
        SPaginationComp,
        STableProductsComp,
        SDetailProductComp,
        SCardProductComp,
        SFormProductComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        MSelectComp,
        SFormPriceProductComp,
        SDialogSearchLaboratoryComp,
        MNoDataComp,
        EDialogFormTypeProductComp,
        EDialogFormLaboratoryComp
    },
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        animationScrollMixin,
        rulesMixin,
        userMixin,
        datesMixin
    ],
    data: () => ({
        tab: 0,
        search: "",
        productsList: [],
        itemsSearchType: [
            { name: "Por Producto", value: 1 },
            { name: "Por Ubicación", value: 2 }
        ],
        searchType: 1,
        product: {},
        dialogDetailProduct: false,
        indexProduct: -1,
        dataProduct: new Product(),
        idProduct: 0,
        isChangeState: false,
        itemsSearchState: [
            { name: "Todos", value: 2 },
            { name: "Habilitados", value: 0 },
            { name: "Deshabilitados", value: 1 },
        ],
        searchState: 2,
        newPrice: -1,
        dataPrice: {
            product: "",
            sale_price: 0
        },
        typesProducts: [{ id: 0, type_product: "Todos"}],
        idTypeProduct: 0,
        laboratoriesList: [{ id:0, laboratory: "Todos" }],
        laboratory: {
            id: 0,
            name: "",
            search: "",
            dialogLoaderData: false,
            filterData: false
        },
        dialogLaboratories: false,
        dataTypeProduct: new TypeProduct,
        isNewTypeProduct: false,
        dialogTypeProduct: false,
        isNewLaboratory: false,
        dataLaboratory: new Laboratory,
        dialogFormLaboratory: false
    }),
    computed: {
        searchFilter: function() {
            return this.search == null ? "" : this.search;
        },
        searchFilterLaboratory: function() {
            return this.laboratory.search == null ? "" : this.laboratory.search;
        },
        typesProductForm: function() {
            return this.typesProducts.filter(typeProduct => {
                return typeProduct.id !== 0;
            });
        },
        laboratoriesForm: function() {
            return this.laboratoriesList.filter(laboratory => {
                return laboratory.id !== 0;
            });
        }
    },
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else {
                if (this.idProduct > 0) {
                    this.idProduct = 0;
                    this.indexProduct = -1;
                }
                this.dialogError = false;
            }
        },
        handleKeyUpProduct (event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 0) this.eCloseDialogConfirm();
                if (actionKeyBoard === 1) this.ePerformDatabaseAction();
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getProducts(page, search, searchType, searchState, idTypeProduct, idLaboratory) {
            this.dialogLoaderData = true;
            try {
                const response = await Product.getProducts(page, search, searchType, searchState, idTypeProduct, idLaboratory);
                this.productsList = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eShowDetail(product) {
            this.product = product;
            this.dialogDetailProduct = true;
        },
        eSearchProducts() {
            if (this.mRuleEmptyField(this.search)) this.search = "";
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 0;
                this.getProducts(1, this.search, this.searchType, this.searchState, this.idTypeProduct, this.laboratory.id);
            }, time);
        },
        eBackTableList() {
            this.isNewTypeProduct = false;
            this.isNewLaboratory = false;
            if (this.tab !== 0) {
                this.dialogLoaderOperation = false;
                //this.tab = 0;
                if (this.newPrice > -1) {
                    this.newPrice = -1;
                    this.$refs.formpricecomp.resetForm();
                } else {
                    this.dataProduct = new Product();
                    this.$refs.formproductcomp.resetForm();
                }
                this.indexProduct = -1;
                this.tab = 0;
            }
        },
        eConfirmForm(isValidForm) {
            const smsConfirm = this.indexProduct === -1
                ? [`¿Está seguro(a) de registrar: ${this.dataProduct.product}?`]
                : [`¿Está seguro(a) de editar: ${this.dataProduct.product}?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
            }, time);
        },
        eShowFormEdit(product) {
            this.indexProduct = this.productsList.indexOf(product);
            this.dataProduct = Object.assign({}, product);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
                setTimeout(() => {
                    this.$refs.formproductcomp.activateFocus();
                }, 150);
            }, time);
        },
        async editProduct() {
            const parameters = {
                'product': this.dataProduct,
                'type_product': this.dataTypeProduct,
                'new_type_product': this.isNewTypeProduct,
                'laboratory': this.dataLaboratory,
                'new_laboratory': this.isNewLaboratory
            };
            try {
                const response = await Product.editProduct(parameters);
                Object.assign(this.productsList[this.indexProduct], response.product);
                if (this.isNewTypeProduct)
                    this.typesProducts.push(response.type_product);
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se editó el producto"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async addProduct() {
            const parameters = {
                'product': this.dataProduct,
                'type_product': this.dataTypeProduct,
                'new_type_product': this.isNewTypeProduct,
                'laboratory': this.dataLaboratory,
                'new_laboratory': this.isNewLaboratory
            };
            try {
                const response = await Product.addProduct(parameters);
                this.productsList.unshift(response.product);
                if (this.isNewTypeProduct)
                    this.typesProducts.push(response.type_product);
                this.total += 1;
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se registró el producto"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async deleteProduct() {
            try {
                await Product.deleteProduct({id: this.idProduct});
                this.productsList.splice(this.indexProduct, 1);
                this.total -= 1;
                this.resetSharedProperties();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se eliminó el producto"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async changeStateProduct() {
            try {
                await Product.changeStateProduct({id: this.idProduct});
                this.productsList[this.indexProduct].disabled = this.productsList[this.indexProduct].disabled === 1 ? 0 : 1;
                this.resetSharedProperties();
                this.mDataGeneralShowSmsAction(["Operación Exitosa",
                    "Se cambió el estado del producto"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async changePriceProduct() {
            const parameters = {
                id: this.dataPrice.id,
                sale_price: this.newPrice
            }
            try {
                await Product.updatePriceProduct(parameters);
                this.productsList[this.indexProduct].sale_price = this.newPrice;
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa",
                    "Se cambió el precio del producto"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        resetSharedProperties() {
            this.idProduct = 0;
            this.indexProduct = -1;
            this.isChangeState = false;
            this.dialogLoaderOperation = false;
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (this.newPrice > -1) this.changePriceProduct();
            else {
                if (this.isChangeState) this.changeStateProduct();
                else {
                    if (this.idProduct > 0) this.deleteProduct();
                    else {
                        if (this.indexProduct === -1) this.addProduct();
                        else this.editProduct();
                    }
                }
            }
        },
        eConfirmDelete(product) {
            this.indexProduct = this.productsList.indexOf(product);
            this.idProduct = product.id;
            this.smsConfirm = [`¿Está seguro(a) de eliminar: ${product.product}?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.dialogConfirm = true;
            }, time);
        },
        eCloseDialogConfirm() {
            if (this.idProduct > 0) this.resetSharedProperties();
            this.dialogConfirm = false;
        },
        eConfirmChangeState(product) {
            this.idProduct = product.id;
            this.indexProduct = this.productsList.indexOf(product);
            this.isChangeState = true;
            const sms = product.disabled === 1 ? 'habilitar' : 'deshabilitar';
            this.smsConfirm = [`¿Está seguro(a) de, ${sms} a: ${product.product}?`];
            this.dialogConfirm = true;
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getProducts(this.currentPage, this.search, this.searchType, this.searchState, this.idTypeProduct, this.laboratory.id);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getProducts(page, this.search, this.searchType, this.searchState, this.idTypeProduct, this.laboratory.id);
                }, time);
            }
        },
        eOpenFormPrice(product) {
            this.newPrice = 0;
            this.indexProduct = this.productsList.indexOf(product);
            this.dataPrice = Object.assign({}, product);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 2;
                setTimeout(() => {
                    this.$refs.formpricecomp.activateFocus();
                }, 150);
            }, time);
        },
        eConfirmFormPrice(info) {
            if (info.isValidForm) this.newPrice = parseFloat(info.newPrice);
            const smsConfirm = [`¿Está seguro de actualizar el precio de: ${this.dataPrice.product},
                a ${parseFloat(info.newPrice).toFixed(2)} Bs.?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.mDataGeneralConfirmForm(info.isValidForm, smsConfirm);
            }, time);
        },
        async getRequest() {
            try {
                const products = Product.getProducts(1, "", 1, 2, 0, 0);
                const typesProducts = TypeProduct.getTypesProducts();
                const responses = await Promise.all([products, typesProducts]);
                this.productsList = responses[0].data;
                this.lastPage = responses[0].last_page;
                this.currentPage = responses[0].current_page;
                this.total = responses[0].total;
                responses[1].forEach(typeProduct => {
                    this.typesProducts.push(typeProduct);
                });
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eChangeTypeProduct(e) {
            this.getProducts(1, this.search, this.searchType, this.searchState, e, this.laboratory.id);
        },
        eChangeState(e) {
            this.getProducts(1, this.search, this.searchType, e, this.idTypeProduct, this.laboratory.id);
        },
        //search laboratory
        async eSearchLaboratories() {
            this.laboratory.dialogLoaderData = true;
            if (this.mRuleEmptyField(this.laboratory.search)) this.laboratory.search = "";
            this.laboratoriesList = [{ id: 0, laboratory: "Todos" }];
            try {
                const response = await Laboratory.getLaboratories(1, this.laboratory.search);
                response.data.forEach(laboratory => {
                    this.laboratoriesList.push(laboratory);
                });
                this.laboratory.dialogLoaderData = false;
            } catch(error) {
                this.dialogLaboratories = false;
                this.processErrorServer(error);
            }
        },
        eOpenDialogLaboratories() {
            this.laboratory.filterData = true;
            this.dialogLaboratories = true;
            setTimeout(() => {
                this.$refs.dialoglaboratory.activateFocus();
            }, 150);
        },
        eCloseSearchLaboratory() {
            if (!this.laboratory.filterData)
                this.dataProduct.activate_error = true;
            this.laboratory.filterData = false;
            this.laboratory.search = "";
            this.laboratoriesList = [{ id: 0, laboratory: "Todos" }];
            this.dialogLaboratories = false;
        },
        eSelectLaboratory(laboratory) {
            if (this.laboratory.filterData) {
                this.laboratory.id = laboratory.id;
                this.laboratory.name = laboratory.laboratory;
                this.eCloseSearchLaboratory();
                setTimeout(() => {
                    this.getProducts(1, this.search, this.searchType, this.searchState, this.idTypeProduct, this.laboratory.id);
                }, 150);
            } else {
                this.dataProduct.fk_laboratory = laboratory.id;
                this.dataProduct.laboratory = laboratory;
                this.eCloseSearchLaboratory();
            }
        },
        //
        eCloseFormTypeProduct() {
            this.dataTypeProduct = new TypeProduct;
            this.$refs.dialogformtypeproduct.resetForm();
            this.dialogTypeProduct = false;
        },
        eSelectTypeProduct(isValidForm) {
            if (isValidForm) {
                this.dataProduct.fk_type_product = 0;
                this.isNewTypeProduct = true;
                this.dialogTypeProduct = false;
            } else this.mDataGeneralShowSmsAction(["Error de datos",
                    "Corrija el tipo de producto"], "error");
        },
        eDeleteNewTypeProduct() {
            this.dataProduct.fk_type_product = null;
            this.dataTypeProduct = new TypeProduct;
            this.$refs.dialogformtypeproduct.resetForm();
            this.isNewTypeProduct = false;
        },
        //
        eCloseFormLaboratory() {
            this.dataLaboratory = new Laboratory;
            this.$refs.dialogformlaboratory.resetForm();
            this.dialogFormLaboratory = false;
        },
        eSelectFormLaboratory(isValidForm) {
            if (isValidForm) {
                this.isNewLaboratory = true;
                this.dataProduct.fk_laboratory = 0;
                this.dialogFormLaboratory = false;
            } else this.mDataGeneralShowSmsAction(["Error de datos",
                    "Corrija los datos del laboratorio"], "error");
        },
        eDeleteNewLaboratory() {
            this.dataLaboratory = new Laboratory;
            this.$refs.dialogformlaboratory.resetForm();
            this.dataProduct.fk_laboratory = null;
            this.isNewLaboratory = false;
        },
        //
        eOpenFormProduct() {
            this.tab = 1;
            setTimeout(() => {
                this.$refs.formproductcomp.activateFocus();
            }, 150);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpProduct);
                this.getRequest();
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpProduct);
    }
}
</script>