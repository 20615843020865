<template>
    <div class="s-form-product">
        <h1
            class="app-title text-center mb-2"
            v-if="!isAloneForm"
        >
            {{indexTypeProduct===-1 ? 'REGISTRAR' : 'EDITAR'}}
        </h1>
        <v-form
            v-model="valid"
            lazy-validation
            ref="formtypeproduct"
            @submit.prevent="eValidForm()"
        >
            <ORowFormComp
                :mobile="mobile"
                informationRow="Tipo producto:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Tipo producto' : ''"
                    v-model="dataTypeProduct.type_product"
                    :counter="30"
                    ref="typeproduct"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El tipo producto es requerido',
                        value => (`${value}`).length<=30 || 'El tipo producto, no debe exceder los 30 dígitos'
                    ]"
                />
            </ORowFormComp>
        </v-form>
        <div class="d-flex justify-center mt-1">
            <MBtnNormalComp
                @click="eValidForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                {{textBtn}}
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import { MBtnNormalComp, MTextFieldComp } from "../molecules";
import { ORowFormComp } from "../organisms";
import { rulesMixin } from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        MBtnNormalComp,
        ORowFormComp,
        MTextFieldComp
    },
    props: {
        indexTypeProduct: Number,
        mobile: Boolean,
        dataTypeProduct: Object,
        textBtn: {
            type: String,
            default: "GUARDAR"
        },
        isAloneForm: Boolean
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.$refs.formtypeproduct.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.formtypeproduct.resetValidation();
        },
        activateFocus() {
            this.$refs.typeproduct.activateFocus();
        }
    }
}
</script>