<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <text-highlight
                class="app-title"
                :queries="search"
            >
                {{product.product}}
            </text-highlight>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Tipo Producto:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.type_product.type_product}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Laboratorio:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.laboratory.laboratory}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">N° Lotes:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.inventories.length}}
                </h1>
            </div>
            <div class="d-flex align-center">
                <h1 class="app-title-dark mr-1">Cantidad Unidades:</h1>
                <div
                    :class="getClassInventory(product.quantity)"
                    style="margin-top:-2px;"
                >
                </div>
                <h1 class="app-subtitle-dark ml-1">
                    {{product.quantity}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-list-box"
                style="margin:2px"
                @click="$emit('clickdetail')"
            />
            <OBtnCardComp
                icon="mdi-medication"
                style="margin:2px"
                @click="$emit('clickinventories')"
            />
            <OBtnCardComp
                v-if="isOrder"
                icon="mdi-check-bold"
                style="margin:2px"
                @click="$emit('clickcheck')"
            />
        </div>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import { OBtnCardComp } from "../organisms";
export default {
    props: {
        product: Object,
        search: {
            type: String,
            default: ''
        },
        setting: Object,
        isOrder: Boolean
    },
    components: {
        TextHighlight,
        OBtnCardComp
    },
    methods: {
        getClassInventory(quantity) {
            if (quantity >= 0 && quantity <= this.setting.low_inventory)
                return 'client__ornament-disabled';
            if (quantity > this.setting.low_inventory && quantity <= this.setting.regular_inventory)
                return 'client__ornament-warning';
            return 'client__ornament-enabled';
        }
    }
}
</script>