<template>
    <div>
        <div class="client__header pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    ESTADÍSTICA ANUAL
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center client__header-block-second">
                <OSearchHeaderComp
                    label="Año"
                    v-model="search"
                    class="mr-1"
                    @entersearch="eSearchStatistics()"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-divider class="mt-2"></v-divider>
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Información"
        />
        <div
            v-else
            class="animation-opacity statistics__container"
        >
            <div class="statistics__container-draw">
                <div id="container" style="width:100%; height:400px;"></div>
            </div>
        </div>
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
    </div>
</template>
<script>
import {
    OBtnToolTipHeaderComp,
    OSmsErrorComp,
    OSmsActionComp,
    OLoaderDataComp,
    OSearchHeaderComp
} from "../../components/organisms";
import Highcharts from "../../plugins/highcharts";
import {
    datesMixin,
    responseServerMixin,
    dataGeneralCompsMixin,
    userMixin,
    rulesMixin
} from "../../mixins";
import {
    Report
} from "../../models";
export default {
    mixins: [
        responseServerMixin,
        dataGeneralCompsMixin,
        userMixin,
        datesMixin,
        rulesMixin
    ],
    components: {
        OBtnToolTipHeaderComp,
        OSmsErrorComp,
        OSmsActionComp,
        OLoaderDataComp,
        OSearchHeaderComp
    },
    props: {
        mini: Boolean
    },
    data: () => ({
        search: "",
        year: "0"
    }),
    methods: {
        handleKeyUpAnnualStatistics(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else this.dialogError = false;
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async monthlySales(year) {
            this.dialogLoaderData = true;
            try {
                const response = await Report.monthlySales(year);
                this.generateStatistics(response);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        generateStatistics(response) {
            var data = [];
            response.forEach(element => {
                const newElement = {
                    name: element.months,
                    y: parseFloat(element.total)
                }
                data.push(newElement);
            });
            this.drawStatistics(data);
        },
        drawStatistics(newData) {
            setTimeout(() => {
                this.chartOptions = Highcharts.chart('container', {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        align: 'center',
                        text: 'VENTAS ANUALES'
                    },
                    subtitle: {
                        align: 'center',
                        text: this.year
                    },
                    accessibility: {
                        announceNewData: {
                            enabled: true
                        }
                    },
                    xAxis: {
                        type: 'category'
                    },
                    yAxis: {
                        title: {
                            text: 'Total Ventas'
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: true,
                                format: '{point.y:.2f}'
                            }
                        }
                    },
                    tooltip: {
                        pointFormat: 'Ventas: {point.y:.2f} Bs.'
                    },
                    series: [
                        {
                            name: "Browsers",
                            colorByPoint: true,
                            data: newData
                        }
                    ]
                });
            }, 200);
            this.dialogLoaderData = false;
        },
        eSearchStatistics() {
            if (this.mRuleEmptyField(this.search) || !this.mRuleNumberInteger(this.search))
                this.mDataGeneralShowSmsAction(["Error de Búsqueda", "El año debe ser un número"], "error");
            else {
                this.year = this.search;
                this.monthlySales(this.search);
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        this.dateOne = this.dateTwo = this.mDateSystem(false);
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpAnnualStatistics);
                this.year = this.search = `${this.mDateYearSystem()}`;
                this.monthlySales(`${this.mDateYearSystem()}`);
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpAnnualStatistics);
    }
}
</script>