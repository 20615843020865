<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <h1 class="app-title">
                {{`Gasto: ${expense.expense.toFixed(2)} Bs.`}}
            </h1>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Descripción:</h1>
                <h1 class="app-subtitle-dark">
                    {{expense.description!=null ? expense.description : "S/D"}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">N° Caja:</h1>
                <h1 class="app-subtitle-dark">
                    {{expense.cash_register.cash_register}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Registrador(a):</h1>
                <h1 class="app-subtitle-dark">
                    {{expense.user}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha/Hora:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${expense.hour} ${expense.date}`}}
                </h1>
            </div>
        </div>
        <div
            class="s-card-client__actions d-flex justify-center flex-wrap"
            v-if="!isInformation"
        >
            <OBtnCardComp
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
        </div>
    </div>
</template>
<script>
import { OBtnCardComp } from "../organisms";
export default {
    props: {
        expense: Object,
        isInformation: Boolean
    },
    components: {
        OBtnCardComp
    }
}
</script>