<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <h1 class="app-title">{{personalControl.user}}</h1>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Hora de Ingreso:</h1>
                <h1 class="app-subtitle-dark">
                    {{personalControl.hour_box_opening}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha Ingreso:</h1>
                <h1 class="app-subtitle-dark">
                    {{personalControl.date_box_opening}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Hora de Salida:</h1>
                <h1 class="app-subtitle-dark">
                    {{
                        personalControl.hour_close_box!=null
                        ? personalControl.hour_close_box
                        : 'S/H'
                    }}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha Salida:</h1>
                <h1 class="app-subtitle-dark">
                    {{
                        personalControl.date_close_box!=null
                        ? personalControl.date_close_box
                        : 'S/F'
                    }}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">N° Caja:</h1>
                <h1 class="app-subtitle-dark">
                    {{personalControl.cash_register.cash_register}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-account-remove"
                style="margin:2px"
                @click="$emit('clickclose')"
            />
        </div>
    </div>
</template>
<script>
import { OBtnCardComp } from "../organisms"
export default {
    components: {
        OBtnCardComp
    },
    props: {
        personalControl: Object
    }
}
</script>