<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Cliente</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">CI/NIT</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Complemento</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Entidad</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Celular</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Correo</h1>
                    </th>
                    <th width="170px">
                        <h1 class="app-title-dark text-left">Fecha Registro</h1>
                    </th>
                    <th width="90px">
                        <h1 class="app-title-dark text-left text-center">Editar</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(client, index) in clientsList"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <text-highlight
                            class="app-subtitle"
                            :queries="search"
                        >
                            {{client.full_name}}
                        </text-highlight>
                    </td>
                    <td>
                        <text-highlight
                            class="app-subtitle"
                            :queries="search"
                        >
                            {{client.ci_nit}}
                        </text-highlight>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{client.complement!=null ? client.complement : '---'}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{client.entity == 0 ? 'Persona' : 'Empresa'}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{client.phone!=null ? client.phone : 'S/N'}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{client.e_mail!=null ? client.e_mail : 'S/C'}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{`${client.hour} ${client.date}`}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            :smsToolTip="`Editar a: ${client.full_name}`"
                            icon="mdi-pencil"
                            @click="$emit('clickedit', client)"
                        />
                    </td>
                </tr>
                <tr v-if="clientsList.length === 0">
                    <td colspan="9">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import { OBtnToolTipTableComp } from "../organisms";
export default {
    components: {
        OBtnToolTipTableComp,
        TextHighlight
    },
    props: {
        clientsList: Array,
        search: {
            type: String,
            default: ''
        }
    }
}
</script>