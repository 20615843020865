<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Product</h1>
                    </th>
                    <th width="77px">
                        <h1 class="app-title-dark text-left">N° Lote</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha Exp.</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">P.U.</h1>
                    </th>
                    <th width="60">
                        <h1 class="app-title-dark text-left">Cant.</h1>
                    </th>
                    <th width="80">
                        <h1 class="app-title-dark text-left">Total</h1>
                    </th>
                    <th width="95">
                        <h1 class="app-title-dark text-left">Dcto. Cial.</h1>
                    </th>
                    <th width="95">
                        <h1 class="app-title-dark text-left">Otro Dcto.</h1>
                    </th>
                    <th width="130">
                        <h1 class="app-title-dark text-left">Total con Dcto.</h1>
                    </th>
                    <th width="120px">
                        <h1 class="app-title-dark text-center">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(productPurchase, index) in productPurchaseList"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{productPurchase.product}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{productPurchase.pivot.lot_number}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <h1 class="app-subtitle">
                                {{mDateFormat(productPurchase.pivot.expiration_date)}}
                            </h1>
                            <div
                                :class="itIsExpiredDate(productPurchase.pivot.expiration_date)
                                    ? 'client__ornament-enabled ml-1'
                                    : 'client__ornament-disabled ml-1'"
                                style="margin-top:-2px;"
                            >
                            </div>
                        </div>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{productPurchase.pivot.unit_price.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{productPurchase.pivot.quantity}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{mPTotalPrice(productPurchase.pivot).toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{productPurchase.pivot.commercial_discount.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{productPurchase.pivot.other_discount.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{mPTotalPriceWithDiscount(productPurchase.pivot).toFixed(2)}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            :smsToolTip="`Ver detalle de: ${productPurchase.product}`"
                            icon="mdi-list-box"
                            class="mr-1"
                            @click="$emit('clickdetail', productPurchase)"
                        />
                        <OBtnToolTipTableComp
                            :smsToolTip="`Editar compra de: ${productPurchase.product}`"
                            icon="mdi-pencil"
                            @click="$emit('clickedit', productPurchase)"
                        />
                        <OBtnToolTipTableComp
                            v-if="productPurchaseList.length > 1"
                            :smsToolTip="`Eliminar compra de: ${productPurchase.product}`"
                            icon="mdi-delete"
                            class="ml-1"
                            @click="$emit('clickdelete', productPurchase)"
                        />
                    </td>
                </tr>
                <tr v-if="productPurchaseList.length === 0">
                    <td colspan="8">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { OBtnToolTipTableComp } from "../organisms";
import {
    datesMixin,
    purchaseMixin
} from "../../mixins";
export default {
    mixins: [
        datesMixin,
        purchaseMixin
    ],
    components: {
        OBtnToolTipTableComp
    },
    props: {
        productPurchaseList: Array
    },
    methods: {
        itIsExpiredDate(date) {
            const dateSystem = this.mDateSystem(false);
            if (date < dateSystem) return false;
            return true;
        }
    }
}
</script>