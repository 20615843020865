<template>
    <v-text-field
        :disabled="disabled"
        :label="label"
        :dense="true"
        :single-line="singleLine"
        :counter="counter"
        outlined
        :hide-details="hideDetails"
        :rules="rules"
        :type="type"
        ref="textfield"
        :value="value"
        @input="e => this.$emit('input', e)"
        :background-color="background"
        :clearable="clearable"
        :append-icon="icon"
        @keyup.enter="$emit('click')"
        @click:append="$emit('clicksee')"
        :placeholder="placeholder"
        @change="e => this.$emit('change', e)"
    ></v-text-field>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        label: String,
        singleLine: Boolean,
        hideDetails: Boolean,
        value: [Number, String],
        rules: Array,
        type: String,
        background: String,
        counter: Number,
        icon: String,
        disabled: Boolean,
        placeholder: String,
        clearable: {
            type: Boolean,
            default: true
        },
        refText: String
    },
    methods: {
        activateFocus() {
            this.$refs.textfield.focus();
        }
    }
}
</script>