<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <h1 class="app-title">
                {{product.product}}
            </h1>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Precio:</h1>
                <h1 class="app-subtitle-dark">
                    {{parseFloat(product.select_sale_price).toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Cantidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.select_quantity}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Total:</h1>
                <h1 class="app-subtitle-dark">
                    {{iTotalSaleProduct(product).toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Ubicación:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.location}}
                </h1>
            </div>
            <v-divider dark class="mb-1 mt-1"></v-divider>
            <div
                class="d-flex"
                v-for="(date_expiration, index) in inventoryOutputsFilter(product.inventory_outputs)"
                :key="index"
            >
                <h1 class="app-sale-subtitle-dark text-right">
                    {{mDateFormat(date_expiration.expiration_date)}}
                </h1>
                <h1 class="app-sale-title-dark text-right ml-1">
                    {{`(${date_expiration.quantity})`}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
            <OBtnCardComp
                icon="mdi-chart-timeline-variant-shimmer"
                style="margin:2px"
                @click="$emit('clicklots')"
            />
            <OBtnCardComp
                icon="mdi-list-box"
                style="margin:2px"
                @click="$emit('clickdetail')"
            />
            <OBtnCardComp
                icon="mdi-format-list-text"
                style="margin:2px"
                @click="$emit('clickinventory')"
            />
            <OBtnCardComp
                icon="mdi-delete"
                style="margin:2px"
                @click="$emit('clickdelete')"
            />
        </div>
        <div class="d-none">
            {{updateComp}}
        </div>
    </div>
</template>
<script>
import { OBtnCardComp } from "../organisms";
import {
    datesMixin
} from "../../mixins";
export default {
    mixins: [datesMixin],
    props: {
        product: Object,
        updateComp: Number
    },
    components: {
        OBtnCardComp
    },
    methods: {
        inventoryOutputsFilter(inventory_outputs) {
            return inventory_outputs.filter(element => {
                return element.quantity != 0;
            });
        },
        iTotalSaleProduct(product) {
            return parseFloat(product.select_sale_price) * parseInt(product.select_quantity);
        },
    }
}
</script>