<template>
    <div
        :class="mobile ? 'd-flex justify-end mt-2' : 'd-flex justify-end mt-1'"
    >
        <h1 class="app-title mt-2 mr-2" v-if="!mobile">
            {{informationRow}}
        </h1>
        <div :class="`${classText} d-flex`">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        mobile: Boolean,
        informationRow: String,
        classText: {
            type: String,
            default: "s-form-product__input"
        }
    }
}
</script>