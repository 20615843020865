<template>
    <div class="s-form-product">
        <h1 class="app-title text-center mb-2">
            {{index==-1 ? "REGISTRAR" : "EDITAR"}}
        </h1>
        <v-form
            v-model="valid"
            lazy-validation
            ref="formclient"
        >
            <ORowFormComp
                :mobile="mobile"
                informationRow="Nombre/Empresa:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Nombre/Empresa' : ''"
                    v-model="dataClient.full_name"
                    ref="client"
                    :counter="70"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El nombre/empresa es requerido',
                        value => (`${value}`).length<=70 || 'El nombre/empresa no debe exceder los 70 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="CI/NIT:"
            >
                <MTextFieldComp
                    :label="mobile ? 'CI/NIT' : ''"
                    v-model="dataClient.ci_nit"
                    :counter="50"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'Es ci/nit es requerido',
                        value => !this.mRuleWithSpaces(value) || 'El ci/nit no debe contener espacios',
                        value => (`${value}`).length<=50 || 'El ci/nit no debe exceder los 50 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Complemento:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Complemento' : ''"
                    v-model="dataClient.complement"
                    :counter="10"
                    :rules="[
                        value => (`${value}`).length<=10 || 'El complemento no debe exceder los 10 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Entidad:"
            >
                <MSelectComp
                    :label="mobile ? 'Entidad' : ''"
                    v-model="dataClient.entity"
                    :items="items"
                    itemValue="value"
                    itemText="name"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Celular:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Celular' : ''"
                    v-model="dataClient.phone"
                    :counter="10"
                    :rules="[
                        value => (`${value}`).length<=10 || 'El celular no debe exceder los 10 dígitos',
                        value => !eIsValidPhone(value) || 'El celular solo debe contener números'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Correo:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Correo' : ''"
                    v-model="dataClient.e_mail"
                    :counter="50"
                    :rules="[
                        value => (`${value}`).length<=50 || 'El correo no debe exceder los 50 dígitos'
                    ]"
                />
            </ORowFormComp>
            <div class="d-flex justify-center mt-1">
                <MBtnNormalComp
                    @click="eValidForm()"
                >
                    <v-icon>mdi-bookmark-check</v-icon>
                    GUARDAR
                </MBtnNormalComp>
            </div>
        </v-form>
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp,
    MSelectComp
} from "../molecules";
import { ORowFormComp } from "../organisms";
import { rulesMixin } from "../../mixins";
export default {
    mixins: [
        rulesMixin
    ],
    components: {
        MTextFieldComp,
        ORowFormComp,
        MBtnNormalComp,
        MSelectComp
    },
    props: {
        mobile: Boolean,
        dataClient: Object,
        index: Number
    },
    data: () => ({
        valid: true,
        items: [
            { name: "Persona", value: 0 },
            { name: "Empresa", value: 1 }
        ]
    }),
    methods: {
        eIsValidPhone(input) {
            if (this.mRuleEmptyField(input)) return false;
            return !this.mRuleNumberInteger(input);
        },
        eValidForm() {
            if (this.$refs.formclient.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        activateFocus() {
            this.$refs.client.activateFocus();
        }
    }
}
</script>