<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <h1 class="app-subtitle">
                {{`Venta: ${sale.sale.toFixed(2)}`}}
            </h1>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Descuento:</h1>
                <h1 class="app-subtitle-dark">
                    {{sale.discount.toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex align-center">
                <h1 class="app-title-dark mr-1">Anulado:</h1>
                <h1 class="app-subtitle-dark mr-1">
                    {{sale.disabled==1 ? "SI" : "NO"}}
                </h1>
                <div
                    :class="sale.disabled==1
                        ? 'client__ornament-disabled'
                        : 'client__ornament-enabled'"
                    style="margin-top:-3px"
                >
                </div>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Tipo Pago:</h1>
                <h1 class="app-subtitle-dark">
                    {{sale.type_sale == "e"
                        ? "Efectivo"
                        : sale.type_sale == "q"
                            ? "Qr" : "Tarjeta"}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Cliente:</h1>
                <h1 class="app-subtitle-dark">
                    {{sale.client.full_name}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha Reg.:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${sale.hour} ${sale.date}`}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-information"
                style="margin:2px"
                @click="$emit('clickinfo')"
            />
            <OBtnCardComp
                icon="mdi-list-box"
                style="margin:2px"
                @click="$emit('clickdetail')"
            />
            <OBtnCardComp
                icon="mdi-account-cash"
                style="margin:2px"
                @click="$emit('clicktypesale')"
            />
            <OBtnCardComp
                v-if="mUserCategorie() == '0'"
                icon="mdi-delete"
                style="margin:2px"
                @click="$emit('clickchangestate')"
            />
        </div>
    </div>
</template>
<script>
import { OBtnCardComp } from "../organisms";
import {
    userMixin
} from "../../mixins";
export default {
    mixins: [ userMixin ],
    props: {
        sale: Object
    },
    components: {
        OBtnCardComp
    }
}
</script>