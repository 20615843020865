<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <h1 class="app-title">
                {{purchase.provider.provider}}
            </h1>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Total Precio:</h1>
                <h1 class="app-subtitle-dark">
                    {{mPSumOfTotalsPrice(purchase.products, true).toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Dcto. Comercial:</h1>
                <h1 class="app-subtitle-dark">
                    {{purchase.discount.toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Otro Dcto.:</h1>
                <h1 class="app-subtitle-dark">
                    {{purchase.other_discount.toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Total Compra:</h1>
                <h1 class="app-subtitle-dark">
                    {{mPTotalPurchaseWithDiscount(purchase).toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">N° Rec./Fac.:</h1>
                <text-highlight
                    class="app-subtitle-dark"
                    :queries="search"
                >
                    {{purchase.bill_receipt}}
                </text-highlight>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha Compra:</h1>
                <h1 class="app-subtitle-dark">
                    {{mDateFormat(purchase.date_purchase)}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-list-box"
                style="margin:2px"
                @click="$emit('clickdetail')"
            />
            <OBtnCardComp
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
            <OBtnCardComp
                icon="mdi-printer"
                style="margin:2px"
                @click="$emit('clickprint')"
            />
            <OBtnCardComp
                icon="mdi-delete"
                style="margin:2px"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import {
    datesMixin,
    purchaseMixin
} from "../../mixins";
import { OBtnCardComp } from "../organisms";
import TextHighlight from "vue-text-highlight";
export default {
    mixins: [
        datesMixin,
        purchaseMixin
    ],
    props: {
        purchase: Object,
        search: String
    },
    components: {
        OBtnCardComp,
        TextHighlight
    }
}
</script>