<template>
    <v-dialog
        persistent
        width="800px"
        :value="value"
    >
        <v-card>
            <div class="s-detail-product__header pl-2 pr-2 d-flex align-center">
                <h1 class="app-title">
                    Movimiento Inventario
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <div class="client__line-header"></div>
            <div class="pa-2 pt-1 pb-1" v-if="product.id!=undefined">
                <div class="d-flex">
                    <h1 class="app-title mr-1">Producto:</h1>
                    <h1 class="app-subtitle">
                        {{product.product}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="app-title mr-1">Cantidad Vendida:</h1>
                    <h1 class="app-subtitle">
                        {{product.pivot.quantity}}
                    </h1>
                </div>
            </div>
            <v-divider></v-divider>
            <div
                v-if="product.id != undefined"
                class="d-flex justify-center flex-wrap"
            >
                <div
                    class="s-card-client ma-2"
                    v-for="inventory in product.inventory_outputs"
                    :key="inventory.id"
                >
                    <div class="s-card-client__header">
                        <h1 class="app-title">
                            {{`Lote N°: ${inventory.lot_number}`}}
                        </h1>
                    </div>
                    <div class="s-card-client__body">
                        <div class="d-flex">
                            <h1 class="app-title-dark mr-1">Fecha de Exp.:</h1>
                            <h1 class="app-subtitle-dark">
                                {{mDateFormat(inventory.expiration_date)}}
                            </h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="app-title-dark mr-1">Cantidad:</h1>
                            <h1 class="app-subtitle-dark">
                                {{inventory.quantity}}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import { OBtnCardComp } from "../organisms";
import {
    datesMixin
} from "../../mixins";
export default {
    components: {
        OBtnCardComp
    },
    mixins: [ datesMixin ],
    props: {
        value: Boolean,
        product: Object
    }
}
</script>