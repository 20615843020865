var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"e-table-product animation-opacity"},[_c('table',{staticClass:"e-table-product__table"},[_c('thead',[_c('tr',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('th',{attrs:{"width":_vm.isOrder ? '120px' : '100px'}},[_c('h1',{staticClass:"app-title-dark text-center"},[_vm._v("Acciones")])])])]),_c('tbody',[_vm._l((_vm.productsList),function(product,index){return _c('tr',{key:index},[_c('td',[_c('h1',{staticClass:"app-subtitle text-right"},[_vm._v(" "+_vm._s((index+1))+" ")])]),_c('td',[_c('text-highlight',{staticClass:"app-subtitle",attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(product.product)+" ")])],1),_c('td',[_c('div',{class:product.disabled==1
                            ? 'client__ornament-disabled'
                            : 'client__ornament-enabled'})]),_c('td',[_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(product.type_product.type_product)+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(product.laboratory.laboratory)+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(product.inventories.length)+" ")])]),_c('td',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{class:_vm.getClassInventory(product.quantity),staticStyle:{"margin-top":"-2px"}}),_c('h1',{staticClass:"app-subtitle ml-2"},[_vm._v(" "+_vm._s(product.quantity)+" ")])])]),_c('td',{staticClass:"d-flex justify-center flex-wrap"},[_c('OBtnToolTipTableComp',{attrs:{"smsToolTip":`Ver detalle de: ${product.product}`,"icon":"mdi-list-box"},on:{"click":function($event){return _vm.$emit('clickdetail', product)}}}),_c('OBtnToolTipTableComp',{attrs:{"smsToolTip":`Ver inventario de: ${product.product}`,"icon":"mdi-medication"},on:{"click":function($event){return _vm.$emit('clickinventories', product)}}}),(_vm.isOrder)?_c('OBtnToolTipTableComp',{attrs:{"smsToolTip":`Seleccionar: ${product.product}`,"icon":"mdi-check-bold"},on:{"click":function($event){return _vm.$emit('clickcheck', product)}}}):_vm._e()],1)])}),(_vm.productsList.length === 0)?_c('tr',[_vm._m(7)]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"50px"}},[_c('h1',{staticClass:"app-title-dark text-center"},[_vm._v("N°")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Producto")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"20"}},[_c('h1',{staticClass:"app-title-dark text-center"},[_vm._v("E")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Tipo Producto")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Laboratorio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("N° Lotes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Cant. Unid.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"9"}},[_c('h1',{staticClass:"app-subtitle text-center"},[_vm._v(" SIN INFORMACIÓN ")])])
}]

export { render, staticRenderFns }