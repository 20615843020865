var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-form-product"},[_c('h1',{staticClass:"app-title text-center mb-2"},[_vm._v(" "+_vm._s(_vm.indexProduct==-1 ? "REGISTRAR" : "EDITAR")+" ")]),_c('v-form',{ref:"formproduct",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Producto:"}},[_c('MTextFieldComp',{ref:"product",attrs:{"label":_vm.mobile ? 'Producto' : '',"counter":30,"rules":[
                    value => !this.mRuleEmptyField(value) || 'El producto es requerido',
                    value => (`${value}`).length<=30 || 'El producto no debe exceder los 30 dígitos'
                ]},model:{value:(_vm.dataProduct.product),callback:function ($$v) {_vm.$set(_vm.dataProduct, "product", $$v)},expression:"dataProduct.product"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Precio Venta:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Precio Venta' : '',"counter":10,"rules":[
                    value => !this.mRuleEmptyField(value) || 'El precio es requerido',
                    value => this.mRuleNumberDecimal(value) || 'El precio debe ser un número',
                    value => (`${value}`).length<=10 || 'El precio, no debe exceder los 10 dígitos'
                ]},model:{value:(_vm.dataProduct.sale_price),callback:function ($$v) {_vm.$set(_vm.dataProduct, "sale_price", $$v)},expression:"dataProduct.sale_price"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Descuento máximo:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Descuento máximo' : '',"rules":[
                    value => !this.mRuleEmptyField(value) || 'El descuento es requerido',
                    value => this.mRuleNumberDecimal(value) || 'El descuento debe ser un número'
                ]},model:{value:(_vm.dataProduct.max_discount),callback:function ($$v) {_vm.$set(_vm.dataProduct, "max_discount", $$v)},expression:"dataProduct.max_discount"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Código Barra:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Código Barra' : '',"counter":30,"rules":[
                    value => (`${value}`).length<=30 || 'El código barra, no debe exceder los 30 dígitos'
                ]},model:{value:(_vm.dataProduct.barcore),callback:function ($$v) {_vm.$set(_vm.dataProduct, "barcore", $$v)},expression:"dataProduct.barcore"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Ubicación:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Ubicación' : '',"counter":20,"rules":[
                    value => (`${value}`).length<=20 || 'La ubicación, no debe exceder los 20 dígitos'
                ]},model:{value:(_vm.dataProduct.location),callback:function ($$v) {_vm.$set(_vm.dataProduct, "location", $$v)},expression:"dataProduct.location"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Reg. Sanitario:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Reg. Sanitario' : '',"counter":30,"rules":[
                    value => (`${value}`).length<=30 || 'El reg. sanitario, no debe exceder los 30 dígitos'
                ]},model:{value:(_vm.dataProduct.health_register),callback:function ($$v) {_vm.$set(_vm.dataProduct, "health_register", $$v)},expression:"dataProduct.health_register"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Composición:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Composición' : '',"counter":100,"rules":[
                    value => (`${value}`).length<=100 || 'La composición, no debe exceder los 100 dígitos'
                ]},model:{value:(_vm.dataProduct.composition),callback:function ($$v) {_vm.$set(_vm.dataProduct, "composition", $$v)},expression:"dataProduct.composition"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Vía administración:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Vía administración' : '',"counter":30,"rules":[
                    value => (`${value}`).length<=30 || 'La vía administración, no debe exceder los 30 dígitos'
                ]},model:{value:(_vm.dataProduct.via_administration),callback:function ($$v) {_vm.$set(_vm.dataProduct, "via_administration", $$v)},expression:"dataProduct.via_administration"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Tipo Producto:"}},[(_vm.isNewTypeProduct)?_c('MTextFieldComp',{attrs:{"disabled":true,"label":_vm.mobile ? 'Tipo Producto' : ''},model:{value:(_vm.dataTypeProduct.type_product),callback:function ($$v) {_vm.$set(_vm.dataTypeProduct, "type_product", $$v)},expression:"dataTypeProduct.type_product"}}):_c('MSelectComp',{attrs:{"items":_vm.typesProducts,"itemValue":"id","itemText":"type_product","label":_vm.mobile ? 'Tipo Producto' : '',"rules":[
                    value => !this.mRuleEmptyField(value) || 'El tipo producto es requerido'
                ]},model:{value:(_vm.dataProduct.fk_type_product),callback:function ($$v) {_vm.$set(_vm.dataProduct, "fk_type_product", $$v)},expression:"dataProduct.fk_type_product"}}),(_vm.isNewTypeProduct)?_c('OBtnToolTipHeaderComp',{staticClass:"ml-1 mt-1",attrs:{"icono":"mdi-close","smsToolTip":"Quitar nuevo tipo producto"},on:{"click":function($event){return _vm.$emit('clickdeletetypeproduct')}}}):_c('OBtnToolTipHeaderComp',{staticClass:"ml-1 mt-1",attrs:{"icono":"mdi-plus","smsToolTip":"Nuevo Tipo Producto"},on:{"click":function($event){return _vm.$emit('clicktypeproduct')}}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Descripción:"}},[_c('MTextAreaComp',{attrs:{"label":_vm.mobile ? 'Descripción' : ''},model:{value:(_vm.dataProduct.description),callback:function ($$v) {_vm.$set(_vm.dataProduct, "description", $$v)},expression:"dataProduct.description"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Laboratorio:"}},[(_vm.isNewLaboratory)?_c('MTextFieldComp',{attrs:{"disabled":true,"label":_vm.mobile ? 'Laboratorio' : ''},model:{value:(_vm.dataLaboratory.laboratory),callback:function ($$v) {_vm.$set(_vm.dataLaboratory, "laboratory", $$v)},expression:"dataLaboratory.laboratory"}}):_c('div',{staticStyle:{"width":"100%"}},[_c('div',{class:_vm.dataProduct.activate_error && _vm.dataProduct.fk_laboratory == null
                        ? 'product__search-laboratories product__search-error d-flex align-center pl-3'
                        : 'product__search-laboratories d-flex align-center pl-3 mb-2',on:{"click":function($event){return _vm.$emit('clicklaboratoryform')}}},[(_vm.mobile)?_c('h2',[_vm._v("Laboratorio")]):_vm._e(),_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(_vm.dataProduct.fk_laboratory==null ? 'Sin elección' : _vm.dataProduct.laboratory.laboratory)+" ")])]),(_vm.dataProduct.activate_error && _vm.dataProduct.fk_laboratory == null)?_c('h3',{staticClass:"app-error-form"},[_vm._v(" El laboratorio es requerido ")]):_vm._e()]),(_vm.isNewLaboratory)?_c('OBtnToolTipHeaderComp',{staticClass:"ml-1 mt-1",attrs:{"icono":"mdi-close","smsToolTip":"Nuevo Laboratorio"},on:{"click":function($event){return _vm.$emit('clickdeletelaboratory')}}}):_c('OBtnToolTipHeaderComp',{staticClass:"ml-1 mt-1",attrs:{"icono":"mdi-plus","smsToolTip":"Nuevo Laboratorio"},on:{"click":function($event){return _vm.$emit('clicknewlaboratory')}}})],1),_c('div',{class:_vm.isNewLaboratory
                ? 'd-flex justify-center'
                : 'd-flex justify-center mt-2'},[_c('MBtnNormalComp',{on:{"click":function($event){return _vm.eValidForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }