<template>
    <v-btn
        depressed
        :disabled="disabled"
        fab
        x-small
        :ripple="false"
        @click="$emit('click')"
        class="fondo-btn-cabecera"
    >
        <v-icon small class="s-menu-pc__list-icon">{{icon}}</v-icon>
    </v-btn>
</template>
<script>
export default {
    props: {
        icon: String,
        disabled: Boolean
    },
    data: () => ({
        size: 42
    })
}
</script>
