<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <h1 class="app-title">
                {{product.product}}
            </h1>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Cantidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.quantity}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Precio Unidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{parseFloat(product.unit_price).toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Total:</h1>
                <h1 class="app-subtitle-dark">
                    {{mPTotalPrice(product).toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Descuento. Comercial:</h1>
                <h1 class="app-subtitle-dark">
                    {{parseFloat(product.commercial_discount).toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Otro Descuento:</h1>
                <h1 class="app-subtitle-dark">
                    {{parseFloat(product.other_discount).toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Total con Descuento:</h1>
                <h1 class="app-subtitle-dark">
                    {{mPTotalPriceWithDiscount(product).toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha Expiración:</h1>
                <h1 class="app-subtitle-dark">
                    {{mDateFormat(product.expiration_date)}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-delete"
                style="margin:2px"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import {
    datesMixin,
    purchaseMixin
} from "../../mixins";
import { OBtnCardComp } from "../organisms";
export default {
    mixins: [
        datesMixin,
        purchaseMixin
    ],
    props: {
        product: Object
    },
    components: {
        OBtnCardComp
    }
}
</script>