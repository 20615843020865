<template>
    <v-dialog
        persistent
        :value="value"
        max-width="800"
    >
        <v-card>
            <div class="s-card-client__header pl-2 pr-2 d-flex align-center">
                <h1 class="app-title">
                    DETALLE VENTA
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <div class="client__line-header"></div>
            <div class="pa-2 pb-0 pt-1">
                <OInfoSaleComp
                    :sale="sale"
                />
            </div>
            <v-divider class="mt-1"></v-divider>
            <STableDetailReportSaleComp
                v-if="!mobile"
                :isInfo="true"
                :detailSale="sale.detail"
            />
            <div
                class="d-flex justify-center flex-wrap"
                v-else
            >
                <SCardDetailSaleComp
                    class="ma-2"
                    v-for="product in sale.detail"
                    :key="product.id"
                    :product="product"
                    :isInfo="true"
                />
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    OBtnCardComp,
    OInfoSaleComp
} from "../organisms";
import {
    STableDetailReportSaleComp,
    SCardDetailSaleComp
} from "../species";
export default {
    props: {
        value: Boolean,
        sale: Object,
        mobile: Boolean
    },
    components: {
        OBtnCardComp,
        OInfoSaleComp,
        STableDetailReportSaleComp,
        SCardDetailSaleComp
    }
}
</script>