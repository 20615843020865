var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"e-table-product animation-opacity"},[_c('table',{staticClass:"e-table-product__table"},[_c('thead',[_c('tr',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('th',{attrs:{"width":"30"}}),(!_vm.isInfo)?_c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Acciones")])]):_vm._e()])]),_c('tbody',[_vm._l((_vm.detailSale),function(product,index){return _c('tr',{key:index},[_c('td',[_c('h1',{staticClass:"app-subtitle text-right"},[_vm._v(" "+_vm._s((index+1))+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle text-right"},[_vm._v(" "+_vm._s(product.product)+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle text-right"},[_vm._v(" "+_vm._s(`${(product.pivot.unit_price+product.pivot.discount).toFixed(2)} Bs.`)+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle text-right"},[_vm._v(" "+_vm._s(`${product.pivot.discount.toFixed(2)} Bs.`)+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle text-right"},[_vm._v(" "+_vm._s(`${product.pivot.unit_price.toFixed(2)} Bs.`)+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle text-right"},[_vm._v(" "+_vm._s(product.pivot.quantity)+" ")])]),_c('td',[_c('h1',{staticClass:"app-subtitle text-right"},[_vm._v(" "+_vm._s(`${(product.pivot.quantity * product.pivot.unit_price).toFixed(2)} Bs.`)+" ")])]),_c('td',[_c('div',{class:product.pivot.discount>0
                            ? 'client__ornament-disabled'
                            : 'client__ornament-enabled'})]),(!_vm.isInfo)?_c('td',{staticClass:"d-flex justify-center flex-wrap"},[_c('OBtnToolTipTableComp',{attrs:{"smsToolTip":"Ver movimiento inventario","icon":"mdi-format-list-text"},on:{"click":function($event){return _vm.$emit('clickinventory', product)}}})],1):_vm._e()])}),(_vm.detailSale.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":_vm.isInfo ? '5' : '6'}},[_c('h1',{staticClass:"app-subtitle text-center"},[_vm._v(" SIN INFORMACIÓN ")])])]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"50px"}},[_c('h1',{staticClass:"app-title-dark text-center"},[_vm._v("N°")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"105"}},[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Product")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("P.U.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Des.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("P.V.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Cant.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"app-title-dark text-left"},[_vm._v("Total Precio")])])
}]

export { render, staticRenderFns }