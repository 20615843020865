<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Laboratorio</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Ubicación</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left text-center">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(laboratory, index) in laboratoriesList"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <text-highlight
                            class="app-subtitle"
                            :queries="search"
                        >
                            {{laboratory.laboratory}}
                        </text-highlight>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{laboratory.location!=null ? laboratory.location : 'S/U'}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            :smsToolTip="`Editar lab.: ${laboratory.laboratory}`"
                            icon="mdi-pencil"
                            @click="$emit('clickedit', laboratory)"
                        />
                        <OBtnToolTipTableComp
                            :smsToolTip="`Eliminar lab.: ${laboratory.laboratory}`"
                            icon="mdi-delete"
                            class="ml-1"
                            @click="$emit('clickdelete', laboratory)"
                        />
                    </td>
                </tr>
                <tr v-if="laboratoriesList.length === 0">
                    <td colspan="4">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import { OBtnToolTipTableComp } from "../organisms";
export default {
    components: {
        OBtnToolTipTableComp,
        TextHighlight
    },
    props: {
        laboratoriesList: Array,
        search: {
            type: String,
            default: ''
        }
    }
}
</script>