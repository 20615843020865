<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <h1 class="app-title">
                {{user.full_name}}
            </h1>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex align-center">
                <h1 class="app-title-dark mr-1">Habilitado:</h1>
                <h1 class="app-subtitle-dark">
                    {{user.disabled==1 ? "NO" : "SI"}}
                </h1>
                <div
                    :class="user.disabled==1
                        ? 'client__ornament-disabled ml-2'
                        : 'client__ornament-enabled ml-2'"
                    style="margin-top:-2px;"
                >
                </div>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Categoría:</h1>
                <h1 class="app-subtitle-dark">
                    {{user.categorie}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Username:</h1>
                <h1 class="app-subtitle-dark">
                    {{user.username}}
                </h1>
            </div>
        </div>
        <div
            v-if="mobile"
            class="s-card-client__actions d-flex justify-center flex-wrap"
        >
            <OBtnCardComp
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
            <OBtnCardComp
                :icon="user.disabled==1 ? 'mdi-eye' : 'mdi-eye-off'"
                style="margin:2px"
                @click="$emit('clickstate')"
            />
            <OBtnCardComp
                icon="mdi-account-lock"
                style="margin:2px"
                @click="$emit('clickcredentials')"
            />
        </div>
        <div
            v-else
            class="s-card-client__actions d-flex justify-center flex-wrap p1-1 pb-1"
        >
            <OBtnToolTipTableComp
                :smsToolTip="`Editar usuario de: ${user.full_name}`"
                icon="mdi-pencil"
                @click="$emit('clickedit')"
            />
            <OBtnToolTipTableComp
                class="ml-1 mr-1"
                :smsToolTip="user.disabled==1
                    ? `Habilitar a: ${user.full_name}`
                    : `Deshabilitar a: ${user.full_name}`"
                :icon="user.disabled==1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click="$emit('clickstate')"
            />
            <OBtnToolTipTableComp
                :smsToolTip="`Cambiar credenciales de: ${user.full_name}`"
                icon="mdi-account-lock"
                @click="$emit('clickcredentials')"
            />
        </div>
    </div>
</template>
<script>
import {
    OBtnCardComp,
    OBtnToolTipTableComp
} from "../organisms";
export default {
    props: {
        user: Object,
        mobile: Boolean
    },
    components: {
        OBtnCardComp,
        OBtnToolTipTableComp
    }
}
</script>