function unidades(num) {
    switch (num) {
        case 1:
            return 'UN';
        case 2:
            return 'DOS';
        case 3:
            return 'TRES';
        case 4:
            return 'CUATRO';
        case 5:
            return 'CINCO';
        case 6:
            return 'SEIS';
        case 7:
            return 'SIETE';
        case 8:
            return 'OCHO';
        case 9:
            return 'NUEVE';
    }
    return '';
}
function decenas(num) {
    let decena = Math.floor(num / 10);
    let unidad = num - (decena * 10);
    switch (decena) {
        case 1:
            switch (unidad) {
                case 0:
                    return 'DIEZ';
                case 1:
                    return 'ONCE';
                case 2:
                    return 'DOCE';
                case 3:
                    return 'TRECE';
                case 4:
                    return 'CATORCE';
                case 5:
                    return 'QUINCE';
                default:
                    return 'DIECI' + unidades(unidad);
            }
        case 2:
            switch (unidad) {
                case 0:
                    return 'VEINTE';
                default:
                    return 'VEINTI' + unidades(unidad);
            }
        case 3:
            return decenasY('TREINTA', unidad);
        case 4:
            return decenasY('CUARENTA', unidad);
        case 5:
            return decenasY('CINCUENTA', unidad);
        case 6:
            return decenasY('SESENTA', unidad);
        case 7:
            return decenasY('SETENTA', unidad);
        case 8:
            return decenasY('OCHENTA', unidad);
        case 9:
            return decenasY('NOVENTA', unidad);
        case 0:
            return unidades(unidad);
    }
} //Unidades()
function decenasY(strSin, numUnidades) {
    if (numUnidades > 0)
        return strSin + ' Y ' + unidades(numUnidades)

    return strSin;
} //DecenasY()
function centenas(num) {
    let centena = Math.floor(num / 100);
    let decena = num - (centena * 100);
    switch (centena) {
        case 1:
            if (decena > 0)
                return 'CIENTO ' + decenas(decena);
            return 'CIEN';
        case 2:
            return 'DOSCIENTOS ' + decenas(decena);
        case 3:
            return 'TRESCIENTOS ' + decenas(decena);
        case 4:
            return 'CUATROCIENTOS ' + decenas(decena);
        case 5:
            return 'QUINIENTOS ' + decenas(decena);
        case 6:
            return 'SEISCIENTOS ' + decenas(decena);
        case 7:
            return 'SETECIENTOS ' + decenas(decena);
        case 8:
            return 'OCHOCIENTOS ' + decenas(decena);
        case 9:
            return 'NOVECIENTOS ' + decenas(decena);
    }

    return decenas(decena);
} //Centenas()
function seccion(num, divisor, strSingular, strPlural) {
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let letras = '';

    if (cientos > 0)
        if (cientos > 1)
            letras = centenas(cientos) + ' ' + strPlural;
        else
            letras = strSingular;

    if (resto > 0)
        letras += '';
    return letras;
} //Seccion()
function miles(num) {
    let divisor = 1000;
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let strMiles = seccion(num, divisor, 'UN MIL', 'MIL');
    let strCentenas = centenas(resto);

    if (strMiles == '')
        return strCentenas;

    return strMiles + ' ' + strCentenas;
} //Miles()
function millones(num) {
    let divisor = 1000000;
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let strMillones = seccion(num, divisor, 'UN MILLON DE', 'MILLONES DE');
    let strMiles = miles(resto);

    if (strMillones == '')
        return strMiles;

    return strMillones + ' ' + strMiles;
} //Millones()
function textIntegerNumber(number, numberString) {
    if (numberString.length === 1) return unidades(number);
    if (numberString.length === 2) return decenas(number);
    if (numberString.length === 3) return centenas(number);
    if (numberString.length === 4) return miles(number);
    return millones(number);
}
function textDecimalNumber(decimal) {
    return `${decimal}/100`;
}
function textPrice(price) {
    const [integer, decimal] = price.split('.');
    const textInteger = textIntegerNumber(parseInt(integer), `${integer}`);
    const textDecimal = textDecimalNumber(decimal);
    return `${textInteger} Y ${textDecimal}`;
}
export default {
    textPrice
}