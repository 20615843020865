<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <h1 class="app-title">
                {{product.product}}
            </h1>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Tipo Producto:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.type_product.type_product}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Laboratorio:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.laboratory.laboratory}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Cantidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.quantity_order}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-list-box"
                style="margin:2px"
                @click="$emit('clickdetail')"
            />
            <OBtnCardComp
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
            <OBtnCardComp
                icon="mdi-delete"
                style="margin:2px"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import {
    OBtnCardComp
} from "../organisms";
export default {
    props: {
        product: Object
    },
    components: {
        OBtnCardComp
    }
}
</script>