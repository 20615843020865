<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <text-highlight
                class="app-title"
                :queries="search"
            >
                {{client.full_name}}
            </text-highlight>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">CI/NIT:</h1>
                <text-highlight
                    class="app-subtitle-dark"
                    :queries="search"
                >
                    {{client.ci_nit}}
                </text-highlight>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Complemento:</h1>
                <h1 class="app-subtitle-dark">
                    {{client.complement!=null ? client.complement : '---'}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Entidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{client.entity == 0 ? 'Persona' : 'Empresa'}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Celular:</h1>
                <h1 class="app-subtitle-dark">
                    {{client.phone!=null ? client.phone : 'S/N'}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Correo:</h1>
                <h1 class="app-subtitle-dark">
                    {{client.e_mail!=null ? client.e_mail : 'S/C'}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha Reg.:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${client.hour} ${client.date}`}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
        </div>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import { OBtnCardComp } from "../organisms";
export default {
    props: {
        client: Object,
        search: {
            type: String,
            default: ''
        }
    },
    components: {
        TextHighlight,
        OBtnCardComp
    }
}
</script>