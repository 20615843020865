<template>
    <div>
        <div class="d-flex">
            <h1 class="app-title mr-1">Venta:</h1>
            <h1 class="app-subtitle">
                {{`${sale.sale.toFixed(2)} Bs.`}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="app-title mr-1">Descuento:</h1>
            <h1 class="app-subtitle">
                {{`${sale.discount.toFixed(2)} Bs.`}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="app-title mr-1">Cliente:</h1>
            <h1 class="app-subtitle">
                {{`${sale.client.full_name}`}}
            </h1>
        </div>
        <div class="d-flex">
            <h1 class="app-title mr-1">Tipo Pago:</h1>
            <h1 class="app-subtitle">
                {{sale.type_sale == "e" ? "Efectivo" : "Pago QR"}}
            </h1>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        sale: Object
    }
}
</script>