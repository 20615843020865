export default {
    methods: {
        mPrintShow(element) {
            let printSection = document.getElementById('printSection');
            var domClone = element.cloneNode(true);
            var nodoImg = document.createElement("img");
            nodoImg.setAttribute("src", '/imgs/logo.png');
            nodoImg.onload = function() {
                printSection.appendChild(domClone);
                window.print();
                printSection.innerHTML = "";
            }
        }
    }
}