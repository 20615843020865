<template>
    <div>
        <div class="d-flex pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">CAJAS REGISTRADORAS</h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex">
                <OBtnToolTipHeaderComp
                    icono="mdi-plus"
                    class="mr-1"
                    smsToolTip="Registrar caja registradora"
                    @click="eOpenForm()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    smsToolTip="Volver a cajas registradoras"
                    @click="eBackTableList()"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="cashRegisterList.length==0 && !dialogLoaderData"
                />
                <div class="d-flex justify-center flex-wrap">
                    <SCardCashRegisterComp
                        class="ma-2"
                        v-for="cashRegister in cashRegisterList"
                        :key="cashRegister.id"
                        :mobile="mobile"
                        :cashRegister="cashRegister"
                        @clickedit="eOpenFormEdit(cashRegister)"
                        @clickdelete="eConfirmDelete(cashRegister)"
                        @clickstate="eConfirmChangeState(cashRegister)"
                        @clickenabled="eEnabledCashRegisterDevice(cashRegister)"
                        @clickcash="eConfirmChangeDiscount(cashRegister)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center mt-3 pl-2 pr-2 pb-2">
                    <SFormCashRegisterComp
                        ref="formcashregistercomp"
                        :dataCashRegister="dataCashRegister"
                        :mobile="mobile"
                        :indexCashRegister="indexCashRegister"
                        @saveform="eConfirmForm"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="eCloseDialogConfirm()"
            @clickyes="ePerformDatabaseAction()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OLoaderDataComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp
} from "../../components/organisms";
import {
    SCardCashRegisterComp,
    SFormCashRegisterComp
} from "../../components/species";
import {
    CashRegister
} from "../../models";
import {
    dataGeneralCompsMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
export default {
    mixins: [
        dataGeneralCompsMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    props: {
        mini: Boolean
    },
    components: {
        OBtnToolTipHeaderComp,
        SCardCashRegisterComp,
        OLoaderDataComp,
        MNoDataComp,
        SFormCashRegisterComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp
    },
    data: () => ({
        cashRegisterList: [],
        tab: 0,
        indexCashRegister: -1,
        dataCashRegister: new CashRegister(),
        idCashRegister: 0,
        isChangeState: false,
        isEnabledDevice: false,
        cashRegister: {},
        isChangeDiscount: false
    }),
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else {
                if (this.idCashRegister > 0) {
                    this.idCashRegister = 0;
                    this.indexCashRegister = -1;
                }
                this.dialogError = false;
            }
        },
        handleKeyUpCashRegister(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 0) this.eCloseDialogConfirm();
                if (actionKeyBoard === 1) this.ePerformDatabaseAction();
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getCasRegisters() {
            this.dialogLoaderData = true;
            try {
                const response = await CashRegister.getCashRegisters();
                this.cashRegisterList = response;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eBackTableList() {
            if (this.tab !== 0) {
                this.tab = 0;
                this.indexCashRegister = -1;
                this.dataCashRegister = new CashRegister();
                this.$refs.formcashregistercomp.resetForm();
                this.dialogLoaderOperation = false;
            }
        },
        eConfirmForm(isValidForm) {
            const smsConfirm = this.indexCashRegister === -1
                ? [`¿Está seguro(a) de registrar caja N°: ${this.dataCashRegister.cash_register}?`]
                : [`¿Está seguro(a) de editar a caja N°: ${this.dataCashRegister.cash_register}?`]
            this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
        },
        eCloseDialogConfirm() {
            this.isEnabledDevice = false;
            if (this.idCashRegister > 0) {
                this.idCashRegister = 0;
                this.indexCashRegister = -1;
                this.isChangeState = false;
                this.isChangeDiscount = false;
            }
            this.dialogConfirm = false;
        },
        resetSharedProperties() {
            this.idCashRegister = 0;
            this.indexCashRegister = -1;
            this.dialogLoaderOperation = false;
            this.isChangeState = false;
            this.isChangeDiscount = false;
        },
        async addCashRegister() {
            try {
                const response = await CashRegister.addCashRegisters(this.dataCashRegister);
                this.cashRegisterList.unshift(response);
                this.total += 1;
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se registró el n° de caja"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async editCashRegister() {
            try {
                const response = await CashRegister.editCashRegisters(this.dataCashRegister);
                Object.assign(this.cashRegisterList[this.indexCashRegister], response);
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se editó el n° de caja"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async deleteCashRegister() {
            try {
                await CashRegister.deleteCashRegister({id: this.idCashRegister});
                this.cashRegisterList.splice(this.indexCashRegister, 1);
                this.total -= 1;
                this.resetSharedProperties();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se eliminó el n° de caja"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async changeStateCashRegister() {
            try {
                await CashRegister.changeStateCashRegister({id: this.idCashRegister});
                this.cashRegisterList[this.indexCashRegister].disabled = this.cashRegisterList[this.indexCashRegister].disabled === 1 ? 0 : 1;
                this.resetSharedProperties();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se cambió el estado del n° de caja"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        enabledDevice() {
            const parameters = {
                'cash_register': this.cashRegister.cash_register,
                'id_cash_register': this.cashRegister.id
            }
            localStorage.setItem('device', JSON.stringify(parameters))
            setTimeout(() => {
                this.isEnabledDevice = false;
                this.dialogLoaderOperation = false;
                this.mDataGeneralShowSmsAction(["Operación Exitosa",
                    `Se habilitó la caja N° ${this.cashRegister.cash_register}`], "success");
            }, 300);
        },
        async changeDiscount() {
            try {
                await CashRegister.changeStateDiscount({id: this.idCashRegister});
                const sms = this.cashRegisterList[this.indexCashRegister].activate_discount === 1
                    ? "Se inhabilitó el descuento" : "Se habilitó el descuento";
                this.cashRegisterList[this.indexCashRegister].activate_discount = this.cashRegisterList[this.indexCashRegister].activate_discount === 1 ? 0 : 1;
                this.resetSharedProperties();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", sms], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (this.isChangeDiscount) this.changeDiscount();
            else {
                if (this.isEnabledDevice) this.enabledDevice()
                else {
                    if (this.isChangeState) this.changeStateCashRegister();
                    else {
                        if (this.idCashRegister > 0) this.deleteCashRegister();
                        else {
                            if (this.indexCashRegister !== -1)
                                this.editCashRegister();
                            else this.addCashRegister();
                        }
                    }
                }
            }
        },
        eOpenFormEdit(cashRegister) {
            this.indexCashRegister = this.cashRegisterList.indexOf(cashRegister);
            this.dataCashRegister = Object.assign({}, cashRegister);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
                setTimeout(() => {
                    this.$refs.formcashregistercomp.activateFocus();
                }, 150);
            }, time);
        },
        eConfirmDelete(cashRegister) {
            this.idCashRegister = cashRegister.id;
            this.indexCashRegister = this.cashRegisterList.indexOf(cashRegister);
            this.smsConfirm = [`¿Está seguro(a) de eliminar la caja n° ${cashRegister.cash_register}?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.dialogConfirm = true;
            }, time);
        },
        eConfirmChangeState(cashRegister) {
            this.idCashRegister = cashRegister.id;
            this.indexCashRegister = this.cashRegisterList.indexOf(cashRegister);
            this.isChangeState = true;
            const sms = cashRegister.disabled === 1 ? 'habilitar' : 'deshabilitar';
            this.smsConfirm = [`¿Está seguro(a) de, ${sms} la caja n°: ${cashRegister.cash_register}?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.dialogConfirm = true;
            }, time);
        },
        eEnabledCashRegisterDevice(cashRegister) {
            this.cashRegister = Object.assign({}, cashRegister);
            this.isEnabledDevice = true;
            this.smsConfirm = [`¿${this.mUserFullName()}, está seguro(a) de habilitar
                la caja N° ${cashRegister.cash_register} en este dispositivo?`];
            this.dialogConfirm = true;
        },
        eOpenForm() {
            this.tab = 1;
            setTimeout(() => {
                this.$refs.formcashregistercomp.activateFocus();
            }, 150);
        },
        //
        eConfirmChangeDiscount(cashRegister) {
            this.idCashRegister = cashRegister.id;
            this.indexCashRegister = this.cashRegisterList.indexOf(cashRegister);
            this.isChangeDiscount = true;
            const sms = cashRegister.activate_discount ? 'inhabilitar' : 'habilitar';
            this.smsConfirm = [`¿Está seguro(a) de, ${sms} el descuento de la caja n°: ${cashRegister.cash_register}?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.dialogConfirm = true;
            }, time);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpCashRegister);
                this.getCasRegisters();
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpCashRegister);
    }
}
</script>