<template>
    <div>
        <v-tooltip bottom content-class="color-tooltip">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :width="sizeBtn"
                    :max-width="sizeBtn"
                    :min-width="sizeBtn"
                    :height="sizeBtn"
                    :min-height="sizeBtn"
                    :max-height="sizeBtn"
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    :color="color"
                    class="fondo-btn-cabecera"
                    :ripple="false"
                    @click="$emit('click')"
                >
                    <v-icon small class="s-menu-pc__list-icon">{{icono}}</v-icon>
                </v-btn>
            </template>
            <MSmsToolTipComp
                :smsToolTip="smsToolTip"
            />
        </v-tooltip>
    </div>
</template>
<script>
import { MSmsToolTipComp } from "../molecules";
export default {
    components: {
        MSmsToolTipComp
    },
    props: {
        icono: String,
        smsToolTip: String,
        color: String
    },
    data: () => ({
        sizeBtn: '32'
    })
}
</script>
