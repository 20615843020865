<template>
    <v-dialog
        persistent
        width="500"
        :value="dialogLaboratory"
    >
        <v-card>
            <div class="s-card-client__header pl-2 pr-2 d-flex align-center">
                <h1 class="app-title">
                    NUEVO LABORATORIO
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <div class="client__line-header"></div>
            <SFormLaboratoryComp
                ref="formlaboratorycomp"
                :mobile="mobile"
                :dataLaboratory="dataLaboratory"
                :isAloneForm="true"
                textBtn="SELECCIONAR"
                @saveform="eSelectLaboratory"
            />
        </v-card>
    </v-dialog>
</template>
<script>
import { OBtnCardComp } from "../organisms";
import { SFormLaboratoryComp } from "../species";
export default {
    components: {
        SFormLaboratoryComp,
        OBtnCardComp
    },
    props: {
        dialogLaboratory: Boolean,
        dataLaboratory: Object,
        mobile: Boolean
    },
    methods: {
        eSelectLaboratory(isValidForm) {
            this.$emit('clickselect', isValidForm);
        },
        resetForm() {
            this.$refs.formlaboratorycomp.resetForm();
        }
    }
}
</script>