var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-card-client animation-opacity"},[_c('div',{staticClass:"s-card-client__header d-flex align-center"},[_c('h1',{staticClass:"app-title"},[_vm._v("CAJA N° "+_vm._s(_vm.cashRegister.cash_register))]),_c('v-spacer'),_c('div',{class:_vm.cashRegister.disabled==1
                ? 'client__ornament-disabled'
                : 'client__ornament-enabled'})],1),_c('v-card',{staticClass:"pl-1 pr-1 rounded-0",attrs:{"elevation":"0"}},[_c('v-divider'),_c('div',{staticClass:"d-flex mt-1"},[_c('h1',{staticClass:"app-title mr-1"},[_vm._v("Monto Caja Inicial:")]),_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(`${parseFloat(_vm.cashRegister.money_box).toFixed(2)} Bs.`)+" ")])]),_c('div',{staticClass:"d-flex align-center mb-1"},[_c('h1',{staticClass:"app-title mr-1"},[_vm._v("Descuento Activo:")]),_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(_vm.cashRegister.activate_discount ? "SI" : "NO")+" ")]),_c('div',{class:_vm.cashRegister.activate_discount
                    ? 'client__ornament-enabled ml-1'
                    : 'client__ornament-disabled ml-1',staticStyle:{"margin-top":"-2px"}})]),_c('v-divider')],1),(_vm.mobile)?_c('div',{staticClass:"s-card-client__actions d-flex justify-center flex-wrap"},[_c('OBtnCardComp',{staticStyle:{"margin":"2px"},attrs:{"icon":"mdi-pencil"},on:{"click":function($event){return _vm.$emit('clickedit')}}}),_c('OBtnCardComp',{staticStyle:{"margin":"2px"},attrs:{"icon":_vm.cashRegister.activate_discount ? 'mdi-cash-remove' : 'mdi-cash-minus'},on:{"click":function($event){return _vm.$emit('clickcash')}}}),_c('OBtnCardComp',{staticStyle:{"margin":"2px"},attrs:{"icon":_vm.cashRegister.disabled==1 ? 'mdi-eye' : 'mdi-eye-off'},on:{"click":function($event){return _vm.$emit('clickstate')}}}),_c('OBtnCardComp',{staticStyle:{"margin":"2px"},attrs:{"icon":"mdi-cash-register"},on:{"click":function($event){return _vm.$emit('clickenabled')}}}),_c('OBtnCardComp',{staticStyle:{"margin":"2px"},attrs:{"icon":"mdi-delete"},on:{"click":function($event){return _vm.$emit('clickdelete')}}})],1):_c('div',{staticClass:"s-card-client__actions d-flex justify-center flex-wrap p1-1 pb-1"},[_c('OBtnToolTipTableComp',{attrs:{"smsToolTip":`Editar caja n° ${_vm.cashRegister.cash_register}`,"icon":"mdi-pencil"},on:{"click":function($event){return _vm.$emit('clickedit')}}}),_c('OBtnToolTipTableComp',{attrs:{"smsToolTip":_vm.cashRegister.activate_discount ? 'Inhabilitar Descuento' : 'Habilitar Descuento',"icon":_vm.cashRegister.activate_discount ? 'mdi-cash-remove' : 'mdi-cash-minus'},on:{"click":function($event){return _vm.$emit('clickcash')}}}),_c('OBtnToolTipTableComp',{staticClass:"ml-1",attrs:{"smsToolTip":_vm.cashRegister.disabled==1
                ? `Habilitar caja n° ${_vm.cashRegister.cash_register}`
                : `Deshabilitar caja n° ${_vm.cashRegister.cash_register}`,"icon":_vm.cashRegister.disabled==1 ? 'mdi-eye' : 'mdi-eye-off'},on:{"click":function($event){return _vm.$emit('clickstate')}}}),_c('OBtnToolTipTableComp',{staticClass:"ml-1",attrs:{"smsToolTip":`Habilitar dispositivo para, Caja N° ${_vm.cashRegister.cash_register}`,"icon":"mdi-cash-register"},on:{"click":function($event){return _vm.$emit('clickenabled')}}}),_c('OBtnToolTipTableComp',{staticClass:"ml-1",attrs:{"smsToolTip":`Eliminar caja n° ${_vm.cashRegister.cash_register}`,"icon":"mdi-delete"},on:{"click":function($event){return _vm.$emit('clickdelete')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }