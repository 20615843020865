import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';
class Client {
    constructor (
        full_name = null,
        ci_nit = null,
        complement = null,
        entity = 0,
        phone = null,
        e_mail = null
    )
    {
        this.full_name = full_name;
        this.ci_nit = ci_nit;
        this.complement = complement;
        this.entity = entity;
        this.phone = phone;
        this.e_mail = e_mail;
    }
    static async getClients(page, search) {
        const response = await axios.get(
            `${apiUrls.CLIENT}/list?page=${page}&search=${search}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editClient(parameters) {
        const response = await axios.put(
            `${apiUrls.CLIENT}/edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getClientsSale(search) {
        const response = await axios.get(
            `${apiUrls.CLIENT}/search-sale?search=${search}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Client;