import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';
class PersonalControl {
    static async close(parameters) {
        const response = await axios.post(
            `${apiUrls.PERSONAL_CONTROL}/close`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async closeUser(parameters) {
        const response = await axios.post(
            `${apiUrls.PERSONAL_CONTROL}/close-user`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async report(page, idUser, idCashRegister) {
        const response = await axios.get(
            `${apiUrls.PERSONAL_CONTROL}/report?page=${page}&fk_user=${idUser}&fk_cash_register=${idCashRegister}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default PersonalControl;