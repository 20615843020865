import Vue from "vue"
import VueRouter from "vue-router"
import {
  Sales,
  Clients,
  Products,
  CashRegisters,
  Users,
  Inventories,
  Laboratories,
  TypesProducts,
  EntriesInventories,
  LowInventories,
  Providers,
  Purchases,
  Expenses,
  Informations,
  Home,
  ReportSales,
  CanceledSales,
  CloseBoxCashRegister,
  ExpenseAdmin,
  InformationAdmin,
  CloseBoxAdmin,
  ExpiringInventory,
  Settings,
  ControlUsers,
  StatisticsLaboratories,
  StatisticsProducts,
  AnnualStatistics,
  SaleMobile,
  PaymentsAdmin,
  PaymentsUser,
  SettingsBill,
  BillsReport,
  Orders,
  InventoryManual
} from "../views/private";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("../views/private/AppPrivate"),
      children: [
        {
          path: "/",
          name: "sales",
          component: Sales
        }, {
          path: "/clients",
          name: "clients",
          component: Clients
        }, {
          path: "/products",
          name: "products",
          component: Products
        }, {
          path: "/cash-registers",
          name: "cash-registers",
          component: CashRegisters
        }, {
          path: "/users",
          name: "users",
          component: Users
        }, {
          path: "/inventories",
          name: "inventories",
          component: Inventories
        }, {
          path: "/laboratories",
          name: "laboratories",
          component: Laboratories
        }, {
          path: "/types-products",
          name: "types-products",
          component: TypesProducts
        }, {
          path: "/entries-inventories",
          name: "entries-inventories",
          component: EntriesInventories
        }, {
          path: "/low-inventories",
          name: "low-inventories",
          component: LowInventories
        }, {
          path: "/providers",
          name: "providers",
          component: Providers
        }, {
          path: "/purchases",
          name: "purchases",
          component: Purchases
        }, {
          path: "/expenses",
          name: "expenses",
          component: Expenses
        }, {
          path: "/informations",
          name: "informations",
          component: Informations
        }, {
          path: "/home",
          name: "home",
          component: Home
        }, {
          path: "/report-sale",
          name: "report-sale",
          component: ReportSales
        }, {
          path: "/canceled-sales",
          name: "canceled-sales",
          component: CanceledSales
        }, {
          path: "/close-box-cash-register",
          name: "close-box-cash-register",
          component: CloseBoxCashRegister
        }, {
          path: "/expenses-admin",
          name: "expenses-admin",
          component: ExpenseAdmin
        }, {
          path: "/informations-admin",
          name: "informations-admin",
          component: InformationAdmin
        }, {
          path: "/close-box-admin",
          name: "close-box-admin",
          component: CloseBoxAdmin
        }, {
          path: "/expiring-inventory",
          name: "expiring-inventory",
          component: ExpiringInventory
        }, {
          path: "/settings",
          name: "settings",
          component: Settings
        }, {
          path: "/personal-control",
          name: "personal-control",
          component: ControlUsers
        }, {
          path: "/statistics-laboratories",
          name: "/statistics-laboratories",
          component: StatisticsLaboratories
        }, {
          path: "/statistics-products",
          name: "statistics-products",
          component: StatisticsProducts
        }, {
          path: "/annual-statistics",
          name: "annual-statistics",
          component: AnnualStatistics
        }, {
          path: "/sale-mobile",
          name: "sale-mobile",
          component: SaleMobile
        }, {
          path: "/payments-admin",
          name: "payments-admin",
          component: PaymentsAdmin
        }, {
          path: "/payments-user",
          name: "payments-user",
          component: PaymentsUser
        }, {
          path: "/setting-bill",
          name: "setting-bill",
          component: SettingsBill
        }, {
          path: "/bills-report",
          name: "bills-report",
          component: BillsReport
        }, {
          path: "/orders",
          name: "orders",
          component: Orders
        }, {
          path: "/lots",
          name: "lots",
          component: InventoryManual
        }
      ]
    },
    {
      path: '/login',
      component: () => import('../views/login/Login')
    },
    {
      path: '/migrations',
      component: () => import('../views/migrations/Migration')
    }
  ]
});
export default router;
