<template>
    <v-dialog
        persistent
        :value="valueDialog"
        max-width="600"
    >
        <v-card
            min-height="300"
        >
            <div class="s-detail-product__header pl-2 pr-2 d-flex align-center">
                <h1 class="app-title">
                    BUSCAR PRODUCTO
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <div class="client__line-header"></div>
            <div class="pa-2">
                <OSearchHeaderComp
                    ref="searchproduct"
                    label="Buscar"
                    :value="value"
                    @input="(e)=>(this.$emit('input', e))"
                    @entersearch="$emit('entersearch')"
                />
            </div>
            <v-divider></v-divider>
            <MNoDataComp
                smsNotData="SIN DATOS"
                class="ma-1"
                v-if="productsList.length==0 && !dialogLoaderData"
            />
            <div class="pa-2 pt-0">
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <div v-else>
                    <div
                        class="mt-2"
                        v-for="product in productsList"
                        :key="product.id"
                    >
                        <v-hover
                            v-slot="{ hover }"
                        >
                            <v-card
                                :class="hover ? 'fondo-btn-cabecera pa-1 pl-2 pr-2' : 'pa-1 pl-2 pr-2'"
                                :elevation="hover ? 1 : 0"
                                outlined
                                :ripple="false"
                                @click="$emit('clickproduct', product)"
                            >
                                <text-highlight
                                    :class="hover ? 'app-subtitle-dark' : 'app-subtitle'"
                                    :queries="searchProduct"
                                >
                                    {{product.product}}
                                </text-highlight>
                            </v-card>
                        </v-hover>
                    </div>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import { MNoDataComp } from "../molecules";
import TextHighlight from "vue-text-highlight";
import {
    OBtnCardComp,
    OSearchHeaderComp,
    OLoaderDataComp
} from "../organisms";
export default {
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: [String, Number],
        valueDialog: Boolean,
        productsList: Array,
        searchProduct: String,
        dialogLoaderData: Boolean
    },
    components: {
        OBtnCardComp,
        OSearchHeaderComp,
        TextHighlight,
        OLoaderDataComp,
        MNoDataComp
    },
    methods: {
        activateFocus() {
            this.$refs.searchproduct.activateFocus();
        }
    }
}
</script>