<template>
    <div>
        <div class="d-flex align-center pa-2">
            <OBtnToolTipHeaderComp
                v-if="!mobile"
                :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                smsToolTip="Ocultar Menú"
                @click="$emit('clickmini')"
            />
            <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                CONTROL PERSONAL
            </h1>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <div class="d-flex justify-center pt-2 pl-2 pr-2">
            <div class="product__search" style="margin-right:2px">
                <MSelectComp
                    label="Usuarios"
                    :items="usersList"
                    itemValue="id"
                    itemText="full_name"
                    :hideDetails="true"
                    v-model="idUser"
                    @change="eChangeUser"
                />
            </div>
            <div class="product__search" style="margin-left:2px">
                <MSelectComp
                    label="Caja Registradoras"
                    :items="cashRegisterList"
                    itemValue="id"
                    itemText="cash_register"
                    :hideDetails="true"
                    v-model="idCashRegister"
                    @change="eChangeCashRegister"
                />
            </div>
        </div>
        <v-divider class="mt-2"></v-divider>
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Información"
        />
        <MNoDataComp
            smsNotData="SIN DATOS"
            class="ma-1"
            v-if="personalControlList.length==0 && !dialogLoaderData && mobile"
        />
        <STablePersonalControlComp
            v-if="!dialogLoaderData && !mobile"
            :personalControlList="personalControlList"
            @clickclose="eConfirmCloseBox"
        />
        <div
            class="d-flex justify-center flex-wrap"
            v-if="!dialogLoaderData && mobile"
        >
            <SCardPersonalControlComp
                class="ma-2"
                v-for="personalControl in personalControlList"
                :key="personalControl.id"
                :personalControl="personalControl"
                @clickclose="eConfirmCloseBox(personalControl)"
            />
        </div>
        <v-divider
            class="mt-1"
            v-if="mobile && !dialogLoaderData && personalControlList.length>0"
        ></v-divider>
        <SPaginationComp
            class="mt-2 mb-2"
            v-if="!dialogLoaderData && personalControlList.length > 0"
            :condicionGo="currentPage < lastPage"
            :condicionBack="currentPage > 1"
            :titlePagination="'Pag. '+currentPage+' de '+lastPage"
            @clickback="eChangePage(false)"
            @clickgo="eChangePage(true)"
            @clickpage="eSearchForPage"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="dialogConfirm=false"
            @clickyes="eCloseBox()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MSelectComp,
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OLoaderDataComp,
    OSmsErrorComp,
    OSmsActionComp,
    OSmsConfirmationComp,
    OOperationLoaderComp
} from "../../components/organisms";
import {
    SPaginationComp,
    STablePersonalControlComp,
    SCardPersonalControlComp
} from "../../components/species";
import {
    PersonalControl,
    User,
    CashRegister
} from "../../models";
import {
    responseServerMixin,
    animationScrollMixin,
    userMixin,
    dataGeneralCompsMixin,
    datesMixin,
    rulesMixin
} from "../../mixins";
export default {
    mixins: [
        responseServerMixin,
        animationScrollMixin,
        userMixin,
        dataGeneralCompsMixin,
        datesMixin,
        rulesMixin
    ],
    props: {
        mini: Boolean
    },
    components: {
        MSelectComp,
        OBtnToolTipHeaderComp,
        MNoDataComp,
        OLoaderDataComp,
        SPaginationComp,
        STablePersonalControlComp,
        OSmsErrorComp,
        OSmsActionComp,
        SCardPersonalControlComp,
        OSmsConfirmationComp,
        OOperationLoaderComp
    },
    data: () => ({
        idUser: 0,
        usersList: [ {id:0, full_name: "Todos"} ],
        idCashRegister: 0,
        cashRegisterList: [ {id: 0, cash_register: "Todos"}],
        personalControlList: [],
        idPersonalControl: -1,
        indexPersonalControl: -1
    }),
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else this.dialogError = false;
        },
        handleKeyUpControlUser(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getRequest() {
            this.dialogLoaderData = true;
            try {
                const getUsersList = User.getUsers(0, 2);
                const getCashRegister = CashRegister.getCashRegisters();
                const getPersonalControl = PersonalControl.report(1, 0, 0);
                const responses = await Promise.all([getUsersList, getCashRegister, getPersonalControl]);
                responses[0].forEach(user => {
                    this.usersList.push(user);
                });
                responses[1].forEach(cash_register => {
                    this.cashRegisterList.push(cash_register);
                });
                this.personalControlList = responses[2].data;
                this.lastPage = responses[2].last_page;
                this.currentPage = responses[2].current_page;
                this.total = responses[2].total;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async getPersonalControls(page, idUser, idCashRegister) {
            this.dialogLoaderData = true;
            try {
                const response = await PersonalControl.report(page, idUser, idCashRegister);
                this.personalControlList = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getPersonalControls(this.currentPage, this.idUser, this.idCashRegister);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getPersonalControls(page, this.idUser, this.idCashRegister);
                }, time);
            }
        },
        eChangeUser(idUser) {
            this.getPersonalControls(this.currentPage, idUser, this.idCashRegister);
        },
        eChangeCashRegister(idCashRegister) {
            this.getPersonalControls(this.currentPage, this.idUser, idCashRegister);
        },
        async eCloseBox() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            try {
                const response = await PersonalControl.close({id: this.idPersonalControl});
                Object.assign(this.personalControlList[this.indexPersonalControl], response);
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se cerró caja"], "success");
                this.dialogLoaderOperation = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eConfirmCloseBox(personalClose) {
            this.smsConfirm = [`¿Está seguro(a) de cerrar caja del usuario: ${personalClose.user}?`];
            this.dialogConfirm = true;
            this.indexPersonalControl = this.personalControlList.indexOf(personalClose);
            this.idPersonalControl = personalClose.id;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpControlUser);
                this.getRequest();
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpControlUser);
    }
}
</script>