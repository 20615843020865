<template>
    <div class="s-form-product">
        <h1 class="app-title text-center mb-2">
            {{indexProvider===-1 ? 'REGISTRAR' : 'EDITAR'}}
        </h1>
        <v-form
            v-model="valid"
            lazy-validation
            ref="formprovider"
        >
            <ORowFormComp
                :mobile="mobile"
                informationRow="Proveedor:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Proveedor' : ''"
                    v-model="dataProvider.provider"
                    ref="provider"
                    :counter="50"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El proveedor es requerido',
                        value => (`${value}`).length<=50 || 'El proveedor, no debe exceder los 50 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Celular:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Celular' : ''"
                    v-model="dataProvider.phone"
                    :counter="10"
                    :rules="[
                        value => (`${value}`).length<=10 || 'El celular no debe exceder los 10 dígitos',
                        value => !eIsValidPhone(value) || 'El celular solo debe contener números'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Dirección:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Dirección' : ''"
                    v-model="dataProvider.address"
                    :counter="50"
                    :rules="[
                        value => (`${value}`).length<=50 || 'La dirección no debe exceder los 50 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Correo:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Correo' : ''"
                    v-model="dataProvider.e_mail"
                    :counter="50"
                    :rules="[
                        value => (`${value}`).length<=50 || 'El correo no debe exceder los 50 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Descripción:"
            >
                <MTextAreaComp
                    :label="mobile ? 'Descripción' : ''"
                    v-model="dataProvider.description"
                    :counter="200"
                    :rules="[
                        value => (`${value}`).length<=200 || 'La descripción no debe exceder los 200 dígitos'
                    ]"
                />
            </ORowFormComp>
        </v-form>
        <div class="d-flex justify-center mt-1">
            <MBtnNormalComp
                @click="eValidForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import {
    MBtnNormalComp,
    MTextFieldComp,
    MTextAreaComp
} from "../molecules";
import { ORowFormComp } from "../organisms";
import { rulesMixin } from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        MBtnNormalComp,
        ORowFormComp,
        MTextFieldComp,
        MTextAreaComp
    },
    props: {
        indexProvider: Number,
        mobile: Boolean,
        dataProvider: Object
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.$refs.formprovider.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.formprovider.resetValidation();
        },
        eIsValidPhone(input) {
            if (this.mRuleEmptyField(input)) return false;
            return !this.mRuleNumberInteger(input);
        },
        activateFocus() {
            this.$refs.provider.activateFocus();
        }
    }
}
</script>