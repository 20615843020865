<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header d-flex align-center">
            <div class="d-flex align-center">
                <div
                    :class="lot.expired
                        ? 'client__ornament-disabled mr-1'
                        : 'client__ornament-enabled mr-1'"
                    style="margin-top:-2px;"
                >
                </div>
                <h1 class="app-title">
                    {{lot.expired ? 'Expirado' : 'No Expirado'}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Cantidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{lot.quantity}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">N° Lote:</h1>
                <h1 class="app-subtitle-dark">
                    {{lot.lot_number}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Precio Compra Unidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{lot.purchase_price.toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha Expiración:</h1>
                <h1 class="app-subtitle-dark">
                    {{mDateFormat(lot.expiration_date)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">
                    {{getTitleDiffDays()}}
                </h1>
                <h1 class="app-subtitle-dark">
                    {{lot.diff_days}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Registro:</h1>
                <h1 class="app-subtitle-dark">
                    {{lot.id_purchase != 0 ? 'Por Compra' : 'Directo'}}
                </h1>
            </div>
        </div>
        <div
            v-if="mobile"
            class="s-card-client__actions d-flex justify-center flex-wrap"
        >
            <OBtnCardComp
                icon="mdi-receipt-text"
                style="margin:2px"
                @click="$emit('clickpurchase')"
            />
        </div>
        <div
            v-else
            class="s-card-client__actions d-flex justify-center flex-wrap p1-1 pb-1"
        >
            <OBtnToolTipTableComp
                :smsToolTip="`Información de compra`"
                icon="mdi-receipt-text"
                @click="$emit('clickpurchase')"
            />
        </div>
    </div>
</template>
<script>
import {
    OBtnCardComp,
    OBtnToolTipTableComp
} from "../organisms";
import { datesMixin } from "../../mixins";
export default {
    mixins: [ datesMixin ],
    components: {
        OBtnCardComp,
        OBtnToolTipTableComp
    },
    props: {
        mobile: Boolean,
        lot: Object
    },
    methods: {
        getTitleDiffDays() {
            if (this.lot.expired) return 'Días expirado:';
            return 'Días faltantes:';
        }
    }
}
</script>