<template>
    <table class="e-table-product__table animation-opacity">
        <thead>
            <tr>
                <th>
                    <h1 class="app-title-dark text-left">Producto</h1>
                </th>
                <th>
                    <h1 class="app-title-dark text-left">Cant.</h1>
                </th>
                <th>
                    <h1 class="app-title-dark text-left">P.U.</h1>
                </th>
                <th>
                    <h1 class="app-title-dark text-left">Total</h1>
                </th>
                <th>
                    <h1 class="app-title-dark text-left">Dcto. Comercial</h1>
                </th>
                <th>
                    <h1 class="app-title-dark text-left">Otro Dcto.</h1>
                </th>
                <th>
                    <h1 class="app-title-dark text-left">Total con Dcto.</h1>
                </th>
                <th>
                    <h1 class="app-title-dark text-left">Fecha EXP.</h1>
                </th>
                <th>
                    <h1 class="app-title-dark text-center"></h1>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="(product, index) in detailProducts"
                :key="index"
            >
                <td>
                    <h1 class="app-subtitle text-right">
                        {{product.product}}
                    </h1>
                </td>
                <td>
                    <h1 class="app-subtitle text-right">
                        {{product.quantity}}
                    </h1>
                </td>
                <td>
                    <h1 class="app-subtitle text-right">
                        {{parseFloat(product.unit_price).toFixed(2)}}
                    </h1>
                </td>
                <td>
                    <h1 class="app-subtitle text-right">
                        {{mPTotalPrice(product).toFixed(2)}}
                    </h1>
                </td>
                <td>
                    <h1 class="app-subtitle text-right">
                        {{parseFloat(product.commercial_discount).toFixed(2)}}
                    </h1>
                </td>
                <td>
                    <h1 class="app-subtitle text-right">
                        {{parseFloat(product.other_discount).toFixed(2)}}
                    </h1>
                </td>
                <td>
                    <h1 class="app-subtitle text-right">
                        {{mPTotalPriceWithDiscount(product).toFixed(2)}}
                    </h1>
                </td>
                <td>
                    <h1 class="app-subtitle text-right">
                        {{mDateFormat(product.expiration_date)}}
                    </h1>
                </td>
                <td class="d-flex justify-center flex-wrap">
                    <OBtnToolTipTableComp
                        :smsToolTip="`Editar: ${product.product}`"
                        icon="mdi-pencil"
                        @click="$emit('clickedit', product)"
                    />
                    <OBtnToolTipTableComp
                        :smsToolTip="`Quitar: ${product.product}`"
                        icon="mdi-delete"
                        @click="$emit('clickdelete', product)"
                    />
                </td>
            </tr>
            <tr v-if="detailProducts.length==0">
                <td colspan="9" class="text-center">
                    <h1 class="app-subtitle">Sin Productos</h1>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import {
    datesMixin,
    purchaseMixin
} from "../../mixins";
import { OBtnToolTipTableComp } from "../organisms";
export default {
    components: {
        OBtnToolTipTableComp
    },
    mixins: [
        datesMixin,
        purchaseMixin
    ],
    props: {
        detailProducts: Array
    }
}
</script>