<template>
    <div>
        <div class="client__header pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    {{`PROVEEDORES (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center client__header-block-second">
                <OSearchHeaderComp
                    label="Buscar"
                    v-model="search"
                    class="mr-1"
                    @entersearch="eSearchProviders()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-plus"
                    class="mr-1"
                    smsToolTip="Nuevo proveedor"
                    @click="eOpenFormProvider()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    smsToolTip="Volver a proveedores"
                    @click="eBackTableList()"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="providersList.length==0 && !dialogLoaderData && mobile"
                />
                <STableProviderComp
                    v-if="!dialogLoaderData && !mobile"
                    :providersList="providersList"
                    :search="searchFilter"
                    @clickedit="eShowFormEdit"
                    @clickdelete="eConfirmDelete"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderData && mobile"
                >
                    <SCardProviderComp
                        class="ma-2"
                        :search="searchFilter"
                        v-for="provider in providersList"
                        :key="provider.id"
                        :provider="provider"
                        @clickedit="eShowFormEdit(provider)"
                        @clickdelete="eConfirmDelete(provider)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="mobile && !dialogLoaderData && providersList.length>0"
                ></v-divider>
                <SPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderData && providersList.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center mt-3 pl-2 pr-2 pb-2">
                    <SFormProviderComp
                        :dataProvider="dataProvider"
                        :mobile="mobile"
                        ref="formprovidercomp"
                        :indexProvider="indexProvider"
                        @saveform="eConfirmForm"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="eCloseDialogConfirm()"
            @clickyes="ePerformDatabaseAction()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSearchHeaderComp,
    OLoaderDataComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp
} from "../../components/organisms";
import {
    SPaginationComp,
    STableProviderComp,
    SFormProviderComp,
    SCardProviderComp
} from "../../components/species";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    animationScrollMixin,
    rulesMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import { Provider } from "../../models";
export default {
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        animationScrollMixin,
        rulesMixin,
        userMixin,
        datesMixin
    ],
    props: {
        mini: Boolean
    },
    components: {
        OBtnToolTipHeaderComp,
        OSearchHeaderComp,
        MNoDataComp,
        OLoaderDataComp,
        SPaginationComp,
        STableProviderComp,
        SFormProviderComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        SCardProviderComp
    },
    data: () => ({
        search: "",
        tab: 0,
        providersList: [],
        indexProvider: -1,
        dataProvider: new Provider,
        idProvider: 0
    }),
    computed: {
        searchFilter: function() {
            return this.search !== null ? this.search : "";
        }
    },
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else {
                if (this.idProvider > 0) {
                    this.idProvider = 0;
                    this.indexProvider = -1;
                }
                this.dialogError = false;
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getProvider(page, search) {
            this.dialogLoaderData = true;
            try {
                const response = await Provider.getProviders(page, search);
                this.providersList = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eConfirmForm(isValidForm) {
            const smsConfirm = this.indexProvider === -1
                ? [`¿Está seguro(a) de registrar a: ${this.dataProvider.provider}?`]
                : [`¿Está seguro(a) de editar a a: ${this.dataProvider.provider}?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
            }, time);
        },
        eCloseDialogConfirm() {
            if (this.idProvider > 0) {
                this.idProvider = 0;
                this.indexProvider = -1;
            }
            this.dialogConfirm = false;
        },
        eBackTableList() {
            if (this.tab !== 0) {
                this.dialogLoaderOperation = false;
                this.tab = 0;
                this.dataProvider = new Provider;
                this.$refs.formprovidercomp.resetForm();
                this.indexProvider = -1;
            }
        },
        eShowFormEdit(provider) {
            this.indexProvider = this.providersList.indexOf(provider);
            this.dataProvider = Object.assign({}, provider);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
                setTimeout(() => {
                    this.$refs.formprovidercomp.activateFocus();
                }, 150);
            }, time);
        },
        async addProvider() {
            try {
                const response = await Provider.addProvider(this.dataProvider);
                this.providersList.unshift(response);
                this.total += 1;
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa",
                    "Se registró el proveedor"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async editProvider() {
            try {
                const response = await Provider.editProvider(this.dataProvider);
                Object.assign(this.providersList[this.indexProvider], response);
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa",
                    "Se editó el proveedor"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async deleteProvider() {
            try {
                await Provider.deleteProvider({id: this.idProvider});
                this.providersList.splice(this.indexProvider, 1);
                this.total -= 1;
                this.idProvider = 0;
                this.indexProvider = -1;
                this.dialogLoaderOperation = false;
                this.mDataGeneralShowSmsAction(["Operación Exitosa",
                    "Se eliminó el proveedor"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (this.idProvider > 0) this.deleteProvider();
            else if (this.indexProvider === -1) this.addProvider();
            else this.editProvider();
        },
        eConfirmDelete(provider) {
            this.idProvider = provider.id;
            this.indexProvider = this.providersList.indexOf(provider);
            this.smsConfirm = [`¿Está seguro(a) de eliminar a: ${provider.provider}?`];
            this.dialogConfirm = true;
        },
        eSearchProviders() {
            if (this.mRuleEmptyField(this.search)) this.search = "";
            this.getProvider(1, this.search);
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getProvider(this.currentPage, this.search);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getProvider(page, this.search);
                }, time);
            }
        },
        handleKeyUpProviders(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 0) this.eCloseDialogConfirm();
                if (actionKeyBoard === 1) this.ePerformDatabaseAction();
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        eOpenFormProvider() {
            this.tab = 1;
            setTimeout(() => {
                this.$refs.formprovidercomp.activateFocus();
            }, 150);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpProviders);
                this.getProvider(1, "");
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpProviders);
    }
}
</script>