<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Hora de Ingreso</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha Ingreso</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Hora de Salida</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha Salida</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">N° Caja</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Usuario</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left"></h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(personalControl, index) in personalControlList"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{personalControl.hour_box_opening}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{personalControl.date_box_opening}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{
                                personalControl.hour_close_box!=null
                                ? personalControl.hour_close_box
                                : 'S/H'
                            }}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{
                                personalControl.date_close_box!=null
                                ? personalControl.date_close_box
                                : 'S/F'
                            }}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{personalControl.cash_register.cash_register}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{personalControl.user}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            :smsToolTip="`Forzar cierre de: ${personalControl.user}`"
                            icon="mdi-account-remove"
                            @click="$emit('clickclose', personalControl)"
                        />
                    </td>
                </tr>
                <tr v-if="personalControlList.length === 0">
                    <td colspan="7">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    OBtnToolTipTableComp
} from "../organisms";
export default {
    components: {
        OBtnToolTipTableComp
    },
    props: {
        personalControlList: Array
    }
}
</script>