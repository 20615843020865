var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","value":_vm.value,"max-width":"400"}},[_c('div',{staticClass:"s-card-client__header"},[_c('div',{staticClass:"d-flex align-center"},[_c('h1',{staticClass:"app-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),_c('OBtnCardComp',{attrs:{"icon":"mdi-close"},on:{"click":function($event){return _vm.$emit('clickclose')}}})],1),_c('v-divider',{staticClass:"mt-1"}),(_vm.lot.product != undefined)?_c('div',[_c('div',{staticClass:"d-flex mt-1"},[_c('h1',{staticClass:"app-title mr-1"},[_vm._v("Producto:")]),_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(_vm.lot.product.product)+" ")])]),_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"app-title mr-1"},[_vm._v("Cantidad:")]),_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(_vm.lot.quantity)+" ")])]),_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"app-title mr-1"},[_vm._v("Fecha expiración:")]),_c('h1',{staticClass:"app-subtitle"},[_vm._v(" "+_vm._s(_vm.mDateFormat(_vm.lot.expiration_date))+" ")]),_c('div',{class:_vm.lot.expired
                        ? 'client__ornament-disabled ml-1'
                        : 'client__ornament-enabled ml-1',staticStyle:{"margin-top":"3px"}})]),_c('v-divider',{staticClass:"mb-4 mt-1"}),_c('v-form',{ref:"forminventory",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('MTextFieldComp',{ref:"deletequantity",attrs:{"label":"Cantidad","rules":[
                        value => !this.mRuleEmptyField(value) || 'La cantidad es requerido',
                        value => this.mRuleNumberInteger(value) || 'La cantidad debe ser un número',
                        value => !this.mRuleExceededNumber(value, _vm.lot.quantity) || 'La cantidad a dar de baja no puede ser superior a la del inventario'
                    ]},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}})],1)],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center mt-1 mb-2"},[_c('MBtnNormalComp',{on:{"click":function($event){return _vm.eValidForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }