const domain = process.env.VUE_APP_ROOT_API;
export const apiUrls = {
    CLIENT: `${domain}client`,
    PRODUCT: `${domain}product`,
    CASH_REGISTER: `${domain}cash-register`,
    USER: `${domain}user`,
    LABORATORY: `${domain}laboratory`,
    TYPE_PRODUCT: `${domain}type-product`,
    SETTING: `${domain}setting`,
    INVENTORY: `${domain}inventory`,
    LOW_INVENTORY: `${domain}low-inventory`,
    PROVIDER: `${domain}provider`,
    PURCHASE: `${domain}purchase`,
    PRODUCT_PURCHASE: `${domain}product-purchase`,
    EXPENSE: `${domain}expense`,
    INFORMATION: `${domain}information`,
    SALE: `${domain}sale`,
    REPORT: `${domain}report`,
    PERSONAL_CONTROL: `${domain}personal-control`,
    PAYMENT: `${domain}payment`,
    PAYMENT_COMPLIANCE: `${domain}payment-compliance`,
    SETTING_BILL: `${domain}setting-bill`,
    MIGRATION: `${domain}migration`
}