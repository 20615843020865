<template>
    <div class="s-table-detail-sale animation-opacity">
        <table class="s-table-detail-sale__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="app-sale-title-dark text-center">Cliente</h1>
                    </th>
                    <th>
                        <h1 class="app-sale-title-dark text-center">CI/NIT</h1>
                    </th>
                    <th width="95">
                        <h1 class="app-sale-title-dark text-center">Fecha Reg.</h1>
                    </th>
                    <th width="65">
                        <h1 class="app-sale-title-dark text-center">N° Caja</h1>
                    </th>
                    <th>
                        <h1 class="app-sale-title-dark text-center">T.V.</h1>
                    </th>
                    <th>
                        <h1 class="app-sale-title-dark text-center">Desc.</h1>
                    </th>
                    <th width="30"></th>
                    <th width="60">
                        <h1 class="app-sale-title-dark text-center">Pago</h1>
                    </th>
                    <th width="50px"></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="sale in salesList"
                    :key="sale.id"
                >
                    <td>
                        <h1 class="app-sale-subtitle text-right">
                            {{sale.client.full_name}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-sale-subtitle text-right">
                            {{sale.client.ci_nit}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-sale-subtitle text-right">
                            {{`${sale.date}`}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-sale-subtitle text-right">
                            {{sale.cash_register.cash_register}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-sale-subtitle text-right">
                            {{sale.sale.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-sale-subtitle text-right">
                            {{sale.discount.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex justify-center align-center">
                            <div
                                :class="sale.disabled==1
                                    ? 'client__ornament-disabled'
                                    : 'client__ornament-enabled'"
                            >
                            </div>
                        </div>
                    </td>
                    <td>
                        <h1 class="app-sale-subtitle text-right">
                            {{sale.type_sale == "e"
                                ? "Efectivo"
                                : sale.type_sale == "t"
                                    ? "Tarjeta"
                                    : "Qr"
                            }}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex align-center justify-center">
                            <div>
                                <v-menu
                                    transition="slide-x-transition"
                                    bottom
                                    right
                                    offset-y
                                    open-on-hover
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            x-small
                                            v-on="on"
                                            v-bind="attrs"
                                            outlined
                                            class="color-border-btn"
                                        >
                                            <v-icon x-small>mdi-text-box</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card class="pa-1">
                                        <v-list-item
                                            v-for="(option, index) in options"
                                            :key="index"
                                            class="s-menu-pc__menu-option"
                                            @click="$emit(option.nameEmit, sale)"
                                            :ripple="false"
                                        >
                                            <v-icon class="color-icon mr-2">{{option.icon}}</v-icon>
                                            <h1 class="app-subtitle">{{option.title}}</h1>
                                        </v-list-item>
                                    </v-card>
                                </v-menu>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-if="salesList.length === 0">
                    <td colspan="9">
                        <h1 class="app-sale-subtitle text-center">
                            SIN TRANSACCIONES
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    MTextFieldHeaderComp
} from "../molecules";
import {
    OBtnToolTipHeaderComp
} from "../organisms";
import {
    userMixin
} from "../../mixins";
export default {
    mixins: [ userMixin ],
    components: {
        MTextFieldHeaderComp,
        OBtnToolTipHeaderComp
    },
    props: {
        salesList: Array
    },
    data: () => ({
        options: [
            {
                icon: "mdi-information",
                title: "Información Venta",
                nameEmit: "clickinfo"
            },
            {
                icon: "mdi-list-box",
                title: "Detalle Venta",
                nameEmit: "clickdetail"
            },
            {
                icon: "mdi-account-cash",
                title: "Cambiar tipo de pago",
                nameEmit: "clicktypesale"
            }
        ]
    }),
    mounted() {
        if (this.mUserCategorie() === "0")
            this.options.push(
                {
                    icon: "mdi-delete",
                    title: "Anular Venta",
                    nameEmit: "clickchangestate"
                }
            );
    }
}
</script>