<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <text-highlight
                class="app-title"
                :queries="search"
            >
                {{product.product}}
            </text-highlight>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex align-center">
                <h1 class="app-title-dark mr-1">Cantidad:</h1>
                <div
                    :class="product.quantity > 0
                        ? 'client__ornament-enabled'
                        : 'client__ornament-disabled'"
                    style="margin-top:-3px"
                >
                </div>
                <h1 class="app-subtitle-dark ml-1">
                    {{product.quantity}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Precio:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${product.sale_price.toFixed(2)}`}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Ubicación:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.location !== null ? product.location : "S/U"}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-check-bold"
                style="margin:2px"
                @click="$emit('clickproduct')"
            />
        </div>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import { OBtnCardComp } from "../organisms";
export default {
    props: {
        product: Object,
        search: {
            type: String,
            default: ''
        }
    },
    components: {
        TextHighlight,
        OBtnCardComp
    }
}
</script>