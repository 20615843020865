<template>
    <div>
        <div class="d-flex align-center pa-2">
            <OBtnToolTipHeaderComp
                v-if="!mobile"
                :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                smsToolTip="Ocultar Menú"
                @click="$emit('clickmini')"
            />
            <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                GASTOS ADMIN
            </h1>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <div class="d-flex justify-center flex-wrap">
            <div class="product__group-search d-flex justify-center pt-2 pl-2 pr-2">
                <div class="product__search" style="margin-right:2px">
                    <MSelectComp
                        label="Usuario"
                        :items="userList"
                        itemValue="id"
                        itemText="full_name"
                        :hideDetails="true"
                        v-model="idUser"
                        @change="eChangeUser"
                    />
                </div>
                <div class="product__search" style="margin-left:2px">
                    <MSelectComp
                        label="Caja Registradora"
                        :items="cashRegisterList"
                        itemValue="id"
                        itemText="cash_register"
                        :hideDetails="true"
                        v-model="idCashRegister"
                        @change="eChangeCashRegister"
                    />
                </div>
            </div>
            <div class="product__group-search d-flex justify-center pt-2 pl-2 pr-2">
                <div class="product__search" style="margin-right:2px">
                    <MTextFieldComp
                        type="date"
                        label="Fecha Inicio"
                        :hideDetails="true"
                        v-model="dateOne"
                        :clearable="false"
                        @change="eChangeDateOne"
                    />
                </div>
                <div class="product__search" style="margin-left:2px">
                    <MTextFieldComp
                        type="date"
                        label="Fecha Final"
                        :hideDetails="true"
                        :clearable="false"
                        v-model="dateTwo"
                        @change="eChangeDateTwo"
                    />
                </div>
            </div>
        </div>
        <v-divider class="mt-2"></v-divider>
        <div class="pa-2 pt-1 pb-0">
            <div class="d-flex">
                <h1 class="app-title mr-1">Total Gastos:</h1>
                <h1 class="app-subtitle">
                    {{`${totalExpenses.toFixed(2)} Bs.`}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title mr-1">Cantidad Gastos:</h1>
                <h1 class="app-subtitle">
                    {{total}}
                </h1>
            </div>
        </div>
        <v-divider></v-divider>
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Información"
        />
        <MNoDataComp
            smsNotData="SIN DATOS"
            class="ma-1"
            v-if="expensesList.length==0 && !dialogLoaderData && mobile"
        />
        <STableExpensesComp
            v-if="!dialogLoaderData && !mobile"
            :expensesList="expensesList"
            :isInformation="true"
        />
        <div
            class="d-flex justify-center flex-wrap"
            v-if="!dialogLoaderData && mobile"
        >
            <SCardExpenseComp
                class="ma-2"
                v-for="expense in expensesList"
                :key="expense.id"
                :expense="expense"
                :isInformation="true"
            />
        </div>
        <v-divider
            class="mt-1"
            v-if="mobile && !dialogLoaderData && expensesList.length>0"
        ></v-divider>
        <SPaginationComp
            class="mt-2 mb-2"
            v-if="!dialogLoaderData && expensesList.length > 0"
            :condicionGo="currentPage < lastPage"
            :condicionBack="currentPage > 1"
            :titlePagination="'Pag. '+currentPage+' de '+lastPage"
            @clickback="eChangePage(false)"
            @clickgo="eChangePage(true)"
            @clickpage="eSearchForPage"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MSelectComp,
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OLoaderDataComp,
    OSmsErrorComp,
    OSmsActionComp
} from "../../components/organisms";
import {
    STableExpensesComp,
    SCardExpenseComp,
    SPaginationComp
} from "../../components/species";
import {
    animationScrollMixin,
    responseServerMixin,
    dataGeneralCompsMixin,
    datesMixin,
    userMixin,
    rulesMixin
} from "../../mixins";
import {
    Expense,
    CashRegister,
    User
} from "../../models";
export default {
    mixins: [
        animationScrollMixin,
        responseServerMixin,
        dataGeneralCompsMixin,
        datesMixin,
        userMixin,
        rulesMixin
    ],
    components: {
        OBtnToolTipHeaderComp,
        MTextFieldComp,
        MSelectComp,
        MNoDataComp,
        OLoaderDataComp,
        STableExpensesComp,
        SCardExpenseComp,
        SPaginationComp,
        OSmsErrorComp,
        OSmsActionComp
    },
    props: {
        mini: Boolean
    },
    data: () => ({
        dateOne: "",
        dateTwo: "",
        expensesList: [],
        totalExpenses: 0,
        userList: [{ id: 0, full_name: "Todos" }],
        idUser: 0,
        cashRegisterList: [{ id: 0, cash_register: "Todos" }],
        idCashRegister: 0
    }),
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else this.dialogError = false;
        },
        handleKeyUpExpenseAdmin(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getRequest() {
            this.dialogLoaderData = true;
            try {
                const expenses = Expense.getExpensesAdmin(1, "", "", 0, 0);
                const cashRegister = CashRegister.getCashRegisters();
                const users = User.getUsers(0, 0);
                const responses = await Promise.all([users, cashRegister, expenses]);
                responses[0].forEach(user => {
                    this.userList.push(user);
                });
                responses[1].forEach(cashRegister => {
                    this.cashRegisterList.push(cashRegister);
                });
                this.expensesList = responses[2].data;
                this.lastPage = responses[2].last_page;
                this.currentPage = responses[2].current_page;
                this.total = responses[2].total;
                this.totalExpenses = responses[2].total_expenses;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async getExpense(page, dateOne, dateTwo, idUser, idCashRegister) {
            this.dialogLoaderData = true;
            try {
                const response = await Expense.getExpensesAdmin(page, dateOne, dateTwo, idUser, idCashRegister);
                this.expensesList = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.totalExpenses = response.total_expenses;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getExpense(page, this.dateOne, this.dateTwo, this.idUser, this.idCashRegister);
                }, time);
            }
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getExpense(this.currentPage, this.dateOne, this.dateTwo, this.idUser, this.idCashRegister);
            }, time);
        },
        eChangeUser(idUser) {
            this.getExpense(1, this.dateOne, this.dateTwo, idUser, this.idCashRegister);
        },
        eChangeCashRegister(idCashRegister) {
            this.getExpense(1, this.dateOne, this.dateTwo, this.idUser, idCashRegister);
        },
        eChangeDateOne(dateOne) {
            if (dateOne == "" || this.dateTwo == "")
                this.mDataGeneralShowSmsAction(["Error de Fechas", "Seleccione bien las fechas"], "error");
            else {
                if (dateOne > this.dateTwo) this.dateTwo = dateOne;
                this.getExpense(1, dateOne, this.dateTwo, this.idUser, this.idCashRegister);
            }
        },
        eChangeDateTwo(dateTwo) {
            if (dateTwo == "" || this.dateOne == "")
                this.mDataGeneralShowSmsAction(["Error de Fechas", "Seleccione bien las fechas"], "error");
            else {
                if (dateTwo < this.dateOne) this.dateOne = dateTwo;
                this.getExpense(1, this.dateOne, dateTwo, this.idUser, this.idCashRegister);
            }
        }
    },
    mounted() {
        this.dateOne = this.dateTwo = this.mDateSystem(false);
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpExpenseAdmin);
                this.getRequest();
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpExpenseAdmin);
    }
}
</script>