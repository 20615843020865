<template>
    <v-dialog
        persistent
        :value="value"
        max-width="350"
    >
        <div class="s-detail-product">
            <div v-if="product.id != undefined">
                <div class="s-detail-product__header d-flex align-center">
                    <h1 class="app-title">
                        {{product.product}}
                    </h1>
                    <v-spacer></v-spacer>
                    <OBtnCardComp
                        icon="mdi-close"
                        @click="$emit('clickclose')"
                    />
                </div>
                <div class="s-detail-product__body">
                    <div
                        v-for="inventory in product.inventories"
                        :key="inventory.id"
                        class="mt-1"
                    >
                        <div class="d-flex align-center">
                            <h1 class="app-subtitle-dark mr-2">
                                {{mDateFormat(inventory.expiration_date)}}
                            </h1>
                            <div
                                :class="inventory.expired
                                    ? 'client__ornament-disabled'
                                    : 'client__ornament-enabled'"
                                style="margin-top:-3px;"
                            >
                            </div>
                            <v-divider dark vertical class="ml-3 mr-3"></v-divider>
                            <h1 class="app-subtitle-dark">
                                Cantidad:
                            </h1>
                            <h1 class="app-subtitle-dark ml-2">
                                {{inventory.quantity}}
                            </h1>
                        </div>
                        <v-divider dark></v-divider>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import {
    OBtnCardComp
} from "../organisms";
import { datesMixin } from "../../mixins";
export default {
    mixins: [ datesMixin ],
    components: {
        OBtnCardComp
    },
    props: {
        value: Boolean,
        product: Object
    }
}
</script>
