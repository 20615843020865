<template>
    <div>
        <div class="d-flex pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    CIERRE DE CAJA
                </h1>
            </div>
            <v-spacer></v-spacer>
            <OBtnToolTipHeaderComp
                icono="mdi-database"
                smsToolTip="Volver a cierre de caja"
                @click="eBackList()"
            />
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <div class="d-flex justify-center pa-2">
                    <SInfoCloseBoxComp
                        :title="`CAJA N° ${infoReport.cash_register}`"
                        v-if="!dialogLoaderData"
                        :infoReport="infoReport"
                        :mobile="mobile"
                        @clickshowsales="eShowSales"
                        @clickexpenses="eShowExpenses()"
                        @clickinfo="eShowInformation()"
                        @clickpayments="eShowPayments()"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2 pb-1">
                    <div class="d-flex">
                        <h1 class="app-title mr-1">Tipo Venta:</h1>
                        <h1 class="app-subtitle">{{titleTypeSale}}</h1>
                    </div>
                    <div class="d-flex" v-if="titleTypeSale != 'Anuladas'">
                        <h1 class="app-title mr-1">Total:</h1>
                        <h1 class="app-subtitle">{{totalSalesView.toFixed(2)}}</h1>
                    </div>
                    <div class="d-flex" v-else>
                        <h1 class="app-title mr-1">Cantidad:</h1>
                        <h1 class="app-subtitle">{{totalSalesView}}</h1>
                    </div>
                </div>
                <v-divider></v-divider>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderDataSale"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="salesList.length==0 && !dialogLoaderDataSale && mobile"
                />
                <STableSaleComp
                    v-if="!dialogLoaderDataSale && !mobile"
                    :salesList="salesList"
                    :isCloseBox="true"
                    @clickinfo="eShowInfoSale"
                    @clickdetail="eShowDetailSale"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderDataSale && mobile"
                >
                    <SCardReportSaleComp
                        class="ma-2"
                        v-for="sale in salesList"
                        :key="sale.id"
                        :sale="sale"
                        :isCloseBox="true"
                        @clickdetail="eShowDetailSale(sale)"
                        @clickinfo="eShowInfoSale(sale)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="mobile && !dialogLoaderDataSale && salesList.length>0"
                ></v-divider>
                <SPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataSale && salesList.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2 pb-0 pt-1">
                    <OInfoSaleComp
                        :sale="sale"
                    />
                </div>
                <v-divider class="mt-1"></v-divider>
                <STableDetailReportSaleComp
                    v-if="!mobile"
                    :detailSale="sale.detail"
                    @clickinventory="eShowDetailExpirationDate"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-else
                >
                    <SCardDetailSaleComp
                        class="ma-2"
                        v-for="product in sale.detail"
                        :key="product.id"
                        :product="product"
                        @clickinventory="eShowDetailExpirationDate(product)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderDataSale"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="expensesList.length==0 && !dialogLoaderDataSale && mobile"
                />
                <STableExpensesComp
                    v-if="!dialogLoaderDataSale && !mobile"
                    :expensesList="expensesList"
                    :isInformation="true"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderDataSale && mobile"
                >
                    <SCardExpenseComp
                        class="ma-2"
                        v-for="expense in expensesList"
                        :key="expense.id"
                        :expense="expense"
                        :isInformation="true"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderDataSale"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="informationsList.length==0 && !dialogLoaderDataSale && mobile"
                />
                <STableInformationsComp
                    v-if="!dialogLoaderDataSale && !mobile"
                    :informationsList="informationsList"
                    :isInformation="true"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderDataSale && mobile"
                >
                    <SCardInformationComp
                        class="ma-2"
                        v-for="information in informationsList"
                        :key="information.id"
                        :information="information"
                        :isInformation="true"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderDataSale"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="informationsList.length==0 && !dialogLoaderDataSale && mobile"
                />
                <STablePaymentComp
                    v-if="!dialogLoaderDataSale && !mobile"
                    :paymentsList="paymentsList"
                    :isInfo="true"
                    @clickdetail="eShowDetail"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderDataSale && mobile"
                >
                    <SCardPaymentComp
                        class="ma-2"
                        :isInfo="true"
                        v-for="payment in paymentsList"
                        :key="payment.id"
                        :payment="payment"
                        @clickdetail="eShowDetail(payment)"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <SInfomationSaleComp
            :value="dialogInfoSale"
            :sale="sale"
            @clickclose="dialogInfoSale=false"
        />
        <SDetailPaymentComp
            :payment="payment"
            :value="dialogDetailPayment"
            @clickclose="dialogDetailPayment=false"
        />
        <SDialogInventoriesMoveComp
            :value="dialogExpirationDate"
            :product="product"
            @clickclose="dialogExpirationDate=false"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSmsErrorComp,
    OLoaderDataComp,
    OInfoSaleComp,
    OSmsActionComp
} from "../../components/organisms";
import {
    SInfoCloseBoxComp,
    SCardReportSaleComp,
    STableSaleComp,
    SInfomationSaleComp,
    STableDetailReportSaleComp,
    SCardDetailSaleComp,
    SDialogInventoriesMoveComp,
    SPaginationComp,
    STableExpensesComp,
    SCardExpenseComp,
    STableInformationsComp,
    SCardInformationComp,
    STablePaymentComp,
    SDetailPaymentComp,
    SCardPaymentComp
} from "../../components/species";
import {
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    dataGeneralCompsMixin,
    datesMixin,
    rulesMixin
} from "../../mixins";
import {
    Report,
    Sale,
    Expense,
    Information,
    Payment
} from "../../models";
export default {
    mixins: [
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        dataGeneralCompsMixin,
        datesMixin,
        rulesMixin
    ],
    components: {
        OBtnToolTipHeaderComp,
        OSmsErrorComp,
        OLoaderDataComp,
        SInfoCloseBoxComp,
        MNoDataComp,
        SCardReportSaleComp,
        STableSaleComp,
        SInfomationSaleComp,
        OInfoSaleComp,
        STableDetailReportSaleComp,
        SCardDetailSaleComp,
        SDialogInventoriesMoveComp,
        SPaginationComp,
        OSmsActionComp,
        STableExpensesComp,
        SCardExpenseComp,
        STableInformationsComp,
        SCardInformationComp,
        STablePaymentComp,
        SDetailPaymentComp,
        SCardPaymentComp
    },
    props: {
        mini: Boolean
    },
    data: () => ({
        tab: 0,
        infoReport: {
            "total_sales_enabled": 0,
            "quantity_sales_disabled": 0,
            "total_expenses": 0,
            "quantity_info": 0,
            "money_box": 0,
            "date": "",
            "total_discount": 0,
            "cash_register": 0,
            "total_payments": 0,
            "total_payments_compliance": 0
        },
        salesList: [],
        dialogLoaderDataSale: false,
        dialogInfoSale: false,
        sale: {},
        dialogExpirationDate: false,
        product: {},
        disabled: 2,
        expensesList: [],
        informationsList: [],
        idCashRegister: 0,
        typeSale: "t",
        paymentsList: [],
        dialogDetailPayment: false,
        payment: {},
        titleTypeSale: "",
        totalSalesView: 0
    }),
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else this.$router.replace('/home');
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        eBackList() {
            if (this.tab === 2) {
                this.tab = 1;
                return;
            } this.tab = 0;
            setTimeout(() => {
                this.paymentsList = [];
                this.salesList = [];
                this.expensesList = [];
                this.informationsList = [];
            }, 200);
        },
        async getReport() {
            this.dialogLoaderData = true;
            try {
                const response =  await Report.closeBoxCashRegister(this.idCashRegister);
                this.infoReport = response;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async getSales(page, disabled, typeSale) {
            this.dialogLoaderDataSale = true;
            try {
                const response = await Sale.salesCashRegister(page, this.idCashRegister, disabled, typeSale);
                this.salesList = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderDataSale = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eShowSales(parameters) {
            this.typeSale = parameters.typeSale;
            this.disabled = parameters.disabled;
            this.titleTypeSale = parameters.titleTypeSale;
            this.totalSalesView = parameters.value;
            this.tab = 1;
            this.getSales(1, parameters.disabled, parameters.typeSale);
        },
        //
        eShowInfoSale(sale) {
            this.sale = sale;
            this.dialogInfoSale = true;
        },
        eShowDetailSale(sale) {
            this.sale = sale;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 2;
            }, time);
        },
        eShowDetailExpirationDate(product) {
            this.product = product;
            this.dialogExpirationDate = true;
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getSales(this.currentPage, this.disabled, this.typeSale);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getSales(page, this.disabled, this.typeSale);
                }, time);
            }
        },
        async eShowExpenses() {
            this.dialogLoaderDataSale = true;
            this.tab = 3;
            try {
                const response = await Expense.getExpenses(this.idCashRegister);
                this.expensesList = response;
                this.dialogLoaderDataSale = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async eShowInformation() {
            this.dialogLoaderDataSale = true;
            this.tab = 4;
            try {
                const response = await Information.getInformations(this.idCashRegister);
                this.informationsList = response;
                this.dialogLoaderDataSale = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        handleKeyUpCloseBoxCashRegister(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        validateOptions() {
            if (JSON.parse(localStorage.getItem("device"))) {
                this.idCashRegister = JSON.parse(localStorage.getItem("device")).id_cash_register;
                this.getReport();
            } else {
                this.smsError = [
                    `Este dispositivo no fue habilitado para funcionar como caja registradora de ventas`,
                    `La información que quiere acceder puede revisarse en la opción "CIERRE CAJA ADMIN"`
                ];
                this.actionError = true;
                this.dialogError = true;
            }
        },
        async eShowPayments() {
            this.dialogLoaderDataSale = true;
            this.tab = 5;
            try {
                const response = await Payment.getPaymentsUser(this.idCashRegister);
                this.paymentsList = response;
                this.dialogLoaderDataSale = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eShowDetail(payment) {
            this.payment = payment;
            this.dialogDetailPayment = true;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            window.addEventListener('keyup', this.handleKeyUpCloseBoxCashRegister);
            this.validateOptions();
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpCloseBoxCashRegister);
    }
}
</script>