import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';

class Purchase {
    constructor (
        bill_receipt = null,
        date_purchase = null,
        discount = null,
        other_discount = null,
        gloss = null,
        fk_provider = null,
        unid_discount = 0,
        activate_error = false
    )
    {
        this.bill_receipt = bill_receipt;
        this.date_purchase = date_purchase;
        this.discount = discount;
        this.other_discount = other_discount;
        this.gloss = gloss;
        this.fk_provider = fk_provider;
        this.unid_discount = unid_discount;
        this.activate_error = activate_error;
    }
    static async getPurchase(page, dateOne, dateTwo, fkProvider, billReceipt) {
        const response = await axios.get(
            `${apiUrls.PURCHASE}/list?page=${page}&date_one=${dateOne}&date_two=${dateTwo}&fk_provider=${fkProvider}&bill_receipt=${billReceipt}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addPurchase(parameters) {
        const response = await axios.post(
            `${apiUrls.PURCHASE}/add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editPurchase(parameters) {
        const response = await axios.put(
            `${apiUrls.PURCHASE}/edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deletePurchase(parameters) {
        const response = await axios.delete(
            `${apiUrls.PURCHASE}/delete`,
            { headers: userStore.state.header, data: parameters }
        );
        return response.data;
    }
}
export default Purchase;