<template>
    <div>
        <div class="d-flex pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">USUARIOS</h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex">
                <OBtnToolTipHeaderComp
                    icono="mdi-plus"
                    class="mr-1"
                    smsToolTip="Registrar usuario"
                    @click="eOpenFormUser()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    smsToolTip="Volver a usuarios"
                    @click="eBackTableList()"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="usersList.length==0 && !dialogLoaderData"
                />
                <div class="d-flex justify-center flex-wrap">
                    <SCardUserComp
                        class="ma-2"
                        v-for="user in usersList"
                        :key="user.id"
                        :user="user"
                        :mobile="mobile"
                        @clickedit="eOpenFormEdit(user)"
                        @clickdelete="eConfirmDelete(user)"
                        @clickstate="eConfirmChangeState(user)"
                        @clickcredentials="eOpenFormChangeCredentials(user)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center mt-3 pl-2 pr-2 pb-2">
                    <SFormUserComp
                        ref="formusercomp"
                        :dataUser="dataUser"
                        :mobile="mobile"
                        :indexUser="indexUser"
                        :actionForm="actionForm"
                        @saveform="eConfirmForm"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="eCloseDialogConfirm()"
            @clickyes="ePerformDatabaseAction()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="dialogError=false"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OLoaderDataComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp
} from "../../components/organisms";
import {
    SCardUserComp,
    SFormUserComp
} from "../../components/species";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    animationScrollMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import { User } from "../../models";
export default {
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        animationScrollMixin,
        userMixin,
        datesMixin
    ],
    props: {
        mini: Boolean
    },
    components: {
        OBtnToolTipHeaderComp,
        MNoDataComp,
        OLoaderDataComp,
        SCardUserComp,
        SFormUserComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp
    },
    data: () => ({
        tab: 0,
        usersList: [],
        dataUser: new User(),
        indexUser: -1,
        idUser: 0,
        actionForm: 0
    }),
    methods: {
        handleKeyUpUser(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 0) this.eCloseDialogConfirm();
                if (actionKeyBoard === 1) this.ePerformDatabaseAction();
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = !this.mResponseServeWithAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getUsers(id) {
            try {
                const response = await User.getUsers(id);
                this.usersList = response;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eConfirmForm(isValidForm) {
            const smsConfirm = this.indexUser === -1
                ? [`¿Está seguro(a) de registrar el usuario: ${this.dataUser.full_name}?`]
                : [`¿Está seguro(a) de editar el usuario: ${this.dataUser.full_name}?`]
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
            }, time);
        },
        eCloseDialogConfirm() {
            if (this.idUser > 0) {
                this.idUser = 0;
                this.indexUser = -1;
            }
            this.dialogConfirm = false;
        },
        eBackTableList() {
            this.indexUser = -1;
            this.dataUser = new User();
            this.$refs.formusercomp.resetForm();
            this.dialogLoaderOperation = false;
            this.actionForm = 0;
            this.tab = 0;
        },
        dataSendUser(user, isAdd) {
            var md5 = require('md5');
            if (isAdd) {
                return {
                    'full_name': user.full_name,
                    'categorie': user.categorie,
                    'username': user.username,
                    'password': md5(user.password)
                };
            } else {
                return {
                    'id': user.id,
                    'username': user.username,
                    'password': md5(user.password)
                };
            }
        },
        async addUser() {
            try {
                const response = await User.addUser(this.dataSendUser(this.dataUser, true));
                this.usersList.unshift(response);
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se registró el usuario"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async editUser() {
            try {
                const response = await User.editUser(this.dataUser);
                Object.assign(this.usersList[this.indexUser], response);
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se editó el usuario"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async changeStateUser() {
            try {
                await User.changeState({id: this.idUser});
                this.usersList[this.indexUser].disabled = this.usersList[this.indexUser].disabled === 1 ? 0 : 1;
                this.idUser = 0;
                this.indexUser = -1;
                this.dialogLoaderOperation = false;
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se cambió el estado del usuario"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        async changeCredentials() {
            try {
                const response = await User.changeCredentials(this.dataSendUser(this.dataUser, false));
                Object.assign(this.usersList[this.indexUser], response);
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se cambió las credenciales del usuario"], "success");
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (this.actionForm === 2) this.changeCredentials();
            else {
                if (this.idUser > 0) this.changeStateUser();
                else {
                    if (this.indexUser === -1) this.addUser();
                    else this.editUser();
                }
            }
        },
        eOpenFormEdit(user) {
            this.actionForm = 1;
            this.indexUser = this.usersList.indexOf(user);
            this.dataUser = Object.assign({}, user);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
                setTimeout(() => {
                    this.$refs.formusercomp.activateFocus();
                }, 150);
            }, time);
        },
        eConfirmChangeState(user) {
            this.indexUser = this.usersList.indexOf(user);
            this.idUser = user.id;
            const smsState = user.disabled === 1 ? 'habilitar' : 'deshabilitar';
            this.smsConfirm = [`¿Está seguro de ${smsState}, a: ${user.full_name}?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.dialogConfirm = true;
            }, time);
        },
        eOpenFormChangeCredentials(user) {
            this.actionForm = 2;
            this.dataUser = Object.assign({}, user);
            this.indexUser = this.usersList.indexOf(user);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
                setTimeout(() => {
                    this.$refs.formusercomp.activateUserFocus();
                }, 150);
            }, time);
        },
        eOpenFormUser() {
            this.tab = 1;
            setTimeout(() => {
                this.$refs.formusercomp.activateFocus();
            }, 150);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpUser);
                this.getUsers(1);
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpUser);
    }
}
</script>