<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <h1 class="app-title">
                {{`Pago: ${payment.payment.toFixed(2)} Bs.`}}
            </h1>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Glosa:</h1>
                <h1 class="app-subtitle-dark">
                    {{payment.glos != null ? payment.glos : "S/D"}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha Destino:</h1>
                <h1 class="app-subtitle-dark">
                    {{mDateFormat(payment.destination_date)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">N° Caja:</h1>
                <h1 class="app-subtitle-dark">
                    {{payment.cash_register.cash_register}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Cancelado:</h1>
                <h1 class="app-subtitle-dark">
                    {{payment.payment_compliance==null
                        ? "0.00 Bs."
                        : `${payment.payment_compliance.payment_compliance.toFixed(2)} Bs.`
                    }}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Saldo:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${totalBalance().toFixed(2)} Bs.`}}
                </h1>
            </div>
        </div>
        <div
            class="s-card-client__actions d-flex justify-center flex-wrap"
        >
            <OBtnCardComp
                icon="mdi-list-box"
                style="margin:2px"
                @click="$emit('clickdetail')"
            />
            <OBtnCardComp
                v-if="isUser && !isInfo"
                icon="mdi-account-cash"
                style="margin:2px"
                @click="$emit('clickadd')"
            />
            <OBtnCardComp
                v-if="!isInfo"
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
            <OBtnCardComp
                v-if="!isUser && !isInfo"
                icon="mdi-delete"
                style="margin:2px"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import { OBtnCardComp } from "../organisms";
import {
    datesMixin
} from "../../mixins";
export default {
    mixins: [ datesMixin ],
    props: {
        payment: Object,
        isUser: Boolean,
        isInfo: Boolean
    },
    components: {
        OBtnCardComp
    },
    methods: {
        totalBalance() {
            if (this.payment.payment_compliance == null)
                return this.payment.payment;
            return this.payment.payment - this.payment.payment_compliance.payment_compliance;
        }
    }
}
</script>