<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Producto</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">N° Lote</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Cantidad</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">P.U.C.</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha Expiración</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">
                            Diferencia días
                        </h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Fecha Registro</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-center">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(entrie, index) in entriesInventories"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <text-highlight
                            class="app-subtitle"
                            :queries="search"
                        >
                            {{entrie.product.product}}
                        </text-highlight>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{entrie.lot_number}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{entrie.quantity}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{entrie.purchase_price.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <h1 class="app-subtitle">
                                {{mDateFormat(entrie.expiration_date)}}
                            </h1>
                            <div
                                :class="entrie.expired
                                    ? 'client__ornament-disabled ml-1'
                                    : 'client__ornament-enabled ml-1'"
                                style="margin-top:-2px;"
                            >
                            </div>
                        </div>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{entrie.diff_days}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{`${entrie.hour} ${entrie.date}`}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            :smsToolTip="`Ver detalle de: ${entrie.product.product}`"
                            icon="mdi-list-box"
                            @click="$emit('clickdetail', entrie)"
                        />
                        <OBtnToolTipTableComp
                            :smsToolTip="`Detalle compra de: ${entrie.product.product}`"
                            icon="mdi-receipt-text"
                            class="ml-1 mr-1"
                            @click="$emit('clickpurchase', entrie)"
                        />
                        <OBtnToolTipTableComp
                            v-if="!isExpiringInventory"
                            smsToolTip="Actualizar lote"
                            icon="mdi-pencil"
                            @click="$emit('clickupdate', entrie)"
                        />
                        <OBtnToolTipTableComp
                            v-if="!isExpiringInventory"
                            smsToolTip="Dar de baja inventario"
                            icon="mdi-delete-sweep"
                            @click="$emit('clickdelete', entrie)"
                        />
                    </td>
                </tr>
                <tr v-if="entriesInventories.length === 0">
                    <td colspan="9">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import { OBtnToolTipTableComp } from "../organisms";
import { datesMixin } from "../../mixins";
export default {
    mixins: [ datesMixin ],
    components: {
        OBtnToolTipTableComp,
        TextHighlight
    },
    props: {
        entriesInventories: Array,
        search: {
            type: String,
            default: ''
        },
        isExpiringInventory: Boolean
    }
}
</script>