<template>
    <div class="e-table-product animation-opacity">
        <table class="e-table-product__table">
            <thead>
                <tr>
                    <th width="50px">
                        <h1 class="app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Producto</h1>
                    </th>
                    <th width="20">
                        <h1 class="app-title-dark text-center">E</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Código Barra</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Precio Venta</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Desc. Máximo</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Ubicación</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Tipo Producto</h1>
                    </th>
                    <th>
                        <h1 class="app-title-dark text-left">Laboratorio</h1>
                    </th>
                    <th :width="productsList.length > 0 ? '180px' : ''">
                        <h1 class="app-title-dark text-center">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(product, index) in productsList"
                    :key="index"
                >
                    <td>
                        <h1 class="app-subtitle text-right">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1
                            class="app-subtitle"
                            v-if="searchType!=1"
                        >
                            {{product.product}}
                        </h1>
                        <text-highlight
                            v-else
                            class="app-subtitle"
                            :queries="search"
                        >
                            {{product.product}}
                        </text-highlight>
                    </td>
                    <td>
                        <div
                            :class="product.disabled==1
                                ? 'client__ornament-disabled'
                                : 'client__ornament-enabled'"
                        >
                        </div>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.barcore!=null ? product.barcore : "S/CB"}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.sale_price.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.max_discount.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1
                            v-if="searchType!=2"
                            class="app-subtitle"
                        >
                            {{product.location!=null ? product.location : "S/U"}}
                        </h1>
                        <text-highlight
                            v-else
                            class="app-subtitle"
                            :queries="search"
                        >
                            {{product.location!=null ? product.location : "S/U"}}
                        </text-highlight>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.type_product.type_product}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="app-subtitle">
                            {{product.laboratory.laboratory}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <OBtnToolTipTableComp
                            :smsToolTip="`Actualizar precio: ${product.product}`"
                            icon="mdi-cash-100"
                            @click="$emit('clickprice', product)"
                        />
                        <OBtnToolTipTableComp
                            :smsToolTip="`Ver detalle de: ${product.product}`"
                            icon="mdi-list-box"
                            @click="$emit('clickdetail', product)"
                        />
                        <OBtnToolTipTableComp
                            :smsToolTip="`Editar: ${product.product}`"
                            icon="mdi-pencil"
                            @click="$emit('clickedit', product)"
                        />
                        <OBtnToolTipTableComp
                            :smsToolTip="product.disabled==1
                                ? `Habilitar: ${product.product}`
                                : `Deshabilitar: ${product.product}`"
                            :icon="product.disabled==1 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click="$emit('clickstate', product)"
                        />
                        <OBtnToolTipTableComp
                            :smsToolTip="`Eliminar: ${product.product}`"
                            icon="mdi-delete"
                            @click="$emit('clickdelete', product)"
                        />
                    </td>
                </tr>
                <tr v-if="productsList.length === 0">
                    <td colspan="10">
                        <h1 class="app-subtitle text-center">
                            SIN INFORMACIÓN
                        </h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import TextHighlight from "vue-text-highlight";
import { OBtnToolTipTableComp } from "../organisms";
export default {
    props: {
        productsList: Array,
        searchType: Number,
        search: {
            type: String,
            default: ''
        }
    },
    components: {
        OBtnToolTipTableComp,
        TextHighlight
    }
}
</script>