<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <h1 class="app-title">
                {{productPurchase.product}}
            </h1>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">N° Lote:</h1>
                <h1 class="app-subtitle-dark">
                    {{productPurchase.pivot.lot_number}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Fecha Expiración:</h1>
                <h1 class="app-subtitle-dark">
                    {{mDateFormat(productPurchase.pivot.expiration_date)}}
                </h1>
                <div
                    :class="itIsExpiredDate(productPurchase.pivot.expiration_date)
                        ? 'client__ornament-enabled ml-1'
                        : 'client__ornament-disabled ml-1'"
                    style="margin-top:-2px;"
                >
                </div>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Precio Unidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{productPurchase.pivot.unit_price.toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Cantidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{productPurchase.pivot.quantity}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Total:</h1>
                <h1 class="app-subtitle-dark">
                    {{mPTotalPrice(productPurchase.pivot).toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Dcto. Cial.:</h1>
                <h1 class="app-subtitle-dark">
                    {{productPurchase.pivot.commercial_discount.toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Otro Dcto.:</h1>
                <h1 class="app-subtitle-dark">
                    {{productPurchase.pivot.other_discount.toFixed(2)}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Total con Dcto.</h1>
                <h1 class="app-subtitle-dark">
                    {{mPTotalPriceWithDiscount(productPurchase.pivot).toFixed(2)}}
                </h1>
            </div>
        </div>
        <div class="s-card-client__actions d-flex justify-center flex-wrap">
            <OBtnCardComp
                icon="mdi-list-box"
                style="margin:2px"
                @click="$emit('clickdetail')"
            />
            <OBtnCardComp
                icon="mdi-pencil"
                style="margin:2px"
                @click="$emit('clickedit')"
            />
            <OBtnCardComp
                v-if="quantityInfo > 1"
                icon="mdi-delete"
                style="margin:2px"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import {
    datesMixin,
    purchaseMixin
} from "../../mixins";
import { OBtnCardComp } from "../organisms";
export default {
    mixins: [
        datesMixin,
        purchaseMixin
    ],
    props: {
        productPurchase: Object,
        quantityInfo: Number
    },
    components: {
        OBtnCardComp
    },
    methods: {
        itIsExpiredDate(date) {
            const dateSystem = this.mDateSystem(false);
            if (date < dateSystem) return false;
            return true;
        }
    }
}
</script>