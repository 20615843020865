<template>
    <div class="d-flex">
        <h1
            :class="darkMode
                ? 'app-title-dark mr-1'
                : 'app-title mr-1'"
        >{{title}}</h1>
        <h1
            :class="darkMode
                ? 'app-subtitle-dark mr-1'
                : 'app-subtitle mr-1'"
        >
            {{subTitle}}
        </h1>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        subTitle: String,
        darkMode: {
            type: Boolean,
            default: true
        }
    }
}
</script>