<template>
    <div>
        <div class="d-flex align-center pa-2">
            <h1 class="app-title">VENDER</h1>
            <v-spacer></v-spacer>
            <div class="d-flex">
                <OBtnToolTipHeaderComp
                    icono="mdi-cash-check"
                    class="mr-1"
                    smsToolTip="Obtener Autorización de descuento"
                    @click="eGetActiveDiscount()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-cash-multiple"
                    class="mr-1"
                    smsToolTip="Ver ventas"
                    @click="tab=1"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-cart-variant"
                    class="mr-1"
                    smsToolTip="Ir a detalle de venta"
                    @click="tab=2"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    class="mr-1"
                    smsToolTip="Volver a búsqueda"
                    @click="tab=0"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-account-remove"
                    smsToolTip="Cerrar Caja"
                    @click="eConfirmCloseBox()"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <!--<v-card
            color="purple"
            v-if="parametersSetting.isWarning"
            class="animation-opacity pa-1 rounded-0"
            elevation="0"
        >
            <h1 class="app-title-dark text-center">
                {{`!!! ${parametersSetting.message}`}}
            </h1>
        </v-card>-->
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="pa-2">
                    <OSearchHeaderComp
                        label="Buscar"
                        v-model="search"
                        @entersearch="getProducts()"
                    />
                </div>
                <v-divider></v-divider>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="productsList.length==0 && !dialogLoaderData"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderData"
                >
                    <SCardProductSaleComp
                        class="ma-2"
                        v-for="product in productsList"
                        :key="product.id"
                        :product="product"
                        :search="searchFilter"
                        @clickproduct="eSelectProduct(product)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap">
                    <SCardSaleDetailComp
                        v-for="sale in salesList"
                        :key="sale.id"
                        class="ma-2"
                        :sale="sale"
                        @clickinfo="eShowInfoSale(sale)"
                        @clickdetail="eShowDetailSale(sale)"
                        @clickchangestate="eConfirmChangeState(sale)"
                        @clicktypesale="eChangeTypePayment(sale)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="detailList.length==0"
                />
                <div class="d-flex justify-center flex-wrap">
                    <SCardDetailSaleMobleComp
                        class="ma-2"
                        v-for="product in detailList"
                        :updateComp="updateComp"
                        :key="product.id"
                        :product="product"
                        @clickdelete="eDeleteDetailProduct(product)"
                        @clickdetail="eShowCardDetailProduct(product)"
                        @clickedit="eShowFormEditProduct(product)"
                        @clickinventory="eShowInventoriesProduct(product)"
                        @clicklots="eShowDialogExpiratesDates(product)"
                    />
                </div>
                <STotalSaleMobileComp
                    :detailList="detailList"
                    ref="totalsalemobilecomp"
                    @clickclear="eClearDetailSale"
                    @clickConfirmSale="eConfirmSaleDirect"
                    @clickConfirmSaleClient="eShowDialogClient"
                />
            </v-tab-item>
        </v-tabs-items>
        <SDetailProductComp
            :product="product"
            :value="dialogDetailProduct"
            @clickclose="eCloseDialogDetailProduct()"
        />
        <SDetailInventoriesProductComp
            :product="product"
            :value="dialogInventories"
            @clickclose="dialogInventories=false"
        />
        <SDialogExpirationDateComp
            :value="dialogExpiratesDates"
            :product="product"
            @clickclose="eDialogCloseExpirationDate()"
            @clickedit="eEditExpiratesDates"
        />
        <SDialogClientSaleComp
            :clientsList="clientsList"
            :dialogLoaderData="dialogLoaderDataClient"
            :value="dialogClient"
            ref="dialogclientsale"
            @entersearch="eSearchClients"
            @clickclose="closeDialogClient"
            @clickclient="eSelectClient"
            @newclient="eConfirmNewClient"
        />
        <SInfomationSaleComp
            :value="dialogInfoSale"
            :sale="sale"
            :isUser="true"
            @clickclose="dialogInfoSale=false"
        />
        <EDialogDetailSaleComp
            :value="dialogDetailSale"
            :sale="sale"
            :mobile="true"
            @clickclose="dialogDetailSale=false"
        />
        <SDialogSelectProductComp
            :value="dialogSelectProduct"
            ref="formproductcomp"
            :product="product"
            :inventories="inventories"
            :isValidDiscount="isValidDiscount"
            @saveform="eProcessForm"
            @clickdetail="eShowDetailProduct"
            :indexProductDetail="indexProductDetail"
            @clickclose="dialogSelectProduct=false"
        />
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="eCloseDialogConfirm()"
            @clickyes="ePerformDatabaseAction()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSearchHeaderComp,
    OLoaderDataComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp
} from "../../components/organisms";
import {
    SCardSaleDetailComp,
    SInfomationSaleComp,
    SDetailProductComp,
    SDetailInventoriesProductComp,
    SDialogExpirationDateComp,
    SDialogClientSaleComp,
    SCardProductSaleComp,
    SDialogSelectProductComp,
    SCardDetailSaleMobleComp,
    STotalSaleMobileComp
} from "../../components/species";
import {
    EDialogDetailSaleComp
} from "../../components/ecosystems";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    userMixin,
    datesMixin,
    animationScrollMixin,
    productMixin,
    rulesMixin
} from "../../mixins";
import {
    Product,
    Sale,
    Client,
    PersonalControl,
    Bill,
    SettingBill,
    CashRegister
} from "../../models";
export default {
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        userMixin,
        datesMixin,
        animationScrollMixin,
        productMixin,
        rulesMixin
    ],
    components: {
        OBtnToolTipHeaderComp,
        OSearchHeaderComp,
        OLoaderDataComp,
        MNoDataComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        SCardSaleDetailComp,
        SInfomationSaleComp,
        EDialogDetailSaleComp,
        SDetailProductComp,
        SDetailInventoriesProductComp,
        SDialogExpirationDateComp,
        SDialogClientSaleComp,
        SCardProductSaleComp,
        SDialogSelectProductComp,
        SCardDetailSaleMobleComp,
        STotalSaleMobileComp
    },
    data: () => ({
        detailList: [],
        productsList: [],
        search: "",
        setTimeOutSearch: "",
        product: {},
        inventories: [],
        tab: 0,
        dialogDetailProduct: false,
        indexProductDetail: -1,
        dialogInventories: false,
        parameters: {
            sale: new Sale,
            detail: [],
            client: {},
            id_cash_register: 0,
            no_print: false,
            bill: new Bill
        },
        dialogExpiratesDates: false,
        dialogLoaderDataClient: false,
        clientsList: [],
        dialogClient: false,
        salesList: [],
        titleSales: "",
        idCashRegister: 0,
        dialogInfoSale: false,
        sale: { 'detail': [] },
        dialogDetailSale: false,
        isCloseBox: false,
        indexSale: -1,
        isChangeState: false,
        dialogSelectProduct: false,
        isSelectProduct: false,
        updateComp: 0,
        //
        parametersSetting: {
            isWarning: false,
            message: "",
            isExired: true
        },
        isValidDiscount: false,
        dateSystem: "",
        disabledBill: false,
        saleBill: {}
    }),
    computed: {
        searchFilter: function() {
            return this.mRuleEmptyField(this.search) ? "" : this.search;
        }
    },
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else {
                if (this.idCashRegister === 0)
                    this.$router.replace('/home');
                else this.dialogError = false;
            }
        },
        async getProducts() {
            if (this.mRuleEmptyField(this.search)) this.search = "";
            this.productsList = [];
            this.dialogLoaderData = true;
            this.tab = 0;
            try {
                var response = await Product.searchSale(this.search);
                response.forEach(element => {
                    element.select_sale_price = element.sale_price;
                    element.select_quantity = 1;
                });
                this.productsList = response;
                this.dialogLoaderData = false;
            } catch(error) {
                console.log(error);
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        /*iSearchProduct() {
            clearTimeout(this.setTimeOutSearch);
            this.setTimeOutSearch = setTimeout(() => {
                if (this.search != "" && this.search != null)
                    this.getProducts();
            }, 200);
        },*/
        eBackProductList() {
            this.indexProductDetail = -1;
            if (this.tab != 0) {
                this.$refs.formproductcomp.resetForm();
                this.tab = 0;
            }
        },
        eSelectProduct(product) {
            this.product = JSON.parse(JSON.stringify(product));
            this.dialogSelectProduct = true;
        },
        existIdDetail(id) {
            for (var i = 0; i < this.detailList.length; i++) {
                if (this.detailList[i].id === id) return true;
            }
            return false;
        },
        eProcessForm(state) {
            if (state) {
                if (this.indexProductDetail === -1) {
                    if (this.existIdDetail(this.product.id))
                        this.mDataGeneralShowSmsAction(["Producto ya agregado",
                            `${this.product.product}, ya está agregado`], "error");                
                    else {
                        const listInventoryOutputs = this.mProductGenerateOutPuts(this.product);
                        this.product.inventory_outputs = listInventoryOutputs;
                        this.detailList.push(this.product);
                        this.dialogSelectProduct = false;
                        this.mDataGeneralShowSmsAction(["Agregado",
                            "Se agregó el producto"], "success");
                    }
                } else {
                    const listInventoryOutputs = this.mProductGenerateOutPuts(this.product);
                    this.product.inventory_outputs = listInventoryOutputs;
                    Object.assign(this.detailList[this.indexProductDetail], this.product);
                    this.indexProductDetail = -1;
                    this.mDataGeneralShowSmsAction(["Editado",
                        "Se editó el producto"], "success");
                    this.dialogSelectProduct = false;
                }
            } else this.mDataGeneralShowSmsAction(["Error de datos",
                "Corrija los errores en rojo"], "error");
        },
        eDeleteDetailProduct(index) {
            this.detailList.splice(index, 1);
        },
        eShowCardDetailProduct(product) {
            this.dialogSelectProduct = false;
            this.product = Object.assign({}, product);
            this.dialogDetailProduct = true;
        },
        eShowDetailProduct(product) {
            this.isSelectProduct = true;
            this.dialogSelectProduct = false;
            this.product = Object.assign({}, product);
            this.dialogDetailProduct = true;
        },
        eCloseDialogDetailProduct() {
            this.dialogDetailProduct = false;
            if (this.isSelectProduct) {
                this.dialogSelectProduct = true;
                this.isSelectProduct = false;
            }
        },
        eShowFormEditProduct(product) {
            this.indexProductDetail = this.detailList.indexOf(product);
            this.product = Object.assign({}, product);
            this.dialogSelectProduct = true;
        },
        eShowInventoriesProduct(product) {
            this.product = Object.assign({}, product);
            this.dialogInventories = true;
        },
        eClearDetailSale() {
            this.$refs.totalsalemobilecomp.resetCancelled();
            this.detailList = [];
        },
        eShowDialogExpiratesDates(product) {
            this.indexProductDetail = this.detailList.indexOf(product);
            this.product = JSON.parse(JSON.stringify(product));
            this.dialogExpiratesDates = true;
        },
        eDialogCloseExpirationDate() {
            this.indexProductDetail = -1;
            this.dialogExpiratesDates = false;
        },
        eEditExpiratesDates(parameters) {
            if (parameters.isValidForm) {
                Object.assign(this.detailList[this.indexProductDetail], this.product);
                this.indexProductDetail = -1;
                ++this.updateComp;
                this.dialogExpiratesDates = false;
            } else {
                if (parameters.errorQuantity)
                    this.mDataGeneralShowSmsAction(["La suma de cantidad",
                        "Debe ser igual al cantidad del producto"], "error");
                else
                    this.mDataGeneralShowSmsAction(["Todas las cantidades",
                        "Deben ser números"], "error");
            }
        },
        getNameTypeSale(typeCoin) {
            if (typeCoin == "e") return "efectivo";
            if (typeCoin == "t") return "tarjeta";
            return "qr"
        },
        //sale detail
        eConfirmSaleDirect(parameters) {
            if (!parameters.isValidForm)
                this.mDataGeneralShowSmsAction(["Error en el Detalle",
                    "Corrija el descuento porfavor"], "error");
            else {
                this.parameters.bill.ci_nit = "0";
                this.parameters.id_cash_register = this.idCashRegister;
                this.parameters.sale = parameters.sale;
                this.parameters.detail = parameters.detail;
                this.smsConfirm = [
                    `¿${this.mUserFullName()}: estás seguro(a) de registrar la venta por:
                    ${this.parameters.sale.sale} Bs., mediante pago:
                    ${this.getNameTypeSale(parameters.sale.type_sale)}?`
                ];
                this.dialogConfirm = true;
            }
        },
        eShowDialogClient(parameters) {
            if (!parameters.isValidForm)
                this.mDataGeneralShowSmsAction(["Error en el Detalle",
                    "Corrija el descuento porfavor"], "error");
            else {
                this.closeDialogClient();
                this.parameters.sale = parameters.sale;
                this.parameters.detail = parameters.detail;
                this.dialogClient = true;
            }
        },
        //client
        async eSearchClients(search) {
            this.dialogLoaderDataClient = true;
            try {
                const response = await Client.getClientsSale(search);
                this.clientsList = response;
                this.dialogLoaderDataClient = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        closeDialogClient() {
            this.$refs.dialogclientsale.resetData();
            this.clientsList = [];
            this.dialogClient = false
        },
        eSelectClient(client) {
            this.parameters.bill.ci_nit = client.ci_nit;
            this.parameters.id_cash_register = this.idCashRegister;
            this.parameters.sale.fk_client = client.id;
            this.parameters.sale.select_client = 1;

            this.smsConfirm = [
                `¿${this.mUserFullName()}: estás seguro(a) de registrar la venta por:
                ${this.parameters.sale.sale} Bs., mediante pago: 
                ${this.getNameTypeSale(this.parameters.sale.type_sale)},
                para: ${client.full_name}?`
            ];
            this.dialogClient = false;
            this.dialogConfirm = true;
        },
        eConfirmNewClient(parameters) {
            if (parameters.isValidForm) {
                this.parameters.bill.ci_nit = parameters.client.ci_nit;
                this.parameters.id_cash_register = this.idCashRegister;
                this.parameters.client = Object.assign({}, parameters.client);
                this.parameters.sale.select_client = 0;
                this.smsConfirm = [
                    `¿${this.mUserFullName()}: estás seguro(a) de registrar la venta por:
                    ${this.parameters.sale.sale} Bs., mediante pago: 
                    ${this.getNameTypeSale(this.parameters.sale.type_sale)}, 
                    para: ${parameters.client.full_name}?`
                ];
                this.dialogClient = false;
                this.dialogConfirm = true;
            } else this.mDataGeneralShowSmsAction(["Error de datos",
                "Corrija los campos rojos"], "error");
        },
        //
        eCloseDialogConfirm() {
            if (this.indexSale !== -1) {
                this.indexSale = -1;
                this.isChangeState = false;
                this.dialogConfirm = false;
            } else {
                this.indexSale = -1;
                this.dialogConfirm = false;
                this.isChangeState = false;
                if (!this.isCloseBox) {
                    if (this.parameters.sale.no_client === 0)
                        this.dialogClient = true;
                } else this.isCloseBox = false;
            }
        },
        async addSale() {
            try {
                const response = await Sale.addSale(this.parameters);
                this.parameters = {
                    'sale': new Sale(),
                    'detail': [],
                    'client': {},
                    'id_cash_register': 0,
                    'activate_print': false,
                    'bill': new Bill()
                };
                this.eClearDetailSale();
                this.dialogLoaderOperation = false;
                this.salesList.unshift(response);
                this.productsList = [];
                this.isValidDiscount = false;
                this.$refs.totalsalemobilecomp.resetCancelled();
                this.mDataGeneralShowSmsAction(["Registro Exitoso",
                    "Se registró la venta"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async closeBoxUser() {
            this.dialogLoaderOperation = true;
            try {
                await PersonalControl.closeUser({id_cash_register: this.idCashRegister});
                this.dialogLoaderOperation = false;
                localStorage.removeItem("nazaret");
                setTimeout(() => {
                    this.$router.replace("/login");
                }, 150);
            } catch(error) {
                this.productsList(error);
            }
        },
        async changeStateSale() {
            this.dialogLoaderOperation = true;
            try {
                await Sale.changeState({id: this.sale.id});
                const valueState = this.sale.disabled === 1 ? 0 : 1;
                this.salesList[this.indexSale].disabled = valueState;
                this.dialogLoaderOperation = false;
                this.isChangeState = false;
                this.indexSale = -1;
                this.mDataGeneralShowSmsAction(["Operación Exitosa",
                    "Se anuló la venta"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async changeTypePayment() {
            try {
                await Sale.changeTypePayment({id: this.sale.id});
                const valueTypePayment = this.sale.type_sale === 'e' ? 'q' : 'e';
                this.salesList[this.indexSale].type_sale = valueTypePayment;
                this.dialogLoaderOperation = false;
                this.indexSale = -1;
                const smsTypePayment = this.sale.type_sale === 'e' ? 'por banco' : 'en efectivo';
                this.mDataGeneralShowSmsAction(["Operación Exitosa",
                    `Pago cambiado a pago ${smsTypePayment}`], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (this.indexSale === -1) {
                if (this.isCloseBox)
                    this.closeBoxUser();
                else this.addSale();
            } else {
                if (this.isChangeState)
                    this.changeStateSale();
                else this.changeTypePayment();
            }
        },
        showWarningExpired(diffDays, expiratedDate) {
            if (diffDays <= 15) {
                this.parametersSetting.isWarning = true;
                this.parametersSetting.message = `La validez de la facturación expira el ${this.mDateFormat(expiratedDate)}`;
            }
        },
        async getRequest() {
            try {
                const getSales = Sale.cashRegisterResume(this.idCashRegister);
                const getSettingsBill = SettingBill.getSettingBill();
                const responses = await Promise.all([getSales, getSettingsBill]);
                if (responses[1].data.id == undefined) {
                    this.parameters.no_print = true;
                    this.parametersSetting.isWarning = true;
                    this.parametersSetting.message = "Facturación inhabilitada";
                } else if (responses[1].data.expired) {
                    this.parameters.no_print = true;
                    this.parametersSetting.isWarning = true;
                    this.parametersSetting.message = `Facturación inhabilitada por vencimiento de trámite
                        en fecha: ${this.mDateFormat(responses[1].data.expiration_date)}`;
                } else {
                    this.showWarningExpired(responses[1].data.diff_days, responses[1].data.expiration_date);
                    this.parametersSetting.isExired = false;
                }
                this.salesList = responses[0];
                this.settingBill = responses[1].data.id == undefined ? {} : responses[1].data;
                this.dateSystem = responses[1].date_system;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        validateOptions() {
            if (JSON.parse(localStorage.getItem("device"))) {
                this.titleSales = `VENTAS - CAJA N° ${JSON.parse(localStorage.getItem("device")).cash_register}`;
                this.idCashRegister = JSON.parse(localStorage.getItem("device")).id_cash_register;
                this.getRequest();
            } else {
                this.smsError = [
                    `Este dispositivo no fue habilitado para funcionar como caja registradora de ventas`,
                    `Habilite este dispositivos como caja registradora y podrá realizar ventas`
                ];
                this.actionError = true;
                this.dialogError = true;
            }
        },
        eShowInfoSale(sale) {
            this.sale = sale;
            this.dialogInfoSale = true;
        },
        eShowDetailSale(sale) {
            this.sale = sale;
            this.dialogDetailSale = true;
        },
        eConfirmCloseBox() {
            this.isCloseBox = true;
            this.smsConfirm = [`¿${this.mUserFullName()}, está seguro(a) de cerrar su caja?`];
            this.dialogConfirm = true;
        },
        //
        eConfirmChangeState(sale) {
            if (sale.disabled === 0) {
                this.isChangeState = true;
                this.sale = sale;
                this.indexSale = this.salesList.indexOf(sale);
                this.smsConfirm = [`¿Está seguro de anular la venta de ${sale.client.full_name}, por:
                    ${sale.sale.toFixed(2)} Bs.?`];
                this.dialogConfirm = true;
            } else
                this.mDataGeneralShowSmsAction(["Venta ya anulada",
                    `La operación ya se realizó`], "error");
        },
        eChangeTypePayment(sale) {
            this.sale = sale;
            this.indexSale = this.salesList.indexOf(sale);
            const smsTypePayment = sale.type_sale === 'e' ? 'por banco' : 'en efectivo';
            this.smsConfirm = [`¿Está seguro de cambiar el tipo de pago a: venta ${smsTypePayment}?`];
            this.dialogConfirm = true;
        },
        //
        async eGetActiveDiscount() {
            this.dialogLoaderOperation = true;
            try {
                const response = await CashRegister.getStateDiscount(this.idCashRegister);
                this.isValidDiscount = response;
                if (response)
                    this.mDataGeneralShowSmsAction(["Descuento Habilitado",
                        `El admin habilitó el descuento para la presente venta`], "success");
                else this.mDataGeneralShowSmsAction(["Operación Denegada",
                    `El admin no habilitó el descuento para la venta`], "error");
                this.dialogLoaderOperation = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        }
    },
    mounted() {
        if (window.innerWidth > 600)
            this.$router.replace('/');
        this.dialogLoaderData = false;
        if (this.mUserSaveHeader(this.mDateSystem(false)))
            this.validateOptions();
        else this.$router.replace('/login');
    }
}
</script>