<template>
    <div class="s-card-client animation-opacity">
        <div class="s-card-client__header">
            <h1 class="app-title">
                {{product.product}}
            </h1>
        </div>
        <div class="s-card-client__body">
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Cantidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.pivot.quantity}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Precio Unidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${(product.pivot.unit_price+product.pivot.discount).toFixed(2)} Bs.`}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Descuento:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${product.pivot.discount.toFixed(2)} Bs.`}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Precio Venta:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${product.pivot.unit_price.toFixed(2)} Bs.`}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Cantidad:</h1>
                <h1 class="app-subtitle-dark">
                    {{product.pivot.quantity}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title-dark mr-1">Total Precio:</h1>
                <h1 class="app-subtitle-dark">
                    {{`${(product.pivot.quantity * product.pivot.unit_price).toFixed(2)} Bs.`}}
                </h1>
            </div>
        </div>
        <div
            v-if="!isInfo"
            class="s-card-client__actions d-flex justify-center flex-wrap"
        >
            <OBtnCardComp
                icon="mdi-format-list-text"
                style="margin:2px"
                @click="$emit('clickinventory')"
            />
        </div>
    </div>
</template>
<script>
import { OBtnCardComp } from "../organisms";
export default {
    props: {
        product: Object,
        isInfo: Boolean
    },
    components: {
        OBtnCardComp
    }
}
</script>