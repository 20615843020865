<template>
    <div>
        <div class="d-flex align-center pa-2">
            <OBtnToolTipHeaderComp
                v-if="!mobile"
                :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                smsToolTip="Ocultar Menú"
                @click="$emit('clickmini')"
            />
            <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                PAGOS A REALIZAR
            </h1>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <div class="pa-2 pt-1 pb-0">
            <div class="d-flex">
                <h1 class="app-title mr-1">Total Pagos a realizar:</h1>
                <h1 class="app-subtitle">
                    {{`${totalPayments.toFixed(2)} Bs.`}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title mr-1">Total cancelado:</h1>
                <h1 class="app-subtitle">
                    {{`${totalCancelled.toFixed(2)} Bs.`}}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="app-title mr-1">Total Saldo:</h1>
                <h1 class="app-subtitle">
                    {{`${totalBalance.toFixed(2)} Bs.`}}
                </h1>
            </div>
        </div>
        <v-divider></v-divider>
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Información"
        />
        <MNoDataComp
            smsNotData="SIN DATOS"
            class="ma-1"
            v-if="paymentsList.length==0 && !dialogLoaderData && mobile"
        />
        <STablePaymentComp
            v-if="!dialogLoaderData && !mobile"
            :paymentsList="paymentsList"
            :isUser="true"
            @clickdetail="eShowDetail"
            @clickadd="eOpenFormAdd"
             @clickedit="eOpenFormEdit"
        />
        <div
            class="d-flex justify-center flex-wrap"
                v-if="!dialogLoaderData && mobile"
        >
            <SCardPaymentComp
                class="ma-2"
                :isUser="true"
                v-for="payment in paymentsList"
                :key="payment.id"
                :payment="payment"
                @clickdetail="eShowDetail(payment)"
                @clickadd="eOpenFormAdd(payment)"
                @clickedit="eOpenFormEdit(payment)"
            />
        </div>
        <SDetailPaymentComp
            :payment="payment"
            :value="dialogDetailPayment"
            @clickclose="dialogDetailPayment=false"
        />
        <SDialogFormPaymentUserComp
            ref="formpaymentcomp"
            :title="indexPayment===-1 ? 'REGISTRAR' : 'EDITAR'"
            :value="dialogForm"
            :dataPaymentCompliance="dataPaymentCompliance"
            :payment="payment"
            @clickclose="eCloseForm()"
            @saveform="eConfirmForm"
        />
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="eCloseDialogConfirm()"
            @clickyes="ePerformDatabaseAction()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OLoaderDataComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp
} from "../../components/organisms";
import {
    STablePaymentComp,
    SCardPaymentComp,
    SDetailPaymentComp,
    SDialogFormPaymentUserComp
} from "../../components/species";
import {
    dataGeneralCompsMixin,
    responseServerMixin,
    animationScrollMixin,
    userMixin,
    datesMixin,
    rulesMixin
} from "../../mixins";
import {
    Payment,
    PaymentCompliance
} from "../../models";
export default {
    mixins: [
        dataGeneralCompsMixin,
        responseServerMixin,
        animationScrollMixin,
        userMixin,
        datesMixin,
        rulesMixin
    ],
    props: {
        mini: Boolean
    },
    components: {
        OBtnToolTipHeaderComp,
        OLoaderDataComp,
        MNoDataComp,
        STablePaymentComp,
        SCardPaymentComp,
        SDetailPaymentComp,
        SDialogFormPaymentUserComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp
    },
    data: () => ({
        idCashRegister: 0,
        paymentsList: [],
        dialogDetailPayment: false,
        dataPaymentCompliance: new PaymentCompliance,
        indexPayment: -1,
        payment: {},
        dialogForm: false,
        isEdit: false
    }),
    computed: {
        totalPayments: function() {
            var total = 0;
            for (var i = 0; i < this.paymentsList.length; i++)
                total += this.paymentsList[i].payment;
            return total;
        },
        totalCancelled: function() {
            var total = 0;
            for (var i = 0; i < this.paymentsList.length; i++) {
                if (this.paymentsList[i].payment_compliance != null)
                    total += this.paymentsList[i].payment_compliance.payment_compliance;
            }
            return total;
        },
        totalBalance: function() {
            return this.totalPayments - this.totalCancelled;
        }
    },
    methods: {
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        handleKeyUpPayUser(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 0) this.eCloseDialogConfirm();
                if (actionKeyBoard === 1) this.ePerformDatabaseAction();
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        validateOptions() {
            if (JSON.parse(localStorage.getItem("device"))) {
                this.idCashRegister = JSON.parse(localStorage.getItem("device")).id_cash_register;
                this.getPayments();
            } else {
                this.smsError = [
                    `Este dispositivo no fue habilitado para funcionar como caja registradora de ventas`,
                    `La información que quiere acceder puede revisarse en la opción "CONTROL VENTAS ADMIN"`
                ];
                this.actionError = true;
                this.dialogError = true;
            }
        },
        async getPayments() {
            this.dialogLoaderData = true;
            try {
                const response = await Payment.getPaymentsUser(this.idCashRegister);
                this.paymentsList = response;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eShowDetail(payment) {
            this.payment = payment;
            this.dialogDetailPayment = true;
        },
        eOpenFormAdd(payment) {
            if (payment.payment_compliance == null) {
                this.dataPaymentCompliance.fk_payment = payment.id;
                this.dataPaymentCompliance.payment_compliance = payment.payment;
                this.indexPayment = this.paymentsList.indexOf(payment);
                this.payment = payment;
                this.dialogForm = true;
            } else {
                const smsError = "El pago seleccionado, ya fue realizado por otro operario";
                this.mDataGeneralShowSmsAction(["Operación Denegada", smsError], "error");
            }
        },
        eCloseForm() {
            this.isEdit = false;
            this.dataPaymentCompliance = new PaymentCompliance;
            this.$refs.formpaymentcomp.resetForm();
            this.dialogForm = false;
        },
        eCloseDialogConfirm() {
            this.dialogConfirm = false;
            this.dialogForm = true;
        },
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else {
                this.dialogError = false;
                this.dialogForm = true;
            }
        },
        eConfirmForm(isValidForm) {
            this.dialogForm = false;
            const smsAccion = this.indexPayment === -1 ? 'registrar' : 'editar';
            const smsConfirm = [`¿${this.mUserFullName()}, está seguro(a) de ${smsAccion} el pago realizado de
                    ${parseFloat(this.dataPaymentCompliance.payment_compliance).toFixed(2)} Bs. para:
                    ${this.payment.glos}?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
            }, time);
        },
        async addPaymentCompliance() {
            try {
                const response = await PaymentCompliance.addPaymentCompliance(this.dataPaymentCompliance);
                Object.assign(this.paymentsList[this.indexPayment], response);
                this.eCloseForm();
                this.dialogLoaderOperation = false;
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se registró el pago"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async editPaymentCompliance() {
            try {
                const response = await PaymentCompliance.editPaymentCompliance(this.dataPaymentCompliance);
                Object.assign(this.paymentsList[this.indexPayment], response);
                this.eCloseForm();
                this.dialogLoaderOperation = false;
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se editó el pago"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (!this.isEdit)
                this.addPaymentCompliance();
            else this.editPaymentCompliance();
        },
        eOpenFormEdit(payment) {
            if (payment.payment_compliance == null) {
                const smsError = "El pago seleccionado, no fue realizado y no puede ser editado";
                this.mDataGeneralShowSmsAction(["Operación Denegada", smsError], "error");
            } else {
                this.isEdit = true;
                this.dataPaymentCompliance = Object.assign({}, payment.payment_compliance);
                this.dataPaymentCompliance.fk_payment = payment.id;
                this.indexPayment = this.paymentsList.indexOf(payment);
                this.payment = payment;
                this.dialogForm = true;
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            window.addEventListener('keyup', this.handleKeyUpPayUser);
            this.validateOptions();
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpPayUser);
    }
}
</script>