<template>
    <div>
        <div
            v-for="(product, index) in productsList"
            :key="product.id"
            @click="$emit('clickproduct', product)"
            :class="(productsList.length-1) != index
                ? 'mb-2 s-group-products-sale__option d-flex'
                : 's-group-products-sale__option d-flex'"
        >
            <div>
                <div class="d-flex align-center">
                    <div
                        :class="product.quantity > 0
                            ? 'client__ornament-enabled'
                            : 'client__ornament-disabled'"
                        style="margin-top:-2px"
                    >
                    </div>
                    <text-highlight
                        class="app-subtitle ml-1"
                        :queries="search"
                    >
                        {{product.composition === ""
                            ? `${product.product} [s/c]`
                            : `${product.product} [${product.composition}]`}}
                    </text-highlight>
                </div>
                <h1 class="ml-5 app-subtitle">Lab: {{product.laboratory.laboratory}}</h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex">
                <div class="s-group-products-sale__sale-price d-flex">
                    <h1 class="app-subtitle">
                        {{`P.V.:`}}
                    </h1>
                    <h1 class="app-title ml-1">
                        {{`${product.sale_price.toFixed(2)}`}}
                    </h1>
                </div>
                <div class="s-group-products-sale__quantity d-flex">
                    <h1 class="app-subtitle">
                        Cant.:
                    </h1>
                    <h1 class="app-title ml-1">
                        {{product.quantity}}
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { OBtnToolTipTableComp } from "../organisms";
import TextHighlight from "vue-text-highlight";
export default {
    components: {
        OBtnToolTipTableComp,
        TextHighlight
    },
    props: {
        productsList: Array,
        search: String
    }
}
</script>