<template>
    <div class="pa-2">
        <h1 class="app-title text-center">REGISTRAR COMPRA</h1>
        <div class="d-flex align-center mb-2 mt-2">
            <h1 class="app-title mr-2">Seleccionar Producto:</h1>
            <MBtnNormalComp
                @click="$emit('clickproducts')"
            >Abrir Buscador</MBtnNormalComp>
        </div>
        <STableDetailPurchaseComp
            v-if="!mobile"
            :detailProducts="detailProducts"
            @clickdelete="eDeleteProductDetail"
            @clickedit="eEditProductDetail"
        />
        <MNoDataComp
            smsNotData="SIN DATOS"
            v-if="detailProducts.length==0 && mobile"
        />
        <div
            class="d-flex justify-center flex-wrap"
            v-if="mobile"
        >
            <SCardDetailPurchaseComp
                class="ma-2"
                v-for="product in detailProducts"
                :key="product.id"
                :product="product"
                @clickdelete="eDeleteProductDetail(product)"
                @clickedit="eEditProductDetail(product)"
            />
        </div>
        <OTotalesDetailPurchaseComp
            class="mt-1"
            :detailProducts="detailProducts"
            :dataPurchase="dataPurchase"
            :isDataBase="false"
        />
        <v-divider class="mb-2 mt-1"></v-divider>
        <div class="d-flex justify-center">
            <SFormPurchaseComp
                :mobile="mobile"
                :dataPurchase="dataPurchase"
                :indexPurchase="indexPurchase"
                :aloneForm="true"
                ref="formpurchasecomp"
                @saveform="eValidForm"
                @clickprovider="$emit('clickprovider')"
            />
        </div>
    </div>
</template>
<script>
import {
    MBtnNormalComp,
    MNoDataComp
} from "../molecules";
import {
    OTotalesDetailPurchaseComp
} from "../organisms";
import {
    SFormPurchaseComp,
    STableDetailPurchaseComp,
    SCardDetailPurchaseComp
} from "../species";
export default {
    components: {
        SFormPurchaseComp,
        STableDetailPurchaseComp,
        OTotalesDetailPurchaseComp,
        MBtnNormalComp,
        SCardDetailPurchaseComp,
        MNoDataComp
    },
    props: {
        mobile: Boolean,
        dataPurchase: Object,
        indexPurchase: Number,
        detailProducts: Array
    },
    methods: {
        eValidForm(isValidForm) {
            if (this.dataPurchase.fk_provider == null)
                this.dataPurchase.activate_error = true;
            var smsError = `El detalle debe tener productos agregados`;
            if (!isValidForm || this.dataPurchase.fk_provider === null)
                smsError = `Corrija todos los errores con rojo del formulario porfavor`;
            const sendData = {
                "smsError": smsError,
                "isValidForm": isValidForm && this.dataPurchase.fk_provider !== null && this.detailProducts.length > 0
            }
            this.$emit('saveForm', sendData);
        },
        resetForm() {
            this.$refs.formpurchasecomp.resetForm();
        },
        eDeleteProductDetail(product) {
            this.$emit('deletedetail', product);
        },
        eEditProductDetail(product) {
            this.$emit('editdetail', product);
        }
    }
}
</script>