<template>
    <div>
        <div class="d-none">
            <div ref="sheetbill">
                <SSheetPrintBillComp
                    :saleBill="sale"
                    :settingBill="settingBill"
                />
            </div>
        </div>
        <div class="section-print">
            <div class="d-flex pa-2">
                <div class="d-flex align-center">
                    <OBtnToolTipHeaderComp
                        v-if="!mobile"
                        :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                        smsToolTip="Ocultar Menú"
                        @click="$emit('clickmini')"
                    />
                    <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">VENTAS</h1>
                </div>
                <v-spacer></v-spacer>
                <div class="d-flex">
                    <OBtnToolTipHeaderComp
                        icono="mdi-microsoft-excel"
                        class="mr-1"
                        smsToolTip="Reporte de Ventas"
                        @click="eProcessExcel()"
                    />
                    <OBtnToolTipHeaderComp
                        icono="mdi-database"
                        smsToolTip="Volver a cajas registradoras"
                        @click="eBackTableList()"
                    />
                </div>
            </div>
            <div class="client__line-header" id="position-scroll"></div>
            <v-tabs-items
                style="background:transparent !important"
                v-model="tab"
                :touchless="true"
            >
                <v-tab-item>
                    <div class="product__group-search d-flex justify-center pt-2 pl-2 pr-2">
                        <div class="product__search" style="margin-right:2px">
                            <MTextFieldComp
                                type="date"
                                label="Fecha Inicio"
                                :hideDetails="true"
                                v-model="dateOne"
                                :clearable="false"
                                @change="eChangeDateOne"
                            />
                        </div>
                        <div class="product__search" style="margin-left:2px">
                            <MTextFieldComp
                                type="date"
                                label="Fecha Final"
                                :hideDetails="true"
                                v-model="dateTwo"
                                :clearable="false"
                                @change="eChangeDateTwo"
                            />
                        </div>
                    </div>
                    <v-divider class="mt-2"></v-divider>
                    <OLoaderDataComp
                        class="mt-2"
                        v-if="dialogLoaderData"
                        smsLoaderData="Obteniendo Información"
                    />
                    <MNoDataComp
                        smsNotData="SIN DATOS"
                        class="ma-1"
                        v-if="salesList.length==0 && !dialogLoaderData && mobile"
                    />
                    <STableSaleComp
                        v-if="!dialogLoaderData && !mobile"
                        :salesList="salesList"
                        :isBill="true"
                        @clickdetail="eShowDetailSale"
                        @clickinfo="eShowInfoSale"
                        @clickprint="ePrintBill"
                    />
                    <div
                        class="d-flex justify-center flex-wrap"
                        v-if="!dialogLoaderData && mobile"
                    >
                        <SCardReportSaleComp
                            class="ma-2"
                            :isBill="true"
                            v-for="sale in salesList"
                            :key="sale.id"
                            :sale="sale"
                            @clickdetail="eShowDetailSale(sale)"
                            @clickinfo="eShowInfoSale(sale)"
                            @clickprint="ePrintBill(sale)"
                        />
                    </div>
                    <v-divider
                        class="mt-1"
                        v-if="mobile && !dialogLoaderData && salesList.length>0"
                    ></v-divider>
                    <SPaginationComp
                        class="mt-2 mb-2"
                        v-if="!dialogLoaderData && salesList.length > 0"
                        :condicionGo="currentPage < lastPage"
                        :condicionBack="currentPage > 1"
                        :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                        @clickback="eChangePage(false)"
                        @clickgo="eChangePage(true)"
                        @clickpage="eSearchForPage"
                    />
                </v-tab-item>
                <v-tab-item>
                    <div class="pa-2 pb-0 pt-1">
                        <OInfoSaleComp
                            :sale="sale"
                        />
                    </div>
                    <v-divider class="mt-1"></v-divider>
                    <STableDetailReportSaleComp
                        v-if="!mobile"
                        :detailSale="sale.detail"
                        @clickinventory="eShowDetailExpirationDate"
                    />
                    <div
                        class="d-flex justify-center flex-wrap"
                        v-else
                    >
                        <SCardDetailSaleComp
                            class="ma-2"
                            v-for="product in sale.detail"
                            :key="product.id"
                            :product="product"
                            @clickinventory="eShowDetailExpirationDate(product)"
                        />
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <SDialogInventoriesMoveComp
            :value="dialogExpirationDate"
            :product="product"
            @clickclose="dialogExpirationDate=false"
        />
        <SInfomationSaleComp
            :value="dialogInfoSale"
            :sale="sale"
            @clickclose="dialogInfoSale=false"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MNoDataComp,
    MTextFieldComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OLoaderDataComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp,
    OInfoSaleComp
} from "../../components/organisms";
import {
    STableSaleComp,
    SCardReportSaleComp,
    SPaginationComp,
    SInfomationSaleComp,
    STableDetailReportSaleComp,
    SCardDetailSaleComp,
    SDialogInventoriesMoveComp,
    SSheetPrintBillComp
} from "../../components/species";
import {
    dataGeneralCompsMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin,
    printMixin,
    rulesMixin,
    utilitiesMixin
} from "../../mixins";
import {
    Sale,
    SettingBill,
    Report
} from "../../models";
import XLSX from "xlsx-js-style";
export default {
    mixins: [
        dataGeneralCompsMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin,
        printMixin,
        rulesMixin,
        utilitiesMixin
    ],
    components: {
        OBtnToolTipHeaderComp,
        OLoaderDataComp,
        MNoDataComp,
        STableSaleComp,
        SCardReportSaleComp,
        SPaginationComp,
        MTextFieldComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        SInfomationSaleComp,
        OInfoSaleComp,
        STableDetailReportSaleComp,
        SCardDetailSaleComp,
        SDialogInventoriesMoveComp,
        SSheetPrintBillComp
    },
    props: {
        mini: Boolean
    },
    data: () => ({
        tab: 0,
        salesList: [],
        dateOne: "",
        dateTwo: "",
        dialogInfoSale: false,
        sale: { detail: [] },
        dialogExpirationDate: false,
        product: {},
        settingBill: {}
    }),
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization(false);
            else this.dialogError = false;
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getBills(page, dateOne, dateTwo) {
            this.dialogLoaderData = true;
            try {
                const response = await Sale.billsReport(page, dateOne, dateTwo);
                this.salesList = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eChangeDateOne(dateOne) {
            if (dateOne == "" || this.dateTwo == "")
                this.mDataGeneralShowSmsAction(["Error de Fechas", "Seleccione bien las fechas"], "error");
            else {
                if (dateOne > this.dateTwo) this.dateTwo = dateOne;
                this.getBills(1, dateOne, this.dateTwo);
            }
        },
        eChangeDateTwo(dateTwo) {
            if (dateTwo == "" || this.dateOne == "")
                this.mDataGeneralShowSmsAction(["Error de Fechas", "Seleccione bien las fechas"], "error");
            else {
                if (dateTwo < this.dateOne) this.dateOne = dateTwo;
                this.getBills(1, this.dateOne, dateTwo);
            }
        },
        eShowDetailSale(sale) {
            this.sale = sale;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eShowInfoSale(sale) {
            this.sale = sale;
            this.dialogInfoSale = true;
        },
        eShowDetailExpirationDate(product) {
            this.product = product;
            this.dialogExpirationDate = true;
        },
        ePrintBill(sale) {
            if (this.settingBill.id != undefined) {
                this.sale = sale;
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.mPrintShow(this.$refs.sheetbill);
                    }, 500);
                });
            } else
                this.mDataGeneralShowSmsAction(["Sin configuraciones", "No existe información para la impresiónd de facturas"], "error");
        },
        async getSettingBill() {
            this.dialogLoaderData = true;
            try {
                const response = await SettingBill.getSettingBill();
                this.settingBill = response.data.id == undefined ? {} : response.data;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eBackTableList() {
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 0;
            }, time);
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getBills(this.currentPage, this.dateOne, this.dateTwo);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getBills(page, this.dateOne, this.dateTwo);
                }, time);
            }
        },
        eProcessExcel() {
            if (this.salesList.length > 0)
                this.eGetSales();
            else this.mDataGeneralShowSmsAction(["Sin Ventas", "Debe haber ventas para generar el reporte Excel"], "error");
        },
        async eGetSales() {
            this.dialogLoaderOperation = true;
            try {
                const response = await Report.reportBillSale(this.dateOne, this.dateTwo);
                this.generateExcel(response);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        generateExcel(sales) {
            const nameColums = [
                'N° FACTURA', 'Fecha de Emisión', 'N° Autorización', 'Estado',
                'CI/NIT Cliente', 'Nombre o Razón Social', 'Importe Total Venta',
                'Desc. Total Venta', 'Importe Base para D.F.',
                'Código de Control'];
            var columns = [];

            nameColums.forEach(column => {
                columns.push({
                    v: column,
                    s: {
                        font: {
                            name: "Arial",
                            sz: 11,
                            color: { rgb: "3c4145" },
                        },
                        fill: {
                            fgColor: { rgb: "cfe2ed" }
                        }
                    }
                })
            });

            var finalList = [columns];
            sales.forEach(sale => {
                finalList.push([
                    { v: sale.bill.bill_number, s: { alignment: { horizontal: "right"} } },
                    { v: sale.bill.date, s: { alignment: { horizontal: "right"} } },
                    { v: sale.bill.authorization_code, s: { alignment: { horizontal: "right"} } },
                    { v: sale.bill.state, s: { alignment: { horizontal: "right"} } },
                    { v: sale.bill.ci_nit, s: { alignment: { horizontal: "right"} } },
                    { v: sale.client.full_name, s: { alignment: { horizontal: "right"} } },
                    { v: this.mUtilitiesNumberExcel(sale.sale.toFixed(2)), s: { alignment: { horizontal: "right"} } },
                    { v: this.mUtilitiesNumberExcel(sale.discount.toFixed(2)), s: { alignment: { horizontal: "right"} } },
                    { v: this.mUtilitiesNumberExcel((sale.sale-sale.discount).toFixed(2)), s: { alignment: { horizontal: "right"} } },
                    { v: sale.bill.control_code, s: { alignment: { horizontal: "right"} } }
                ]);
            });

            const wb = XLSX.utils.book_new();
            var ws = XLSX.utils.aoa_to_sheet(finalList);
            ws["!cols"] = [
                { wch: 15 }, { wch: 20 }, { wch: 20 },
                { wch: 10 }, { wch: 20 }, { wch: 30 },
                { wch: 20 }, { wch: 20 }, { wch: 25 }, { wch: 20 }
            ];
            XLSX.utils.book_append_sheet(wb, ws, "Reporte");
            if (this.dateOne !== this.dateTwo)
                var nameFileExcel = `Reporte_${this.mDateFormatFile(this.dateOne)}_al_${this.mDateFormatFile(this.dateTwo)}`;
            else var nameFileExcel = `Reporte_${this.mDateFormatFile(this.dateOne)}`;
            XLSX.writeFile(wb, `${nameFileExcel}.xlsx`, { bookType: 'xlsx', type: 'buffer' });
            this.dialogLoaderOperation = false;
        },
        async getSetting() {
            this.dialogLoaderData = true;
            try {
                const response = await SettingBill.getSettingBill();
                this.settingBill = response.data;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        }
    },
    mounted() {
        this.dialogLoaderData = false;
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            window.addEventListener('keyup', this.handleKeyUpReportBills);
            this.getSetting();
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpReportBills);
    }
}
</script>