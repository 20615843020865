<template>
    <v-dialog
        persistent
        :value="value"
        max-width="600"
    >
        <div class="s-detail-product">
            <div v-if="payment.id != undefined">
                <div class="s-detail-product__header d-flex align-center">
                    <div
                        :class="payment.payment_compliance==null
                            ? 'client__ornament-disabled'
                            : 'client__ornament-enabled'"
                    >
                    </div>
                    <h1 class="app-title ml-1">
                        {{`Pago: ${payment.payment.toFixed(2)} Bs.`}}
                    </h1>
                    <v-spacer></v-spacer>
                    <OBtnCardComp
                        icon="mdi-close"
                        @click="$emit('clickclose')"
                    />
                </div>
                <div class="s-detail-product__body">
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Glosa:</h1>
                        <h1 class="app-subtitle-dark">
                            {{payment.glos != null ? payment.glos : "S/D"}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Fecha Destino:</h1>
                        <h1 class="app-subtitle-dark">
                            {{mDateFormat(payment.destination_date)}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">N° Caja:</h1>
                        <h1 class="app-subtitle-dark">
                            {{payment.cash_register.cash_register}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Cancelado:</h1>
                        <h1 class="app-subtitle-dark">
                            {{payment.payment_compliance==null
                                ? "0.00 Bs."
                                : `${payment.payment_compliance.payment_compliance.toFixed(2)} Bs.`
                            }}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Saldo:</h1>
                        <h1 class="app-subtitle-dark">
                            {{`${totalBalance().toFixed(2)} Bs.`}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Cancelador Por:</h1>
                        <h1 class="app-subtitle-dark">
                            {{payment.payment_compliance==null
                                ? "Sin Cancelar"
                                : payment.payment_compliance.user.full_name
                            }}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Fecha Cancelación:</h1>
                        <h1 class="app-subtitle-dark">
                            {{payment.payment_compliance==null
                                ? "S/F"
                                : `${payment.payment_compliance.hour} ${payment.payment_compliance.date}`
                            }}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Registrado Por:</h1>
                        <h1 class="app-subtitle-dark">
                            {{payment.user.full_name}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Fecha Registro:</h1>
                        <h1 class="app-subtitle-dark">
                            {{`${payment.hour} ${payment.date}`}}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import {
    OBtnCardComp
} from "../organisms";
import {
    datesMixin
} from "../../mixins";
export default {
    mixins: [ datesMixin ],
    components: {
        OBtnCardComp
    },
    props: {
        value: Boolean,
        payment: Object
    },
    methods: {
        totalBalance() {
            if (this.payment.payment_compliance == null)
                return this.payment.payment;
            return this.payment.payment - this.payment.payment_compliance.payment_compliance;
        }
    }
}
</script>
