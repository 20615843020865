<template>
    <v-dialog
        width="450"
        persistent
        :value="value"
    >
        <v-card>
            <div class="s-detail-product__header pl-2 pr-2 d-flex align-center">
                <h1 class="app-title">
                    {{indexLot == -1 ? "Registrar Lote" : "Actualizar Lote"}}
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <div class="client__line-header"></div>
            <div class="pa-2 pt-5 pb-3">
                <v-form
                    v-model="valid"
                    lazy-validation
                    ref="formlot"
                >
                    <MTextFieldComp
                        label="Cantidad"
                        v-model="dataLot.quantity"
                        :rules="[
                            value => !this.mRuleEmptyField(value) || 'La cantidad es requerida',
                            value => this.mRuleNumberInteger(value) || 'La cantidad debe ser un número'
                        ]"
                    />
                    <MTextFieldComp
                        class="mt-3"
                        label="Fecha Expiración"
                        v-model="dataLot.expiration_date"
                        type="date"
                        :rules="[
                            value => !this.mRuleEmptyField(value) || 'La fecha es requerida'
                        ]"
                    />
                    <MTextFieldComp
                        label="Precio Compra"
                        v-model="dataLot.purchase_price"
                        :rules="[
                            value => !this.mRuleEmptyField(value) || 'El precio es requerido',
                            value => this.mRuleNumberDecimal(value) || 'El precio debe ser un número'
                        ]"
                    />
                </v-form>
                <div class="d-flex justify-center mt-1">
                    <MBtnNormalComp
                        @click="eValidForm()"
                    >
                        <v-icon>mdi-bookmark-check</v-icon>
                        GUARDAR
                    </MBtnNormalComp>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp
} from "../molecules";
import {
    OBtnCardComp
} from "../organisms";
import {
    rulesMixin
} from "../../mixins";
export default {
    mixins: [
        rulesMixin
    ],
    props: {
        value: Boolean,
        indexLot: Number,
        dataLot: Object
    },
    components: {
        OBtnCardComp,
        MTextFieldComp,
        MBtnNormalComp
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.$refs.formlot.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.formlot.resetValidation();
        }
    }
}
</script>