import axios from "axios";
import { apiUrls } from "../plugins/apiUrls";
import { userStore } from '../store/modules';
class TypeProduct {
    constructor(
        type_product = null
    ) {
        this.type_product = type_product;
    }
    static async getTypesProducts() {
        const response = await axios.get(
            `${apiUrls.TYPE_PRODUCT}/list`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addTypeProduct(parameters) {
        const response = await axios.post(
            `${apiUrls.TYPE_PRODUCT}/add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editTypeProduct(parameters) {
        const response = await axios.put(
            `${apiUrls.TYPE_PRODUCT}/edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deleteTypeProduct(parameters) {
        const response = await axios.delete(
            `${apiUrls.TYPE_PRODUCT}/delete`,
            { headers: userStore.state.header, data: parameters }
        );
        return response.data;
    }
}
export default TypeProduct;