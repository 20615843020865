var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-form-product"},[_c('h1',{staticClass:"app-title text-center mb-2"},[_vm._v(" "+_vm._s(_vm.indexCashRegister===-1 ? 'REGISTRAR' : 'EDITAR')+" ")]),_c('v-form',{ref:"formcashregister",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Número de caja:"}},[_c('MTextFieldComp',{ref:"cashregister",attrs:{"label":_vm.mobile ? 'Número de caja' : '',"counter":3,"rules":[
                    value => !this.mRuleEmptyField(value) || 'El n° de caja es requerido',
                    value => this.mRuleNumberInteger(value) || 'El n° debe ser un número',
                    value => (`${value}`).length<=3 || 'El n° caja, no debe exceder los 3 dígitos'
                ]},model:{value:(_vm.dataCashRegister.cash_register),callback:function ($$v) {_vm.$set(_vm.dataCashRegister, "cash_register", $$v)},expression:"dataCashRegister.cash_register"}})],1),_c('ORowFormComp',{attrs:{"mobile":_vm.mobile,"informationRow":"Monto caja inicial:"}},[_c('MTextFieldComp',{attrs:{"label":_vm.mobile ? 'Monto Caja Inicial' : '',"rules":[
                    value => !this.mRuleEmptyField(value) || 'El monto de caja es requerido',
                    value => this.mRuleNumberDecimal(value) || 'El monto de caja debe ser un número'
                ]},model:{value:(_vm.dataCashRegister.money_box),callback:function ($$v) {_vm.$set(_vm.dataCashRegister, "money_box", $$v)},expression:"dataCashRegister.money_box"}})],1)],1),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('MBtnNormalComp',{on:{"click":function($event){return _vm.eValidForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }