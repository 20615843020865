<template>
    <div>
        <div class="client__header pa-2">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    {{`LABORATORIOS (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center client__header-block-second">
                <OSearchHeaderComp
                    label="Buscar"
                    v-model="search"
                    class="mr-1"
                    @entersearch="eSearchLaboratories()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-plus"
                    class="mr-1"
                    smsToolTip="Registrar laboratorio"
                    @click="eOpenForm()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    smsToolTip="Volver a laboratorios"
                    @click="eBackTableList()"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="laboratoriesList.length==0 && !dialogLoaderData && mobile"
                />
                <STableLaboratoriesComp
                    v-if="!dialogLoaderData && !mobile"
                    :laboratoriesList="laboratoriesList"
                    :search="searchFilter"
                    @clickedit="eOpenFormEdit"
                    @clickdelete="eConfirmDelete"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderData && mobile"
                >
                    <SCardLaboratoyComp
                        class="ma-2"
                        :search="searchFilter"
                        v-for="laboratory in laboratoriesList"
                        :key="laboratory.id"
                        :laboratory="laboratory"
                        @clickedit="eOpenFormEdit(laboratory)"
                        @clickdelete="eConfirmDelete(laboratory)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="mobile && !dialogLoaderData && laboratoriesList.length>0"
                ></v-divider>
                <SPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderData && laboratoriesList.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center mt-3 pl-2 pr-2 pb-2">
                    <SFormLaboratoryComp
                        :dataLaboratory="dataLaboratory"
                        :mobile="mobile"
                        :indexLaboratory="indexLaboratory"
                        ref="formlaboratorycomp"
                        @saveform="eConfirmForm"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="eCloseDialogConfirm()"
            @clickyes="ePerformDatabaseAction()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OSearchHeaderComp,
    OLoaderDataComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp
} from "../../components/organisms";
import {
    SPaginationComp,
    STableLaboratoriesComp,
    SFormLaboratoryComp,
    SCardLaboratoyComp
} from "../../components/species";
import {
    animationScrollMixin,
    dataGeneralCompsMixin,
    responseServerMixin,
    rulesMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import {
    Laboratory
} from "../../models";
export default {
    mixins: [
        animationScrollMixin,
        dataGeneralCompsMixin,
        responseServerMixin,
        rulesMixin,
        userMixin,
        datesMixin
    ],
    props: {
        mini: Boolean
    },
    components: {
        OBtnToolTipHeaderComp,
        OSearchHeaderComp,
        MNoDataComp,
        OLoaderDataComp,
        SPaginationComp,
        STableLaboratoriesComp,
        SFormLaboratoryComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        SCardLaboratoyComp
    },
    data: () => ({
        tab: 0,
        search: "",
        laboratoriesList: [],
        dataLaboratory: new Laboratory(),
        indexLaboratory: -1,
        idLaboratory: 0
    }),
    computed: {
        searchFilter: function() {
            return this.search === null ? "" : this.search;
        }
    },
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else {
                if (this.idLaboratory > 0) {
                    this.idLaboratory = 0;
                    this.indexLaboratory = -1;
                }
                this.dialogError = false;
            }
        },
        handleKeyUpLabs(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 0) this.eCloseDialogConfirm();
                if (actionKeyBoard === 1) this.ePerformDatabaseAction();
                if (actionKeyBoard === 2) this.eCloseError();
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getLaboratories(page, search) {
            this.dialogLoaderData = true;
            try {
                const response = await Laboratory.getLaboratories(page, search);
                this.laboratoriesList = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderData = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eBackTableList() {
            if (this.tab !== 0) {
                this.indexLaboratory = -1;
                this.dataLaboratory = new Laboratory();
                this.$refs.formlaboratorycomp.resetForm();
                this.dialogLoaderOperation = false;
                this.tab = 0;
            }
        },
        eSearchLaboratories() {
            if (this.mRuleEmptyField(this.search)) this.search = "";
            this.getLaboratories(1, this.search);
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getLaboratories(this.currentPage, this.search);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mDataGeneralSearchForPage(page, this.mRuleNumberInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(this.mAnimationScrollGetTopNode(), this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getLaboratories(page, this.search);
                }, time);
            }
        },
        eOpenFormEdit(laboratory) {
            this.dataLaboratory = Object.assign({}, laboratory);
            this.indexLaboratory = this.laboratoriesList.indexOf(laboratory);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
                setTimeout(() => {
                    this.$refs.formlaboratorycomp.activateFocus();
                }, 150);
            }, time);
        },
        eConfirmDelete(laboratory) {
            this.indexLaboratory = this.laboratoriesList.indexOf(laboratory);
            this.idLaboratory = laboratory.id;
            this.smsConfirm = [`¿Está seguro(a) de eliminar el laboratorio: ${laboratory.laboratory}?`];
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.dialogConfirm = true;
            }, time);
        },
        eCloseDialogConfirm() {
            if (this.idLaboratory > 0) {
                this.idLaboratory = 0;
                this.indexLaboratory = -1;
            }
            this.dialogConfirm = false;
        },
        eConfirmForm(isValidForm) {
            const smsConfirm = this.indexLaboratory === -1
                ? [`¿Está seguro(a) de registrar el lab.: ${this.dataLaboratory.laboratory}?`]
                : [`¿Está seguro(a) de editar el lab.: ${this.dataLaboratory.laboratory}?`];
            this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
        },
        async addLaboratory() {
            try {
                const response = await Laboratory.addLaboratory(this.dataLaboratory);
                this.laboratoriesList.unshift(response);
                this.total += 1;
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se registró el laboratorio"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async editLaboratory() {
            try {
                const response = await Laboratory.editLaboratory(this.dataLaboratory);
                Object.assign(this.laboratoriesList[this.indexLaboratory], response);
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se editó el laboratorio"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async deleteLaboratory() {
            try {
                await Laboratory.deleteLaboratory({id: this.idLaboratory});
                this.laboratoriesList.splice(this.indexLaboratory, 1);
                this.total -= 1;
                this.idLaboratory = 0;
                this.indexLaboratory = -1;
                this.dialogLoaderOperation = false;
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se eliminó el laboratorio"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (this.idLaboratory > 0)
                this.deleteLaboratory();
            else {
                if (this.indexLaboratory === -1)
                    this.addLaboratory();
                else this.editLaboratory();
            }
        },
        eOpenForm() {
            this.tab = 1;
            setTimeout(() => {
                this.$refs.formlaboratorycomp.activateFocus();
            }, 150);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategorie() === "0") {
                window.addEventListener('keyup', this.handleKeyUpLabs);
                this.getLaboratories(1, "");
            } else this.$router.replace('/home');
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpLabs);
    }
}
</script>