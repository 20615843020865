<template>
    <div class="s-form-product">
        <h1 class="app-title text-center mb-1">
            {{titleForm}}
        </h1>
        <v-form
            v-model="valid"
            lazy-validation
            ref="formpayment"
        >
            <ORowFormComp
                :mobile="mobile"
                informationRow="Pago a realizar:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Pago a realizar' : ''"
                    v-model="dataPayment.payment"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El pago es requerido',
                        value => this.mRuleNumberDecimal(value) || 'El pago debe ser un número'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Glosa:"
            >
                <MTextAreaComp
                    :label="mobile ? 'Glosa' : ''"
                    v-model="dataPayment.glos"
                    :counter="150"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'La glosa es requerida',
                        value => (`${value}`).length<=150 || 'La glosa, no debe exceder los 150 dígitos'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="Fecha Destino:"
            >
                <MTextFieldComp
                    :label="mobile ? 'Fecha Destino' : ''"
                    v-model="dataPayment.destination_date"
                    type="date"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'La fecha es requerida'
                    ]"
                />
            </ORowFormComp>
            <ORowFormComp
                :mobile="mobile"
                informationRow="N° Caja:"
            >
                <MSelectComp
                    :label="mobile ? 'N° Caja' : ''"
                    v-model="dataPayment.fk_cash_register"
                    :items="cashRegisterList"
                    itemValue="id"
                    itemText="cash_register"
                    :rules="[
                        value => !this.mRuleEmptyField(value) || 'El dato es requerido'
                    ]"
                />
            </ORowFormComp>
        </v-form>
        <div class="d-flex justify-center mt-1">
            <MBtnNormalComp
                @click="eValidForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp,
    MTextAreaComp,
    MSelectComp
} from "../molecules";
import { ORowFormComp } from "../organisms";
import { rulesMixin } from "../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        MTextFieldComp,
        MBtnNormalComp,
        ORowFormComp,
        MTextAreaComp,
        MSelectComp
    },
    props: {
        titleForm: String,
        mobile: Boolean,
        dataPayment: Object,
        cashRegisterList: Array
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidForm() {
            if (this.$refs.formpayment.validate())
                this.$emit('saveform', true);
            else this.$emit('saveform', false);
        },
        resetForm() {
            this.$refs.formpayment.resetValidation();
        }
    }
}
</script>