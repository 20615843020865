import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from '../store/modules';

class Setting {
    constructor (
        low_inventory = 0,
        regular_inventory = 0
    ) {
        this.low_inventory = low_inventory;
        this.regular_inventory = regular_inventory;
    }
    static async getSetting() {
        const response =  await axios.get(
            `${apiUrls.SETTING}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async updateSetting(parameters) {
        const response =  await axios.put(
            `${apiUrls.SETTING}/update`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Setting;