<template>
    <v-dialog
        persistent
        :value="valueDialog"
        max-width="500"
    >
        <v-card
            min-height="300"
        >
            <div class="s-card-client__header pl-2 pr-2 d-flex align-center">
                <h1 class="app-title">
                    SELECCIONAR PRODUCTO
                </h1>
                <v-spacer></v-spacer>
                <OBtnCardComp
                    icon="mdi-arrow-left-bold"
                    class="mr-1"
                    @click="eBackListProducts()"
                />
                <OBtnCardComp
                    icon="mdi-close"
                    @click="$emit('clickclose')"
                />
            </div>
            <div class="client__line-header"></div>
            <v-tabs-items
                style="background:transparent !important"
                v-model="tab"
                :touchless="true"
            >
                <v-tab-item>
                    <div class="pa-2">
                        <OSearchHeaderComp
                            label="Buscar"
                            ref="search"
                            :value="value"
                            @input="(e)=>(this.$emit('input', e))"
                            @entersearch="$emit('entersearch')"
                        />
                    </div>
                    <v-divider></v-divider>
                    <MNoDataComp
                        smsNotData="SIN DATOS"
                        class="ma-1"
                        v-if="productsList.length==0 && !dialogLoaderData"
                    />
                    <div class="pa-2 pt-0">
                        <OLoaderDataComp
                            class="mt-2"
                            v-if="dialogLoaderData"
                            smsLoaderData="Obteniendo Información"
                        />
                        <div v-else>
                            <div
                                class="mt-2"
                                v-for="product in productsList"
                                :key="product.id"
                            >
                                <v-hover
                                    v-slot="{ hover }"
                                >
                                    <v-card
                                        :class="hover ? 'fondo-btn-cabecera pa-1 pl-2 pr-2' : 'pa-1 pl-2 pr-2'"
                                        :elevation="hover ? 1 : 0"
                                        outlined
                                        @click="eSelectProduct(product)"
                                    >
                                        <text-highlight
                                            :class="hover ? 'app-subtitle-dark' : 'app-subtitle'"
                                            :queries="searchProduct"
                                        >
                                            {{product.product}}
                                        </text-highlight>
                                    </v-card>
                                </v-hover>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div>
                        <div v-if="product.id != undefined" class="pa-2 pt-1 pb-1">
                            <div class="d-flex">
                                <h1 class="app-title mr-1">Producto:</h1>
                                <h1 class="app-subtitle">
                                    {{product.product}}
                                </h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="app-title mr-1">Laboratorio:</h1>
                                <h1 class="app-subtitle">
                                    {{product.laboratory.laboratory}}
                                </h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="app-title mr-1">Precio Venta:</h1>
                                <h1 class="app-subtitle">
                                    {{`${product.sale_price.toFixed(2)} Bs.`}}
                                </h1>
                            </div>
                        </div>
                        <v-divider></v-divider>
                        <div class="pa-2 pb-3 pt-4">
                            <SFormProductPurchaseComp
                                :dataProductPurchase="dataProductPurchase"
                                ref="formproductpurchasecomp"
                                titleForm="AGREGAR"
                                @saveform="eSendDataProduct"
                            />
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-dialog>
</template>
<script>
import { MNoDataComp } from "../molecules";
import { SFormProductPurchaseComp } from "../species";
import TextHighlight from "vue-text-highlight";
import {
    OBtnCardComp,
    OSearchHeaderComp,
    OLoaderDataComp
} from "../organisms";
export default {
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: [String, Number],
        valueDialog: Boolean,
        productsList: Array,
        searchProduct: String,
        dialogLoaderData: Boolean,
        dataProductPurchase: Object
    },
    components: {
        OBtnCardComp,
        OSearchHeaderComp,
        TextHighlight,
        OLoaderDataComp,
        MNoDataComp,
        SFormProductPurchaseComp
    },
    data: () => ({
        tab: 0,
        product: {}
    }),
    methods: {
        eSelectProduct(product) {
            this.product = product;
            this.dataProductPurchase.product_id = product.id;
            this.dataProductPurchase.product = product.product;
            this.tab = 1;
            setTimeout(() => {
                this.$refs.formproductpurchasecomp.activateFocus();
            }, 150);
        },
        eSendDataProduct(isValidForm) {
            this.$emit('selectproduct', isValidForm);
        },
        eBackListProducts() {
            if (this.tab !== 0) {
                this.$refs.formproductpurchasecomp.resetForm();
                this.tab = 0;
            }
        },
        activateFocus() {
            this.$refs.search.activateFocus();
        }
    }
}
</script>