<template>
    <div>
        <div class="pa-2 d-flex align-center">
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    v-if="!mobile"
                    :icono="mini ? 'mdi-arrow-right-bold-box' : 'mdi-arrow-left-bold-box'"
                    smsToolTip="Ocultar Menú"
                    @click="$emit('clickmini')"
                />
                <h1 :class="mobile ? 'app-title' : 'app-title ml-2'">
                    {{`GASTOS (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <OBtnToolTipHeaderComp
                    icono="mdi-plus"
                    class="mr-1"
                    smsToolTip="Registrar gasto"
                    @click="eOpenFormExpense()"
                />
                <OBtnToolTipHeaderComp
                    icono="mdi-database"
                    smsToolTip="Volver a gastos"
                    @click="eBackTableList()"
                />
            </div>
        </div>
        <div class="client__line-header" id="position-scroll"></div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    smsNotData="SIN DATOS"
                    class="ma-1"
                    v-if="expensesList.length==0 && !dialogLoaderData && mobile"
                />
                <STableExpensesComp
                    v-if="!dialogLoaderData && !mobile"
                    :expensesList="expensesList"
                    @clickedit="eOpenFormEdit"
                />
                <div
                    class="d-flex justify-center flex-wrap"
                    v-if="!dialogLoaderData && mobile"
                >
                    <SCardExpenseComp
                        class="ma-2"
                        v-for="expense in expensesList"
                        :key="expense.id"
                        :expense="expense"
                        @clickedit="eOpenFormEdit(expense)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center mt-3 pl-2 pr-2 pb-2">
                    <SFormExpenseComp
                        ref="formexpensecomp"
                        :dataExpense="dataExpense"
                        :mobile="mobile"
                        :indexExpense="indexExpense"
                        @saveform="eConfirmForm"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OSmsConfirmationComp
            :value="dialogConfirm"
            :smsConfirm="smsConfirm"
            :mobile="mobile"
            @clickno="dialogConfirm=false"
            @clickyes="ePerformDatabaseAction()"
        />
        <OSmsErrorComp
            :smsError="smsError"
            :value="dialogError"
            :actionError="actionError"
            :mobile="mobile"
            @click="eCloseError()"
        />
        <OSmsActionComp
            :value="frameCorrect"
            :colorMessage="colorMessage"
            :smsAction="smsAction"
            :timeSucess="timeSucess"
            @click="mDataGeneralFrameTimeReset()"
        />
        <OOperationLoaderComp
            smsLoaderOperation="Realizando Operación"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    MNoDataComp
} from "../../components/molecules";
import {
    OBtnToolTipHeaderComp,
    OLoaderDataComp,
    OSmsConfirmationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OOperationLoaderComp
} from "../../components/organisms";
import {
    STableExpensesComp,
    SFormExpenseComp,
    SCardExpenseComp
} from "../../components/species";
import {
    Expense
} from "../../models";
import {
    dataGeneralCompsMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
export default {
    mixins: [
        dataGeneralCompsMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    components: {
        OBtnToolTipHeaderComp,
        OLoaderDataComp,
        MNoDataComp,
        STableExpensesComp,
        SFormExpenseComp,
        OSmsConfirmationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OOperationLoaderComp,
        SCardExpenseComp
    },
    props: {
        mini: Boolean
    },
    data: () => ({
        tab: 0,
        expensesList: [],
        dataExpense: new Expense,
        indexExpense: -1,
        idCashRegister: 0
    }),
    methods: {
        eCloseError() {
            if (this.noAccess.withoutAutorization)
                this.mDataGeneralActionWithOutAutorization();
            else {
                if (this.idCashRegister === 0)
                    this.$router.replace('/home');
                else this.dialogError = false;
            }
        },
        handleKeyUpExpense(event) {
            if (!this.mobile) {
                const actionKeyBoard = this.mDataGeneralActionKeyboard(event.keyCode);
                if (actionKeyBoard === 1) this.ePerformDatabaseAction();
            }
        },
        processErrorServer(error) {
            this.dialogLoaderOperation = false;
            this.noAccess = this.mResponseServeAutorization(error);
            this.smsError = this.mResposeServerSmsError(error);
            this.actionError = this.mResponseServeAction(error);
            this.dialogError = true;
        },
        async getExpenses() {
            this.dialogLoaderData = true;
            try {
                const response = await Expense.getExpenses(this.idCashRegister);
                this.expensesList = response;
                this.total = response.length;
                this.dialogLoaderData = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eBackTableList() {
            if (this.tab !== 0) {
                this.tab = 0;
                this.indexExpense = -1;
                this.dataExpense = new Expense;
                this.$refs.formexpensecomp.resetForm();
                this.dialogLoaderOperation = false;
            }
        },
        eConfirmForm(isValidForm) {
            this.dataExpense.fk_cash_register = 1;
            const valueExpense = isValidForm ? parseFloat(this.dataExpense.expense).toFixed(2) : '0.00';
            const smsConfirm = this.indexExpense === -1
                ? [`¿Está seguro(a) de registrar el gasto: ${valueExpense} Bs.?`]
                : [`¿Está seguro(a) de editar el gasto: ${valueExpense} Bs.?`];
            this.mDataGeneralConfirmForm(isValidForm, smsConfirm);
        },
        async addExpense() {
            try {
                const response = await Expense.addExpense(this.dataExpense);
                this.expensesList.unshift(response);
                this.total += 1;
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se registró el gasto"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async editExpense() {
            try {
                const response = await Expense.editExpense(this.dataExpense);
                Object.assign(this.expensesList[this.indexExpense], response);
                this.eBackTableList();
                this.mDataGeneralShowSmsAction(["Operación Exitosa", "Se editó el gasto"], "success");
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        ePerformDatabaseAction() {
            this.dialogConfirm = false;
            this.dialogLoaderOperation = true;
            if (this.indexExpense === -1)
                this.addExpense();
            else this.editExpense();
        },
        eOpenFormEdit(expense) {
            this.indexExpense = this.expensesList.indexOf(expense);
            this.dataExpense = Object.assign({}, expense);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
                setTimeout(() => {
                    this.$refs.formexpensecomp.activateFocus();
                }, 150);
            }, time);
        },
        validateOptions() {
            if (JSON.parse(localStorage.getItem("device"))) {
                this.idCashRegister = JSON.parse(localStorage.getItem("device")).id_cash_register;
                this.getExpenses();
            } else {
                this.smsError = [
                    `Este dispositivo no fue habilitado para funcionar como caja registradora de ventas`,
                    `La información que quiere acceder puede revisarse en la opción "CONTROL VENTAS ADMIN"`
                ];
                this.actionError = true;
                this.dialogError = true;
            }
        },
        eOpenFormExpense() {
            this.tab = 1;
            setTimeout(() => {
                this.$refs.formexpensecomp.activateFocus();
            }, 150);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            window.addEventListener('keyup', this.handleKeyUpExpense);
            this.validateOptions();
        } else this.$router.replace('/login');
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUpExpense);
    }
}
</script>