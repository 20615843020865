<template>
    <div class="d-flex align-center">
        <v-icon class="mr-1 s-menu-pc__list-icon">mdi-hand-pointing-right</v-icon>
        <h1 class="app-title-dark">{{smsToolTip}}</h1>
    </div>
</template>
<script>
export default {
    props: {
        smsToolTip: String
    }
}
</script>