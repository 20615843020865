<template>
    <v-dialog
        persistent
        :value="value"
        max-width="600"
    >
        <div class="s-detail-product">
            <div v-if="product.id != undefined">
                <div class="s-detail-product__header d-flex align-center">
                    <h1 class="app-title">
                        {{product.product}}
                    </h1>
                    <v-spacer></v-spacer>
                    <OBtnCardComp
                        icon="mdi-close"
                        @click="$emit('clickclose')"
                    />
                </div>
                <div class="s-detail-product__body">
                    <OFirstPartDataProductComp
                        :product="product"
                    />
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Cant. Inventario:</h1>
                        <h1 class="app-subtitle-dark">
                            {{product.quantity+" Unid."}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Reg. Sanitario:</h1>
                        <h1 class="app-subtitle-dark">
                            {{product.health_register!=null ? product.health_register : "S/RS"}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Vía Administración:</h1>
                        <h1 class="app-subtitle-dark">
                            {{product.via_administration!=null ? product.via_administration : "S/C"}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Composición:</h1>
                        <h1 class="app-subtitle-dark">
                            {{product.composition!=null ? product.composition : "S/C"}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Contraindicación:</h1>
                        <h1 class="app-subtitle-dark">
                            {{product.contraindications!=null ? product.contraindications : "S/C"}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="app-title-dark mr-1">Descripción:</h1>
                        <h1 class="app-subtitle-dark">
                            {{product.description!=null ? product.description : "S/D"}}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import {
    OBtnCardComp,
    OFirstPartDataProductComp
} from "../organisms";
export default {
    components: {
        OBtnCardComp,
        OFirstPartDataProductComp
    },
    props: {
        value: Boolean,
        product: Object
    }
}
</script>
